import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { AuthenticationService } from '../_services/authentication.service'
import { TranslateService } from '../_services/translate.service'

@Injectable()
export class BasicAuthInterceptor implements HttpInterceptor {
  public currrentLang: string = localStorage.getItem('language');
  constructor(private authenticationService: AuthenticationService,
    private translate: TranslateService) {}
  intercept(request: HttpRequest < any > , next: HttpHandler): Observable < HttpEvent < any >> {
    const currentUserDetails = this.authenticationService.currentUserValue;
    const currentLang = this.translate.currentLanguageValue;
    console.log("xyz",currentLang)
    if (currentUserDetails && currentUserDetails.token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUserDetails.token}`,
          language: currentLang.language
        }
      });
    } else {
      request = request.clone({
        setHeaders: {
          language: currentLang.language
        }
      });
    }
    return next.handle(request);
  }
}
