import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
declare var $: any;
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-report-view',
  templateUrl: './report-view.component.html',
  styleUrls: ['./report-view.component.scss']
})
export class ReportViewComponent implements OnInit {

  id: any;
  page: any;
  public todayDate: any = new Date();
  response: any;
  cnssReport: any;
  purpose: any;
  employerDetails : any;  
  reportAllList: any;  
  constructor(private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private snackBar: MatSnackBar) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.page = this.router.getCurrentNavigation().extras.state.data;
      console.log("19", this.page)
    }
  }

  ngOnInit() {
    console.log("20", this.page)
    this.route.params.subscribe(
      (params: Params) => {
        console.log("36", params['id']);
        console.log("37", params);
        if (params['id'] != 'cnamgs') {
          this.id = params['id'];
         this.purpose = params['purpose'];
          this.getReportsbyID()
          switch(this.id) {
            case 'normalcnss': {
                console.log("45 normalcnss")
                $('.cnamgs_report').addClass('dNone');
                $('.payment_table').addClass('dNone');
                $('.dateTerminateInput').removeClass('dNone');
                $('.dateTerminate').removeClass('dBlock');

                $('.pendingStatus').removeClass('dBlock');
                $('.ongoingStatus').removeClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.completedStatus').addClass('dNone');

                $('input').removeClass('changeInput_style');
                $('.tableFooter').removeClass('dNone');
                $('#resubMit_btn').removeClass('d-block');
                $('#submit_btn').addClass('d-block');
                break;
            }
            case 'pendingcnss': {
               console.log("64 pendingcnss")
                $('.cnamgs_report').addClass('dNone');
                $('.payment_table').addClass('dNone');
                //$('.dateTerminateInput').addClass('dNone');
                $('.dateTerminate').addClass('dBlock');

                $('.pendingStatus').addClass('dBlock');
                $('.ongoingStatus').addClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.completedStatus').addClass('dNone');
                // $('input').prop('disabled', true);
                $('input').addClass('changeInput_style');
                $('.tableFooter').addClass('dNone');
                break;
            }
            case 'payNowCnss': {
                 console.log("81 payNowCnss")
                $('.cnamgs_report').addClass('dNone');

                $('.payment_table').addClass('dInTable');
                $('.quarter_table').addClass('dNone');

                $('.pendingStatus').addClass('dNone');
                $('.ongoingStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.payNowStatus').addClass('dBlock');
                $('.completedStatus').addClass('dNone');

                $('.tableFooter').addClass('dNone');
                $('.tableFooter2').addClass('d-block');
                $('.tableFooter3').addClass('dNone');
                break;
            }
            case 'completedCnss': {
                console.log("81 completedCnss")
                $('.cnamgs_report').addClass('dNone');

                $('.payment_table').addClass('dInTable');
                $('.quarter_table').addClass('dNone');

                $('.pendingStatus').addClass('dNone');
                $('.ongoingStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.completedStatus').addClass('dBlock');

                $('.tableFooter').addClass('dNone');
                $('.tableFooter2').addClass('dNone');
                $('.tableFooter3').addClass('d-block');
                break;
            }
            case 'rejectedCnss': {
              console.log("117 rejectedCnss")
                $('.cnamgs_report').addClass('dNone');
                $('.payment_table').addClass('dInTable');
                $('.dateTerminateInput').removeClass('dNone');
                $('.dateTerminate').removeClass('dBlock');
                $('.quarter_table').addClass('dNone');
                $('.pendingStatus').addClass('dNone');
                $('.ongoingStatus').addClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dBlock');
                $('.completedStatus').addClass('dNone');
                $('.rejectedNote').addClass('d-block');
                $('input').removeClass('changeInput_style');
                $('.tableFooter').removeClass('dNone'); 
                break;

            }
          }
        } else {
          this.page = params['id'];
          console.log("114", this.page)
        }
      });
    // if (this.route.snapshot.paramMap.get('purpose')) {
    //   this.purpose = this.route.snapshot.paramMap.get('purpose');
    //   console.log("139", this.purpose)
    // }
  }

  getReportsbyID() {
    this.ngxUiLoaderService.start();
    this.api.getReportsbyID(this.purpose).pipe(first())
      .subscribe(data => {
          this.response = data;
          this.ngxUiLoaderService.stop();
          if (this.response.status == 200) {
            this.employerDetails = this.response.results.data;
            this.cnssReport = this.response.results.quarter;
            this.reportAllList = this.response.results
            console.log("152", this.cnssReport)
            console.log("153", this.employerDetails)
            console.log("154", this.employerDetails.employer_cnss)
          } else {
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }

  paymentSubmit() {
    this.router.navigate(['/payment/cnss']);
  }
  showCnssList() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    $('#reportModal').modal('hide');
    this.id = 'normalcnss';
    switch (this.id) {
      case 'normalcnss':
        {
          console.log('aa')
          $('.cnamgs_report').addClass('dNone');
          $('.payment_table').addClass('dNone');
          $('.dateTerminateInput').removeClass('dNone');
          $('.dateTerminate').removeClass('dBlock');

          $('.pendingStatus').removeClass('dBlock');
          $('.ongoingStatus').removeClass('dNone');
          $('.payNowStatus').addClass('dNone');
          $('.rejectedStatus').addClass('dNone');
          $('.completedStatus').addClass('dNone');

          $('input').removeClass('changeInput_style');
          $('.tableFooter').removeClass('dNone');
          $('#resubMit_btn').removeClass('d-block');
          $('#submit_btn').addClass('d-block');
          break;
        }
    }

  }
  goToViewPage() {
    $('#successModal').modal('hide');

    this.router.navigate(['/reportList'], { state: { data: this.page } });
  }

  goback() {
    this.router.navigate(['/reportList'], { state: { data: this.page } });
  }
  

}
