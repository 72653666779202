<app-header></app-header>
<section class="container-fluid mb-5">
  <div class="initiale_sestion">
    <div class="dFlex">
      <div class="head" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1500">
        <h4 class="form-title-sm">{{'Profile' | translate}}</h4>
      </div>
    </div>
  </div>
  <div class="profile_dash">
    <div class="grid-col">
      <div class="col_1 bgStyle" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1500">
        <div class="details">
          <figure>
            <div *ngIf="!employee" class="image_container">
              <img src="assets/images/company_logo_default.png" alt="" class="img-fluid">
            </div>
            <img *ngIf="employee && accountDetails.image_url" src="{{ accountDetails.image_url }}" alt="profile pic" class="img-fluid">
            <img *ngIf="employee && !accountDetails.image_url" src="assets/images/default_user.png" alt="" class="img-fluid">
            <figcaption>
              <!--  <h3  *ngIf="!employee">Baywing Pvt Ltd</h3>
                                    <h3  *ngIf="employee">Ronin Malof</h3> -->
              <i class="fa-solid fa-pen-to-square"></i>
            </figcaption>
          </figure>
          <h4 *ngIf="!employee" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1500">{{ accountDetails.company_name }}</h4>
          <h4 *ngIf="employee" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1500">{{ accountDetails.first_name }} {{ accountDetails.last_name }}</h4>
          <!-- <h6 class="colour1" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1500">{{'Company' | translate}} : Baywing Pvt Ltd</h6> -->
          <h6 data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1500">+241 {{ accountDetails.mobile }}</h6>
        </div>
        <div class="sideOptions">
          <div class="sidemenu " [ngClass]="{'enabled': !bankDetails}" (click)="open('sidenav1')"><span>{{'General' | translate}}</span></div>
          <div class="sidemenu" data-toggle="modal" data-target="#signinModal">{{'Change Password' | translate}}</div>
          <div *ngIf="!employee" class="sidemenu" class="sidenav3" (click)="open('sidenav3')" [ngClass]="{'enabled': bankDetails}"><span>{{'Bank Details' | translate}}</span></div>
          <div (click)="logout()">{{'Logout' | translate}}</div>
        </div>
      </div>
      <div class="col_2 bgStyle" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1500" *ngIf="!bankDetails">
        <h2>{{'Profile Details' | translate }}</h2>
        <div class="currentStatus" data-aos="zoom-out" data-aos-delay="500" data-aos-duration="1500">
          <div class="table">
            <div class="td_1">
              <div *ngIf="employee" class="row line">
                <div class="col-md-4 col-4">
                  <h6 class="label-txt">{{'CCPS ID' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 *ngIf="employee" class="text-md">{{accountDetails.ccps_code}}</h5>
                  <h5 *ngIf="!employee" class="text-md">{{accountDetails.ccps_id}}</h5>
                </div>
              </div>
              <div class="row line">
                <div class="col-md-4 col-4">
                  <h6 *ngIf="employee" class="label-txt">{{'Employee ID' | translate}}</h6>
                  <h6 *ngIf="!employee" class="label-txt">{{'Employer ID' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 *ngIf="employee" class="text-md">{{ accountDetails.employee_id }}</h5>
                  <h5 *ngIf="!employee" class="text-md">{{ accountDetails.employer_id }}</h5>
                </div>
              </div>
              <div class="row line">
                <div class="col-md-4 col-4">
                  <h6 class="label-txt">{{'CNAMGS ID' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 *ngIf="employee" class="text-md">{{ accountDetails.cnamgs }}</h5>
                  <h5 *ngIf="!employee" class="text-md">{{ accountDetails.cnamgs_id }}</h5>
                </div>
              </div>
              <div class="row line">
                <div class="col-md-4 col-4">
                  <h6 class="label-txt">{{'CNSS ID' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 *ngIf="employee" class="text-md">{{ accountDetails.cnss}}</h5>
                  <h5 *ngIf="!employee" class="text-md">{{ accountDetails.cnss_id }}</h5>
                </div>
              </div>
              <div class="row line">
                <div class="col-md-4 col-4">
                  <h6 class="label-txt">{{'Address' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 class="text-md">{{ accountDetails.address }}</h5>
                </div>
              </div>
              <div class="row line">
                <div class="col-md-4 col-4">
                  <h6 class="label-txt">{{'Registered' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 *ngIf="employee" class="text-md">{{ accountDetails.join_date }}</h5>
                  <h5 *ngIf="!employee" class="text-md">{{ accountDetails.registered }}</h5>
                </div>
              </div>
              <div class="row line">
                <div class="col-md-4 col-4">
                  <h6 class="label-txt">{{'Email Id' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 *ngIf="employee" class="text-md">{{accountDetails.email}}</h5>
                  <h5 *ngIf="!employee" class="text-md">{{accountDetails.email}}</h5>
                </div>
              </div>
              <div *ngIf="!employee && accountDetails.cnss" class="row line">
                <div class="col-md-12">
                  <div class="card-export">
                    <div class="image_shadow cursor_pointor" (click)="openDoc()"><img src="/assets/images/download.png" class="img-fluid"></div>
                    <h6 class="label-txt">{{'Approved CNSS form Can be downloaded from below' | translate}}</h6>
                    <button (click)="openDoc()" class="btn-medium">{{'Download' | translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col_2 bgStyle profile_bank_section profile_page" *ngIf="accountDetails.GNI == '1' && bankDetails" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1500">
        <div class="currentStatus">
          <div class="row">
            <div class="col-md-12 mt-4 mb-4 text-center">
              <div id="tab-parent">
                <div class="tabs">
                  <input type="radio" id="radio-1" name="tabs" checked />
                  <label class="tabq" for="radio-1">{{'Bank / Mobile Money'| translate}}</label>
                  <input type="radio" id="radio-2" name="tabs" />
                  <!-- <label class="tabq" for="radio-2" (click)="open('money')">{{'Mobile Money'| translate}}</label> -->
                  <span class="glider"></span>
                </div>
              </div>
            </div>
          </div>
          <form id="bankDetailsForm" *ngIf="bankDetails" [formGroup]="bankDetailsForm" (ngSubmit)="onSubmitBankForm()">
            <div class="line">
              <h4>{{'Bank Account Details'|translate}}</h4>
            </div>
            <div class="enroll_form">
              <div>
                <div class="input_section">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12">
                        <label for=""> {{'Select Bank'| translate}}</label>
                        <div class="grid-parent">
                          <div class="grid-container">
                            <div class="grid-child" *ngFor="let bank of bankdata">
                              <input type="radio" id="bank{{ bank.bank_id }}" name="banks" formControlName="banks" value="{{ bank.bank_id }}"  (change)="handleChange($event, bank.bank_name, bank.bank_id)">
                              <label for="bank{{ bank.bank_id }}">
                                <img src="{{ bank.bank_logo }}">
                              </label>
                            </div>
                          </div>
                        </div>
                        <div *ngIf="isSubmitted && g.banks.errors" class="invalid-feedback">
                          <div *ngIf="g.banks.errors?.required">{{'Bank'|translate}} {{'is required'|translate}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 mt-3">
                        <label for="">{{'Account number'|translate}}</label>
                        <input type="text" formControlName="account_number">
                        <div *ngIf="isSubmitted && g.account_number.errors" class="invalid-feedback">
                          <div *ngIf="g.account_number.errors?.required">{{'Account number'|translate}} {{'is required'|translate}}</div>
                          <div *ngIf="g.account_number.errors?.pattern">{{'Account number'|translate}} {{'should be valid'|translate}}</div>
                        </div>
                      </div>
                      <div class="col-md-6 mt-3">
                        <label for="">{{'Account name'|translate}}</label>
                        <input type="text" formControlName="account_name">
                        <div *ngIf="isSubmitted && g.account_name.errors" class="invalid-feedback">
                          <div *ngIf="g.account_name.errors?.required">{{'Account name'|translate}} {{'is required'|translate}}</div>
                          <div *ngIf="g.account_name.errors?.pattern">{{'Account name'|translate}} {{'should be valid'|translate}}</div>
                        </div>
                      </div>
                      <div class="col-md-6 mt-3">
                        <label for="">{{'Bank name'|translate}}</label>
                        <input type="text" formControlName="bank_name">
                        <div *ngIf="isSubmitted && g.bank_name.errors" class="invalid-feedback">
                          <div *ngIf="g.bank_name.errors?.required">{{'Bank name'|translate}} {{'is required'|translate}}</div>
                          <div *ngIf="g.bank_name.errors?.pattern">{{'Bank name'|translate}} {{'should be valid'|translate}}</div>
                        </div>
                      </div>
                      <div class="col-md-6 mt-3">
                        <label for="">{{'Bank number'|translate}}</label>
                        <input type="text" formControlName="bank_number">
                        <div *ngIf="isSubmitted && g.bank_number.errors" class="invalid-feedback">
                          <div *ngIf="g.bank_number.errors?.required">{{'Bank number'|translate}} {{'is required'|translate}}</div>
                          <div *ngIf="g.bank_number.errors?.pattern">{{'Bank number'|translate}} {{'should be valid'|translate}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 mt-4 mb-4 text-center">
                        <button type="submit" id="submit_btn" class="btn btn-main">{{'Save Changes' | translate}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <!--           <form id="mobileMoneyForm" *ngIf="mobileMoney" [formGroup]="mobileMoneyForm" (ngSubmit)="onSubmitMobileMoneyForm()">
            <div class="line">
              <h4>{{'Mobile Money Account Details' | translate}}</h4>
            </div>
            <div class="enroll_form">
              <div>
                <div class="input_section">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12">
                        <label for=""> {{'Select Bank'| translate}}</label>
                        <div class="grid-container">
                          <div class="grid-child" *ngFor="let moneybank of moneydata">
                            <input type="radio" id="mobile_money{{ moneybank.id }}" name="mobile_money" formControlName="mobile_money" value="{{ moneybank.value }}" (change)="handleChange($event, moneybank.name, 2)">
                            <label for="mobile_money{{ moneybank.id }}">
                              <img src="{{ moneybank.image }}">
                            </label>
                          </div>
                        </div>
                        <div *ngIf="isSubmitted && h.mobile_money.errors" class="invalid-feedback">
                          <div *ngIf="h.mobile_money.errors?.required">{{'Bank'|translate}} {{'is required'|translate}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 mt-3">
                        <label for="">{{'Account name'|translate}}</label>
                        <input type="text" formControlName="mobile_money_account_name">
                        <div *ngIf="isSubmitted && h.mobile_money_account_name.errors" class="invalid-feedback">
                          <div *ngIf="h.mobile_money_account_name.errors?.required">{{'Account name'|translate}} {{'is required'|translate}}</div>
                          <div *ngIf="h.mobile_money_account_name.errors?.pattern">{{'Account name'|translate}} {{'should be valid'|translate}}</div>
                        </div>
                      </div>
                      <div class="col-md-6 mt-3">
                        <label for="">{{'Account number'|translate}}</label>
                        <input type="text" formControlName="mobile_money_account_number">
                        <div *ngIf="isSubmitted && h.mobile_money_account_number.errors" class="invalid-feedback">
                          <div *ngIf="h.mobile_money_account_number.errors?.required">{{'Account number'|translate}} {{'is required'|translate}}</div>
                          <div *ngIf="h.mobile_money_account_number.errors?.pattern">{{'Account number'|translate}} {{'should be valid'|translate}}</div>
                        </div>
                      </div>
                      <div class="col-md-6 mt-3">
                        <label for="">{{'Facility name'|translate}}</label>
                        <input type="text" formControlName="facility_name">
                        <div *ngIf="isSubmitted && h.facility_name.errors" class="invalid-feedback">
                          <div *ngIf="h.facility_name.errors?.required">{{'Facility name'|translate}} {{'is required'|translate}}</div>
                          <div *ngIf="h.facility_name.errors?.pattern">{{'Facility name'|translate}} {{'should be valid'|translate}}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 mt-4 mb-5 text-center">
                        <button type="submit" id="submit_btn" class="btn btn-main">{{'Save Changes' | translate}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form> -->
        </div>
      </div>
      <div class="col_2 bgStyle profile_bank_section profile_page" *ngIf="accountDetails.GNI == '0'" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1500">
        <div class="parent">
          <div class="row">
            <div class="col-md-6 offset-md-3">
              <div class="image-container">
                <img src="assets/images/link_success.png" class="img-fluid">
              </div>
              <div class="text-container text-center mt-5">
                <h4 class="modal-header-txt">{{'Inorder to Continue' | translate}}</h4>
                <p class="para-light">{{'Please Import Business From GNI First' | translate }}</p>
              </div>
              <div class="button-container justify-content-center mt-4 d-flex">
                <button type="submit" id="submit_btn" class="btn btn-medium" routerLink="/link-business">{{'Import Business' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--  <div  class="col_2 bgStyle mobile_money_section" *ngIf="mobileMoney && !bankDetails" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1500">
                        <div class="currentStatus">
                            
                        </div>
                    </div> -->
    </div>
  </div>
</section>
<app-footer></app-footer>
<section class="modal-section">
  <div class="modal fade-scale" id="signinModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <div class="modal-image-container">
            <h5 class="modal-title title-medium" id="exampleModalLongTitle">{{'CCPS' | translate}}</h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button> -->
          </div>
          <div id="signin" class="text-container">
            <h5 class="title-medium">{{'Change Password' | translate}}</h5>
            <!-- <p>{{'Login to your Account' | translate}}</p> -->
            <form class="form-container" [formGroup]="resetPasswordForm" (ngSubmit)="onSubmitResetPassword()">
              <div class="input-container">
                <div class="child-container">
                  <label class="labeltxt">{{'Current Password' | translate}}</label>
                  <div>
                    <input name="current_password" type="{{ passwordFieldType }}" formControlName="current_password">
                    <span class="eye-icon" (click)="eyeclose()"><i id="eye" class="fa fa-eye" aria-hidden="true"></i></span>
                  </div>
                  <div *ngIf="isSubmitted && f.current_password.errors" class="invalid-feedback">
                    <div *ngIf="f.current_password.errors?.required">{{'Current Password'|translate}} {{'is required'|translate}}</div>
                  </div>
                </div>
                <div class="child-container">
                  <label class="labeltxt">{{'New Password' | translate}}</label>
                  <input type="{{ passwordFieldType }}" name="password" formControlName="password">
                  <div *ngIf="isSubmitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors?.required">{{'Password'|translate}} {{'is required'|translate}}</div>
                    <div *ngIf="f.password.errors.minlength">{{'Password must be at least 8 characters'|translate}}</div>
                    <div *ngIf="f.password.errors?.pattern ">{{'password should be valid' | translate}}</div>
                  </div>
                </div>
                <div class="child-container">
                  <label class="labeltxt">{{'Confirm Password' | translate}}</label>
                  <input type="{{ passwordFieldType }}" name="confirm_Password" formControlName="confirmPassword">
                  <div *ngIf="isSubmitted && f.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors?.required">{{'Confirm Password'|translate}} {{'is required'|translate}}</div>
                    <div *ngIf="f.confirmPassword.errors.ConfirmPassword">{{'Passwords must match' | translate}}</div>
                  </div>
                  <!-- <span class="eye-icon"><i class="fa fa-eye" aria-hidden="true"></i></span> -->
                </div>
              </div>
              <button type="submit" id="submit_btn" class="btn btn-main">{{'Save Password' | translate}}</button>
            </form>
            <!-- <p class="small-txt">{{'No Account' | translate}}? <button id="show-signup" (click)="showSignup()">{{'Sign Up' | translate}}</button></p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-contact-floating></app-contact-floating>
