import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../_services/authentication.service'
import { environment } from '../environments/environment';
import { tap, catchError,map,switchMap, filter, take } from 'rxjs/operators';
import { User } from '../_models/user';
import { ApiService } from '../_services/api.service';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { TranslateService } from '../_services/translate.service'
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  currrentLang: any;
   private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private router: Router,
    private authenticationService: AuthenticationService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private http: HttpClient,
    private api: ApiService,
    private translate: TranslateService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  return next.handle(request).pipe(
    catchError(err => {
      console.log(err);
      if (err && err.status === 401) {
        console.log("30")
        console.log("31",this.isRefreshing)
                if (!this.isRefreshing) {
                    this.isRefreshing = true;
                    this.refreshTokenSubject.next(null);
                    console.log(this.authenticationService.currentUserValue)
                    console.log(this.authenticationService.currentUserValue.reftoken)
                    const token = this.authenticationService.currentUserValue.reftoken; 
                    var data = {
                        token: token
                    }
                    const httpOptions = {
                      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
                      observe: 'response' as 'response'
                    };
                    if (token) {
                      console.log("40")
                        return this.http.post<any>(`${environment.apiUrl}auths/refresh-auth`, data,httpOptions)
                        .pipe(switchMap((user: HttpResponse<any>) => {
                          console.log("update success")
                          console.log(user.body.token)
                            this.isRefreshing = false;
                            console.log(user)
                            if (user.body.status == 200) {
                              this.authenticationService.currentUserSubject.next(user.body);
                              console.log(this.authenticationService.currentUserValue)
                              this.refreshTokenSubject.next(user.body.token);
                              localStorage.setItem('currentUser', JSON.stringify(user.body));
                              console.log("refresh next")
                              console.log(request.url)
                              return next.handle(this.addTokenHeader(request, user.body.token));
                            } else {
                              this.ngxUiLoaderService.stop();
                              console.log("60")
                             
                              this.authenticationService.logout();
                        
                            }
                          }),
                          catchError((err) => {
                            this.ngxUiLoaderService.stop();
                            this.isRefreshing = false;
                            this.authenticationService.logout();
                            console.log("70",err)
                            return throwError(err);
                          })
                        );
                    }
                }
                return this.refreshTokenSubject.pipe(
                    filter(token => token !== null),
                    take(1),
                    switchMap((token) => next.handle(this.addTokenHeader(request, token)))
                );
      }

      const error = err.error.message || err.statusText;
      return throwError(error);
    })
  );
}
      private addTokenHeader(request: HttpRequest<any>, token: string) {
        const currentUser = this.authenticationService.currentUserValue;
        const currentLang = this.translate.currentLanguageValue;
        if(currentLang){
          this.currrentLang = currentLang.language;
      }
        if (currentUser.token) {
          console.log(currentUser.token)
          return request.clone({
            setHeaders: { 
              Authorization: `Bearer ${currentUser.token  }`,
              language: this.currrentLang
            }
          });
        }
        /* for Spring Boot back-end */
        // return request.clone({ headers: request.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + token) });
        /* for Node.js Express back-end */
      }

}
