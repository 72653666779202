import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
declare var $: any;
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-salary-revise',
  templateUrl: './salary-revise.component.html',
  styleUrls: ['./salary-revise.component.scss']
})
export class SalaryReviseComponent implements OnInit {
  paramsArray: any;
  listView = false;
  isSubmitted = false;
  employee = false;
  salaryDetails: any;
  response: any;
  login: any;
  accountDetails: any;
  employeeDetails: any;
  employeeFullDetails: any;
  public todayDate: any = new Date();
  public employeeSalaryForm: FormGroup;
  uuid: any;
  responseSalary: any;
  company_id: any
  calculateMinDate(): Date {
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 40);
    return minDate;
  }
  constructor(private router: Router,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private ngxUiLoaderService: NgxUiLoaderService,
    private snackBar: MatSnackBar) {
    this.getCurrentUser();
    if (this.route.snapshot.paramMap.get('id')) {
      this.uuid = this.route.snapshot.paramMap.get('id');
      console.log("44", this.uuid)
    }
    // if (this.route.snapshot.paramMap.get('uuid')) {
    //   this.company_id = this.route.snapshot.paramMap.get('uuid');
    //   console.log("49", this.company_id)
    // }
  }

  ngOnInit() {
    this.employeeSalaryForm = this.formBuilder.group({
      join_date: ['', [Validators.required]],
      designation: ['', [Validators.required, Validators.pattern("^(?=.*[a-zA-Z])[a-zA-Z0-9 ]+$")]],
      base_salary: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      salary: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]
    });
    if (!this.api.getValidationStatus()) {
      this.api.getFormValidation(this.employeeSalaryForm);
    }
    console.log("63", this.accountDetails)
    if (this.uuid && this.accountDetails.current_user == 1) {
      this.getSalaryDetails();
    } else {
      this.getEmployeeSalaryList();
    }
  }
  onSubmitSalary() {
    this.isSubmitted = true;
    console.log(this.employeeSalaryForm.value)
    if (this.employeeSalaryForm.invalid) {
      return;
    }
    var data = this.employeeSalaryForm.value;
    data['join_date'] = this.convertOwl(data.join_date)
    data['employee_id'] = this.uuid;
    console.log("66", data)
    this.ngxUiLoaderService.start();
    this.api.salaryDetails(data).pipe(first())
      .subscribe(data => {
          this.isSubmitted = false;
          this.ngxUiLoaderService.stop();
          this.responseSalary = data;
          if (this.responseSalary.status == 200) {
            $('#employeeWithCcpsStage2').modal('hide');
            this.getSalaryDetails();
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
            this.employeeSalaryForm.reset();
          } else {
            this.snackBar.open(this.responseSalary.message, "dismiss", {
              duration: 2000,
            });
            this.employeeSalaryForm.reset();
          }
        },
        error => {
          this.isSubmitted = false;
          this.ngxUiLoaderService.stop();
        });
  }
  goToEmpolyeeList() {
    if (this.accountDetails.current_user == 1) {
      console.log("67")
      this.router.navigate(['/employees'], { state: { data: 0 } });
    } else {
      console.log("71")
      this.router.navigate(['/experience']);
    }
  }

  closeModal() {
    $('#employeeWithCcpsStage2').modal('hide');
    this.employeeSalaryForm.reset();
    this.isSubmitted = false;
    console.log(this.employeeSalaryForm)
  }
  get f() {
    return this.employeeSalaryForm.controls;
  }
  getCurrentUser() {
    const data = localStorage.getItem('currentUserDetails');
    if (data) {
      this.accountDetails = JSON.parse(data);
    }
    if (this.accountDetails.current_user == 1) {
      this.employee = false;
    } else {
      this.employee = true;
    }
  }
  getSalaryDetails() {
    this.ngxUiLoaderService.start();
    this.api.getSalaryDetails(this.uuid).pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          this.response = data;
          if (this.response.status == 200) {
            console.log("121", data)
            this.employeeDetails = this.response.results.employee;
            this.employeeFullDetails = this.response.results.employee_salary;
            console.log("126", this.employeeDetails)
            console.log("127", this.employeeFullDetails)
          } else {
            this.snackBar.open(this.response.message, "dismiss", {
              duration: 2000,
            });
          }

        },
        error => {
          this.ngxUiLoaderService.stop();
        });

  }
  convertOwl(str) {
    if (str == null)
      return str;
    var mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
      },

      date = str.toString().split(" ");
    if (date.length == 1)
      return str;
    else
      return [date[2], mnths[date[1]], date[3]].join("-");
  }

  getEmployeeSalaryList() {
    this.ngxUiLoaderService.start();
    this.api.getEmployeeSalaryRevise(this.uuid).pipe(first())
      .subscribe(data => {
          console.log("189", data)
          this.ngxUiLoaderService.stop();
          this.response = data;
          if (this.response.status == 200) {
            console.log("121", data)
            this.employeeDetails = this.response.results.employee;
            this.employeeFullDetails = this.response.results.employee_salary;
            console.log("126", this.employeeDetails)
            console.log("127", this.employeeFullDetails)
          } else {
            this.snackBar.open(this.response.message, "dismiss", {
              duration: 2000,
            });
          }

        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }

}
