import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import AOS from 'aos';
import 'aos/dist/aos.css';

@Component({
  selector: 'app-cnamgs-reportlist',
  templateUrl: './cnamgs-reportlist.component.html',
  styleUrls: ['./cnamgs-reportlist.component.scss']
})
export class CnamgsReportlistComponent implements OnInit {
  constructor(private router: Router) { 
   
  }

  ngOnInit() {
    AOS.init({
      disable: function() {
        var maxWidth = 600;
        return window.innerWidth < maxWidth;
      }
    });
  }

  gotoUrl() {
    this.router.navigate(['/cnamgs-report']);
  }

}
