import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { EmployeesComponent } from './pages/employees/employees.component';
import { AuthGuard } from '.././_helpers';
import { CnssComponent } from './pages/cnss/cnss.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { FaqComponent } from './pages/faq/faq.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DependenceComponent } from './pages/dependence/dependence.component';
import { ExperienceComponent } from './pages/experience/experience.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { SalaryReviseComponent } from './pages/salary-revise/salary-revise.component';
import { MainLayoutComponent } from './pages/layout/main-layout/main-layout.component';
import { GetStartedComponent } from './pages/get-started/get-started.component';
import { LinkBusinessComponent } from './pages/link-business/link-business.component';
import { ReportsListComponent } from './pages/reports-list/reports-list.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { ReportViewComponent } from './pages/report-view/report-view.component';
import { ApprovalFormComponent } from './pages/approval-form/approval-form.component';
import { AddBankComponent } from './pages/add-bank/add-bank.component';
import { ContactFloatingComponent } from './pages/layout/contact-floating/contact-floating.component';
import { OnboardingPortalComponent } from './pages/layout/onboarding-portal/onboarding-portal.component';
import { DependenceListComponent } from './pages/dependence-list/dependence-list.component';
import { CnamgsFormComponent } from './pages/cnamgs-form/cnamgs-form.component';
import { CnamgsReportComponent } from './pages/cnamgs-report/cnamgs-report.component';
import { CnamgsReportlistComponent } from './pages/cnamgs-reportlist/cnamgs-reportlist.component';
import { CnamgsReportviewComponent } from './pages/cnamgs-reportview/cnamgs-reportview.component';

const routes: Routes = [
  {
    path: '', component: HomePageComponent, pathMatch: 'full', data: { state: 'home'}
  },
    { 
    path: '',
    component: MainLayoutComponent, 
    children: [
      {
        path: 'dashboard', component: DashboardComponent, pathMatch: 'full', data: { state: 'dashboard'}
      },
      {
        path: 'employees', component: EmployeesComponent, pathMatch: 'full', data: { state: 'employees'}
      },
      {
        path: 'cnss', component: CnssComponent, pathMatch: 'full', data: { state: 'cnss'}
      },
      {
        path: 'cnss/:id', component: CnssComponent, pathMatch: 'full', data: { state: 'cnss'}
      },
      {
        path: 'notfication', component: NotificationComponent, pathMatch: 'full', data: { state: 'notfication'}
      },
      {
        path: 'profile', component: ProfileComponent, pathMatch: 'full',  data: {animation: 'Article'}
      },
      {
        path: 'dependence', component: DependenceComponent, pathMatch: 'full', data: { state: 'dependence'}
      },
      {
        path: 'experience', component: ExperienceComponent, pathMatch: 'full', data: { state: 'experience'}
      },
      {
        path: 'faq', component: FaqComponent, pathMatch: 'full', data: { state: 'faq'}
      },
      {
        path: 'salary-revise', component: SalaryReviseComponent, pathMatch: 'full', data: { state: 'salary-revise'}
      },
      {
        path: 'salary-revise/:id', component: SalaryReviseComponent, pathMatch: 'full', data: { state: 'salary-revise'}
      },
      {
        path: 'get-started', component: GetStartedComponent, pathMatch: 'full', data: {animation: 'Home'}
      },
      {
        path: 'link-business', component: LinkBusinessComponent, pathMatch: 'full', data: { state: 'link-business'}
      },
      {
        path: 'reportList', component: ReportsListComponent, pathMatch: 'full', data: { state: 'reportList'}
      },
      {
        path: 'reports/:id', component: ReportsComponent, pathMatch: 'full', data: { state: 'reports'}
      },
      {
        path: 'reports', component: ReportsComponent, pathMatch: 'full', data: { state: 'reports'}
      },
      {
        path: 'payment/:id', component: PaymentComponent, pathMatch: 'full', data: { state: 'payment'}
      },
      {
        path: 'report-view/:id/:purpose', component: ReportViewComponent, pathMatch: 'full', data: { state: 'report-view'}
      },
      {
        path: 'approval-form', component: ApprovalFormComponent, pathMatch: 'full', data: { state: 'approval-form'}
      },
      {
        path: 'add-bank', component: AddBankComponent, pathMatch: 'full', data: { state: 'add-bank'}
      },
      {
        path: 'contact-floating', component: ContactFloatingComponent, pathMatch: 'full', data: { state: 'contact-floating'}
      },
      {
        path: 'onboarding-portal', component: OnboardingPortalComponent, pathMatch: 'full', data: { state: 'onboarding-portal'}
      },
      {
        path: 'dependence-list', component: DependenceListComponent, pathMatch: 'full', data: { state: 'dependence-list'}
      },
      {
        path: 'cnamgs-form', component: CnamgsFormComponent, pathMatch: 'full', data: { state: 'cnamgs-form'}
      },
      {
        path: 'cnamgs-report', component: CnamgsReportComponent, pathMatch: 'full', data: { state: 'cnamgs-report'}
      },
      {
        path: 'cnamgs-reportlist', component: CnamgsReportlistComponent, pathMatch: 'full', data: { state: 'cnamgs-reportlist'}
      },
      {
        path: 'cnamgs-reportview/:id', component: CnamgsReportviewComponent, pathMatch: 'full', data: { state: 'cnamgs-reportview'}
      },
      {
        path: 'contact-us', component: ContactUsComponent, pathMatch: 'full', data: { state: 'contact-us'}
      },

      ],canActivate: [AuthGuard]  
  },
  
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
