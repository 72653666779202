import { Component, OnInit, NgZone } from '@angular/core';
declare var $: any;
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { TranslateService } from '../../../_services/translate.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
import { AnpiService } from '../../../_services/anpi-code.service';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
declare var $ : any;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  response: any;
  faqDetails: any;
  faqAllList:any;
  constructor(private translate: TranslateService,
              private api: ApiService,
              private router: Router,
              private ngxUiLoaderService: NgxUiLoaderService,
              private snackBar: MatSnackBar,
              private location: Location) {}

  ngOnInit() {
    this.getFaqList();
  }


  getFaqList(){
    this.ngxUiLoaderService.start();
    this.api.getFaqList().pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res;
          if (this.response.status == 200) {
            this.faqAllList =  this.response.results;
            this.faqDetails = this.response.results;
            console.log("this.faqDetails");
            console.log(this.faqDetails);
            this.hideshow() ;
          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }
    hideshow () {
      $(document).on('click', '.faq_list', function(event) {
        if ($(event.currentTarget).find('.answer').is(':visible')) {
          if($(event.currentTarget).find('.moreButton').hasClass('clicked') && $(event.currentTarget).find('.question').hasClass('questionBold')) {
            $(event.currentTarget).find('.moreButton').removeClass("clicked")
            $(event.currentTarget).find('.question').removeClass('questionBold')
          }
          $(event.currentTarget).find('.answer').hide(750);
        } else {
          if ($('.answer').is(':visible') || $('.answer').is(':hidden')) {
            if ($('.moreButton').hasClass('clicked')) {
              // console.log('11122222')
              $('.moreButton').removeClass('clicked')
              $('.question').removeClass('questionBold')
            }
            $('.answer').hide(750);
            $(event.currentTarget).find('.answer').toggle(750);
            $(event.currentTarget).find('.moreButton').addClass('clicked')
            $(event.currentTarget).find('.question').addClass('questionBold')
          }
        }
      });
    }
  showSearch () { 
    console.log('333')
    $('#searchStyle').addClass('searchTransform');
    $('#searchInput').addClass('changeSearchStyle');
    $('small').css({display: "none"});
  }
    setFilteredFaq(e: any) {
    console.log(e)
    this.faqDetails = this.faqAllList.filter((faqList: any) => {
      if (faqList.question != null) {
        return faqList.question.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 || faqList.answer
          .toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
      }
    });
  }
}
