import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../../../_services/translate.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $ : any;
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-get-started',
  templateUrl: './get-started.component.html',
  styleUrls: ['./get-started.component.scss']
})
export class GetStartedComponent implements OnInit {
   public languageForm: FormGroup;
   currentLang:any;
  constructor(private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router) { }

  ngOnInit() {
      this.languageForm = this.formBuilder.group({
        lang: ['', []],
      });
      this.currentLang = localStorage.getItem('language') || "fr";
  this.languageForm.get('lang').setValue(this.currentLang);
  if (this.currentLang == 'fr') {
		$('.checkbox').prop('checked', false);
	} else {
		$('.checkbox').prop('checked', true);
	}
  if (this.currentLang == 'fr') {
    console.log("23")
    $('.myCheckbox').prop('checked', true);
    $("#lang").text("FR");
  } else {
    $('.myCheckbox').prop('checked', false);
    $("#lang").text("EN");
  }
  console.log(this.languageForm.get('lang').value);
  
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "305px";
     document.getElementById("main").style.marginRight = "0px";
     document.getElementById("menuOverlay").style.display = "block";
     
   }
   
   closeNav() {
     document.getElementById("mySidenav").style.width = "0";
     document.getElementById("main").style.marginRight= "0";
     document.getElementById("menuOverlay").style.display = "none";
   }
      setLang(lang) {
    console.log('46')
    this.currentLang = lang;
    var useLang = {language: this.currentLang};
    this.translate.setCurrentLangage(useLang);
    localStorage.setItem('language', lang);

    this.changeLang();
  }

  changeLang(){
    console.log("59")
  this.translate.use(this.currentLang);
  }

  checkValue(e) {
  console.log("64")
    if (e == true) {
      this.setLang('fr');
      $("#lang").text("FR");
    } else {
      this.setLang('en');
      $("#lang").text("EN");
    }
  }
 getChangelang(e) {
  console.log("73")
    if (e.target.checked == true) {
      var lang = 'en';
    } else {
      lang = 'fr';
    }
    this.setLang(lang)
    }

    getStarted() {
      if (localStorage.getItem('business')) {
        this.router.navigate(['/dashboard']);
      } else {
        this.router.navigate(['/link-business']);
      }
    }

}
