<section class="top-section main-navbar">
  <div class="container-fluid nav-bar">
    <div id="menuOverlay" (click)="closeNav()"></div>
    <div id="mySidenav" class="sidenav">
      <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
      <div *ngIf="isBusiness || employee" class="profile-container">
        <a *ngIf="!employee" routerLink="/profile">In Flow Gabon SARL</a>
        <a *ngIf="employee" routerLink="/profile">Ronin Malof</a>
        <div id="profile-image" routerLink="/profile">
          <img id="company_logo" *ngIf="!employee" src="assets/images/company_logo_default.png">
          <img id="employee_pic" *ngIf="employee" src="{{ accountDetails.image_url }}">
          <img *ngIf="employee && !accountDetails.image_url" src="assets/images/default_user.png" alt="" class="img-fluid">
        </div>
      </div>
      <li> <a routerLink="/dashboard" class="hvr-efx1" [ngClass]="{ 'active': url == 'dashboard'}">{{'Dashboard' | translate}}</a> </li>
      <li *ngIf="isBusiness"><a *ngIf="login == '1'" [ngClass]="{ 'active': url == 'employees'}" class="active" routerLink="/employees">{{'Employees' | translate}}</a></li>
      <li *ngIf="isBusiness"><a *ngIf="login == '1'" (click)="getCnssPage()">{{'CNSS' | translate}} </a></li>
      <li *ngIf="isBusiness"><a *ngIf="login == '1'" (click)="getCnamgs()" [ngClass]="{ 'active': url == 'cnss'}">{{'CNAMGS' | translate}}</a> </li>
      <li>
        <a *ngIf="login == '2'" routerLink="/dependence-list">{{'Dependants' | translate}}</a>
      </li>
      <li>
        <a routerLink="/experience" *ngIf="login == '2'">{{'Experience' | translate}}</a>
      </li>
      <li>
        <a routerLink="/cnss" *ngIf="login == '2'">{{'CNSS' | translate}}</a>
      </li>
      <li>
        <a (click)="getCnamgsemployee()" *ngIf="login == '2'">{{'CNAMGS' | translate}}</a>
      </li>
      <li *ngIf="!isBusiness && login != '2'"><a routerLink="/link-business">{{'Link Business' | translate}}</a></li>
      <li><a routerLink="/contact-us">{{'Contact' | translate}}</a></li>
      <div class="language-container">
        
          <!-- <select  formControlName="lang" (change)="setLang($event.target.value)"  class="bootstrap-select">
                  <option value="en" selected="selected">{{'English' | translate}}</option>
                  <option value="fr">{{'French' | translate}}</option>
                </select> -->
          <div class="toggle-button-cover">
            <div class="button-cover">
              <div class="button r" id="button-6">
                <input type="checkbox" class="checkbox"   (change)="getChangelang($event)" />
                <div class="knobs"></div>
                <div class="layer"></div>
              </div>
            </div>
          </div>
       
      </div>
    </div>
    <div id="main">
      <header class="block-header home-header">
        <div class="container-custom">
          <div class="row justify-content-between sub-nav">
            <div class="notofication_button"><a [routerLink]="['/notfication']"><i class="fa-solid fa-bell"></i></a></div>
            <div class="button_container">
              <div (click)="openNav()"><span class="top"></span><span class="middle"></span><span class="bottom">
                </span></div>
            </div>
            <div class="col-sm-2 col-md-2">
              <a class="logo-block" routerLink="">{{'CCPS' | translate}}</a>
            </div>
            <div class="col-10 justify-content-end align-items-center lang-desktop">
              <ul *ngIf="login == '2' || isBusiness">
                <li>
                  <a class="" routerLink="/notfication">{{'Notifications' | translate}}</a>
                </li>
                <li>
                  <a routerLink="/faq">{{'FAQ' | translate}}</a>
                </li>
              </ul>
              <!-- <label>{{'Language' | translate}}&nbsp;:</label> -->
              <div class="dropdown">
            
                  <!-- <select  formControlName="lang" (change)="setLang($event.target.value)"  class="bootstrap-select">
                                          <option value="en" selected="selected">{{'English' | translate}}</option>
                                          <option value="fr">{{'French' | translate}}</option>
                                        </select> -->
                  <div class="toggle-button-cover">
                    <div class="button-cover">
                      <div class="button r" id="button-6">
                        <input type="checkbox" class="checkbox"  (change)="getChangelang($event)" />
                        <div class="knobs"></div>
                        <div class="layer"></div>
                      </div>
                    </div>
                  </div>
               
              </div>
            </div>
          </div>
          <hr>
          <div class="top-nav-container">
            <div class="row align-items-center">
              <div class="col-sm-12">
                <ul class="nav-block link-efx justify-content-end" id="page-menu">
                  <li *ngIf="login == '2' || isBusiness"> <a routerLink="/dashboard" class="hvr-efx1" [ngClass]="{ 'active': url == 'dashboard'}">{{'Dashboard' | translate}}</a> </li>
                  <li *ngIf="isBusiness"><a *ngIf="login == '1'" [ngClass]="{ 'active': url == 'employees'}" class="active" routerLink="/employees">{{'Employees' | translate}}</a></li>
                  <li *ngIf="isBusiness"><a *ngIf="login == '1'" (click)="getCnssPage()">{{'CNSS' | translate}} </a></li>
                  <li *ngIf="isBusiness"><a *ngIf="login == '1'" routerLink="/cnamgs-report" [ngClass]="{ 'active': url == 'cnamgs-report'}">{{'CNAMGS' | translate}}</a> </li>
                  <li>
                    <a *ngIf="login == '2'" routerLink="/dependence-list">{{'Dependants' | translate}}</a>
                  </li>
                  <li>
                    <a routerLink="/experience" *ngIf="login == '2'">{{'Experience' | translate}}</a>
                  </li>
                  <li>
                    <a routerLink="/cnss" *ngIf="login == '2'">{{'CNSS' | translate}}</a>
                  </li>
                  <li>
                    <a (click)="getCnamgsemployee()" *ngIf="login == '2'">{{'CNAMGS' | translate}}</a>
                  </li>
                  <li *ngIf="!isBusiness && login != '2'"><a routerLink="/link-business">{{'Link Business' | translate}}</a></li>
                  <li><a routerLink="/contact-us">{{'Contact' | translate}}</a></li>
                  <div *ngIf="isBusiness || employee" class="profile-container">
                    <a *ngIf="!employee" routerLink="/profile">{{ accountDetails.company_name }}</a>
                    <a *ngIf="employee" routerLink="/profile">{{ accountDetails.first_name }} {{ accountDetails.last_name }}</a>
                    <div id="profile-image" routerLink="/profile">
                      <img id="company_logo" *ngIf="!employee" src="assets/images/company_logo_default.png">
                      <img id="employee_pic" *ngIf="employee && accountDetails.image_url" src="{{ accountDetails.image_url }}">
                     <img *ngIf="employee && !accountDetails.image_url" src="assets/images/default_user.png" alt="" class="img-fluid">
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  </div>
</section>
