import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
declare var $ : any;

@Component({
  selector: 'app-cnamgs-reportview',
  templateUrl: './cnamgs-reportview.component.html',
  styleUrls: ['./cnamgs-reportview.component.scss']
})
export class CnamgsReportviewComponent implements OnInit {

  id: any;
  page: any;
  public todayDate:any = new Date();
  constructor(private router: Router,
              private route: ActivatedRoute) { 
    if (this.router.getCurrentNavigation().extras.state) {
                  this.page = this.router.getCurrentNavigation().extras.state.data;
                  console.log("19",this.page)
              }
  }


  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        console.log("28",params['id']);
        if (params['id'] != 'cnamgs') {
          this.id = params['id'];
          switch(this.id) {
            case 'normalcnss': {
                $('.cnamgs_report').addClass('dNone');
                $('.payment_table').addClass('dNone');
                $('.dateTerminateInput').removeClass('dNone');
                $('.dateTerminate').removeClass('dBlock');
  
                $('.pendingStatus').removeClass('dBlock');
                $('.ongoingStatus').removeClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.completedStatus').addClass('dNone');
  
                $('input').removeClass('changeInput_style');
                $('.tableFooter').removeClass('dNone');
                $('#resubMit_btn').removeClass('d-block');
                $('#submit_btn').addClass('d-block');
                break;
            }
            case 'pendingcnss': {
                $('.cnamgs_report').addClass('dNone');
                $('.payment_table').addClass('dNone');
                //$('.dateTerminateInput').addClass('dNone');
                $('.dateTerminate').addClass('dBlock');
  
                $('.pendingStatus').addClass('dBlock');
                $('.ongoingStatus').addClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.completedStatus').addClass('dNone');
                // $('input').prop('disabled', true);
                $('input').addClass('changeInput_style');
                $('.tableFooter').addClass('dNone');
                break;
            }
            case 'payNowCnss': {
                $('.cnamgs_report').addClass('dNone');

                $('.payment_table').addClass('dInTable');
                $('.quarter_table').addClass('dNone');
  
                $('.pendingStatus').addClass('dNone');
                $('.ongoingStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.payNowStatus').addClass('dBlock');
                $('.completedStatus').addClass('dNone');
  
                $('.tableFooter').addClass('dNone');
                $('.tableFooter2').addClass('d-block');
                $('.tableFooter3').addClass('dNone');
                break;
            }
            case 'completedCnss': {
                $('.cnamgs_report').addClass('dNone');

                $('.payment_table').addClass('dInTable');
                $('.quarter_table').addClass('dNone');
  
                $('.pendingStatus').addClass('dNone');
                $('.ongoingStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.completedStatus').addClass('dBlock');
  
                $('.tableFooter').addClass('dNone');
                $('.tableFooter2').addClass('dNone');
                $('.tableFooter3').addClass('d-block');
                break;
            }
            case 'rejectedCnss': {
                $('.cnamgs_report').addClass('dNone');

                $('.payment_table').addClass('dInTable');
                $('.dateTerminateInput').removeClass('dNone');
                $('.dateTerminate').removeClass('dBlock');
                $('.quarter_table').addClass('dNone');
               
                $('.pendingStatus').addClass('dNone');
                $('.ongoingStatus').addClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dBlock');
                $('.completedStatus').addClass('dNone');

                
                $('.rejectedNote').addClass('d-block');
                $('input').removeClass('changeInput_style');
                $('.tableFooter').removeClass('dNone'); 
                break;
                
            }
        }
        } 
      }
    );
  }
 paymentSubmit(){
     this.router.navigate(['/payment/cnamgs']);
  }
  showCnssList(){
    window.scrollTo({ top: 0, behavior: 'smooth' }); 

    $('#reportModal').modal('hide');
    this.id ='normalcnss';
    switch(this.id) {

      case 'normalcnss': {
        console.log('aa')
          $('.cnamgs_report').addClass('dNone');
          $('.payment_table').addClass('dNone');
          $('.dateTerminateInput').removeClass('dNone');
          $('.dateTerminate').removeClass('dBlock');

          $('.pendingStatus').removeClass('dBlock');
          $('.ongoingStatus').removeClass('dNone');
          $('.payNowStatus').addClass('dNone');
          $('.rejectedStatus').addClass('dNone');
          $('.completedStatus').addClass('dNone');

          $('input').removeClass('changeInput_style');
          $('.tableFooter').removeClass('dNone');
          $('#resubMit_btn').removeClass('d-block');
          $('#submit_btn').addClass('d-block');
          break;
      }
    }
    
  }
   goToViewPage(){
  $('#successModal').modal('hide');

   this.router.navigate(['/cnamgs-reportlist']);
  }

  goback() {
    this.router.navigate(['/cnamgs-reportlist']);
  }

}
