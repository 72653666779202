<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * * * The content below * * * * * * * * * * * -->
<!-- * * * * * * * * * * is only a placeholder * * * * * * * * * * -->
<!-- * * * * * * * * * * and can be replaced. * * * * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- * * * * * * * * * Delete the template below * * * * * * * * * * -->
<!-- * * * * * * * to get started with your project! * * * * * * * * -->
<!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * -->
<!-- <app-header></app-header> -->
<!-- <div [@routeAnimations]="o && o.activatedRouteData 
      && o.activatedRouteData['animation']"> -->
        <ngx-ui-loader text="{{ 'Loading' | translate }} .."></ngx-ui-loader>
    <router-outlet #o="outlet"></router-outlet>
<!-- </div> -->