import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
declare var $ : any;
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  id: any;

  constructor(private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => { 
        this.id = params['id'];
        console.log(this.id)
      })
  }
  goToViewPage(){
    console.log("sdasdasdasd")
     $('#successModal').modal('hide');
   // this.reportList= false;
   // this.lastsec= true;
   // this.cnsstable = false;
   if (this.id == 'cnss') {
    this.router.navigate(['/reportList']);
   } else {
    this.router.navigate(['/cnamgs-reportlist']);
   }
  }
}
