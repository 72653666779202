import { Component, OnInit, NgZone } from '@angular/core';
declare var $: any;
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { TranslateService } from '../../../_services/translate.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
import { AnpiService } from '../../../_services/anpi-code.service';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
declare var $: any;
@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  public languageForm: FormGroup;
  response: any
  notificationDetails: any
  notificationList: any;
  constructor(private translate: TranslateService,
    private api: ApiService,
    private router: Router,
    private ngxUiLoaderService: NgxUiLoaderService,
    private snackBar: MatSnackBar,
    private location: Location) {}


  ngOnInit() {

    this.getEmployeeNotfication();

  }

  naviagteToPage(navigateUrl, NotificationId) {
    /// call the api for status change ++SUBY
    this.router.navigate([navigateUrl]);
  }


  getEmployeeNotfication() {

    this.ngxUiLoaderService.start();
    this.api.getNotfication().pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res;
          if (this.response.status == 200) {
            // console.log(this.response.results);
            this.notificationList = this.response.results;
            this.notificationDetails = this.response.results;
            console.log(this.notificationDetails);
          } else {
            console.log("57")
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }
  showSearch() {
    console.log('333')
    $('#searchStyle').addClass('searchTransform');
    $('#searchInput').addClass('changeSearchStyle');
    $('small').css({ display: "none" });

  }

  setFilteredNotification(e: any) {
    console.log(e)
    this.notificationDetails = this.notificationList.filter((notifcationList: any) => {
      if (notifcationList.title != null) {
        return notifcationList.title.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
      }
    });
  }

}
