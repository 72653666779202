<app-header></app-header>
<section class="reportsList">
    <div class="container-fluid">
     <div class="initiale_sestion">
         <div class="head">
            <h4>{{'Manage CNSS'| translate}}</h4>
             <button id="submit_btn" class="btn btn-main2" (click)="gotoUrl()">{{'Initiate a declaration' | translate}}</button>
         </div>
         <div class="legend">
             <div id="pending">
                 <div class="circle"> </div>
                 <h4 class="legend_txt">{{'Waiting for Approval'| translate}}</h4>
             </div>
             <div id="paynow">
                 <div class="circle"> </div>
                 <h4 class="legend_txt">{{'Waiting for payment'| translate}}</h4>
             </div>
             <div id="rejected">
                 <div class="circle"> </div>
                 <h4 class="legend_txt">{{'Report Rejected'| translate}}</h4>
             </div>
             <div id="completed">
                 <div class="circle"> </div>
                 <h4 class="legend_txt">{{'Payment Completed'| translate}}</h4>
             </div>
         </div>
     </div>
        
    </div>
 <div class="mt-4">
     <div class="container-fluid reports_status mt-4 mb-5">
         <div class="card-grid {{item.type}}" *ngFor="let item of cnssreportList">
             
             <div class="child1">
                 <div class="dflex">
                     <div class="status {{item.type}}"><h6>{{ item.status_type | translate}}</h6></div>
                     <div class="columns">
                         <h3 class="sub-title">{{ item.quarter }}</h3>
                         <h6 class="sm_text">{{'Employer Name'| translate}} : <span>{{ item.employer_name }}</span></h6>
                         <h6 class="sm_text">{{'CNSS Employer ID'| translate}} : <span>CN2352355</span></h6>
                     </div>
                 </div>
             </div>

             <div class="child2">
                 <div class="columns">
                     <h6 class="sm_text">{{'No of Employees'| translate}} : <span>{{ item.employee_count }}</span></h6>
                     <h6 class="sm_text">{{'Company name'| translate}} : <span>{{ item.company_name }}</span></h6>
                     <h6 class="sm_text">{{'Email'| translate}} : <span>{{ item.email }}</span></h6>
                 </div>
             </div>
             <div class="child3">
                 <div class="columns">
                     <h6 class="sm_text">{{'Post box'| translate}} : <span>{{ item.fax }}</span></h6>
                     <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 {{ item.telephone }}</span></h6>
                     <h6 class="sm_text">{{'Submit Date'| translate}} : <span>{{ item.submit_date }}</span></h6>
                 </div>
             </div>
             <div class="child4">
                <!-- [routerLink]="['/report-view', 'pendingcnss']" -->
                 <button id="submit_btn"  class="btn btn-main2" (click)="getViewofReports(item.reportid, item.cnss_status)"><a>{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
             </div>
         </div>

         <!-- <div class="card-grid">
             
             <div class="child1">
                 <div class="dflex">
                     <div class="status payNow"><h6>{{'PayNow'| translate}}</h6></div>
                     <div class="columns">
                         <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                         <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Lionel Nwegnegue</span></h6>
                         <h6 class="sm_text">{{'CNSS Employer ID'| translate}} : <span>CN2352355</span></h6>
                     </div>
                 </div>
             </div>

             <div class="child2">
                 <div class="columns">
                     <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                     <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                     <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                 </div>
             </div>
             <div class="child3">
                 <div class="columns">
                     <h6 class="sm_text">{{'Post box'| translate}} : <span>235364</span></h6>
                     <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                     <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28/10/2022</span></h6>
                 </div>
             </div>
             <div class="child4">
                 <button [routerLink]="['/report-view', 'payNowCnss']" id="submit_btn" class="btn btn-main2"><a>{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
             </div>
         </div>

         <div class="card-grid">
             
             <div class="child1">
                 <div class="dflex">
                     <div class="status reject"><h6>{{'Rejected'| translate}}</h6></div>
                     <div class="columns">
                         <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                         <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Lionel Nwegnegue</span></h6>
                         <h6 class="sm_text">{{'CNSS Employer ID'| translate}} : <span>CN2352355</span></h6>
                     </div>
                 </div>
             </div>

             <div class="child2">
                 <div class="columns">
                     <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                     <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                     <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                 </div>
             </div>
             <div class="child3">
                 <div class="columns">
                     <h6 class="sm_text">{{'Post box'| translate}} : <span>235364</span></h6>
                     <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                     <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28/10/2022</span></h6>
                 </div>
             </div>
             <div class="child4">
                 <button [routerLink]="['/report-view', 'rejectedCnss']" id="submit_btn" class="btn btn-main2"><a>{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
             </div>
         </div>

         <div class="card-grid completed_bg"  >
             
             <div class="child1">
                 <div class="dflex">
                     <div class="status completed"><h6>{{'Completed'| translate}}</h6></div>
                     <div class="columns">
                         <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                         <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Lionel Nwegnegue</span></h6>
                         <h6 class="sm_text">{{'CNSS Employer ID'| translate}} : <span>CN2352355</span></h6>
                     </div>
                 </div>
             </div>

             <div class="child2">
                 <div class="columns">
                     <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                     <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                     <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                 </div>
             </div>
             <div class="child3">
                 <div class="columns">
                     <h6 class="sm_text">{{'Post box'| translate}} : <span>235364</span></h6>
                     <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                     <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28/10/2022</span></h6>
                 </div>
             </div>
             <div class="child4">
                 <button [routerLink]="['/report-view', 'completedCnss']" id="submit_btn" class="btn btn-main2"><a>{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
             </div>
         </div>
         <div class="card-grid completed_bg"  >
             
             <div class="child1">
                 <div class="dflex">
                     <div class="status completed"><h6>{{'Completed'| translate}}</h6></div>
                     <div class="columns">
                         <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                         <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Lionel Nwegnegue</span></h6>
                         <h6 class="sm_text">{{'CNSS Employer ID'| translate}} : <span>CN2352355</span></h6>
                     </div>
                 </div>
             </div>

             <div class="child2">
                 <div class="columns">
                     <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                     <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                     <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                 </div>
             </div>
             <div class="child3">
                 <div class="columns">
                     <h6 class="sm_text">{{'Post box'| translate}} : <span>235364</span></h6>
                     <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                     <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28/10/2022</span></h6>
                 </div>
             </div>
             <div class="child4">
                <button [routerLink]="['/report-view', 'completedCnss']" id="submit_btn" class="btn btn-main2"><a>{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
             </div>
         </div> -->
     </div>
 </div>

 <!-- cnamgs list start-->
  <!-- <div *ngIf="page" class="mt-4">
     <div class="container-fluid reports_status mt-4 mb-5">
         <div class="card-grid"  >
             
             <div class="child1">
                 <div class="dflex">
                     <div class="status pending"><h6>{{'Pending'| translate}}</h6></div>
                     <div class="columns">
                         <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                         <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Fedrik Schwegnar</span></h6>
                         <h6 class="sm_text">{{'CNAMGS Employer ID'| translate}} : <span>CN2352355</span></h6>
                     </div>
                 </div>
             </div>

             <div class="child2">
                 <div class="columns">
                     <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                     <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                     <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                 </div>
             </div>
             <div class="child3">
                 <div class="columns">
                     <h6 class="sm_text">{{'Post box'| translate}} : <span>235364</span></h6>
                     <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                     <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28/10/2022</span></h6>
                 </div>
             </div>
             <div class="child4">
                 <button id="submit_btn" [routerLink]="['/report-view', 'pendingcnamgs']" class="btn btn-main2"><a>{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
             </div>
         </div>

         <div class="card-grid"  >
             
             <div class="child1">
                 <div class="dflex">
                     <div class="status payNow"><h6>{{'PayNow'| translate}}</h6></div>
                     <div class="columns">
                         <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                         <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Fedrik Schwegnar</span></h6>
                         <h6 class="sm_text">{{'CNAMGS Employer ID'| translate}} : <span>CN2352355</span></h6>
                     </div>
                 </div>
             </div>

             <div class="child2">
                 <div class="columns">
                     <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                     <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                     <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                 </div>
             </div>
             <div class="child3">
                 <div class="columns">
                     <h6 class="sm_text">{{'Post box'| translate}} : <span>235364</span></h6>
                     <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                     <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28/10/2022</span></h6>
                 </div>
             </div>
             <div class="child4">
                 <button [routerLink]="['/report-view', 'payNowCnamgs']" id="submit_btn" class="btn btn-main2"><a>{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
             </div>
         </div>

         <div class="card-grid">
             
             <div class="child1">
                 <div class="dflex">
                     <div class="status reject"><h6>{{'Rejected'| translate}}</h6></div>
                     <div class="columns">
                         <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                         <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Fedrik Schwegnar</span></h6>
                         <h6 class="sm_text">{{'CNAMGS Employer ID'| translate}} : <span>CN2352355</span></h6>
                     </div>
                 </div>
             </div>

             <div class="child2">
                 <div class="columns">
                     <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                     <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                     <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                 </div>
             </div>
             <div class="child3">
                 <div class="columns">
                     <h6 class="sm_text">{{'Post box'| translate}} : <span>235364</span></h6>
                     <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                     <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28/10/2022</span></h6>
                 </div>
             </div>
             <div class="child4">
                 <button [routerLink]="['/report-view', 'rejectedCnamgs']" id="submit_btn" class="btn btn-main2"><a>{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
             </div>
         </div>

         <div class="card-grid completed_bg"  >
             
             <div class="child1">
                 <div class="dflex">
                     <div class="status completed"><h6>{{'Completed'| translate}}</h6></div>
                     <div class="columns">
                         <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                         <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Fedrik Schwegnar</span></h6>
                         <h6 class="sm_text">{{'CNAMGS Employer ID'| translate}} : <span>CN2352355</span></h6>
                     </div>
                 </div>
             </div>

             <div class="child2">
                 <div class="columns">
                     <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                     <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                     <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                 </div>
             </div>
             <div class="child3">
                 <div class="columns">
                     <h6 class="sm_text">{{'Post box'| translate}} : <span>235364</span></h6>
                     <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                     <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28/10/2022</span></h6>
                 </div>
             </div>
             <div class="child4">
                 <button [routerLink]="['/report-view', 'completedCnamgs']" id="submit_btn" class="btn btn-main2"><a>{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
             </div>
         </div>
         <div class="card-grid completed_bg">
             
             <div class="child1">
                 <div class="dflex">
                     <div class="status completed"><h6>{{'Completed'| translate}}</h6></div>
                     <div class="columns">
                         <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                         <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Fedrik Schwegnar</span></h6>
                         <h6 class="sm_text">{{'CNAMGS Employer ID'| translate}} : <span>CN2352355</span></h6>
                     </div>
                 </div>
             </div>

             <div class="child2">
                 <div class="columns">
                     <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                     <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                     <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                 </div>
             </div>
             <div class="child3">
                 <div class="columns">
                     <h6 class="sm_text">{{'Post box'| translate}} : <span>235364</span></h6>
                     <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                     <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28/10/2022</span></h6>
                 </div>
             </div>
             <div class="child4">
                <button [routerLink]="['/report-view', 'completedCnamgs']" id="submit_btn" class="btn btn-main2"><a>{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
             </div>
         </div>
     </div>
 </div> -->
<div class="middle-section getstarted" *ngIf="cnssreportList && cnssreportList.length == 0">
    <div class="image-container reportsIMain">
        <img src="assets/images/cnss_pic.png" class="img-fluid">
        <h3 class="text"> {{"You haven't initiate any"| translate}}<br> {{'CNSS reports yet.'| translate}}</h3>
    </div>
</div>

<div class="modal fade" id="successModal" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div class="modal-content">
           
            <div class="modal-body">
                <div class="parent">
                    <div class="cnsslogo">
                        <img src="assets/images/logoWithText1.png" class="img-fluid">
                        <p>CAISSE NATIONALE<br>DE SECURITE SOCIALE</p>
                    </div>
                    <div class="image-container">
                        <img src="assets/images/link_success.png">
                    </div>
                    <div class="text-container text-center" *ngIf="data">
                        <h4 class="modal-header-txt">{{'Congratulations! We acknowledge receipt of your DTS'| translate}} {{ data.quarter }}</h4>
                        <p class="para-light">{{'We will study your declaration and get back to you to enable you to make payment.' | translate}}</p>
                       <button id="submit_btn" class="btn btn-medium" data-dismiss="modal">{{'OK'| translate}}</button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 
</section> 
<app-footer></app-footer>
<app-contact-floating></app-contact-floating>