import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { User } from '../_models/user';
import { ApiService } from './api.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject < User > ;
  public currentUser: Observable < User > ;
  constructor(private http: HttpClient, 
              private api: ApiService,
              private router: Router) {
    this.currentUserSubject = new BehaviorSubject < User > (JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }
  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }
  login(data): Observable < any > {
    return this.http.post < any > (`${environment.apiUrl}auths`, data)
      .pipe(map((response: any) => {
        console.log("36", response) 
        if (response.status == 200) {
          if (response.reftoken && response.token) {
            localStorage.setItem('currentUser', JSON.stringify(response));
            this.currentUserSubject.next(response);
          }
          return response;
        } else {
          return response;
        }
      }));
  }
  loginOtp(data) {
    return this.http.post < any > (`${environment.apiUrl}auths/validate-otp`, data)
      .pipe(map((response: any) => {
        console.log("36", response)
        if (response.status == 200) {
          console.log("38", response)
          localStorage.setItem('currentUser', JSON.stringify(response));
          console.log("32 token set")
          this.currentUserSubject.next(response);
        }
        return response;
      }));
  }

  logout() {
    console.log("logout")
    localStorage.removeItem('Authorization-Refresh');
    localStorage.removeItem('currentUserDetails');
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    this.router.navigate(['/']);
  }
}
