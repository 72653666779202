import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
declare var $ : any;
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, FormArray, Validators  } from '@angular/forms';
import { AnpiService } from '../../../_services/anpi-code.service';

@Component({
  selector: 'app-cnamgs-report',
  templateUrl: './cnamgs-report.component.html',
  styleUrls: ['./cnamgs-report.component.scss']
})
export class CnamgsReportComponent implements OnInit {

  initiateReportForm: FormGroup;
  cnssForm: FormGroup;
  contactForm: FormGroup;
  reportList = true;
  lastsec = false;
  cnsstable = false;
  isSubmitted = false;
  id: any;
  page: any;
  response: any;
  title:any;
  public todayDate:any = new Date();
  Data: any;
  business:any;
  bank:any;
  employees:any;
  hasCnssForm:any;
  value:any;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private api: ApiService,
              private formBuilder: FormBuilder,
              private anpiService: AnpiService,
              private location: Location) { 
                if (this.router.getCurrentNavigation().extras.state) {
                  console.log(this.router.getCurrentNavigation().extras)
                  this.value = this.router.getCurrentNavigation().extras.state.data;
                  console.log("47",this.value)
                  if(this.value == '1'){
                      setTimeout(()=> { 
                      $('#successModal').modal('show');
                    }, 1000)
                  }
                  
                }
                this.checkLocalStorage();
              }

  ngOnInit() {

    this.anpiService.changeEvent.subscribe(() => {
      console.log('inside employees emit event')
    });
    this.cnssForm = this.formBuilder.group({
      reportMonth: this.formBuilder.array([''])
    });
    this.contactForm = this.formBuilder.group({
      reportMonth: this.formBuilder.array([''])
    });
    this.initiateReportForm = this.formBuilder.group({
      initiate_report_year : ['', [Validators.required]],
      initiate_report_quarter : ['', [Validators.required]],

    });
    this.initiateReportForm.controls['initiate_report_year'].setValue('1');
    this.initiateReportForm.controls['initiate_report_quarter'].setValue('1');
    this.route.params.subscribe(
      (params: Params) => {
        console.log("28",params['id']);
        if (params['id'] != 'cnamgs') {
          this.id = params['id'];
          switch(this.id) {
            case 'normalcnss': {
              console.log('normalcnss')
                $('.payment_table').addClass('dNone');
                $('.dateTerminateInput').removeClass('dNone');
                $('.dateTerminate').removeClass('dBlock');
                $('.cnamgs_report').addClass('dNone');
                $('.employer_dash').addClass('dNone');
                $('.pendingStatus').removeClass('dBlock');
                $('.ongoingStatus').removeClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.completedStatus').addClass('dNone');
  
                $('input').removeClass('changeInput_style');
                $('.tableFooter').removeClass('dNone');
                $('#resubMit_btn').removeClass('d-block');
                $('#submit_btn').addClass('d-block');
                break;
            }
            case 'pendingcnss': {
                $('.cnamgs_report').addClass('dNone');
                $('.employer_dash').addClass('dNone');
                $('.payment_table').addClass('dNone');
                //$('.dateTerminateInput').addClass('dNone');
                $('.dateTerminate').addClass('dBlock');
  
                $('.pendingStatus').addClass('dBlock');
                $('.ongoingStatus').addClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.completedStatus').addClass('dNone');
                $('input').addClass('changeInput_style');
                $('.tableFooter').addClass('dNone');
                break;
            }
            case 'payNowCnss': {
                $('.cnamgs_report').addClass('dNone');
                $('.employer_dash').addClass('dNone');
                $('.payment_table').addClass('dInTable');
                $('.quarter_table').addClass('dNone');
  
                $('.pendingStatus').addClass('dNone');
                $('.ongoingStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.payNowStatus').addClass('dBlock');
                $('.completedStatus').addClass('dNone');
  
                $('.tableFooter').addClass('dNone');
                $('.tableFooter2').addClass('d-block');
                $('.tableFooter3').addClass('dNone');
                break;
            }
            case 'completedCnss': {
                $('.cnamgs_report').addClass('dNone');
                $('.employer_dash').addClass('dNone');
                $('.payment_table').addClass('dInTable');
                $('.quarter_table').addClass('dNone');
  
                $('.pendingStatus').addClass('dNone');
                $('.ongoingStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.completedStatus').addClass('dBlock');
  
                $('.tableFooter').addClass('dNone');
                $('.tableFooter2').addClass('dNone');
                $('.tableFooter3').addClass('d-block');
                break;
            }
            case 'rejectedCnss': {
                $('.cnamgs_report').addClass('dNone');
                $('.employer_dash').addClass('dNone');
                $('.payment_table').addClass('dBlock');
                $('.dateTerminateInput').removeClass('dNone');
                $('.dateTerminate').removeClass('dBlock');
                $('.quarter_table').addClass('dNone');
  
                $('.pendingStatus').addClass('dNone');
                $('.ongoingStatus').addClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dBlock');
                $('.completedStatus').addClass('dNone');

                
                $('.rejectedNote').addClass('d-block');
                $('input').removeClass('changeInput_style');
                $('.tableFooter').removeClass('dNone');
                break;  
            }
          case 'resubmitCnss': {
                $('.cnamgs_report').addClass('dNone');
                $('.employer_dash').addClass('dNone');
                $('.payment_table').addClass('dBlock');
                $('.dateTerminateInput').removeClass('dNone');
                $('.dateTerminate').removeClass('dBlock');
                $('.quarter_table').addClass('dBlock');
  
                $('.pendingStatus').addClass('dNone');
                $('.ongoingStatus').addClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dBlock');
                $('.completedStatus').addClass('dNone');

                
                $('.rejectedNote').addClass('d-block');
                $('input').removeClass('changeInput_style');
                $('.tableFooter').removeClass('dNone');
                break;  
            }
          }
        } else {
          this.page = params['id'];
          console.log("114",this.page )
        }
      }
    );
      // this.showCnssList();
      this.getReports();
  }

  closeModal(){
    $('#reportModal').modal('hide');
    this.isSubmitted = false;
  }
  onSubmitInitiateReport(){
    console.log("175")
    this.isSubmitted = true;
        if (this.initiateReportForm.invalid) {
          console.log("61",this.initiateReportForm)
            return;
      }
      var data = this.initiateReportForm.value;
        // this.ngxUiLoaderService.start();
        this.api.initiateReport(data).pipe(first())
          .subscribe(data => {
          // this.ngxUiLoaderService.stop();
            // this.response = data;
            if(this.response.status == 200){
              // this.CompanyDetails = this.response.data;
            }else{
                // this.snackBar.open(res.message, "dismiss", {
                //      duration: 2000,
                //   });
            }
          },
          error => {
            // this.ngxUiLoaderService.stop();
          });  
      this.isSubmitted = false;
      this.showCnssList();
  }
  getReports() {
    this.api.getcnssReports().pipe(first())
    .subscribe(res => { 
      this.response = res.results;
      console.log(this.response)
      for (let key in this.response) {
        var reportMonth = this.cnssForm.controls.reportMonth as FormArray;
        reportMonth.controls[key] = this.formBuilder.group({
                                  reportEachemployee: this.formBuilder.array([''])
                                }) 
        for (let key1 in this.response[key].employees) { 
          var reportEachemployee = reportMonth.controls[key].controls.reportEachemployee as FormArray; 
          reportEachemployee.controls[key1] = this.formBuilder.group({
                                  termination_date: [''],
                                  working_hour: '',
                                  working_days: '',
                                  ccpsid: '',
                                  salary: ''
                                })  
          reportEachemployee.controls[key1].get('working_hour').setValue(this.response[key].employees[key1].working_hour)                  
          reportEachemployee.controls[key1].get('working_days').setValue(this.response[key].employees[key1].working_days)                  
          reportEachemployee.controls[key1].get('salary').setValue(this.response[key].employees[key1].salary.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '))                  
          reportEachemployee.controls[key1].get('ccpsid').setValue(this.response[key].employees[key1].ccpsid)                  
          console.log(reportEachemployee.controls[key1].get('termination_date'))                                               
        }                         
      }
      console.log(this.cnssForm.controls.reportMonth)
    },
    error => {

    });
  }


  showCnssList(){
    window.scrollTo({ top: 0, behavior: 'smooth' }); 
    this.reportList= false;
    this.lastsec= false;
    this.cnsstable = true;
    $('#reportModal').modal('hide');
    this.id ='normalcnss';
    switch(this.id) {

      case 'normalcnss': {
        console.log('aa')
          $('.employer_dash').addClass('dNone');
          $('.cnamgs_report').addClass('dNone');
          $('.payment_table').addClass('dNone');
          $('.dateTerminateInput').removeClass('dNone');
          $('.dateTerminate').removeClass('dBlock');

          $('.pendingStatus').removeClass('dBlock');
          $('.ongoingStatus').removeClass('dNone');
          $('.payNowStatus').addClass('dNone');
          $('.rejectedStatus').addClass('dNone');
          $('.completedStatus').addClass('dNone');

          $('input').removeClass('changeInput_style');
          $('.tableFooter').removeClass('dNone');
          $('#resubMit_btn').removeClass('d-block');
          $('#submit_btn').addClass('d-block');
          break;
      }
    }
    
  }
  goToViewPage(val=null){
    if(val){
      this.value = '1';
    }
    else{
      $('#successModal').modal('hide');
      this.reportList= false;
      this.lastsec= true;
      this.cnsstable = false;
      this.router.navigate(['/cnamgs-reportlist'],{ state: { data: this.page}});
    }

  }

  paymentSubmit(){
      this.router.navigate(['/payment']);
  }

  onSubmit() {
    var data = [];
    for (let key in this.cnssForm.controls.reportMonth['controls']) {
      data.push(this.cnssForm.controls.reportMonth['controls'][key].value)
    }
    console.log(data)
    $('#successModal').modal('show');
  }

  checkLocalStorage(){
    this.business = localStorage.getItem('business');
    this.bank = localStorage.getItem('bank');
    this.employees = localStorage.getItem('employees');
        console.log("142",this.value);
    if(this.value != "1"){
      console.log("sdfsdggsgsdgyuiaaswj")
      this.hasCnssForm = localStorage.getItem('cnamgsForm');
    }
    console.log("141",this.hasCnssForm);

    console.log("142",this.bank);
    if(this.business== null){
      this.title = 'business';
      console.log("138", this.title) 
      setTimeout(function () {
        $('#initiateReportContainer').css('display','none');
      }, 100);
    }  
    else if(this.business && this.bank== null){
      this.title = 'bank';
      console.log("143", this.title)
      setTimeout(function () {
        $('#initiateReportContainer').css('display','none');
      }, 100);
    }
    // else if(this.business && this.bank && this.employees == null){
    //    this.title = 'employees';
    //    console.log("148", this.title)
    //    setTimeout(function () {
    //    $('#initiateReportContainer').css('display','none');
    //    }, 100);
    //  }
    else if(this.business && this.bank && this.hasCnssForm  == null){
      this.title = 'cnssForm';
      console.log("288", this.title)
      setTimeout(function () {
      $('#initiateReportContainer').css('display','none');
      }, 100);
    }
    else if(this.hasCnssForm ){
        this.title = 'initiateReport';
        console.log("341")
        setTimeout(function () {
      $('#initiateReportContainer').css('display','block');
      $('#report-header').css('display','block');
      }, 100);
    }
  }

  onSearchChange(e) {
    console.log(e.replace(' '))
    console.log(e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '))
  }

  goback(val) {
    if (val == 2) {
      this.id = '';
    } else if (val == 1) {
      this.location.back();
    } else {
      this.router.navigate(['/dashboard']);
    }
  }

}
