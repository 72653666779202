<app-header></app-header>
<section class="container-fluid mb-5">
  <div class="initiale_sestion">
    <div class="dFlex">
      <div class="head">
        <h4 class="form-title-sm">{{'Notifications' | translate}}</h4>
      </div>
      <div class="search" (click)="showSearch()">
        <div class="a">
          <input type="text" placeholder="Search" id="searchInput" (keyup)="setFilteredNotification($event)" (keydown)="setFilteredNotification($event)">
          <div id="searchStyle">
            <i class="fa-solid fa-magnifying-glass"></i><small>{{'Search' | translate}}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="notification_section mt-4">
    <!-- 
                    class
                    Rejected == For rejected 
                    Read === For already clicked notifications
                 -->
    <div *ngFor="let notificationlist of notificationDetails;">
      <div class="cardStyle   col_1 {{ notificationlist.class }} ">
        <div class="logo">
          <img src="assets/images/business_logo.png" alt="" class="img-fluid">
        </div>
        <div>
          <h5>{{ notificationlist.title }}</h5>
          <p>{{ notificationlist.message }}</p>
        </div>
        <div class="date">
          <span>{{notificationlist.datetime }}</span>
        </div>
      </div>
    </div>
    
  </div>
  <div *ngIf="notificationDetails && notificationDetails.length == '0'"> <h5> {{'No Messages' | translate}}</h5></div>
</section>
<app-footer></app-footer>
<app-contact-floating></app-contact-floating>
