import { AbstractControl } from '@angular/forms';
export class ConfirmPasswordValidator {
  static MatchPassword(control: AbstractControl) {
    let password = control.get('password').value;
    let confirmPassword = control.get('confirmPassword').value;

    if (!confirmPassword && password) {
      control.get('confirmPassword').setErrors({ required: true });
    } else if (password !== confirmPassword) {
      control.get('confirmPassword').setErrors({ ConfirmPassword: true });
    } else {
      if (control.get('confirmPassword').errors) {
        if (!control.get('confirmPassword').errors.required || control.get('confirmPassword').errors.ConfirmPassword) {
          control.get('confirmPassword').setErrors(null);
        }
      }
    }
    return null;
  }
}