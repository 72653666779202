import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../_services/api.service';
import { AnpiService } from '../../../_services/anpi-code.service';
import { first } from 'rxjs/operators';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;
import AOS from 'aos';
import 'aos/dist/aos.css';

@Component({
  selector: 'app-add-bank',
  templateUrl: './add-bank.component.html',
  styleUrls: ['./add-bank.component.scss']
})
export class AddBankComponent implements OnInit {

  response: any;
  isSubmitted = false;
  public bankDetailsForm: FormGroup;
  bankdata: any;
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private route: ActivatedRoute,
    private anpiService: AnpiService,
    private ngzone: NgZone,
    private ngxUiLoaderService: NgxUiLoaderService,
    private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.anpiService.changeEvent.subscribe(() => {
      this.bankDetailsForm.get('banks').setValue('1');
    });
    this.bankDetailsForm = this.formBuilder.group({
      banks: ['1', [Validators.required]],
      account_number: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      account_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$")]],
      bank_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      bank_number: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    });

    if (!this.api.getValidationStatus()) {
      this.api.getFormValidation(this.bankDetailsForm);
      // this.api.getFormValidation(this.mobileMoneyForm);
    }
    AOS.init({
      disable: function() {
        var maxWidth = 600;
        return window.innerWidth < maxWidth;
      }
    });
    $("#bankAccountModal").on('shown.bs.modal', (e) => {
      console.log("sdsadsadsadsadasd")
      setTimeout(() => {
        AOS.refresh();
        this.getBanks();
        console.log("sdsadsadsad232222sadasd")
      }, 100);
      if (this.bankdata) {
        console.log($('#bank1'))
        setTimeout(() => {
          this.ngzone.run(() => {
            console.log("fdsfdsfsdfd")
            $('#bank1').prop('checked', true);
            // $('#mobile_money10').prop('checked', true);
            // this.bankDetailsForm.controls['bank_name'].setValue(this.bankdata[0].name);
          });
        }, 1000)
      }
    });
  }

  onSubmitBankForm() {
    console.log("77",this.bankDetailsForm)
    this.isSubmitted = true;
    if (this.bankDetailsForm.invalid) {
      return;
    }

    console.log("169")
    
    var data = {
      account_no: this.g.account_number.value,
      account_name: this.g.account_name.value,
      bank_name: this.g.bank_name.value,
      bank_no: this.g.bank_number.value,
      bank_id: this.g.banks.value
    }
    console.log("164", data)
    this.ngxUiLoaderService.start();
    this.api.bankDetails(data).pipe(first())
      .subscribe(data => {
          console.log(data)
          this.ngxUiLoaderService.stop();
          this.response = data;
          if (this.response.status == 200) {
            this.bankDetailsForm.reset();
            this.isSubmitted = false;
            $('#bankAccountModal').modal('hide');
            // localStorage.setItem('bank', "1");
            this.anpiService.emitEvent();
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
            this.router.navigate(['/dashboard']);
          } else {
            this.isSubmitted = false;
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.isSubmitted = false;
          this.ngxUiLoaderService.stop();
        });
  }

  // onSubmitMobileMoneyForm() {
  //   this.isSubmitted = true;
  //   if (this.mobileMoneyForm.invalid) {
  //     console.log("196", this.mobileMoneyForm)
  //     return;
  //   }

  //   var data = {
  //     ac_number: this.h.mobile_money_account_number.value,
  //     ac_name: this.h.mobile_money_account_name.value,
  //     bank_name: this.h.facility_name.value,
  //     bank_number: null
  //   }
  //   this.ngxUiLoaderService.start();
  //   this.api.bankDetails(data).pipe(first())
  //     .subscribe(data => {
  //         this.ngxUiLoaderService.stop();
  //         this.response = data;
  //         if (this.response.status == 200) {
  //           this.mobileMoneyForm.reset();
  //           this.isSubmitted = false;
  //           $('#bankAccountModal').modal('hide');
  //           localStorage.setItem('bank', "1");
  //           this.anpiService.emitEvent();
  //           this.snackBar.open(data.message, "dismiss", {
  //             duration: 2000,
  //           });
  //           this.router.navigate(['/dashboard']);
  //         } else {
  //           this.isSubmitted = false;
  //           this.snackBar.open(data.message, "dismiss", {
  //             duration: 2000,
  //           });
  //         }
  //       },
  //       error => {
  //         this.isSubmitted = false;
  //         this.ngxUiLoaderService.stop();
  //       });
  // }

  closeModal() {
    this.isSubmitted = false;
    this.bankDetailsForm.reset();
    $('#bankAccountModal').modal('hide');
  }
  get g() {
    return this.bankDetailsForm.controls;
  }

  getBanks() {
    console.log("260")
    this.ngxUiLoaderService.start();
    this.api.getBankList().pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          console.log("263", data)
          this.response = data.Results;
          if (data.status == 200) {
            this.bankdata = data.Results;
            console.log("239", this.bankdata)
            setTimeout(() => {
              this.ngzone.run(() => {
                console.log("fdsfdsfsdfd")
                console.log("244", this.bankdata)
                $('#bank1').prop('checked', true);
                console.log($('#bank1'))
                // console.log($('#mobile_money10'))
                this.bankDetailsForm.controls['bank_name'].setValue(this.bankdata[0].bank_name);
              });
            }, 2000)
          } else {
            // this.snackBar.open(data.message, "dismiss", {
            //   duration: 2000,
            // });
          }

        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }

  handleChange(e, value, banks) {
    console.log(value, e)
    // if (val == 1) {
    this.bankDetailsForm.controls['banks'].setValue(banks);
    this.bankDetailsForm.controls['bank_name'].setValue(value);
    // } else {
    //   this.mobileMoneyForm.controls['facility_name'].setValue(value);
    // }
  }

}
