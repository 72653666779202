<!-- Bank mdoal  -->
<div class="modal fade" id="bankAccountModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body">
        <div class="col_2 bgStyle profile_bank_section mt-75">
          <div class="currentStatus">
            <div id="tab-parent">
              <div class="tabs">
                <input type="radio" id="radio-1" name="tabs" checked />
                <label class="tabq" for="radio-1">{{'Bank / Mobile Money'| translate}}</label>
                <input type="radio" id="radio-2" name="tabs" />
                <!-- <label class="tabq" for="radio-2" (click)="open('money')">{{'Mobile Money'| translate}}</label> -->
                <span class="glider"></span>
              </div>
            </div>
            <form id="bankDetailsForm" [formGroup]="bankDetailsForm" (ngSubmit)="onSubmitBankForm()">
              <div class="line">
                <h4>{{'Bank Account Details'|translate}}</h4>
              </div>
              <div class="enroll_form">
                <div>
                  <div class="input_section">
                    <div class="container">
                      <div class="row">
                        <div class="col-md-12 col-lg-12 col-sm-12">
                          <label for="" class="text-center"> {{'Select Bank'| translate}}</label>
                          <div class="grid-parent">
                            <div class="grid-container">
                              <div class="grid-child" *ngFor="let bank of bankdata">
                                <input type="radio" id="bank{{ bank.bank_id }}" name="banks" formControlName="banks" value="{{ bank.bank_id }}" (change)="handleChange($event, bank.bank_name, bank.bank_id)">
                                <label for="bank{{ bank.bank_id }}">
                                  <img src="{{ bank.bank_logo }}">
                                </label>
                              </div>
                            </div>
                          </div>
                          <div *ngIf="isSubmitted && g.banks.errors" class="invalid-feedback">
                            <div *ngIf="g.banks.errors?.required">{{'Bank'|translate}} {{'is required'|translate}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="sub-container">
                        <div class="row">
                          <div class="col-md-6 col-lg-6 col-sm-12 mt-3">
                            <label for="">{{'Account number'|translate}}</label>
                            <input type="text" formControlName="account_number" maxlength="30">
                            <div *ngIf="isSubmitted && g.account_number.errors" class="invalid-feedback">
                              <div *ngIf="g.account_number.errors?.required">{{'Account number'|translate}} {{'is required'|translate}}</div>
                              <div *ngIf="g.account_number.errors?.pattern">{{'Account number'|translate}} {{'should be valid'|translate}}</div>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 mt-3">
                            <label for="">{{'Account name'|translate}}</label>
                            <input type="text" formControlName="account_name" maxlength="30">
                            <div *ngIf="isSubmitted && g.account_name.errors" class="invalid-feedback">
                              <div *ngIf="g.account_name.errors?.required">{{'Account name'|translate}} {{'is required'|translate}}</div>
                              <div *ngIf="g.account_name.errors?.pattern">{{'Account name'|translate}} {{'should be valid'|translate}}</div>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 mt-3">
                            <label for="">{{'Bank name'|translate}}</label>
                            <input type="text" formControlName="bank_name">
                            <div *ngIf="isSubmitted && g.bank_name.errors" class="invalid-feedback">
                              <div *ngIf="g.bank_name.errors?.required">{{'Bank name'|translate}} {{'is required'|translate}}</div>
                              <div *ngIf="g.bank_name.errors?.pattern">{{'Bank name'|translate}} {{'should be valid'|translate}}</div>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 mt-3">
                            <label for="">{{'Bank number'|translate}}</label>
                            <input type="text" formControlName="bank_number" maxlength="30">
                            <div *ngIf="isSubmitted && g.bank_number.errors" class="invalid-feedback">
                              <div *ngIf="g.bank_number.errors?.required">{{'Bank number'|translate}} {{'is required'|translate}}</div>
                              <div *ngIf="g.bank_number.errors?.pattern">{{'Bank number'|translate}} {{'should be valid'|translate}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 offset-md-3 mt-4 mb-5 text-center">
                            <button type="submit" id="submit_btn" class="btn btn-medium">{{'Save Changes' | translate}}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <!--  <form id="mobileMoneyForm" *ngIf="mobileMoney" [formGroup]="mobileMoneyForm" (ngSubmit)="onSubmitBankForm()">
              <div class="line">
                <h4>{{'Mobile Money Account Details' | translate}}</h4>
              </div>
              <div class="enroll_form">
                <div>
                  <div class="input_section">
                    <div class="container">
                      <div class="row">
                        <div class="col-md-12 col-lg-12 col-sm-12">
                          <label for=""> {{'Select Bank'| translate}}</label>
                          <div class="grid-container">
                            <div class="grid-child" *ngFor="let moneybank of moneydata">
                              <input type="radio" id="mobile_money{{ moneybank.id }}" name="mobile_money" formControlName="mobile_money" value="{{ moneybank.value }}" (change)="handleChange($event, moneybank.name, 2)">
                              <label for="mobile_money{{ moneybank.id }}">
                                <img src="{{ moneybank.image }}">
                              </label>
                            </div>
                          </div>
                          <div *ngIf="isSubmitted && h.mobile_money.errors" class="invalid-feedback">
                            <div *ngIf="h.mobile_money.errors?.required">{{'Bank'|translate}} {{'is required'|translate}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="sub-container">
                        <div class="row">
                          <div class="col-md-6 col-lg-6 col-sm-12 mt-3">
                            <label for="">{{'Account name2'|translate}}</label>
                            <input type="text" formControlName="mobile_money_account_name">
                            <div *ngIf="isSubmitted && h.mobile_money_account_name.errors" class="invalid-feedback">
                              <div *ngIf="h.mobile_money_account_name.errors?.required">{{'Account name2'|translate}} {{'is required'|translate}}</div>
                              <div *ngIf="h.mobile_money_account_name.errors?.pattern">{{'Account name2'|translate}} {{'should be valid'|translate}}</div>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 mt-3">
                            <label for="">{{'Account number / Phone number'|translate}}</label>
                            <input type="text" formControlName="mobile_money_account_number">
                            <div *ngIf="isSubmitted && h.mobile_money_account_number.errors" class="invalid-feedback">
                              <div *ngIf="h.mobile_money_account_number.errors?.required">{{'Account number'|translate}} {{'is required'|translate}}</div>
                              <div *ngIf="h.mobile_money_account_number.errors?.pattern">{{'Account number'|translate}} {{'should be valid'|translate}}</div>
                            </div>
                          </div>
                          <div class="col-md-6 col-lg-6 col-sm-12 mt-3">
                            <label for="">{{'Facility name'|translate}}</label>
                            <input type="text" formControlName="facility_name">
                            <div *ngIf="isSubmitted && h.facility_name.errors" class="invalid-feedback">
                              <div *ngIf="h.facility_name.errors?.required">{{'Facility name'|translate}} {{'is required'|translate}}</div>
                              <div *ngIf="h.facility_name.errors?.pattern">{{'Facility name'|translate}} {{'should be valid'|translate}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6 offset-md-3 mt-4 mb-5 text-center">
                          <button type="submit" id="submit_btn" class="btn btn-medium">{{'Save Changes' | translate}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
