import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../_services/api.service';
import { AnpiService } from '../../../_services/anpi-code.service';
import { first } from 'rxjs/operators';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;
import AOS from 'aos';
import 'aos/dist/aos.css';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  employer: any;
  currentLang: any;
  isSubmitted = false;
  public languageForm: FormGroup;
  response: any;
  id: any;
  accountDetails: any;
  currentUser: any;
  login: any;
  employeeDetails: any;
  currentDetails: any;
  previousDetails: any;
  companyDetails: any;
  contribution: any;
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private route: ActivatedRoute,
    private ngxUiLoaderService: NgxUiLoaderService,
    private snackBar: MatSnackBar,
    private anpiService: AnpiService) {

    const data = localStorage.getItem('currentUserDetails');
    if (data) {
      this.accountDetails = JSON.parse(data);
      this.login = this.accountDetails.current_user;
      console.log("36 login value:", this.login)
      if (this.login == "1") {
        this.employer = true;
        console.log("36", this.employer)
      } else {
        this.employer = false;
        console.log("40", this.employer)
      }
    }

    if (this.router.getCurrentNavigation().extras.state) {
      console.log("54", this.router.getCurrentNavigation().extras.state)
      const data = this.router.getCurrentNavigation().extras.state.show_modal
      console.log(this.router.getCurrentNavigation().extras.state.show_modal)
      console.log(data)
      if (data == 1) {
        setTimeout(() => {
          console.log("60")
          $('#successModal').modal('show');
        }, 500);
      } else if (data == 2) {
        console.log("64")
        setTimeout(() => {
          $('#successModalCnssForm').modal('show');
        }, 500);
      }

    }
  }

  ngOnInit() {


    this.languageForm = this.formBuilder.group({
      lang: ['', []],
    });

    if (!this.api.getValidationStatus()) {}

    this.currentLang = localStorage.getItem('language') || "fr";
    this.languageForm.get('lang').setValue(this.currentLang);
    if (this.currentLang == 'fr') {
      console.log("23")
      $('.myCheckbox').prop('checked', true);
      $("#lang").text("FR");
    } else {
      $('.myCheckbox').prop('checked', false);
      $("#lang").text("EN");
    }

    AOS.init({
      disable: function() {
        var maxWidth = 600;
        return window.innerWidth < maxWidth;
      }
    });
    this.route.paramMap.subscribe((params) => {
      console.log("34")
      this.getAccountData();
    });
    this.getAccountData();
  }
  getAccountData() {
    console.log("442 get employer details")
    this.ngxUiLoaderService.start();
    this.api.getAccountDetails().pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res.results;
          if (res.status == 200) {
            console.log("88", res)
            this.accountDetails = this.response;
            if (this.response.current_user == 1) {
              this.currentUser = this.response.current_user;
              console.log("450", this.currentUser)
            } else if (this.response.employee.current_user == 2) {
              this.currentUser = this.response.employee.current_user;
              console.log("107", this.currentUser)
              this.employeeDetails = this.response.employee;
              this.companyDetails = this.response.company_details;
              this.contribution = this.response.contribution_rate;
              console.log("110", this.employeeDetails)
              console.log("111", this.currentDetails)
              console.log("114", this.contribution)
            }
            this.login = this.currentUser;
            console.log("92", this.login)
            if (this.login == "1") {
              this.employer = true;
            } else {
              this.employer = false;
            }
            if (this.currentUser == 1) {
              // this.accountDetails['BankDetails'] = 1;
              const data = JSON.stringify(this.accountDetails);
              localStorage.setItem('currentUserDetails', data);
            } else if (this.currentUser == 2) {
              const data = JSON.stringify(this.accountDetails.employee);
              localStorage.setItem('currentUserDetails', data);
              console.log("117", data)
            }
            this.anpiService.emitEvent();
            this.anpiService.emitAccountEvent();
          } else {
            console.log("no result")
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          console.log("errorrrrrrrrr")
          this.ngxUiLoaderService.stop();
        });
    console.log("no result")
  }
  closeModal() {
    $('#successModalCnssForm').modal('hide');
  }

}
