import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
import { TranslateService } from '../../../_services/translate.service';
import { AnpiService } from '../../../_services/anpi-code.service';
import { Location } from '@angular/common';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;

@Component({
  selector: 'app-approval-form',
  templateUrl: './approval-form.component.html',
  styleUrls: ['./approval-form.component.scss']
})
export class ApprovalFormComponent implements OnInit {

  public cnssForm: FormGroup;
  public todayDate: Date  = new Date();
  startat = new Date();
  isSubmitted = false;
  response: any;
  bankDetails: any;
  bankdata: any;
  regionList: any;
  companyDetails: any;
  neighborhoodlist: any;
  districtList: any;
  accountDetails: any;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private translate: TranslateService,
    private anpiService: AnpiService,
    private location: Location,
    private ngzone: NgZone,
    private ngxUiLoaderService: NgxUiLoaderService,
    private snackBar: MatSnackBar) {
    const data = localStorage.getItem('currentUserDetails');
    if (data) {
      this.accountDetails = JSON.parse(data);
      console.log(this.accountDetails)
    }
  }
  ngOnInit() {
    console.log(this.todayDate)
    this.startat.setHours(0, 0, 0, 0);
    this.cnssForm = this.formBuilder.group({
      company_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      trade_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      sigle: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      district: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      neighborhood: ['', [Validators.required]],
      rue: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      door_address: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      place: ['', [Validators.required]],
      locality: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      region: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      contact_1: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      bp: ['', [Validators.required]],
      contact_2: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      fax: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      email: ['', [Validators.required, Validators.email]],
      website: ['', [Validators.required]],
      created_date: ['', [Validators.required]],
      service_start_date: ['', [Validators.required]],
      legal_status: ['', [Validators.required]],
      employer_plan: ['', [Validators.required]],
      tax_no: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      rccm: ['', [Validators.required]],
      nif: ['', [Validators.required]],
      seeg_subscription_no: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      guardianship: ['', [Validators.required]],
      branch: ['', [Validators.required]],
      workers_men: ['', [Validators.required]],
      workers_women: ['', [Validators.required]],
      confirm_account: ['', [Validators.requiredTrue]],
      selected_bank_id: this.formBuilder.array([], Validators.required)

    });
    if (!this.api.getValidationStatus()) {
      this.api.getFormValidation(this.cnssForm);
    }
    this.getRegionData();
    this.getDistrictData();
    this.getNeighbourhoodList();
    this.getCompanyData();
  }

  onSubmitCnssForm() {
    console.log("61", this.cnssForm.getRawValue());
    this.isSubmitted = true;
    if (this.cnssForm.invalid) {
      console.log("61", this.cnssForm.getRawValue());
      console.log("97", this.cnssForm.value);
      return;
    }
    var data = this.cnssForm.getRawValue();
    delete data['confirm_account'];
    data['created_date'] = this.convertOwl(data.created_date)
    data['service_start_date'] = this.convertOwl(data.service_start_date)
    console.log("100", data)
    this.ngxUiLoaderService.start();
    this.api.cnssApprovalForm(data).pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          this.response = data;
          if (this.response.status == 200) {
            this.isSubmitted = false;
            this.router.navigate(['/dashboard'], { state: { show_modal: '2' } });
          } else {
            this.isSubmitted = false;
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.isSubmitted = false;
          this.ngxUiLoaderService.stop();
        });

  }
  makeInputReadOnly() {
    Object.keys(this.cnssForm.controls).forEach(controlName => {
      const control = this.cnssForm.get(controlName);
      if (control && control.value !== null && control.value !== undefined && control.value !== '') {
        control.disable();
      }
    });
  }


  setCnssFormValues() {
    console.log("138")
    this.cnssForm.controls['company_name'].setValue(this.companyDetails.company_name);
    this.cnssForm.controls['trade_name'].setValue(this.companyDetails.trade_name);
    this.cnssForm.controls['sigle'].setValue(this.companyDetails.acrononym);
    this.cnssForm.controls['district'].setValue(this.companyDetails.district);
    this.cnssForm.controls['neighborhood'].setValue(this.companyDetails.neighborhood);
    this.cnssForm.controls['rue'].setValue(this.companyDetails.street);
    this.cnssForm.controls['door_address'].setValue(this.companyDetails.door_address);
    this.cnssForm.controls['place'].setValue(this.companyDetails.place);
    this.cnssForm.controls['locality'].setValue(this.companyDetails.locality);
    this.cnssForm.controls['region'].setValue(this.companyDetails.region);
    this.cnssForm.controls['contact_1'].setValue(this.companyDetails.contact_1);
    this.cnssForm.controls['bp'].setValue(this.companyDetails.bp);
    this.cnssForm.controls['contact_2'].setValue(this.companyDetails.contact_2);
    this.cnssForm.controls['fax'].setValue(this.companyDetails.fax);
    this.cnssForm.controls['email'].setValue(this.companyDetails.email);
    this.cnssForm.controls['website'].setValue(this.companyDetails.website);
    this.cnssForm.controls['created_date'].setValue(this.companyDetails.created_date);
    this.cnssForm.controls['service_start_date'].setValue(this.companyDetails.service_started_date);
    this.cnssForm.controls['legal_status'].setValue(this.companyDetails.legal_status);
    this.cnssForm.controls['employer_plan'].setValue(this.companyDetails.employer_plan);
    this.cnssForm.controls['tax_no'].setValue(this.companyDetails.tax_no);
    this.cnssForm.controls['rccm'].setValue(this.companyDetails.rccm);
    this.cnssForm.controls['nif'].setValue(this.companyDetails.nif);
    this.cnssForm.controls['seeg_subscription_no'].setValue(this.companyDetails.seeg_subscription_no);
    this.cnssForm.controls['guardianship'].setValue(this.companyDetails.guardianship);
    this.cnssForm.controls['branch'].setValue(this.companyDetails.branch_of_activity);
    this.cnssForm.controls['workers_men'].setValue(this.companyDetails.workers_men);
    this.cnssForm.controls['workers_women'].setValue(this.companyDetails.workers_women);
    if (this.bankdata) {
      this.bankdata.forEach(item => {
        this.selectedBanks.push(this.formBuilder.control(item.bank_id));
      });
    } else {
      this.selectedBanks.clearValidators();
      this.selectedBanks.updateValueAndValidity();
    }
    console.log(this.cnssForm.value)
    // const initialValue = this.cnssForm.get('banks').value;
    // if(initialValue){
    //   this.showSelectedGridChild(initialValue);
    // }    
  }
  get selectedBanks(): FormArray {
    return this.cnssForm.get('selected_bank_id') as FormArray;
  }
  getRegionData() {
    this.ngxUiLoaderService.start();
    this.api.getRegionList().pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res;
          console.log("541", this.response)
          if (this.response.status == 200) {
            this.regionList = this.response.results;
            console.log("522", this.regionList)
          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }
  getDistrictData() {
    this.ngxUiLoaderService.start();
    this.api.getDistrictData().pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res;
          console.log("211", this.response)
          if (this.response.status == 200) {
            this.districtList = this.response.results;
            console.log("214", this.districtList)
          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }
  getNeighbourhoodList() {
    console.log("612")
    this.ngxUiLoaderService.start();
    this.api.getNeighbourhoodList().pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res;
          console.log("208", this.response)
          if (this.response.status == 200) {
            if (this.response.results.length === 0) {
              this.f.neighbourhood.clearValidators();
              this.f.neighbourhood.updateValueAndValidity();
            } else {
              this.neighborhoodlist = this.response.results;
              console.log("345", this.neighborhoodlist)
            }
          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }
  getCompanyData() {
    this.ngxUiLoaderService.start();
    this.api.getCompanyDetails().pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          this.response = data;
          console.log("237", data);
          if (this.response.status == 200) {
            this.bankdata = this.response.bank_details;
            this.companyDetails = this.response.company_details;
            console.log("239", this.companyDetails)
            if (this.companyDetails) {
              this.setCnssFormValues();
            }
          } else {
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.isSubmitted = false;
          this.ngxUiLoaderService.stop();
        });
  }

  get f() {
    return this.cnssForm.controls;
  }

  goback() {
    this.location.back();
  }
  convertOwl(str) {
    if (str == null)
      return str;
    var mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
      },
      date = str.toString().split(" ");
    if (date.length == 1)
      return str;
    else
      return [date[2], mnths[date[1]], date[3]].join("-");
  }
  // handleChange(e, value, val) {
  //   console.log(value)
  //   console.log()
  //   if (val == 1) {
  //     this.cnssForm.controls['bank_name'].setValue(value);
  //   } else {
  //     this.cnssForm.controls['facility_name'].setValue(value);
  //   }
  // }

  onChange(id: string, isChecked: boolean) {

    if (isChecked) {
      console.log("333")
      this.selectedBanks.push(this.formBuilder.control(id));
    } else {
      console.log("336")
      const index = this.selectedBanks.controls.findIndex(x => x.value === id);
      this.selectedBanks.removeAt(index);
    }
  }
}
