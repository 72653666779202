import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';

@Component({
  selector: 'app-dependence-list',
  templateUrl: './dependence-list.component.html',
  styleUrls: ['./dependence-list.component.scss']
})
export class DependenceListComponent implements OnInit {

  response: any;
  dependantsList: any;

  constructor(private router: Router,
              private api: ApiService,
              private ngxUiLoaderService: NgxUiLoaderService) { }

  ngOnInit() {
    this.getDependantsList();
  }

  getUrl(id) {
    this.router.navigate(['/dependence'], { state: { data: id }} );
  }
  goToCreatSection(id){
    this.router.navigate(['/dependence'], { state: { data: id }} );
  }
  getDependantsList() {
    this.ngxUiLoaderService.start(); 
    this.api.getDependantsList().pipe(first())
    .subscribe(data => {
      this.ngxUiLoaderService.stop();
        this.response = data;
        if (this.response.status == 200) {
          this.dependantsList = this.response.results.employee_nominies;
        }
      },
      error => {
        this.ngxUiLoaderService.stop();
      });
  }

}
