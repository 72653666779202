<app-header></app-header>
    <section class="middle-section getstarted reportsIMain">

        <div class="container-fluid" [hidden]="id">
            <div class="initiale_sestion">
                <div class="head">
                    <div class="d-flex">
                        <a (click)="goback()"><i class="fa fa-angle-left"></i></a>
                        <h4>{{'Manage CNSS' | translate}}</h4>
                    </div>
                    <button id="submit_btn" class="btn btn-main2" data-toggle="modal" data-target="#reportModal">{{'Initiate Reports'| translate}}.</button>
                </div>
            </div>
           <!-- <div class="image-container" *ngIf="!page">

               <img src="assets/images/cnss_pic.png" class="img-fluid">

            	<div class="text"> {{"You haven't initiate any"| translate}}<br> {{'CNSS reports yet.'| translate}}</div>
           	</div> -->
       </div>
            <div class="container-fluid" [hidden]="!id">
                <div class="initiale_sestion">
                    <div class="head">
                        <div class="d-flex">
                            <a (click)="goback()"><i class="fa fa-angle-left"></i></a>
                            <h4 class="form-title-sm">{{'Manage CNSS'| translate}}</h4>
                        </div>
                    </div>
                    <div class="head_2">
                        <h5 *ngIf="employerDetails"> {{employerDetails.quarter}}</h5>
                        <div>
                            <h5 class="ongoingStatus"><i class="fa-solid fa-circle-exclamation"></i> {{'Not yet Submitted'| translate}}</h5>
                            <h5 class="pendingStatus"><i class="fa-solid fa-circle-exclamation"></i> {{'Waiting for Approval'| translate}}</h5>
                            <h5 class="payNowStatus"><i class="fa-solid fa-circle-exclamation"></i> {{'Waiting for payment'| translate}}</h5>
                            <h5 class="rejectedStatus"><i class="fa-solid fa-circle-exclamation"></i> {{'Report Rejected'| translate}}</h5>
                            <h5 class="completedStatus"><i class="fa-solid fa-check"></i>{{'Payment Completed'| translate}}</h5>
                            <i class="fa-solid fa-angle-up"></i>
                        </div>
                    </div>
                </div>
                
                <div  class="cnss_report">
                    <div class="reportTable">
                        <div class="rejectedNote">
                            <h5>{{'Reason for rejection'| translate}}</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit tempora quia, veritatis numquam laboriosam perferendis dignissimos temporibus maxime provident blanditiis culpa laborum excepturi voluptas iure officia reiciendis maiores sunt eaque ipsam, quibusdam quam ab repellat? Nobis expedita quis voluptate voluptatem quae, impedit eveniet harum facilis.</p>
                        </div>
                        <div class="overview">
                            <div class="table_head">
                                <h5 *ngIf="employerDetails">{{'Reports for'| translate}}  {{employerDetails.declaration}}</h5>
                            </div>
                            <div class="container cnss_bg" *ngIf="employerDetails">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <ul class="sec1">
                                            <li><h6 class="t1">{{'CNSS Employer ID'| translate}}&nbsp;: </h6><h6>{{employerDetails.employer_cnss}}</h6></li>
                                            <li><h6 class="t1">{{'Name'| translate}}&nbsp;: </h6><h6>{{employerDetails.employer_cnss}}</h6></li>
                                            <li><h6 class="t1">{{'Post box'| translate}}&nbsp;: </h6><h6>{{employerDetails.employer_cnss}}</h6></li>
                                            <li><h6 class="t1">{{'Cellphone No'| translate}}&nbsp;: </h6><h6>+241 {{employerDetails.Cellphone_No}}</h6></li>
                                            <li><h6 class="t1">{{'Town'| translate}}&nbsp;: </h6><h6>{{employerDetails.city}}</h6></li>
                                            <li><h6 class="t1">{{'Email'| translate}}&nbsp;: </h6><h6>{{employerDetails.email}}</h6></li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <ul class="sec2">
                                            <li><h6 class="t1">{{'Date'| translate}}&nbsp;: </h6><h6>{{employerDetails.date}}</h6></li>
                                            <li><h6 class="t1">{{'Net contribution due'| translate}}&nbsp;:</h6><h6>{{employerDetails.net_contribution_due}}</h6></li>
                                            <li><h6 class="t1">{{'Contribution paid to CNSS'| translate}}&nbsp;:</h6><h6>{{employerDetails.contribution_paid}}</h6></li>
                                        </ul>
                                        <div class="total_table">
                                            <ul>
                                                <li class="justify-content-between"><h6 class="">{{'Contribution rate'| translate}}&nbsp;: </h6><h6>{{employerDetails.contribution_paid}} %</h6></li>
                                                <li class="justify-content-between"><h6 class="t1">{{'Employer'| translate}}&nbsp;: </h6><h6>{{employerDetails.total_amount_employer}} %</h6></li>
                                                <li class="justify-content-between"><h6 class="t1">{{'Worker'| translate}}&nbsp;: </h6><h6>{{employerDetails.total_amount_workers}} %</h6></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        <div class="table-container" *ngIf="employerDetails && employerDetails.status == '0'">
                            <form>
                                <table class="quarter_table" *ngFor="let report of cnssReport">
                                	<caption>{{'Reports for'| translate}}  {{report.date}}</caption>
                     
                                    <colgroup>
                                        <col span="1" class="slno">
                                        <col span="1" class="ccpsId">
                                        <col span="1" class="cnssId">

                                        <col span="1" class="name">
                                        <col span="1" class="hgDate">
                                        <col span="1" class="tnDate">

                                        <col span="1" class="hour">
                                        <col span="1" class="wd">
                                        <col span="1" class="salary"> 
                                    </colgroup>
                                    <thead>
                                        <tr>
                                        <th scope="col">{{'SL NO'| translate}}</th>
                                        <th scope="col">{{'CCPS ID'| translate}}</th>
                                        <th scope="col">{{'CNSS ID'| translate}}</th>
                                        <th scope="col">{{'Name'| translate}}</th>
                                        <th scope="col">{{'Hiring Date'| translate}}</th>
                                        <th scope="col">{{'Termination Date'| translate}}</th>
                                        <th scope="col">{{'Working hours'| translate}}</th>
                                        <th scope="col">{{'Working Days'| translate}}</th>
                                        <th scope="col">{{'Salary1'| translate}}/{{'Month2'| translate}}(FCFA)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let employee of report.employees; let i = index">
                                            <td attr.data-label="{{'SL NO' | translate}}">{{i + 1}}</td>
                                            <td *ngIf="!employee.ccpsid" attr.data-label="{{'CCPS ID' | translate}}">--</td>
                                            <td *ngIf="employee.ccpsid" attr.data-label="{{'CCPS ID' | translate}}">{{employee.ccpsid}}</td>
                                            <td *ngIf="!employee.cnssid" attr.data-label="{{'CNSS ID' | translate}}">--</td>
                                            <td *ngIf="employee.cnssid" attr.data-label="{{'CNSS ID' | translate}}">{{employee.cnssid}}</td>
                                            <td attr.data-label="{{'Name' | translate}}">{{employee.name}}</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">{{employee.hiring_date}}</td>
                                            <td *ngIf="!employee.cnssid" attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td *ngIf="employee.cnssid" attr.data-label="{{'Termination Date' | translate}}">{{employee.termination_date}}</td>
                                            <td attr.data-label="{{'Working hours' | translate}}">{{employee.working_hour}}</td>
                                            <td attr.data-label="{{'Working Days' | translate}}">{{employee.working_days}}</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">{{employee.salary}}</td>
                                        </tr>
                                      
                                    </tbody>
                          
                                </table>
                            </form>
                        </div>
                       <!--  <div class="table-container">
                            <form>
                                <table class="quarter_table">
                                    <caption>{{'Reports for'| translate}}  {{'February'| translate}} 2022</caption>
                                    <colgroup>
                                        <col span="1" class="slno">
                                        <col span="1" class="ccpsId">
                                        <col span="1" class="cnssId">

                                        <col span="1" class="name">
                                        <col span="1" class="hgDate">
                                        <col span="1" class="tnDate">

                                        <col span="1" class="hour">
                                        <col span="1" class="wd">
                                        <col span="1" class="salary">
                                        </colgroup>
                                    <thead>
                                        <tr>
                                        <th scope="col">{{'SL NO'| translate}}</th>
                                        <th scope="col">{{'CCPS ID'| translate}}</th>
                                        <th scope="col">{{'CNSS ID'| translate}}</th>
                                        <th scope="col">{{'Name'| translate}}</th>
                                        <th scope="col">{{'Hiring Date'| translate}}</th>
                                        <th scope="col">{{'Termination Date'| translate}}</th>
                                        <th scope="col">{{'Working hours'| translate}}</th>
                                        <th scope="col">{{'Working Days'| translate}}</th>
                                        <th scope="col">{{'Salary1'| translate}}/ {{'Month2'| translate}}(FCFA)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">1</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC594615</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN594615</td>
                                            <td attr.data-label="{{'Name' | translate}}">Martha</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="10000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">2</td> 
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534168</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN534168</td>
                                            <td attr.data-label="{{'Name' | translate}}">Jack merci</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="20000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">3</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC573215</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN573215</td>
                                            <td attr.data-label="{{'Name' | translate}}">Barthoz peki</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="18000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">4</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534518</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN534518</td>
                                            <td attr.data-label="{{'Name' | translate}}">Ronin malof</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">14/2/2022</td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="14"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="25000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">5</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC458936</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN458936</td>
                                            <td attr.data-label="{{'Name' | translate}}">John snow</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="15000 "></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                        <div class="table-container">
                            <form>
                                <table class="quarter_table">
                                    <caption>{{'Reports for'| translate}} {{'March'| translate}} 2022</caption>
                                    <colgroup>
                                        <col span="1" class="slno">
                                        <col span="1" class="ccpsId">
                                        <col span="1" class="cnssId">

                                        <col span="1" class="name">
                                        <col span="1" class="hgDate">
                                        <col span="1" class="tnDate">

                                        <col span="1" class="hour">
                                        <col span="1" class="wd">
                                        <col span="1" class="salary">
                                        </colgroup>
                                    <thead>
                                        <tr>
                                        <th scope="col">{{'SL NO'| translate}}</th>
                                        <th scope="col">{{'CCPS ID'| translate}}</th>
                                        <th scope="col">{{'CNSS ID'| translate}}</th>
                                        <th scope="col">{{'Name'| translate}}</th>
                                        <th scope="col">{{'Hiring Date'| translate}}</th>
                                        <th scope="col">{{'Termination Date'| translate}}</th>
                                        <th scope="col">{{'Working hours'| translate}}</th>
                                        <th scope="col">{{'Working Days'| translate}}</th>
                                        <th scope="col">{{'Salary1'| translate}}/{{'Month2'| translate}}(FCFA)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td  attr.data-label="{{'SL NO' | translate}}">1</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC594615</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN594615</td>
                                            <td attr.data-label="{{'Name' | translate}}">Martha</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--
                                          
                                        	</td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="10000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">2</td> 
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534168</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN534168</td>
                                            <td attr.data-label="{{'Name' | translate}}">Jack merci</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="20000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">3</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC573215</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN573215</td>
                                            <td attr.data-label="{{'Name' | translate}}">Barthoz peki</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="18000 "></td>
                                        </tr>
                                     
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">5</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC458936</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN458936</td>
                                            <td attr.data-label="{{'Name' | translate}}">John snow</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="15000 "></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div> -->
                        <div *ngIf="employerDetails && employerDetails.status != '0'" class="table-container">
                            <form>
                                <table class="payment_table"  *ngFor="let report of cnssReport">
                                    <caption>{{'Reports for'| translate}}  {{report.date}} </caption>
                                    <colgroup>
                                        <col span="1" class="slno">
                                        <col span="1" class="ccpsId">
                                        <col span="1" class="cnssId">

                                        <col span="1" class="name">
                                        <col span="1" class="hgDate">
                                        <col span="1" class="tnDate">

                                        <col span="1" class="wHD">
                                        <col span="1" class="salary">
                                        <col span="1" class="contribution_col">
                                       

                                        <col span="1" class="wcCol">
                                        <col span="1" class="amount">
                                        </colgroup>
                                        
                                    <thead>
                                        <tr>
                                        <th scope="col">{{'SL NO'| translate}}</th>
                                        <th scope="col">{{'CCPS ID'| translate}}</th>
                                        <th scope="col">{{'CNSS ID'| translate}}</th>
                                        <th scope="col">{{'Name'| translate}}</th>
                                        <th scope="col">{{'Hiring Date'| translate}}</th>
                                        <th scope="col">{{'Termination Date'| translate}}</th>
                                        <th scope="col">{{'Working hours/day'| translate}}</th>
                                        <th scope="col">{{'Salary'| translate}}/ {{'Month'| translate}}(FCFA)</th>
                                        <th scope="col">{{'Employer Contribution'| translate}}</th>
                                        

                                        <th scope="col">{{'Worker Contribution'| translate}}</th>
                                        <th scope="col">{{'Amount'| translate}}(FCFA)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let employee of report.employees; let i = index">
                                            <td  attr.data-label="{{'SL NO' | translate}}">{{i + 1}}</td>
                                            <td *ngIf="!employee.ccpsid" attr.data-label="{{'CCPS ID' | translate}}">--</td>
                                            <td *ngIf="employee.ccpsid" attr.data-label="{{'CCPS ID' | translate}}">{{employee.ccpsid}}</td>
                                            <td *ngIf="!employee.cnssid" attr.data-label="{{'CNSS ID' | translate}}">{{employee.cnssid}}</td>
                                            <td *ngIf="employee.cnssid" attr.data-label="{{'CNSS ID' | translate}}">{{employee.ccpsid}}</td>
                                            <td attr.data-label="{{'Name' | translate}}">{{employee.name}}</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">{{employee.hiring_date}}</td>
                                            <td  *ngIf="!employee.termination_date" attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td *ngIf="employee.termination_date" attr.data-label="{{'Termination Date' | translate}}">{{employee.termination_date}}</td>
                                            <td data-label="Working hours/day">{{employee.working_hour}}</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">{{employee.salary}}</td>
                                            <td data-label="Employer Contribution">{{employee.employer_share_total}}</td>
                                            <td data-label="Worker Contribution">{{employee.employee_share_total}}</td>
                                            <td data-label="Amount">{{employee.amount}}</td>
                                        </tr>
                                 
                                        <tr>
                                            <td class="showRows" attr.data-label="{{'SL NO' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'CCPS ID' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'CNSS ID' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Name' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Hiring Date' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Termination Date' | translate}}"></td>
                                            <td class="showRows" data-label="Working hours/day"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">{{report.totalsalary}} FCFA</td>
                                            <td data-label="Employer Contribution">{{report.totalemployer}} FCFA</td>
                                            <td data-label="Worker Contribution">{{report.totalworker}} FCFA</td>
                                            <td data-label="Amount">{{report.totalamount}} FCFA</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                            
                        </div>
                       <!--  <div class="table-container">
                            <form>
                                <table class="payment_table">
                                    <caption>{{'Reports for'| translate}} 2022 {{'February'| translate}}</caption>
                                    <colgroup>
                                        <col span="1" class="slno">
                                        <col span="1" class="ccpsId">
                                        <col span="1" class="cnssId">

                                        <col span="1" class="name">
                                        <col span="1" class="hgDate">
                                        <col span="1" class="tnDate">

                                        <col span="1" class="wHD">
                                        <col span="1" class="salary">
                                        <col span="1" class="contribution_col">
                                       

                                        <col span="1" class="wcCol">
                                        <col span="1" class="amount">
                                        </colgroup>
                                        
                                    <thead>
                                        <tr>
                                        <th scope="col">{{'SL NO'| translate}}</th>
                                        <th scope="col">{{'CCPS ID'| translate}}</th>
                                        <th scope="col">{{'CNSS ID'| translate}}</th>
                                        <th scope="col">{{'Name'| translate}}</th>
                                        <th scope="col">{{'Hiring Date'| translate}}</th>
                                        <th scope="col">{{'Termination Date'| translate}}</th>
                                        <th scope="col">{{'Working hours/day'| translate}}</th>
                                        <th scope="col">{{'Salary'| translate}}/ {{'Month'| translate}}(FCFA)</th>
                                        <th scope="col">{{'Employer Contribution'| translate}}</th>
                                        

                                        <th scope="col">{{'Worker Contribution'| translate}}</th>
                                        <th scope="col">{{'Amount'| translate}}(FCFA)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td  attr.data-label="{{'SL NO' | translate}}">1</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC594615</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN594615</td>
                                            <td attr.data-label="{{'Name' | translate}}">Martha</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">2</td> 
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534168</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN534168</td>
                                            <td attr.data-label="{{'Name' | translate}}">Jack merci</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">3</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC573215</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN573215</td>
                                            <td attr.data-label="{{'Name' | translate}}">Barthoz peki</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">4</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534518</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN534518</td>
                                            <td attr.data-label="{{'Name' | translate}}">Ronin malof</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">5</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC458936</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN458936</td>
                                            <td attr.data-label="{{'Name' | translate}}">John snow</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td class="showRows" attr.data-label="{{'SL NO' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'CCPS ID' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'CNSS ID' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Name' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Hiring Date' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Termination Date' | translate}}"></td>
                                            <td class="showRows" data-label="Working hours/day"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">88 000 FCFA</td>
                                            <td data-label="Employer Contribution">3 608 FCFA</td>
                                            <td data-label="Worker Contribution">1 760 FCFA</td>
                                            <td data-label="Amount">5 368 FCFA</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                        <div class="table-container">
                            <form>
                                <table class="payment_table">
                         
                                    <caption>{{'Reports for'| translate}} 2022 {{'March'| translate}}</caption>
                                    <colgroup>
                                        <col span="1" class="slno">
                                        <col span="1" class="ccpsId">
                                        <col span="1" class="cnssId">

                                        <col span="1" class="name">
                                        <col span="1" class="hgDate">
                                        <col span="1" class="tnDate">

                                        <col span="1" class="wHD">
                                        <col span="1" class="salary">
                                        <col span="1" class="contribution_col">
                                       

                                        <col span="1" class="wcCol">
                                        <col span="1" class="amount">
                                        </colgroup>
                                        
                                    <thead>
                                        <tr>
                                        <th scope="col">{{'SL NO'| translate}}</th>
                                        <th scope="col">{{'CCPS ID'| translate}}</th>
                                        <th scope="col">{{'CNSS ID'| translate}}</th>
                                        <th scope="col">{{'Name'| translate}}</th>
                                        <th scope="col">{{'Hiring Date'| translate}}</th>
                                        <th scope="col">{{'Termination Date'| translate}}</th>
                                        <th scope="col">{{'Working hours/day'| translate}}</th>
                                        <th scope="col">{{'Salary'| translate}}/ {{'Month'| translate}}(FCFA)</th>
                                        <th scope="col">{{'Employer Contribution'| translate}}</th>
                                        

                                        <th scope="col">{{'Worker Contribution'| translate}}</th>
                                        <th scope="col">{{'Amount'| translate}}(FCFA)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td  attr.data-label="{{'SL NO' | translate}}">1</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC594615</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN594615</td>
                                            <td attr.data-label="{{'Name' | translate}}">Martha</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">2</td> 
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534168</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN534168</td>
                                            <td attr.data-label="{{'Name' | translate}}">Jack merci</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">3</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC573215</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN573215</td>
                                            <td attr.data-label="{{'Name' | translate}}">Barthoz peki</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">4</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534518</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN534518</td>
                                            <td attr.data-label="{{'Name' | translate}}">Ronin malof</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">5</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC458936</td>
                                            <td attr.data-label="{{'CNSS ID' | translate}}">CN458936</td>
                                            <td attr.data-label="{{'Name' | translate}}">John snow</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">--</td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td class="showRows" attr.data-label="{{'SL NO' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'CCPS ID' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'CNSS ID' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Name' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Hiring Date' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Termination Date' | translate}}"></td>
                                            <td class="showRows" data-label="Working hours/day"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">88 000 FCFA</td>
                                            <td data-label="Employer Contribution">3 608 FCFA</td>
                                            <td data-label="Worker Contribution">1 760 FCFA</td>
                                            <td data-label="Amount">5 368 FCFA</td>
                                        </tr>
                                    </tbody>
                                 
                                </table>
                            </form>
                        </div>
 -->
                        <div class="tableFooter">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    En cliquant sur <b>Soumettre la déclaration, vous attestez que les informations déclarées reflètent la réalité de la situation sociale de l'entreprise.</b> 
                                </label>
                              </div>
                              <button *ngIf="id != 'rejectedCnss'" id="submit_btn" class="btn btn-main2" data-toggle="modal" data-target="#successModal">{{'Submit Report'| translate}}</button>
                              <button *ngIf="id == 'rejectedCnss'" id="resubMit_btn" (click)="goToResubmit()"  class="btn btn-main2">{{'Resubmit'| translate}}</button>
                        </div>

                        <div class="tableFooter2">
                            <div *ngIf="reportAllList" class="total_each">
                                <h6>{{'Total Amount by Employer'| translate}} : {{reportAllList.amount_by_employer}} FCFA</h6>
                                <h6>{{'Total Amount by worker'| translate}} : {{reportAllList.amount_by_worker}} FCFA</h6>
                                <h6>{{'Total Salary provided'| translate}} : {{reportAllList.total_salary}} FCFA</h6>
                            </div>
                            <div *ngIf="reportAllList" class="total_amount">
                                <h4>{{'Total Amount to be paid to CNSS'| translate}} : <span>{{reportAllList.paid_to_cnss}} FCFA</span></h4>
                            </div>
                            <button *ngIf="reportAllList" class="btn btn-main2"  (click)="paymentSubmit()">{{'Pay'| translate}} {{reportAllList.paid_to_cnss}} FCFA</button>
                        </div>

                        <div class="tableFooter3">
                            <div *ngIf="reportAllList" class="total_each">
                                <h6>{{'Total Amount by Employer'| translate}} : {{reportAllList.total_employer}} FCFA</h6>
                                <h6 >{{'Total Amount by worker'| translate}} : {{reportAllList.total_worker}} FCFA</h6>
                                <h6>{{'Total Salary provided'| translate}} : {{reportAllList.total_salary}} FCFA</h6>
                            </div>
                            <div class="total_amount">
                                <!-- <h3 *ngIf="id != 'completedCnss'">{{'Pay via CLIK'| translate}}</h3> -->
                                <h3 *ngIf="id == 'completedCnss' && employerDetails">{{'Paid via'| translate}} {{employerDetails.payment_gateway}}<i class="fa-solid fa-check"></i></h3>
                 <!--                <h4 *ngIf="id != 'completedCnss'">{{'Total Amount to be paid to CNSS'| translate}} : <span>14 579 FCFA</span></h4>
                                <h4 *ngIf="id == 'completedCnss'">{{'Total Amount paid to CNSS'| translate}} : <span>14 579 FCFA</span></h4> -->
                            </div>
                        </div>
                    </div>
                </div>

                 <!-- <div  class="cnamgs_report">
                    <div class="reportTable">
                        <div class="rejectedNote">
                            <h5>{{'Reason for rejection'| translate}}</h5>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Fugit tempora quia, veritatis numquam laboriosam perferendis dignissimos temporibus maxime provident blanditiis culpa laborum excepturi voluptas iure officia reiciendis maiores sunt eaque ipsam, quibusdam quam ab repellat? Nobis expedita quis voluptate voluptatem quae, impedit eveniet harum facilis.</p>
                        </div>
                        <div class="overview">
                            <div class="table_head">
                                <h5>{{'Reports for'| translate}} 2022 {{'First Quarter'| translate}}</h5>
                            </div>
                            <div class="container cnamgs_bg">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <ul class="sec1">
                                            <li><h6 class="t1">{{'CNAMGS Employer ID'| translate}} : </h6><h6>CN2352355</h6></li>
                                            <li><h6 class="t1">{{'Name'| translate}}  : </h6><h6>Terrence Bongoboue</h6></li>
                                            <li><h6 class="t1">{{'Post box'| translate}}  : </h6><h6>682023</h6></li>
                                            <li><h6 class="t1">{{'Cellphone No'| translate}}  : </h6><h6>+241 321 34 452</h6></li>
                                            <li><h6 class="t1">{{'Town'| translate}}  : </h6><h6>Gabon</h6></li>
                                            <li><h6 class="t1">{{'Fax'| translate}} : </h6><h6>23536426</h6></li>
                                            <li><h6 class="t1">{{'Email'| translate}}  : </h6><h6>fedrikschwegnar@gmail.com</h6></li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <ul class="sec2">
                                            <li><h6 class="t1">{{'Date'| translate}} : </h6><h6>24/03/2022</h6></li>
                                            <li><h6 class="t1">{{'Net contribution due'| translate}}  :</h6></li>
                                            <li *ngIf="!page"><h6 class="t1">{{'Contribution paid to CNSS'| translate}}  :</h6></li>
                                            <li *ngIf="page"><h6 class="t1">{{'Contribution paid to cnamgs'| translate}}  :</h6></li>
                                        </ul>
                                        <div class="total_table">
                                            <ul>
                                                <li class="justify-content-between"><h6 class="">{{'Contribution rate'| translate}} : </h6><h6>6.1 %</h6></li>
                                                <li class="justify-content-between"><h6 class="t1">{{'Employer'| translate}} : </h6><h6>4.1 %</h6></li>
                                                <li class="justify-content-between"><h6 class="t1">{{'Worker'| translate}} : </h6><h6>2.0 %</h6></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        

                        <div class="table-container">
                            <form>
                                <table class="quarter_table">
                        
                                    <caption>{{'Reports for'| translate}}  {{'January'| translate}} 2022</caption>
                                    <colgroup>
                                        <col span="1" class="slno">
                                        <col span="1" class="ccpsId">
                                        <col span="1" class="cnamgsId">

                                        <col span="1" class="name">
                                        <col span="1" class="hgDate">
                                        <col span="1" class="tnDate">

                                        <col span="1" class="hour">
                                        <col span="1" class="wd">
                                        <col span="1" class="salary">

                                        
                                    </colgroup>
                                    <thead>
                                        <tr>
                                        <th scope="col">{{'SL NO'| translate}}</th>
                                        <th scope="col">{{'CCPS ID'| translate}}</th>
                                        <th scope="col">{{'CNAMGS ID'| translate}}</th>
                                        <th scope="col">{{'Name'| translate}}</th>
                                        <th scope="col">{{'Hiring Date'| translate}}</th>
                                        <th scope="col">{{'Termination Date'| translate}}</th>
                                        <th scope="col">{{'Working hours'| translate}}</th>
                                        <th scope="col">{{'Working Days'| translate}}</th>
                                        <th scope="col">{{'Salary1'| translate}}/{{'Month2'| translate}}(FCFA)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">1</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC594615</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS594615</td>
                                            <td attr.data-label="{{'Name' | translate}}">Martha</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt60">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt60" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt60></owl-date-time>
                                                </div>
                                            </td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="10000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">2</td> 
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534168</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS534168</td>
                                            <td attr.data-label="{{'Name' | translate}}">Jack merci</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt61">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt61" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt61></owl-date-time>
                                                </div></td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="20000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">3</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC573215</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS573215</td>
                                            <td attr.data-label="{{'Name' | translate}}">Barthoz peki</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt62">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt62" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt62></owl-date-time>
                                                </div></td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="18000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">4</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534518</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS534518</td>
                                            <td attr.data-label="{{'Name' | translate}}">Ronin malof</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt63">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt63" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt63></owl-date-time>
                                                </div></td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="25000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">5</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC458936</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS458936</td>
                                            <td attr.data-label="{{'Name' | translate}}">John snow</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt64">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt64" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt64></owl-date-time>
                                                </div></td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="15000 "></td>
                                        </tr>
                                    </tbody>
                        
                                </table>
                            </form>
                        </div>
                        <div class="table-container">
                            <form>
                                <table class="quarter_table">
                                    <caption>{{'Reports for'| translate}}  {{'February'| translate}} 2022</caption>
                                    <colgroup>
                                        <col span="1" class="slno">
                                        <col span="1" class="ccpsId">
                                        <col span="1" class="cnamgsId">

                                        <col span="1" class="name">
                                        <col span="1" class="hgDate">
                                        <col span="1" class="tnDate">

                                        <col span="1" class="hour">
                                        <col span="1" class="wd">
                                        <col span="1" class="salary">
                                        </colgroup>
                                    <thead>
                                        <tr>
                                        <th scope="col">{{'SL NO'| translate}}</th>
                                        <th scope="col">{{'CCPS ID'| translate}}</th>
                                        <th scope="col">{{'CNAMGS ID'| translate}}</th>
                                        <th scope="col">{{'Name'| translate}}</th>
                                        <th scope="col">{{'Hiring Date'| translate}}</th>
                                        <th scope="col">{{'Termination Date'| translate}}</th>
                                        <th scope="col">{{'Working hours'| translate}}</th>
                                        <th scope="col">{{'Working Days'| translate}}</th>
                                        <th scope="col">{{'Salary1'| translate}}/ {{'Month2'| translate}}(FCFA)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">1</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC594615</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS594615</td>
                                            <td attr.data-label="{{'Name' | translate}}">Martha</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt65">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt65" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt65></owl-date-time>
                                                </div></td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="10000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">2</td> 
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534168</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS534168</td>
                                            <td attr.data-label="{{'Name' | translate}}">Jack merci</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt66">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt66" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt66></owl-date-time>
                                                </div></td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="20000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">3</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC573215</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS573215</td>
                                            <td attr.data-label="{{'Name' | translate}}">Barthoz peki</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt67">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt67" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt67></owl-date-time>
                                                </div></td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="18000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">4</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534518</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS534518</td>
                                            <td attr.data-label="{{'Name' | translate}}">Ronin malof</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt68">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt68" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt68></owl-date-time>
                                                </div>
                                            </td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="25000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">5</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC458936</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS458936</td>
                                            <td attr.data-label="{{'Name' | translate}}">John snow</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt69">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt69" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt69></owl-date-time>
                                                </div></td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="15000 "></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                        <div class="table-container">
                           <form> 
                                <table class="quarter_table">
                                    <caption>{{'Reports for'| translate}} {{'March'| translate}} 2022</caption>
                                    <colgroup>
                                        <col span="1" class="slno">
                                        <col span="1" class="ccpsId">
                                        <col span="1" class="cnamgsId">

                                        <col span="1" class="name">
                                        <col span="1" class="hgDate">
                                        <col span="1" class="tnDate">

                                        <col span="1" class="hour">
                                        <col span="1" class="wd">
                                        <col span="1" class="salary">
                                        </colgroup>
                                    <thead>
                                        <tr>
                                        <th scope="col">{{'SL NO'| translate}}</th>
                                        <th scope="col">{{'CCPS ID'| translate}}</th>
                                        <th scope="col">{{'CNAMGS ID'| translate}}</th>
                                        <th scope="col">{{'Name'| translate}}</th>
                                        <th scope="col">{{'Hiring Date'| translate}}</th>
                                        <th scope="col">{{'Termination Date'| translate}}</th>
                                        <th scope="col">{{'Working hours'| translate}}</th>
                                        <th scope="col">{{'Working Days'| translate}}</th>
                                        <th scope="col">{{'Salary1'| translate}}/{{'Month2'| translate}}(FCFA)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td  attr.data-label="{{'SL NO' | translate}}">1</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC594615</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS594615</td>
                                            <td attr.data-label="{{'Name' | translate}}">Martha</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt70">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt70" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt70></owl-date-time>
                                                </div></td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="10000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">2</td> 
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534168</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS534168</td>
                                            <td attr.data-label="{{'Name' | translate}}">Jack merci</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt71">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt71" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt71></owl-date-time>
                                                </div></td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="20000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">3</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC573215</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS573215</td>
                                            <td attr.data-label="{{'Name' | translate}}">Barthoz peki</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt72">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt72" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt72></owl-date-time>
                                                </div></td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="18000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">4</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534518</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS534518</td>
                                            <td attr.data-label="{{'Name' | translate}}">Ronin malof</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt73">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt73" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt73></owl-date-time>
                                                </div></td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="25000 "></td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">5</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC458936</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS458936</td>
                                            <td attr.data-label="{{'Name' | translate}}">John snow</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt74">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt74" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt74></owl-date-time>
                                                </div>
                                            </td>
                                            <td attr.data-label="{{'Working hours' | translate}}"><input type="text" readonly value="8"></td>
                                            <td attr.data-label="{{'Working Days' | translate}}"><input type="text"readonly value="30"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input type="text"  value="15000 "></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                        <div class="table-container">
                            <form>
                                <table class="payment_table">
                                    <caption>{{'Reports for'| translate}} 2022 {{'January'| translate}} </caption>
                                    <colgroup>
                                        <col span="1" class="slno">
                                        <col span="1" class="ccpsId">
                                        <col span="1" class="cnamgsId">

                                        <col span="1" class="name">
                                        <col span="1" class="hgDate">
                                        <col span="1" class="tnDate">

                                        <col span="1" class="wHD">
                                        <col span="1" class="salary">
                                        <col span="1" class="contribution_col">
                                       

                                        <col span="1" class="wcCol">
                                        <col span="1" class="amount">
                                        </colgroup>
                                        
                                    <thead>
                                        <tr>
                                        <th scope="col">{{'SL NO'| translate}}</th>
                                        <th scope="col">{{'CCPS ID'| translate}}</th>
                                        <th scope="col">{{'CNAMGS ID'| translate}}</th>
                                        <th scope="col">{{'Name'| translate}}</th>
                                        <th scope="col">{{'Hiring Date'| translate}}</th>
                                        <th scope="col">{{'Termination Date'| translate}}</th>
                                        <th scope="col">{{'Working hours/day'| translate}} </th>
                                        <th scope="col">{{'Salary'| translate}}/ {{'Month'| translate}}(FCFA)</th>
                                        <th scope="col">{{'Employer Contribution'| translate}}</th>
                                        

                                        <th scope="col">{{'Worker Contribution'| translate}}</th>
                                        <th scope="col">{{'Amount'| translate}}(FCFA)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td  attr.data-label="{{'SL NO' | translate}}">1</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC594615</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS594615</td>
                                            <td attr.data-label="{{'Name' | translate}}">Martha</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt75">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt75" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt75></owl-date-time>
                                                </div></td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">2</td> 
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534168</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS534168</td>
                                            <td attr.data-label="{{'Name' | translate}}">Jack merci</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt76">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt76" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt76></owl-date-time>
                                                </div></td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">3</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC573215</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS573215</td>
                                            <td attr.data-label="{{'Name' | translate}}">Barthoz peki</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt77">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt77" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt77></owl-date-time>
                                                </div></td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">4</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534518</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS534518</td>
                                            <td attr.data-label="{{'Name' | translate}}">Ronin malof</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt78">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt78" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt78></owl-date-time>
                                                </div></td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">5</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC458936</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS458936</td>
                                            <td attr.data-label="{{'Name' | translate}}">John snow</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt79">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt79" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt79></owl-date-time>
                                                </div></td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td class="showRows" attr.data-label="{{'SL NO' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'CCPS ID' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'CNAMGS ID' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Name' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Hiring Date' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt80">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt80" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt80></owl-date-time>
                                                </div></td>
                                            <td class="showRows" data-label="Working hours/day"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">88 000 FCFA</td>
                                            <td data-label="Employer Contribution">3 608 FCFA</td>
                                            <td data-label="Worker Contribution">1 760 FCFA</td>
                                            <td data-label="Amount">5 368 FCFA</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                        <div class="table-container">
                            <form>
                                <table class="payment_table">
                                    <caption>{{'Reports for'| translate}} 2022 {{'February'| translate}}</caption>
                                    <colgroup>
                                        <col span="1" class="slno">
                                        <col span="1" class="ccpsId">
                                        <col span="1" class="cnamgsId">

                                        <col span="1" class="name">
                                        <col span="1" class="hgDate">
                                        <col span="1" class="tnDate">

                                        <col span="1" class="wHD">
                                        <col span="1" class="salary">
                                        <col span="1" class="contribution_col">
                                       

                                        <col span="1" class="wcCol">
                                        <col span="1" class="amount">
                                        </colgroup>
                                        
                                    <thead>
                                        <tr>
                                        <th scope="col">{{'SL NO'| translate}}</th>
                                        <th scope="col">{{'CCPS ID'| translate}}</th>
                                        <th scope="col">{{'CNAMGS ID'| translate}}</th>
                                        <th scope="col">{{'Name'| translate}}</th>
                                        <th scope="col">{{'Hiring Date'| translate}}</th>
                                        <th scope="col">{{'Termination Date'| translate}}</th>
                                        <th scope="col">{{'Working hours/day'| translate}}</th>
                                        <th scope="col">{{'Salary'| translate}}/ {{'Month'| translate}}(FCFA)</th>
                                        <th scope="col">{{'Employer Contribution'| translate}}</th>
                                        

                                        <th scope="col">{{'Worker Contribution'| translate}}</th>
                                        <th scope="col">{{'Amount'| translate}}(FCFA)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td  attr.data-label="{{'SL NO' | translate}}">1</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC594615</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS594615</td>
                                            <td attr.data-label="{{'Name' | translate}}">Martha</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt81">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt81" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt81></owl-date-time>
                                                </div></td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">2</td> 
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534168</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS534168</td>
                                            <td attr.data-label="{{'Name' | translate}}">Jack merci</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt82">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt82" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt82></owl-date-time>
                                                </div></td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">3</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC573215</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS573215</td>
                                            <td attr.data-label="{{'Name' | translate}}">Barthoz peki</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt83">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt83" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt83></owl-date-time>
                                                </div></td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">4</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534518</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS534518</td>
                                            <td attr.data-label="{{'Name' | translate}}">Ronin malof</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt84">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt84" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt84></owl-date-time>
                                                </div></td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">5</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC458936</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS458936</td>
                                            <td attr.data-label="{{'Name' | translate}}">John snow</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt85">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt85" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt85></owl-date-time>
                                                </div></td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td class="showRows" attr.data-label="{{'SL NO' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'CCPS ID' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'CNAMGS ID' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Name' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Hiring Date' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt86">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt86" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt86></owl-date-time>
                                                </div></td>
                                            <td class="showRows" data-label="Working hours/day"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">88 000 FCFA</td>
                                            <td data-label="Employer Contribution">3 608 FCFA</td>
                                            <td data-label="Worker Contribution">1 760 FCFA</td>
                                            <td data-label="Amount">5 368 FCFA</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </form>
                        </div>
                        <div class="table-container">
                            <form>
                                <table class="payment_table">
                 
                                    <caption>{{'Reports for'| translate}} 2022 {{'March'| translate}}</caption>
                                    <colgroup>
                                        <col span="1" class="slno">
                                        <col span="1" class="ccpsId">
                                        <col span="1" class="cnamgsId">

                                        <col span="1" class="name">
                                        <col span="1" class="hgDate">
                                        <col span="1" class="tnDate">

                                        <col span="1" class="wHD">
                                        <col span="1" class="salary">
                                        <col span="1" class="contribution_col">
                                       

                                        <col span="1" class="wcCol">
                                        <col span="1" class="amount">
                                        </colgroup>
                                        
                                    <thead>
                                        <tr>
                                        <th scope="col">{{'SL NO'| translate}}</th>
                                        <th scope="col">{{'CCPS ID'| translate}}</th>
                                        <th scope="col">{{'CNAMGS ID'| translate}}</th>
                                        <th scope="col">{{'Name'| translate}}</th>
                                        <th scope="col">{{'Hiring Date'| translate}}</th>
                                        <th scope="col">{{'Termination Date'| translate}}</th>
                                        <th scope="col">{{'Working hours/day'| translate}}</th>
                                        <th scope="col">{{'Salary'| translate}}/ {{'Month'| translate}}(FCFA)</th>
                                        <th scope="col">{{'Employer Contribution'| translate}}</th>
                                        

                                        <th scope="col">{{'Worker Contribution'| translate}}</th>
                                        <th scope="col">{{'Amount'| translate}}(FCFA)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td  attr.data-label="{{'SL NO' | translate}}">1</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC594615</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS594615</td>
                                            <td attr.data-label="{{'Name' | translate}}">Martha</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt87">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt87" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt87></owl-date-time>
                                                </div></td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">2</td> 
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534168</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS534168</td>
                                            <td attr.data-label="{{'Name' | translate}}">Jack merci</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt88">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt88" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt88></owl-date-time>
                                                </div></td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">3</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC573215</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS573215</td>
                                            <td attr.data-label="{{'Name' | translate}}">Barthoz peki</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt89">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt89" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt89></owl-date-time>
                                                </div></td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">4</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC534518</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS534518</td>
                                            <td attr.data-label="{{'Name' | translate}}">Ronin malof</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt90">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt90" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt90></owl-date-time>
                                                </div></td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td attr.data-label="{{'SL NO' | translate}}">5</td>
                                            <td attr.data-label="{{'CCPS ID' | translate}}">CC458936</td>
                                            <td attr.data-label="{{'CNAMGS ID' | translate}}">CGS458936</td>
                                            <td attr.data-label="{{'Name' | translate}}">John snow</td>
                                            <td attr.data-label="{{'Hiring Date' | translate}}">12/10/2020</td>
                                            <td attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt91">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt91" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt91></owl-date-time>
                                                </div></td>
                                            <td data-label="Working hours/day">8</td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">1 000 </td>
                                            <td data-label="Employer Contribution">30</td>
                                            <td data-label="Worker Contribution">30</td>
                                            <td data-label="Amount">1 000 </td>
                                        </tr>
                                        <tr>
                                            <td class="showRows" attr.data-label="{{'SL NO' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'CCPS ID' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'CNAMGS ID' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Name' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Hiring Date' | translate}}"></td>
                                            <td class="showRows" attr.data-label="{{'Termination Date' | translate}}">
                                                <div class="child-container report-date"  [owlDateTimeTrigger]="dt92">

                                                <div class="date-field">
                                                    <input class="" [max]="todayDate" [owlDateTime]="dt92" readonly name="dob" >
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                    </div>
                                                </div>
                                                 <owl-date-time [pickerType]="'calendar'"  #dt92></owl-date-time>
                                                </div></td>
                                            <td class="showRows" data-label="Working hours/day"></td>
                                            <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}">88 000 FCFA</td>
                                            <td data-label="Employer Contribution">3 608 FCFA</td>
                                            <td data-label="Worker Contribution">1 760 FCFA</td>
                                            <td data-label="Amount">5 368 FCFA</td>
                                        </tr>
                                    </tbody>
                             
                                </table>
                            </form>
                        </div>

                        <div class="tableFooter">
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                                <label class="form-check-label" for="flexCheckDefault">
                                    En cliquant sur <b>Soumettre la DTS</b>, vous attestez que les informations déclarées reflètent la réalité de la situation sociale de l'entreprise.
                                </label>
                              </div>
                              <button *ngIf="id != 'rejectedCnss'" id="submit_btn" class="btn btn-main2" data-toggle="modal" data-target="#successModal">{{'Submit Report'| translate}}</button>
                              <button *ngIf="id == 'rejectedCnss'" id="resubMit_btn" class="btn btn-main2" data-toggle="modal" data-target="#successModal">{{'Resubmit'| translate}}</button>
                        </div>

                        <div class="tableFooter2">
                            <div class="total_each">
                                <h6>{{'Total Amount by Employer'| translate}} : 9 799 FCFA</h6>
                                <h6>{{'Total Amount by worker'| translate}} : 4 700 FCFA</h6>
                                <h6>{{'Total Salary provided'| translate}} : 239 000 FCFA</h6>
                            </div>
                            <div class="total_amount">
                                <h4>{{'Total Amount to be paid to CNSS'| translate}} : <span>14 579 FCFA</span></h4>
                            </div>
                            <button class="btn btn-main2"  (click)="paymentSubmit()">{{'Pay'| translate}} 14 579 FCFA</button>
                        </div>

                        <div class="tableFooter3">
                            <div class="total_each">
                                <h6>{{'Total Amount by Employer'| translate}} : 9 799 FCFA</h6>
                                <h6>{{'Total Amount by worker'| translate}} : 4 700 FCFA</h6>
                                <h6>{{'Total Salary provided'| translate}} : 239 000 FCFA</h6>
                            </div>
                            <div class="total_amount">
                                <h3>{{'Pay via CLIK'| translate}}</h3>
                                <h4>{{'Total Amount to be paid to CNSS'| translate}} : <span>14 579 FCFA</span></h4>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
               

          
               <div class="modal fade" id="successModal" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content">
                       
                        <div class="modal-body">
                            <div class="parent">
                                <div class="image-container">
                                    <img src="assets/images/link_success.png">
                                </div>
                                <div class="text-container text-center">
                                    <h4 class="modal-header-txt">{{'Your report has been successfully submitted.'| translate}}</h4>
                                    <p class="para-light">{{'review your declaration.' | translate}}</p>
                                   <button id="submit_btn" class="btn btn-medium" (click)="goToViewPage()">{{'Go to home'| translate}}</button> 
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        

        </section>
        <!-- reportList -->

       


        <app-footer></app-footer>   
            <div class="modal fade" id="reportModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLongTitle" *ngIf="!page">{{'Initiate CNSS Reports'| translate}}</h5>
                            <h5 class="modal-title" id="exampleModalLongTitle" *ngIf="page">{{'Initiate CNAMGS Reports'| translate}}</h5>
                            <button type="button" class="close close-modal" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                          </div>
                        <div class="modal-body">
                            <div class="parent">
                                <label for="">{{'Select Year'| translate}}</label>
                                <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                                    <option value="1" selected="selected">2022</option>
                                    <option value="2">2023</option>
                                    <option value="3">2024</option>
                                </select>
                                <div class="parent">
                                    <label for="">{{'Select Quarter'| translate}}</label>
                                        <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example">
                                            <option value="1"selected >{{'One'| translate}}</option>
                                            <option value="2">{{'Two'| translate}}</option>
                                            <option value="3">{{'Three'| translate}}</option>
                                            <option value="4">{{'Four'| translate}}</option>
                                        </select>
                                </div>
                                
                                <button id="submit_btn" class="btn btn-medium" (click)="showCnssList()" >{{'Generate'| translate}}</button> 
                            </div>
                        
                            
                        
                            
                        </div>
                    </div>
                </div>
            </div>   

<app-contact-floating></app-contact-floating>