<app-header></app-header>



    <section class="middle-section getstarted reportsIMain">

        <div class="container-fluid" [hidden]="id">
            <!-- <div *ngIf="accountDetails.cnss == 1 && value != '1'" class="initiale_sestion" id="initiateReportContainer"> -->
            <div *ngIf="accountDetails.cnss == '1'" class="initiale_sestion" id="initiateReportContainer">
                <div class="head">
                    <div class="cnsshead">
                        <div class="d-flex">
                            <a (click)="goback('3')"><i class="fa fa-angle-left"></i></a>
                            <h4 *ngIf="!page">{{'Manage CNSS' | translate}}</h4>
                            <h4 *ngIf="page">{{'Manage CNAMGS'| translate}}</h4>
                            <div class="cnsslogo">
                                <img src="assets/images/logoWithText1.png" class="img-fluid">
                                <p>CAISSE NATIONALE<br>DE SECURITE SOCIALE</p>
                            </div>
                        </div>
                    </div>
                    <button id="submit_btn" class="btn btn-main2" data-toggle="modal" data-target="#reportModal">{{'Initiate Reports'| translate}}</button>         
                </div>
            </div>
            <div *ngIf="!hasCnssForm" class="initiale_sestion">
                <div class="head">
                    <div class="cnsshead">
                        <div class="d-flex">
                            <a (click)="goback('1')"><i class="fa fa-angle-left"></i></a>
                            <h4 *ngIf="!page">{{'Manage CNSS' | translate}}</h4>
                            <h4 *ngIf="page">{{'Manage CNAMGS'| translate}}</h4>
                            <div class="cnsslogo">
                                <img src="assets/images/logoWithText1.png" class="img-fluid">
                                <p>CAISSE NATIONALE<br>DE SECURITE SOCIALE</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="image-container" *ngIf="!page">
                    <!-- <div class="row">
                        <div class="col-md-2">
                            <div class="cnsslogo">
                                <img src="assets/images/logoWithText1.png" class="img-fluid">
                                <p>CAISSE NATIONALE<br>DE SECURITE SOCIALE</p>
                            </div>
                        </div>
                        <div class="col-md-4 offset-md-2">
                            <img src="assets/images/cnss_pic.png" class="img-fluid">
                        </div>
                    </div> -->
                    <img src="assets/images/cnss_pic.png" class="img-fluid">

                    <h3  *ngIf="accountDetails.cnss == '1' && title == 'initiateReport'" class="text"> {{"You haven't initiate any"| translate}}<br> {{'CNSS reports yet.'| translate}}</h3>
                    <h3 *ngIf="accountDetails.cnss == '2' || accountDetails.cnss == '3'" class="text"> {{"You will only be able to initiate a DTS"| translate}}<br> {{'once your account is activated.'| translate}}</h3>
                    <h3 *ngIf="title == 'business'"  class="text"> {{'import business title' | translate}}</h3>
                    <h3 *ngIf="title == 'bank'"  class="text">{{'import bank title' | translate}}</h3>
                    <h3 *ngIf="accountDetails.BankDetails == '1' && accountDetails.cnss == '0'"  class="text">{{'cnssForm title' | translate}} <b>{{'cnssForm title2' | translate}}</b></h3>
                    <h3 *ngIf="title == 'employees'" class="text">{{'add employee title2' | translate}}</h3> 
               </div>
               <div class="image-container" *ngIf="page">
                <img src="assets/images/cnss_pic.png" class="img-fluid">
                <h3 *ngIf="!value" class="text"> {{"You haven't initiate any"| translate}}<br> {{'CNAMGS reports yet.'| translate}}</h3>
               </div>
        </div>
            <div class="container-fluid" [hidden]="!id">
                <div class="initiale_sestion">
                    <div class="head">
                        <div class="d-flex">
                            <a (click)="goback('2')"><i class="fa fa-angle-left"></i></a>
                            <h4 *ngIf="!page" class="form-title-sm">{{'Manage CNSS'| translate}}</h4>
                            <h4 *ngIf="page" class="form-title-sm">{{'Manage CNAMGS'| translate}}</h4>
                        </div>
                        <div class="cnsslogo logocnss">
                            <img src="assets/images/logoWithText1.png" class="img-fluid">
                            <p>CAISSE NATIONALE<br>DE SECURITE SOCIALE</p>
                        </div>
                    </div>
                    <div *ngIf="employerDetails" class="head_2">
                        <h5> {{employerDetails.quarter}}</h5>
                        <div >
                            <h5 *ngIf="employerDetails.status=='3'" class="ongoingStatus"><i class="fa-solid fa-circle-exclamation"></i> {{ employerDetails.status_text | translate }}</h5>
                            <h5 *ngIf="employerDetails.status== '4'" class="rejectedStatus"><i class="fa-solid fa-circle-exclamation"></i> {{'Report Rejected'| translate}}</h5>
                
                            <i class="fa-solid fa-angle-up"></i>
                        </div>
                    </div>
                </div>
                <div class="cnss_report">
                    <div class="reportTable">
                        <div  class="rejectedNote">
                            <h5>{{'Reason for rejection'| translate}}</h5>
                            <p *ngIf="employerDetails" class="text-center">{{ employerDetails.reason}}</p>
                        </div>
                        <div class="overview">
                            <div class="table_head">
                                <h5>{{'Reports for'| translate}} 2022 {{'First Quarter'| translate}}</h5>
                            </div>
                            <div class="container cnss_bg" *ngIf="employerDetails">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <ul class="sec1">
                                            <li><h6 class="t1">{{'CNSS Employer ID'| translate}} : </h6><h6>{{ employerDetails.employer_code}}</h6></li>
                                            <li><h6 class="t1">{{'Name'| translate}}  : </h6><h6>{{ employerDetails.name }}</h6></li>
                                            <li><h6 class="t1">{{'Post box'| translate}}  : </h6><h6>{{ employerDetails.post_box }}</h6></li>
                                            <li><h6 class="t1">{{'Cellphone No'| translate}}  : </h6><h6>+241 {{ employerDetails.mobile }}</h6></li>
                                            <li><h6 class="t1">{{'Town'| translate}}  : </h6><h6>Gabon</h6></li>
                                            <li><h6 class="t1">{{'Email'| translate}}  : </h6><h6>{{ employerDetails.email }}</h6></li>
                                        </ul>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-12">
                                        <ul class="sec2">
                                            <li><h6 class="t1">{{'Date'| translate}} : </h6><h6>{{ employerDetails.date }}</h6></li>
                                            <li><h6 class="t1">{{'Net contribution due'| translate}}  :</h6><h6>{{ employerDetails.net_contribution_due }}</h6></li>
                                            <li *ngIf="!page"><h6 class="t1">{{'Contribution paid to CNSS'| translate}}  :</h6><h6>{{ employerDetails.contribution_paid }}</h6></li>
                                            <li *ngIf="page"><h6 class="t1">{{'Contribution paid to cnamgs'| translate}}  :</h6></li>
                                        </ul>
                                        <div class="total_table">
                                            <ul>
                                                <li class="justify-content-between"><h6 class="">{{'Contribution rate'| translate}} : </h6><h6>{{ employerDetails.contribution_paid }} %</h6></li>
                                                <li class="justify-content-between"><h6 class="t1">{{'Employer'| translate}} : </h6><h6>{{ employerDetails.total_amount_employer }} %</h6></li>
                                                <li class="justify-content-between"><h6 class="t1">{{'Worker'| translate}} : </h6><h6>{{ employerDetails.total_amount_workers }} %</h6></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <form [formGroup]="cnssForm">
                            <div class="table-container">
                                <ng-container formArrayName="reportMonth">
                                    <table class="quarter_table" *ngFor="let quarter of cnssReport;let i = index" [formGroupName]="i">
                                        <caption>{{'Reports for'| translate}}  {{ quarter.date | translate}}</caption>
                                        <colgroup>
                                            <col span="1" class="slno">
                                            <col span="1" class="ccpsId">
                                            <col span="1" class="cnssId">

                                            <col span="1" class="name">
                                            <col span="1" class="hgDate">
                                            <col span="1" class="tnDate">

                                            <col span="1" class="hour">
                                            <col span="1" class="wd">
                                            <col span="1" class="salary">

                                            
                                        </colgroup>
                                        <thead>
                                            <tr>
                                            <th scope="col">{{'SL NO'| translate}}</th>
                                            <th scope="col">{{'Employee CCPS ID'| translate}}</th>
                                            <th scope="col">{{'CNSS ID'| translate}}</th>
                                            <th scope="col">{{'Name'| translate}}</th>
                                            <th scope="col">{{'Hiring Date'| translate}}</th>
                                            <th scope="col">{{'Termination Date'| translate}}</th>
                                            <th scope="col">{{'Working hours'| translate}}</th>
                                            <th scope="col">{{'Working Days'| translate}}</th>
                                            <th scope="col">{{'Salary1'| translate}}/{{'Month2'| translate}}(FCFA)</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <ng-container formArrayName="reportEachemployee">
                                                <tr *ngFor="let item of quarter.employees;let key = index" [formGroupName]="key">
                                                    <td attr.data-label="{{'SL NO' | translate}}">{{key +1}}</td>
                                                    <td attr.data-label="{{'CCPS ID' | translate}}">{{item.ccpsid}}</td>
                                                    <td attr.data-label="{{'CNSS ID' | translate}}">{{item.cnssid}}</td>
                                                    <td attr.data-label="{{'Name' | translate}}">{{item.name}}</td>
                                                    <td attr.data-label="{{'Hiring Date' | translate}}">{{item.hiring_date}}</td>
                                                    <td attr.data-label="{{'Termination Date' | translate}}">{{item.termination_date}}</td>
                                                    <!-- <td attr.data-label="{{'Termination Date' | translate}}">
                                                        <div class="child-container report-date" [owlDateTimeTrigger]="dt45">
                                                            <div class="date-field">
                                                                <input disabled readonly type="text" class="" [max]="todayDate" [owlDateTime]="dt45" name="dob" formControlName="termination_date">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i class="fa fa-calendar"></i></span> 
                                                                </div>
                                                            </div>
                                                            <owl-date-time [pickerType]="'calendar'" #dt45></owl-date-time>
                                                        </div>
                                                    </td> -->
                                                    <td attr.data-label="{{'Working hours' | translate}}"><input formControlName="working_hour" type="text" readonly></td>
                                                    <td attr.data-label="{{'Working Days' | translate}}"><input formControlName="working_days" type="text"></td>
                                                    <td attr.data-label="{{'Salary'| translate}}/{{'Month'| translate}}"><input (input)="onSearchChange($event.target.value)" formControlName="salary" type="text"></td>
                                                </tr>
                                            </ng-container>
                                        </tbody>
                                    </table>
                                </ng-container>
                            </div>
                            <div class="tableFooter">
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="confirm">
                                    <label class="form-check-label" for="flexCheckDefault">
                                        En cliquant sur <b>Soumettre la DTS</b>, vous attestez que les informations déclarées reflètent la réalité de la situation sociale de l'entreprise.
                                    </label>
                                </div>

                                <div *ngIf="submitted && f.confirm.errors" class="invalid-feedback text-center">
                                    <div *ngIf="f.confirm.errors?.required">Please confirm all the data is correct.</div>
                                  </div>
                                <!-- data-toggle="modal" data-target="#successModal"  -->

                                <button *ngIf="id != 'rejectedCnss' && !isResubmit " id="submit_btn" class="btn btn-main2"(click)="onSubmit()">{{'Submit Report'| translate}}</button>
                                <button *ngIf="isResubmit " id="submit_btn" class="btn btn-main2"(click)="onResubmit()">{{'Resubmit Report'| translate}}</button>
                                <button *ngIf="id == 'rejectedCnss'" id="resubMit_btn" class="btn btn-main2" data-toggle="modal" data-target="#successModal">{{'Resubmit'| translate}}</button>
                            </div>
                        </form>


                        <div class="tableFooter2">
                            <div class="total_each">
                                <h6>{{'Total Amount by Employer'| translate}} : 9799 FCFA</h6>
                                <h6>{{'Total Amount by worker'| translate}} : 4700 FCFA</h6>
                                <h6>{{'Total Salary provided'| translate}} : 239 000 FCFA</h6>
                            </div>
                            <div class="total_amount">
                                <h4>{{'Total Amount to be paid to CNAMGS'| translate}} : <span>14579 FCFA</span></h4>
                            </div>
                            <button class="btn btn-main2"  (click)="paymentSubmit()">{{'Pay'| translate}} 14579 FCFA</button>
                        </div>

                        <div class="tableFooter3">
                            <div class="total_each">
                                <h6>{{'Total Amount by Employer'| translate}} : 9799 FCFA</h6>
                                <h6>{{'Total Amount by worker'| translate}} : 4700 FCFA</h6>
                                <h6>{{'Total Salary provided'| translate}} : 239 000 FCFA</h6>
                            </div>
                            <div class="total_amount">
                                <h3>{{'Pay via CLIK'| translate}}</h3>
                                <h4>{{'Total Amount to be paid to CNAMGS'| translate}} : <span>14579 FCFA</span></h4>
                            </div>
                        </div>
                    </div>
                </div>

             
            </div>
               

          
               <div class="modal fade" id="successModal" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content">
                       
                        <div class="modal-body">
                            <div class="parent">
                                <div class="cnsslogo">
                                    <img src="assets/images/logoWithText1.png" class="img-fluid">
                                    <p>CAISSE NATIONALE<br>DE SECURITE SOCIALE</p>
                                </div>
                                <div class="image-container">
                                    <img src="assets/images/link_success.png">
                                </div>
                                <div class="text-container text-center" [hidden]="value">
                                    <h4 class="modal-header-txt">{{'Congratulations! We acknowledge receipt of your DTS'| translate}} {{'Quarter'| translate}} 2, 2023</h4>
                                    <p class="para-light">{{'We will study your declaration and get back to you to enable you to make payment.' | translate}}</p>
                                   <button id="submit_btn" class="btn btn-medium" (click)="goToViewPage()">{{'OK'| translate}}</button> 
                                </div>
                             <!--    <div class="text-container text-center" [hidden]="!value">
                                    <h4 class="modal-header-txt">{{'Congratulations! We acknowledge receipt of your activation request.'| translate}}</h4>
                                    <p class="para-light">{{'We will study your file and get back to you as soon as possible.' | translate}}</p>
                                   <button id="submit_btn" class="btn btn-medium" data-dismiss="modal" (click)="goToViewPage(1)">{{'OK'| translate}}</button> 
                                </div>  -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        

        </section>
        <!-- reportList -->

            <section class="reportsList" [hidden]="!lastsec">
            <div class="container-fluid">
                <div class="initiale_sestion">
                    <div class="head">
                        <h4>{{'Manage CNSS'| translate}}</h4>
                        <button id="submit_btn" class="btn btn-main2" data-toggle="modal" data-target="#reportModal">{{'Initiate Reports'| translate}}</button>
                    </div>
                </div>
                
            </div>
            <div class="mt-4">
                <div class="container-fluid reports_status mt-4">
                    <div class="card-grid">
                        
                        <div class="child1">
                            <div class="dflex">
                                <div class="status pending"><h6>{{'Pending'| translate}}</h6></div>
                                <div class="columns">
                                    <h3 class="sub-title">{{'Second quarter Report'| translate}}2022</h3>
                                    <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Chijioke Okeke</span></h6>
                                    <h6 class="sm_text">{{'CNAMGS Employer ID'| translate}} : <span>CN2352355</span></h6>
                                </div>
                            </div>
                        </div>

                        <div class="child2">
                            <div class="columns">
                                <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                                <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                                <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                            </div>
                        </div>
                        <div class="child3">
                            <div class="columns">
                                <h6 class="sm_text">{{'Fax'| translate}}: <span>23536426</span></h6>
                                <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                                <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28 Oct 2022</span></h6>
                            </div>
                        </div>
                        <div class="child4">
                            <button id="submit_btn" class="btn btn-main2"><a href="reports.html?pendingcnss">{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
                        </div>
                    </div>

                    <div class="card-grid">
                        
                        <div class="child1">
                            <div class="dflex">
                                <div class="status payNow"><h6>{{'PayNow'| translate}}</h6></div>
                                <div class="columns">
                                    <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                                    <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Terrence Bongoboue</span></h6>
                                    <h6 class="sm_text">{{'CNAMGS Employer ID'| translate}} : <span>CN2352355</span></h6>
                                </div>
                            </div>
                        </div>

                        <div class="child2">
                            <div class="columns">
                                <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                                <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                                <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                            </div>
                        </div>
                        <div class="child3">
                            <div class="columns">
                                <h6 class="sm_text">{{'Fax'| translate}}: <span>23536426</span></h6>
                                <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                                <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28 Oct 2022</span></h6>
                            </div>
                        </div>
                        <div class="child4">
                            <a ><button id="submit_btn" class="btn btn-main2">{{'View'| translate}}<br> {{'Details'| translate}}</button></a>
                        </div>
                    </div>

                    <div class="card-grid">
                        
                        <div class="child1">
                            <div class="dflex">
                                <div class="status reject"><h6>{{'Rejected'| translate}}</h6></div>
                                <div class="columns">
                                    <h3 class="sub-title">{{'Second quarter Report'| translate}}2022</h3>
                                    <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Terrence Bongoboue</span></h6>
                                    <h6 class="sm_text">{{'CNAMGS Employer ID'| translate}} : <span>CN2352355</span></h6>
                                </div>
                            </div>
                        </div>

                        <div class="child2">
                            <div class="columns">
                                <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                                <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                                <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                            </div>
                        </div>
                        <div class="child3">
                            <div class="columns">
                                <h6 class="sm_text">{{'Fax'| translate}}: <span>23536426</span></h6>
                                <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                                <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28 Oct 2022</span></h6>
                            </div>
                        </div>
                        <div class="child4">
                            <a href="reports.html?rejectedCnss"><button id="submit_btn" class="btn btn-main2">{{'View'| translate}}<br> {{'Details'| translate}}</button></a>
                        </div>
                    </div>

                    <div class="card-grid completed_bg">
                        
                        <div class="child1">
                            <div class="dflex">
                                <div class="status completed"><h6>{{'Completed'| translate}}</h6></div>
                                <div class="columns">
                                    <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                                    <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Terrence Bongoboue</span></h6>
                                    <h6 class="sm_text">{{'CNAMGS Employer ID'| translate}} : <span>CN2352355</span></h6>
                                </div>
                            </div>
                        </div>

                        <div class="child2">
                            <div class="columns">
                                <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                                <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                                <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                            </div>
                        </div>
                        <div class="child3">
                            <div class="columns">
                                <h6 class="sm_text">{{'Fax'| translate}}: <span>23536426</span></h6>
                                <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                                <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28 Oct 2022</span></h6>
                            </div>
                        </div>
                        <div class="child4">
                            <a href="reports.html?completedCnss"><button id="submit_btn" class="btn btn-main2" data-toggle="modal" data-target="#reportModal">View <br> Details</button></a>
                        </div>
                    </div>
                    <div class="card-grid completed_bg">
                        
                        <div class="child1">
                            <div class="dflex">
                                <div class="status completed"><h6>{{'Completed'| translate}}</h6></div>
                                <div class="columns">
                                    <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                                    <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Terrence Bongoboue</span></h6>
                                    <h6 class="sm_text">{{'CNAMGS Employer ID'| translate}} : <span>CN2352355</span></h6>
                                </div>
                            </div>
                        </div>

                        <div class="child2">
                            <div class="columns">
                                <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                                <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                                <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                            </div>
                        </div>
                        <div class="child3">
                            <div class="columns">
                                <h6 class="sm_text">{{'Fax'| translate}}: <span>23536426</span></h6>
                                <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                                <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28 Oct 2022</span></h6>
                            </div>
                        </div>
                        <div class="child4">
                           <a href="reports.html?completedCnss"><button id="submit_btn" class="btn btn-main2">{{'View'| translate}} <br> {{'Details'| translate}}</button></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div class="modal fade" id="reportModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLongTitle" *ngIf="!page">{{'Initiate CNSS Reports'| translate}}</h5>
                        <h5 class="modal-title" id="exampleModalLongTitle" *ngIf="page">{{'Initiate CNAMGS Reports'| translate}}</h5>
                        <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" (click)="closeGenrateReport()">
                            <span aria-hidden="true">×</span>
                        </button>
                      </div> 
                    <div class="modal-body">
                        <form class="form-container" [formGroup]="contactForm"> 
                            <div class="parent">
                                <label for="">{{'Select Year'| translate}}</label>
                                <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example" formControlName="year">
                                    <option value="2022" selected="selected">2022</option>
                                    <option value="2023">2023</option>
                                    <option value="2024">2024</option>
                                </select>
                                <div class="parent">
                                    <label for="">{{'Select Quarter'| translate}}</label>
                                        <select class="form-select form-select-lg mb-3" aria-label=".form-select-lg example" formControlName="quarter">
                                            <option value="1"selected >{{'One'| translate}}</option>
                                            <option value="2">{{'Two'| translate}}</option>
                                            <option value="3">{{'Three'| translate}}</option>
                                            <option value="4">{{'Four'| translate}}</option>
                                        </select>
                                </div>
                                
                                <button id="submit_btn" class="btn btn-medium" (click)="showCnssList()" >{{'Generate'| translate}}</button> 
                            </div>
                        </form>
                        
                    </div>
                </div>
            </div>
        </div> 



<app-onboarding-portal></app-onboarding-portal>
        <app-footer></app-footer>     
            <app-add-bank></app-add-bank>
<app-contact-floating></app-contact-floating>