import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
import { Location } from '@angular/common';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {
  response: any;
  showPassword = false;
  profileDetails: any;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private api: ApiService,
    private route: ActivatedRoute,
    private location: Location,
    private ngxUiLoaderService: NgxUiLoaderService,
    private snackBar: MatSnackBar) {
     }
  ngOnInit() {
    this.getCompanylist();
  }

  goBack() {
    this.location.back();
  }

  getCompanylist() {
    this.ngxUiLoaderService.start();
    this.api.getCompanylist().pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          this.response = data;
          console.log("41", this.response)
          if (this.response) {
            this.profileDetails = this.response.results;
            console.log("41", this.profileDetails)
          } else {
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });

  }
  redirectTo(url, id:any ) {
    console.log("57", url, id)
    switch (url) {
      case 'salary':
        {
          // this.router.navigate(['/salary-revise'], { state: { id: id, uuid: uuid } });
          this.router.navigate(['/salary-revise', id]);
          break;
        }
      case 'cnamgs':
        {

          break;
        }
      case 'cnss':
        {
           // this.router.navigate(['/cnss'], { state: { id: id, uuid:uuid } });
           this.router.navigate(['/cnss', id]);
          break;
        }
    }
  }
  
}
