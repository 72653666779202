import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';

@Component({
  selector: 'app-dependence',
  templateUrl: './dependence.component.html',
  styleUrls: ['./dependence.component.scss']
})
export class DependenceComponent implements OnInit {

  currentLang: any;
  public dependenceForm: FormGroup;
  public todayDate: any = new Date();
  isSubmitted = false;
  response: any;
  uuid: any;
  dependenceData: any;
  responseupload: any;
  id_Front_photo: any;
  id_Back_Photo: any;
  employee_Photo: any;
  dependenceEditData: any;
  showDetails = false
  constructor(private formBuilder: FormBuilder,
    private api: ApiService,
    private router: Router,
    private snackBar: MatSnackBar,
    private ngxUiLoaderService: NgxUiLoaderService) {

    if (this.router.getCurrentNavigation().extras.state) {
      this.uuid = this.router.getCurrentNavigation().extras.state.data;
      console.log("37", this.uuid)
      if (this.uuid != 1) {
        this.showDetails = true;
        this.getDependenceDetails(this.uuid);
      } else {
        this.showDetails = false;
      }
    } else {
      this.router.navigate(['/dependence-list']);
    }
  }
  ngOnInit() {
    this.dependenceForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.pattern("^[a-zA-Z]+$")]],
      lastName: ['', [Validators.required, Validators.pattern("^[a-zA-Z]+$")]],
      mob: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(8), Validators.maxLength(8)]],
      relation: ['', [Validators.required]],
      dob: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      marital_status: ['', [Validators.required]],
      occupation: ['', [Validators.required]],
      imageFile: [''],
      profile_photo: ['', [Validators.required]],
      id_front_photo: ['', [Validators.required]],
      id_back_photo: ['', [Validators.required]],
    });
    // if (!this.api.getValidationStatus()) {
    //   this.api.getFormValidation(this.dependenceForm);
    // }
    this.currentLang = localStorage.getItem('language') || "fr";
    console.log("104", this.currentLang)

  }

  upload(inputId: any, displayId: any, event, id) {

    console.log("2250", event)
    console.log("2251", event.target.files[0])
    const fileNameDisplay = document.getElementById(id);
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log('uploaded image: ', file)
      const filename = file.name;
      var ext = filename.split('.').pop();
      if (file.size > 5242880) {

        if (this.currentLang == 'fr') {
          alert('La taille du fichier doit être inférieure à 5MB')
        } else {
          alert('File Size should be less than 5MB')
        }
        return;
      }
      if (ext != 'pdf' && ext != 'jpg' && ext != 'png' && ext != 'jpeg' && ext != 'docx' && ext != 'doc' && ext != 'txt' && ext != 'xlsx') {
        if (this.currentLang == 'fr') {
          alert('Type de fichier non autorisé. Les types de fichier autorisés sont . pdf . jpg . png . jpeg . docx . doc . txt.')
        } else {
          alert('File type not allowed. Allowed file types are .pdf .jpg .png .jpeg .docx .doc .txt.')
        }
        return;
      }

      this.ngxUiLoaderService.start();
      const target = event.target.dataset.target;
      console.log(target)
      const formData = new FormData();
      formData.append('image', file);
      formData.append('target', target);
      console.log("file is :", file)
      console.log("target is :", target)
      // formData['encrypt_mode'] = false;
      // formData.append('uuid', this.businessData.business_code);
      this.api.dependenceUpload(formData).pipe(first())
        .subscribe(data => {
            this.ngxUiLoaderService.stop();
            this.responseupload = data;
            if (this.responseupload.status == 200) {
              if (target == 'id_front_photo') {
                this.dependenceForm.get('id_front_photo').setValue(this.responseupload.results.file);
                this.id_Front_photo = this.responseupload.results.imageUrl;
              }
              if (target == 'id_back_photo') {
                this.dependenceForm.get('id_back_photo').setValue(this.responseupload.results.file);
                this.id_Back_Photo = this.responseupload.results.imageUrl;
              }
              if (target == 'profile_photo') {
                this.dependenceForm.get('profile_photo').setValue(this.responseupload.results.file);
                this.employee_Photo = this.responseupload.results.imageUrl;
              }
            }
          },
          error => {

          });

      const fileInput: any = document.getElementById(inputId);
      const fileNameDisplay = document.getElementById(displayId);
      if (fileInput.files.length > 0) {
        // const fileName = fileInput.files[0].name;
        // fileNameDisplay.textContent = `${fileName}`;
        if (this.currentLang == 'fr') {
          fileNameDisplay.textContent = `Cliquez pour voir le fichier`
        } else {
          fileNameDisplay.textContent = `Click to view File`
        }
      } else {
        if (this.currentLang == 'fr') {
          fileNameDisplay.textContent = "Téléchargez une image ou déposez un fichier";
        } else {
          fileNameDisplay.textContent = "Upload image or drop a file";
        }
      }
    }
  }

  editForm() {
    // document.getElementById("dependenceDetails").style.display = "none";
    // document.getElementById("dependenceCreate").style.display = "block";
    this.showDetails = false;
    this.editDependence();
  }

  onSubmitDependence() {
    this.isSubmitted = true;
    if (this.dependenceForm.invalid) {
      console.log(this.dependenceForm)
      return;
    }
    var data = this.dependenceForm.value;
    delete data['imageFile'];
    console.log(this.dependenceForm)
    console.log(data)
    if (this.uuid != 1) {
      data.nominie_id = this.uuid;
    }
    console.log("173",data)
    this.dependenceData = data;
    this.dependenceData['dob'] = this.dateconverter(this.dependenceForm.value.dob);
    // this.dependenceData['employee_Photo'] = this.employee_Photo;
    // this.dependenceData['id_Front_photo'] = this.id_Front_photo;
    // this.dependenceData['id_Back_photo'] = this.id_Back_Photo;
    console.log(this.dependenceData)

    this.ngxUiLoaderService.start();
    this.api.submitDependantsDetails(data).pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          this.response = data;
          if (this.response.status == 200) {
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
            this.router.navigate(['/dependence-list']);
          } else {
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });

    // if (this.dependenceData) {
    //   document.getElementById("dependenceCreate").style.display = "none";
    //   document.getElementById("dependenceDetails").style.display = "block";
    // }
  }

  // submitDependantsDetails() {
  //   var data = this.dependenceForm.value;
  //   this.api.submitDependantsDetails(data).pipe(first())
  //     .subscribe(data => {
  //         this.response = data;
  //         if (this.response.status == 200) {
  //           this.router.navigate(['/dependence-list']);
  //         } else {
  //           this.snackBar.open(this.response.message, "dismiss", {
  //             duration: 2000,
  //           });
  //         }
  //       },
  //       error => {
  //         this.ngxUiLoaderService.stop();
  //       });
  // }

  getDependenceDetails(data) {
    this.ngxUiLoaderService.start();
    this.api.getDependenceDetails(data).pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          this.response = data;
          if (this.response.status == 200) {
            $('#dependenceCreate').css('display', 'none');
            $('#dependenceDetails').css('display', 'block');
            this.dependenceData = this.response.results;

          } else {
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }
  editDependence() {
    this.ngxUiLoaderService.start();
    this.api.getDependenceData(this.uuid).pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          this.response = data;
          if (this.response.status == 200) {
            this.dependenceEditData = this.response.results;
            this.setFieldValue()
            console.log("247", this.response)
          } else {
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }

  showForm(val) {
    if (val == 1) {
      this.showDetails = false;
    } else {
      this.router.navigate(['/dependence-list']);
    }
  }
  get f() {
    return this.dependenceForm.controls;
  }

  dateconverter(val) {
    const date = new Date(val);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${day}/${month}/${year}`;
    console.log(formattedDate);
    return formattedDate;
  }
  setFieldValue() {
    console.log("284")

    this.dependenceForm.controls['relation'].setValue(this.dependenceEditData.relation);
    this.dependenceForm.controls['firstName'].setValue(this.dependenceEditData.first_name);
    this.dependenceForm.controls['lastName'].setValue(this.dependenceEditData.last_name);
    this.dependenceForm.controls['mob'].setValue(this.dependenceEditData.mobile);
    this.dependenceForm.controls['dob'].setValue(this.dependenceEditData.dob);
    this.dependenceForm.controls['gender'].setValue(this.dependenceEditData.gender);
    this.dependenceForm.controls['marital_status'].setValue(this.dependenceEditData.marital_status);
    this.dependenceForm.controls['occupation'].setValue(this.dependenceEditData.occupation);
    this.dependenceForm.get('id_front_photo').setValue(this.dependenceEditData.id_Front_photo);
    this.dependenceForm.get('id_back_photo').setValue(this.dependenceEditData.id_Back_photo);
    this.dependenceForm.get('profile_photo').setValue(this.dependenceEditData.Profile_photo);
    this.id_Front_photo = this.dependenceEditData.id_front_photo;
    this.id_Back_Photo = this.dependenceEditData.id_back_photo;
    this.employee_Photo = this.dependenceEditData.profile_photo;

    const photoProperties = [
      { elementId: 'photoFileName', apiValue: this.dependenceEditData.profile_photo },
      { elementId: 'frontPhotoName', apiValue: this.dependenceEditData.id_front_photo },
      { elementId: 'backPhotoName', apiValue: this.dependenceEditData.id_back_photo }
    ];

    for (const property of photoProperties) {
      const element = document.getElementById(property.elementId);

      if (property.apiValue) {
        element.textContent = this.currentLang === 'fr' ?
          `Cliquez pour voir le fichier` :
          `Click to view File`;
      }
    }

  }


  goback() {
    this.router.navigate(['/dependence-list'])
  }
}
