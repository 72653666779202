<app-header></app-header>
<section *ngIf="login == '2' && employeeDetails" class="container-fluid mb-5 employee_dash">
  <div class="initiale_sestion">
    <div class="dFlex">
      <div class="head">
        <h4 class="form-title-sm">{{'Employee informations' | translate}}</h4>
      </div>
    </div>
  </div>
  <div class="employee_dash" *ngIf="this.login == '2'">
    <div class="grid-col">
      <div class="col_1 bgStyle" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1500">
        <div class="details">
          <div class="profile_pic">
            <img *ngIf="employeeDetails.image_url" src="{{employeeDetails.image_url}}" alt="employee pic" class="img-fluid">
            <img *ngIf="!employeeDetails.image_url" src="assets/images/default_user.png" alt="employee pic" class="img-fluid">
          </div>
          <h4>{{employeeDetails.first_name}} {{employeeDetails.last_name}}</h4>
          <h6 class="colour1">{{'Currently Working' | translate}} : {{employeeDetails.designation}}</h6>
          <!-- <h6 class="colour2">{{'Status' | translate}} : <span class="colour2">{{'Active' | translate}}</span></h6> -->
          <h6>+241 {{employeeDetails.mobile}}</h6>
        </div>
      </div>
      <div class="col_2 bgStyle" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1500">
        <h2>{{'Employee Details' | translate }}</h2>
        <div class="currentStatus flexClass">
          <div class="table">
            <div class="td_1">
              <div class="row line">
                <div class="col-md-4 col-4">
                  <h6 class="label-txt">{{'CCPS ID dashboard' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 *ngIf="employeeDetails.ccps_code" class="text-md">{{employeeDetails.ccps_code}}</h5>
                  <h5 *ngIf="!employeeDetails.ccps_code"class="text-md">--</h5>
                </div>
              </div>
              <div class="row line">
                <div class="col-md-4 col-4">
                  <h6 class="label-txt">{{'Employee ID' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 class="text-md">{{employeeDetails.employee_id}}</h5>
                </div>
              </div>
              <div class="row line">
                <div class="col-md-4 col-4">
                  <h6 class="label-txt">{{'CNAMGS ID' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 *ngIf="employeeDetails.cnamgs" class="text-md">{{employeeDetails.cnamgs}}</h5>
                  <h5  *ngIf="!employeeDetails.cnamgs" class="text-md">--</h5>
                </div>
              </div>
              <div class="row line">
                <div class="col-md-4 col-4">
                  <h6 class="label-txt">{{'CNSS ID' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 *ngIf="employeeDetails.cnss" class="text-md">{{employeeDetails.cnss}}</h5>
                  <h5 *ngIf="!employeeDetails.cnss" class="text-md">--</h5>
                </div>
              </div>
              <div class="row line">
                <div class="col-md-4 col-4">
                  <h6 class="label-txt">{{'Hired Date' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 class="text-md">{{employeeDetails.join_date}}</h5>
                </div>
              </div>
              <div class="row line">
                <div class="col-md-4 col-4">
                  <h6 class="label-txt">{{'Salary' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 class="text-md">{{employeeDetails.salary}} FCFA</h5>
                </div>
              </div>
              <div class="row line">
                <div class="col-md-4 col-4">
                  <h6 class="label-txt">{{'Designation' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 class="text-md">{{employeeDetails.designation}}</h5>
                </div>
              </div>
              <div class="row line">
                <div class="col-md-4 col-4">
                  <h6 class="label-txt">{{'Email Id' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 class="text-md">{{employeeDetails.email}}</h5>
                </div>
              </div>
              <div class="row line">
                <div class="col-md-4 col-4">
                  <h6 class="label-txt">{{'Address' | translate}}</h6>
                </div>
                <div class="col-md-8 col-8">
                  <h5 class="text-md">{{employeeDetails.address}}</h5>
                </div>
              </div>
            </div>
            <!-- <div class="td_1">
                            <h6 class="label-txt">{{'CCPS ID dashboard' | translate}}</h6>
                            <h5 class="text-md">CC23526</h5>
                        </div>
                        <div class="td_2">
                            <h6 class="label-txt">{{'Employee ID' | translate}}</h6>
                            <h5 class="text-md"> BW23526</h5>
                        </div>
                        <div class="td_3">
                            <h6 class="label-txt">{{'CNAMGS ID' | translate}}</h6>
                            <h5 class="text-md">CG2352355</h5>
                        </div>
                        <div class="td_4">
                            <h6 class="label-txt">{{'CNSS ID' | translate}}</h6>
                            <h5 class="text-md">CN12356</h5>
                        </div> -->
          </div>
          <!-- <div class="table mt-3">
                        <div class="td_1">
                            <h6 class="label-txt">{{'Hired Date' | translate}}</h6>
                            <h5 class="text-md">12 Oct 2020</h5>
                        </div>
                        <div class="td_2">
                            <h6 class="label-txt">{{'Salary' | translate}}</h6>
                            <h5 class="text-md">25000 FCFA</h5>
                        </div>
                        <div class="td_3">
                            <h6 class="label-txt">{{'Designation' | translate}}</h6>
                            <h5 class="text-md">Marketing Head</h5>
                        </div>
                        <div class="td_4"></div>
                    </div>
                    <div class="table mt-3">
                        <div class="td_5">
                            <h6 class="label-txt">{{'Email Id' | translate}}</h6>
                            <h5 class="text-md">roninmalof34@gmail.com</h5>
                        </div>
                        <div class="td_6">
                            <h6 class="label-txt">{{'Address' | translate}}</h6>
                            <h5 class="text-md">Lapson Arcade, Abc Building, Libreville, Gabon</h5>
                        </div>
                    </div> -->
        </div>
      </div>
    </div>
    <div class="grid-col2 company-detail-container">
      <div class="col_2 bgStyle" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1500">
        <div class="company_details">
          <div class="head">
            <h5 class="text-md text-center">{{'Company Details Employee' | translate}}</h5>
          </div>
          <div class="data p45">
            <div class="logo dashboard_company"><img src="assets/images/company_logo_default.png" alt=""></div>
            <div>
              <div class="cName">
                <h4 class="text-md">{{employeeDetails.company_name}} <span>({{'Currently working here' | translate}})</span></h4>
                <h6 class="sm_text">{{employeeDetails.experience}}</h6>
              </div>
              <div class="position">
                <div class="lineStyle">
                  <div class="circle"></div>
                  <div class="line"></div>
                  <div class="circle"></div>
                </div>
                <div class="items">
                  <div *ngFor="let detail of companyDetails">
                    <h5 class="title">{{detail.designation}}</h5>
                    <h6 class="sm_text">{{detail.date}}</h6>
                    <h6 class="sm_text">{{'Salary' | translate}} - {{detail.salary}} FCFA</h6>
                    <h6 class="sm_text">{{detail.address}}asdasd</h6>
                  </div>
                <!--   <div>
                    <h5 class="title">{{previousDetails.designation}}</h5>
                    <h6 class="sm_text">{{previousDetails.date}}</h6>
                    <h6 class="sm_text">{{'Salary' | translate}} - {{previousDetails.salary}} FCFA</h6>
                    <h6 class="sm_text">Gabon, Libreville</h6>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <a routerLink="/experience">
            <div class="more">{{'More Details' | translate}}<i class="fa-solid fa-angle-right"></i></div>
          </a>
        </div>
      </div>
      <div *ngIf="contribution" class="col_1 bgStyle" data-aos="fade-right" data-aos-delay="100" data-aos-duration="1500">
        <div class="graph">
          <h5 class="text-md text-center">{{'Contribution rate' | translate}}</h5>
          <div class="sec1">
            <img src="assets/images/chart.png" alt="">
            <ul>
              <li>
                <h6 class="colorBlack">{{'Contribution rate' | translate}}</h6><span class="colorBlack">{{contribution.contribution_rate}} %</span>
              </li>
              <li>
                <h6 class="colorGrey">{{'Employer' | translate}} : </h6><span class="colorBlack">{{contribution.employer}} %</span>
              </li>
              <li>
                <h6 class="colorGrey">{{'Worker' | translate}} : </h6><span class="colorBlack">{{contribution.worker}} %</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section *ngIf="login == '1' " class="middle-section getstarted employer_dash">
  <div class="container-fluid">
    <div class="image-container">
      <img src="assets/images/dashboard_employer.png">
    </div>
    <div class="txt-container mb-5 mt-5">
      <h3 class="title-txt text-center">{{'Get Started with CCPS' | translate}}</h3>
      <p>{{'get started para 1' | translate }}</p>
    </div>
  </div>
  <app-onboarding-portal></app-onboarding-portal>
</section>
<section class="modal-section2">
  <div class="modal fade" id="successModal" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="parent">
            <div class="image-container">
              <img src="assets/images/link_success.png">
            </div>
            <div class="text-container text-center">
              <h4 class="modal-header-txt">{{'linked Company with CCPS' | translate}}</h4>
              <p class="para-light">{{'linked company with CCPS message' | translate}}</p>
              <button id="submit_btn" class="btn btn-medium" data-dismiss="modal">{{'OK' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-add-bank></app-add-bank>
<app-footer></app-footer>
<app-contact-floating></app-contact-floating>

 <div class="modal fade" id="successModalCnssForm" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" data-backdrop="static">
                <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                    <div class="modal-content">
                       
                        <div class="modal-body">
                            <div class="parent">
                                <div class="cnsslogo">
                                    <img src="assets/images/logoWithText1.png" class="img-fluid">
                                    <p>CAISSE NATIONALE<br>DE SECURITE SOCIALE</p>
                                </div>
                                <div class="image-container">
                                    <img src="assets/images/link_success.png">
                                </div>
                                <div class="text-container text-center">
                                    <h4 class="modal-header-txt">{{'Congratulations! We acknowledge receipt of your activation request.'| translate}}</h4>
                                    <p class="para-light">{{'We will study your file and get back to you as soon as possible.' | translate}}</p>
                                   <button id="submit_btn" class="btn btn-medium" data-dismiss="modal" (click)="closeModal()">{{'OK'| translate}}</button> 
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
