<app-header></app-header>
<!-- Add employees choice -->
<div class="container-fluid" [hidden]="(!employeeList && !employeeWithCcpsStage1) || employeeWithCcpsStage1">
  <section id="employee-nolist">
    <div class="image-container col-sm-12">
      <img src="assets/images/employee_nolist.png">
    </div>
    <div class="text-container">
      <h3 *ngIf="title == 'employees'" class="header-txt text-center">{{'add employee title' | translate}}</h3>
      <h3 *ngIf="title == 'business'" class="header-txt text-center">{{'import business title' | translate}}</h3>
      <h3 *ngIf="title == 'bank'" class="header-txt text-center">{{'import bank title' | translate}}</h3>
    </div>
    <div id="addEmployeeContainer" class="custom-container">
      <button id="submit_btn" class="btn btn-main2" data-toggle="modal" data-target="#addEmployeeModal">{{'Add Employee Details2' | translate}}</button>
      <button for="bulk-file-upload" class="btn btn-main2" data-toggle="modal" data-target="#bulkUpload">
        {{'Bulk Upload' | translate}}
      </button>
    </div>
  </section>
</div>
<!-- Add employees modal -->
<div class="modal fade" id="addEmployeeModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body">
        <h5 class="form-title text-center">{{'Add Employees' | translate}}</h5>
        <div class="form-container">
          <div class="image-container">
            <div class="child-container" id="WithCcpsForm1">
              <img src="assets/images/add_employee1.png">
              <h5 class="form-title text-center">{{'Employee with CCPS ID' | translate}}</h5>
            </div>
            <div class="line"></div>
            <div class="child-container" id="WithoutCcpsForm1">
              <img src="assets/images/bulk_employee.png">
              <h5 class="form-title text-center">{{'Employee without CCPS ID' | translate}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Add employees with ccps modal -->
<!-- Add employees with ccps stage2 -->
<!-- success modal -->
<section class="modal-section2">
  <div class="modal fade" id="successModal" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="parent">
            <div class="image-container">
              <img src="assets/images/link_success.png">
            </div>
            <div class="text-container text-center">
              <h4 class="modal-header-txt">{{'linked Company with CCPS' | translate}}</h4>
              <p class="para-light">{{'linked company with CCPS message' | translate}}</p>
              <button id="submit_btn" class="btn btn-medium" data-dismiss="modal">{{'Done' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- success modal  end-->
<!-- employee list section -->
<section class="middle-section getstarted employe-list" [hidden]="employeeList || employeeWithCcpsStage1">
  <div class="container-fluid">
    <div class="initiale_sestion">
      <div class="head">
        <div class="d-flex">
          <a (click)="goback()"><i class="fa fa-angle-left"></i></a>
          <h4 class="form-title-sm">{{'Manage Employees' | translate}}</h4>
        </div>
        <div class="custom-container">
          <button for="bulk-file-upload" class="btn btn-main2" data-toggle="modal" data-target="#bulkUpload">{{'Bulk Upload' | translate}}</button>
          <!-- <input id="bulk-file-upload" type="file"/> -->
          <button id="submit_btn" class="btn btn-main2" data-toggle="modal" data-target="#addEmployeeModal">{{'Register a New Employee' | translate}}</button>
        </div>
      </div>
      <div class="head_2">
        <div class="text-container">
          <i class="fa-solid fa-circle-exclamation"></i>
          <select class="bootstrap-select" disabled>
            <option value="1" selected="selected"> {{ pendingEmployeeCount }} {{'Employees Application Pending' | translate}}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="reportTable">
      <table id="tooltip-table" class="quarter_table employeeTable">
        <colgroup>
          <col span="1" class="slno">
          <col span="1" class="ccpsId">
          <col span="1" class="name">
          <col span="1" class="designation">
          <col span="1" class="cnamgsId">
          <col span="1" class="ccpsId">
          <col span="1" class="mobile">
          <col span="1" class="status">
          <col span="1" class="action">
        </colgroup>
        <thead>
          <tr>
            <td colspan="100">
              <div class="search-container"><i class="fa-solid fa-magnifying-glass hide"></i><label><input placeholder="{{'Search employee' | translate}}" class="search_icon" (keyup)="setFilteredEmployees($event)" (keydown)="setFilteredEmployees($event)"></label></div>
            </td>
          </tr>
          <tr>
            <th scope="col">{{'SL NO' | translate}}</th>
            <th scope="col">{{'CCPS ID' | translate}}</th>
            <th scope="col">{{'Name' | translate}}</th>
            <th scope="col">{{'Designation' | translate}}</th>
            <th scope="col">{{'CNAMGS ID' | translate}}</th>
            <th scope="col">{{'CNSS ID' | translate}}</th>
            <th scope="col">{{'Mobile' | translate}}</th>
            <th scope="col">{{'Status' | translate}}</th>
            <th scope="col">{{'Action' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="search-mob" colspan="100">
              <div class="search-parent"><i class="fa-solid fa-magnifying-glass hide"></i><label><input placeholder="Search employee" class="search_icon"></label></div>
            </td>
          </tr>
          <tr *ngFor="let employee of employeesallList; let i = index" [ngClass]="{'pending-employee' : employee.status == 0, 'rejected-employee' : employee.status == 4}">
            <td data-label="SL NO">{{ i + 1}}</td>
            <td attr.data-label="{{'CCPS ID' | translate}}" *ngIf="employee.ccps_code">{{ employee.ccps_code }}</td>
            <td attr.data-label="{{'CCPS ID' | translate}}" *ngIf="!employee.ccps_code">--</td>
            <td data-label="Name">{{ employee.name }}</td>
            <td data-label="Designation">{{ employee.designation }}</td>
            <td data-label="CNAMGS ID" class="my-class" *ngIf="employee.cnamgs">{{ employee.cnamgs }}</td>
            <td data-label="CNAMGS ID" class="my-class" *ngIf="!employee.cnamgs">--</td>
            <td attr.data-label="{{'CCPS ID' | translate}}" *ngIf="employee.cnss">{{ employee.cnss }}</td>
            <td attr.data-label="{{'CCPS ID' | translate}}" *ngIf="!employee.cnss">--</td>
            <td data-label="Mobile">{{ employee.mobile }}</td>
            <td data-label="Status">{{ employee.status_type }}</td>
            <td data-label="Action">
              <div class="action-container">
                <a (click)="getEmployeeDetails(employee.id)"> <img src="assets/images/money.png"></a>
                <a *ngIf="employee.status == 1" (click)="resignEmployee(employee)"><img src="assets/images/cancelation.png"></a></div>
            </td>
          </tr>
          <!-- <tr>
            <td data-label="SL NO">2</td>
            <td attr.data-label="{{'CCPS ID' | translate}}">CC534168</td>
            <td data-label="Name">Diane Mouiguissi</td>
            <td data-label="Designation">{{'Developer' | translate}}</td>
            <td data-label="CNAMGS ID" class="my-class">CG534168
              <div class="tool-tip">
                <h6>CG534168</h6>
                <button type="button" id="submit_btn" class="btn btn-sm">{{'View Details' | translate}}</button>
              </div>
            </td>
            <td attr.data-label="{{'CCPS ID' | translate}}">CN534168</td>
            <td data-label="Mobile">89562376</td>
            <td data-label="Status">{{'Active' | translate}}</td>
            <td data-label="Action">
              <div class="action-container"> <a routerLink="/salary-revise"> <img src="assets/images/money.png"></a>
                <a href="#" data-toggle="modal" data-target="#employeeResignModal"><img src="assets/images/cancelation.png"></a></div>
            </td>
          </tr>
          <tr class="pending-employee">
            <td data-label="SL NO">3</td>
            <td attr.data-label="{{'CCPS ID' | translate}}">--</td>
            <td data-label="Name">Terrence Bongoboue</td>
            <td data-label="Designation">{{'Designer' | translate}}</td>
            <td data-label="CNAMGS ID">CG534518
              <div class="tool-tip">
                <h6>CG534518</h6>
                <button type="button" id="submit_btn" class="btn btn-sm">{{'View Details' | translate}}</button>
              </div>
            </td>
            <td attr.data-label="{{'CCPS ID' | translate}}">--</td>
            <td data-label="Mobile">89562365</td>
            <td data-label="Status">{{'Pending' | translate}}</td>
            <td data-label="Action">
              <div class="action-container">

              </div>
            </td>
          </tr>
          <tr>
            <td data-label="SL NO">4</td>
            <td attr.data-label="{{'CCPS ID' | translate}}">CC534518</td>
            <td data-label="Name">Rudy Ebang</td>
            <td data-label="Designation">{{'Analyst' | translate}}</td>
            <td data-label="CNAMGS ID">CG534518
              <div class="tool-tip">
                <h6>CG534518</h6>
                <button type="button" id="submit_btn" class="btn btn-sm">{{'View Details' | translate}}</button>
              </div>
            </td>
            <td attr.data-label="{{'CCPS ID' | translate}}">CN534518</td>
            <td data-label="Mobile">89562568</td>
            <td data-label="Status">{{'Active' | translate}}</td>
            <td data-label="Action">
              <div class="action-container"> <a routerLink="/salary-revise"> <img src="assets/images/money.png"></a>
                <a href="#" data-toggle="modal" data-target="#employeeResignModal"><img src="assets/images/cancelation.png"></a></div>
            </td>
          </tr>
          <tr>
            <td data-label="SL NO">5</td>
            <td attr.data-label="{{'CCPS ID' | translate}}">CC458936</td>
            <td data-label="Name">Stella Ibouanga </td>
            <td data-label="Designation">{{'Analyst' | translate}}</td>
            <td data-label="CNAMGS ID">CG458936
              <div class="tool-tip">
                <h6>CG458936</h6>
                <button type="button" id="submit_btn" class="btn btn-sm">{{'View Details' | translate}}</button>
              </div>
            </td>
            <td attr.data-label="{{'CCPS ID' | translate}}">CN458936</td>
            <td data-label="Mobile">8956235632</td>
            <td data-label="Status">{{'Active' | translate}}</td>
            <td data-label="Action">
              <div class="action-container"> <a routerLink="/salary-revise"> <img src="assets/images/money.png"></a>
                <a href="#" data-toggle="modal" data-target="#employeeResignModal"><img src="assets/images/cancelation.png"></a></div>
            </td>
          </tr>
          <tr class="rejected-employee">
            <td data-label="SL NO">6</td>
            <td attr.data-label="{{'CCPS ID' | translate}}">--</td>
            <td data-label="Name">Ismael Moussavou</td>
            <td data-label="Designation">{{'Salesman' | translate}}</td>
            <td data-label="CNAMGS ID">--
              <div class="tool-tip">
                <h6>--</h6>
                <button type="button" id="submit_btn" class="btn btn-sm">{{'View Details' | translate}}</button>
              </div>
            </td>
            <td attr.data-label="{{'CCPS ID' | translate}}">--</td>
            <td data-label="Mobile">89562398</td>
            <td data-label="Status">{{'Rejected' | translate}}</td>
            <td data-label="Action rejected">
              <div class="action-container">
                <a (click)="showAddEmployee('')"> <i class="fa-solid fa-repeat"></i></a>
              </div>
            </td>
          </tr>
          <tr>
            <td data-label="SL NO">7</td>
            <td attr.data-label="{{'CCPS ID' | translate}}">CC524164</td>
            <td data-label="Name">Axel Mbele</td>
            <td data-label="Designation">{{'HR manager' | translate}}</td>
            <td data-label="CNAMGS ID">CG524164
              <div class="tool-tip">
                <h6>CG524164</h6>
                <button type="button" id="submit_btn" class="btn btn-sm">{{'View Details' | translate}}</button>
              </div>
            </td>
            <td attr.data-label="{{'CCPS ID' | translate}}">CN524164</td>
            <td data-label="Mobile">89562334</td>
            <td data-label="Status">{{'Active' | translate}}</td>
            <td data-label="Action">
              <div class="action-container"> <a routerLink="/salary-revise"> <img src="assets/images/money.png"></a>
                <a href="#" data-toggle="modal" data-target="#employeeResignModal"><img src="assets/images/cancelation.png"></a></div>
            </td>
          </tr>
          <tr class="pending-employee">
            <td data-label="SL NO">8</td>
            <td attr.data-label="{{'CCPS ID' | translate}}">--</td>
            <td data-label="Name">Primaelle Ithou </td>
            <td data-label="Designation">{{'Analyst' | translate}}</td>
            <td data-label="CNAMGS ID">--
              <div class="tool-tip">
                <h6>CG574515</h6>
                <button type="button" id="submit_btn" class="btn btn-sm">{{'View Details' | translate}}</button>
              </div>
            </td>
            <td attr.data-label="{{'CCPS ID' | translate}}">CN574515</td>
            <td data-label="Mobile">89562334</td>
            <td data-label="Status">{{'Pending' | translate}}</td>
            <td data-label="Action">
              <div class="action-container">
                
              </div>
            </td>
          </tr> -->
        </tbody>
      </table>
    </div>
  </div>
</section>
<app-onboarding-portal></app-onboarding-portal>
<!-- Add employee resignation modal -->
<div class="modal fade" id="employeeResignModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <button type="button" class="close close-modal" data-title="Close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body">
        <div class="parent-container">
          <h5 class="form-title text-center">{{'Employee Resignation' | translate}}</h5>
          <form id="employeeResignForm" [formGroup]="resignationForm" (ngSubmit)="onSubmitResign()">
            <div class="user-data-container">
              <div class="user-data" *ngIf="employeeDetail">
                <div class="child-container">
                  <label class="labeltxt-sm">{{'CCPS ID' | translate}}</label>
                  <h6>{{ employeeDetail.ccps_code }}</h6>
                </div>
                <div class="child-container">
                  <label class="labeltxt-sm">{{'Name' | translate}}</label>
                  <h6>{{ employeeDetail.name }}</h6>
                </div>
                <div class="child-container">
                  <label class="labeltxt-sm">{{'Company ID' | translate}}</label>
                  <h6>{{ employeeDetail.company_id }}</h6>
                </div>
                <div class="child-container">
                  <label class="labeltxt-sm">{{'Designation' | translate}}</label>
                  <h6>{{ employeeDetail.designation }}</h6>
                </div>
                <div class="child-container">
                  <label class="labeltxt-sm">{{'CNAMGS ID' | translate}}</label>
                  <h6>{{ employeeDetail.cnamgs }}</h6>
                </div>
                <div class="child-container">
                  <label class="labeltxt-sm">{{'CNSS ID' | translate}}</label>
                  <h6>{{ employeeDetail.cnss }}</h6>
                </div>
                <div class="child-container">
                  <label class="labeltxt-sm">{{'Mobile' | translate}}</label>
                  <h6>{{ employeeDetail.mobile }}</h6>
                </div>
              </div>
            </div>
            <div class="input-container text-center input-box-white" [owlDateTimeTrigger]="dt42">
              <label class="labeltxt-sm text-center">{{'Select Date of Resignation' | translate}}</label>
              <div class="date-field">
                <input class="" [owlDateTime]="dt42" name="dob" formControlName="date_of_resignation">
                <div class="calendar input-group-prepend">
                  <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                </div>
              </div>
              <owl-date-time [pickerType]="'calendar'" #dt42></owl-date-time>
              <div *ngIf="rsubmitted && a.date_of_resignation.errors" class="invalid-feedback">
                <div *ngIf="a.date_of_resignation.errors?.required">{{'Select Date of Resignation'|translate}} {{'is required'|translate}}</div>
              </div>
            </div>
            <div class="input-container text-center input-box-white">
              <label class="labeltxt-sm text-center">{{'Reason for Resignation' | translate}}</label>
              <select type="text" name="reason_leaving" class="bootstrap-select" formControlName="reason" (change)="setValidator($event)">
                <option value="0" selected="selected">{{'contract comes to an end' | translate}}</option>
                <option value="1">{{'Resignation' | translate}}</option>
                <option value="2">{{'Termination' | translate}}</option>
                <option value="3">{{'Death' | translate}}</option>
                <option value="4">{{'Illness' | translate}}</option>
                <option value="5">{{'disappearance' | translate}}</option>
                <option value="6">{{'repatriation' | translate}}</option>
                <option value="7">{{'other' | translate}}</option>
              </select>
              <div *ngIf="rsubmitted && a.reason.errors" class="invalid-feedback">
                <div *ngIf="a.reason.errors?.required">{{'Reason for Resignation'|translate}} {{'is required'|translate}}</div>
              </div>
            </div>
            <div class="text-container" *ngIf="a.reason.value == 7">
              <textarea placeholder="{{'write reason here' | translate}}" formControlName="reason_type"></textarea>
              <div *ngIf="rsubmitted && a.reason_type.errors" class="invalid-feedback">
                <div *ngIf="a.reason_type.errors?.required" class="text-center">{{'Reason for Resignation'|translate}} {{'is required'|translate}}</div>
              </div>
            </div>
            <button type="submit" id="submit_btn" class="btn btn-medium">{{'Submit' | translate}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Add employees modal -->
<div class="modal fade" id="addEmployeeModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body">
        <h5 class="form-title text-center">{{'Add Employees' | translate}}</h5>
        <div class="form-container">
          <div class="image-container">
            <div class="child-container" id="WithCcpsForm1">
              <img src="assets/images/add_employee1.png">
              <h5 class="form-title text-center">{{'Employee with CCPS ID' | translate}}</h5>
            </div>
            <div class="line"></div>
            <div class="child-container" id="WithoutCcpsForm1">
              <img src="assets/images/bulk_employee.png">
              <h5 class="form-title text-center">{{'Employee without CCPS ID' | translate}}</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Add employees with ccps modal -->
<div class="modal fade" id="employeeWithCcpsModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <button type="button" class="close close-modal" data-title="Close" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body">
        <div class="parent-container">
          <h5 class="form-title text-center">{{'Add Employees step' | translate}}</h5>
          <div class="text-container text-center">
            <h6 class="stage-header">{{'Step' | translate}} 1</h6>
            <div class="stage-container">
              <div class="stepper-wrapper">
                <div class="stepper-item active">
                  <div class="step-counter">1</div>
                </div>
                <div class="stepper-item">
                  <div class="step-counter">2</div>
                </div>
                <div class="stepper-item">
                  <div class="step-counter">3</div>
                </div>
              </div>
            </div>
          </div>
          <form id="employeeWithCcpsForm" [formGroup]="withCcpsForm" (ngSubmit)="onSubmitEmployeeStage1()">
            <div class="input-container input-box-white">
              <label class="labeltxt text-center">{{'Enter CCPS ID of Employee' | translate}}</label>
              <input formControlName="ccpsid" type="text" name="ccps_id">
              <div *ngIf="isSubmitted && f.ccpsid.errors" class="invalid-feedback text-center">
                <div *ngIf="f.ccpsid.errors?.required">{{'CCPS ID'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.ccpsid.errors?.pattern">{{'CCPS ID' | translate}} {{'should be valid' | translate}}</div>
              </div>
            </div>
            <button type="submit" id="submit_btn" class="btn btn-medium">{{'Proceed' | translate}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Add employees with ccps stage1 class="modal fade"-->
<div id="employeeWithCcpsStage1" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" [hidden]="!employeeWithCcpsStage1">
  <div class="head container">
    <div class="d-flex">
      <a (click)="gobacklist()"><i class="fa fa-angle-left"></i></a>
      <h4 class="form-title-sm">{{'Manage Employees' | translate}}</h4>
    </div>
  </div>
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <!-- <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button> -->
      <div class="modal-body">
        <div class="parent-container">
          <h5 class="form-title text-center">{{'Add Employees step2' | translate}}</h5>
          <div class="text-container text-center">
            <h6 *ngIf="!employeeWithCcps" class="stage-header">{{'Step' | translate}} 1</h6>
            <h6 *ngIf="employeeWithCcps" class="stage-header">{{'Step' | translate}} 2</h6>
            <div *ngIf="!employeeWithCcps" class="stage-container">
              <div class="stepper-wrapper">
                <div class="stepper-item active">
                  <div class="step-counter">1</div>
                </div>
                <div class="stepper-item">
                  <div class="step-counter">2</div>
                </div>
              </div>
            </div>
            <div *ngIf="employeeWithCcps" class="stage-container">
              <div class="stepper-wrapper">
                <div class="stepper-item active completed">
                  <div class="step-counter">1</div>
                </div>
                <div class="stepper-item active">
                  <div class="step-counter">2</div>
                </div>
                <div class="stepper-item">
                  <div class="step-counter">3</div>
                </div>
              </div>
            </div>
          </div>
          <form id="WithCcpsStage1Form" [formGroup]="CcpsStage1Form" (ngSubmit)="onSubmitEmployeeStage2()">
            <div class="input-container input-box-white">
              <div class="child-container">
                <label class="labeltxt">{{'First name' | translate}}</label>
                <input type="text" name="first_name" formControlName="first_name">
                <div *ngIf="isSubmit && g.first_name.errors" class="invalid-feedback">
                  <div *ngIf="g.first_name.errors?.required">{{'First name'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="g.first_name.errors?.pattern">{{'First name'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'Last name' | translate}}</label>
                <input type="text" name="last_name" formControlName="last_name">
                <div *ngIf="isSubmit && g.last_name.errors" class="invalid-feedback">
                  <div *ngIf="g.last_name.errors?.required">{{'Last name'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="g.last_name.errors?.pattern">{{'Last name'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container" [owlDateTimeTrigger]="dt43">
                <label class="labeltxt">{{'Date of Birth' | translate}}</label>
                <div class="date-field">
                  <input class="" [max]="todayDate" [min]="calculateMinDate()" [owlDateTime]="dt43" readonly name="dob" formControlName="dob">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                  </div>
                </div>
                <div *ngIf="isSubmit && g.dob.errors" class="invalid-feedback">
                  <div *ngIf="g.dob.errors?.required">{{'Date of birth'|translate}} {{'is required'|translate}}</div>
                </div>
                <owl-date-time [pickerType]="'calendar'" #dt43></owl-date-time>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'Mobile No' | translate}}</label>
                <input type="text" name="mobile_no" formControlName="mobile" minlength="8" maxlength="8">
                <div *ngIf="isSubmit && g.mobile.errors" class="invalid-feedback">
                  <div *ngIf="g.mobile.errors?.required">{{'Mobile'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="g.mobile.hasError('pattern')">{{'Mobile'|translate}} {{'should be valid' | translate}}</div>
                  <div *ngIf="g.mobile.hasError('minlength') && !g.mobile.hasError('pattern')">{{ 'Mobile' | translate }} {{ 'must Contain 8 Numbers' | translate }}</div>
                </div>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'Address' | translate}}</label>
                <input type="text" name="address" formControlName="address">
                <div *ngIf="isSubmit && g.address.errors" class="invalid-feedback">
                  <div *ngIf="g.address.errors?.required">{{'Address'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="g.address.errors?.pattern">{{'Address'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'Company ID' | translate}}</label>
                <input type="text" name="company_id" formControlName="company_id">
                <div *ngIf="isSubmit && g.company_id.errors" class="invalid-feedback">
                  <div *ngIf="g.company_id.errors?.required">{{'Company ID'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="g.company_id.errors?.pattern">{{'Company ID'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'Email ID' | translate}}</label>
                <input type="text" name="email" formControlName="email">
                <div *ngIf="isSubmit && g.email.errors" class="invalid-feedback">
                  <div *ngIf="g.email.errors?.required">{{'Email ID'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="g.email.errors?.email || g.email.errors?.pattern">{{'Email ID'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'CNSS ID' | translate}}</label>
                <input type="text" name="cnss_id" formControlName="cnss">
                <div *ngIf="isSubmit && g.cnss.errors" class="invalid-feedback">
                  <div *ngIf="g.cnss.errors?.required">{{'CNSS ID'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="g.cnss.errors?.pattern">{{'CNSS ID'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'CNAMGS ID' | translate}}</label>
                <input type="text" name="cnamgs_id" formControlName="cnamgs">
                <div *ngIf="isSubmit && g.cnamgs.errors" class="invalid-feedback">
                  <div *ngIf="g.cnamgs.errors?.required">{{'CNAMGS ID'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="g.cnamgs.errors?.pattern">{{'CNAMGS ID'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'Identity Document' | translate}}</label>
                <select type="text" name="document_type" class="bootstrap-select" formControlName="id_type" [(ngModel)]="selectedValue">
                  <option value="null" disabled selected>{{'Please Select one' | translate}}</option>
                  <option value="{{ id.id }}" *ngFor="let id of idtypelist">{{ id.name }}</option>
                </select>
                <div *ngIf="isSubmit && g.id_type.errors" class="invalid-feedback">
                  <div *ngIf="g.id_type.errors?.required">{{'Identity Document'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="g.id_type.errors?.pattern">{{'Identity Document'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'Identity Document Number' | translate}}</label>
                <input type="text" name="id_num" formControlName="id_number">
                <div *ngIf="isSubmit && g.id_number.errors" class="invalid-feedback">
                  <div *ngIf="g.id_number.errors?.required">{{'Identity Document Number'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="g.id_number.errors?.pattern">{{'Identity Document Number'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container" [owlDateTimeTrigger]="dt46">
                <label class="labeltxt">{{'Join Date' | translate}}</label>
                <div class="date-field">
                  <input class="" [max]="todayDate" [owlDateTime]="dt46" readonly name="joindate" formControlName="joindate">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                  </div>
                </div>
                <div *ngIf="isSubmit && h.dob.errors" class="invalid-feedback">
                  <div *ngIf="g.joindate.errors?.required">{{'Join Date'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="g.joindate.errors?.pattern">{{'Join Date'|translate}} {{'should be valid' | translate}}</div>
                </div>
                <owl-date-time [pickerType]="'calendar'" #dt46></owl-date-time>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'position' | translate}}</label>
                <input type="text" name="position" formControlName="position">
                <div *ngIf="isSubmit && g.position.errors" class="invalid-feedback">
                  <div *ngIf="g.position.errors?.required">{{'position'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="g.position.errors?.pattern">{{'position'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'Base Salary' | translate}}</label>
                <input type="text" name="base_salary" formControlName="base_salary">
                <div *ngIf="isSubmit && g.base_salary.errors" class="invalid-feedback">
                  <div *ngIf="g.base_salary.errors?.required">{{'Base Salary'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="g.base_salary.errors?.pattern">{{'Base Salary'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'Salary' | translate}}</label>
                <input type="text" name="salary" formControlName="salary">
                <div *ngIf="isSubmit && g.salary.errors" class="invalid-feedback">
                  <div *ngIf="g.salary.errors?.required">{{'Salary'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="g.salary.errors?.pattern">{{'Salary'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label for="id_Front_Photo" class="labeltxt">{{'Upload ID Front Photo' | translate}}</label>
                <label for="id_Front_Photo" class="file-upload-card labeltxt">
                  <div class="custom-container">
                    <p class="text-center">{{'Upload image or drop a file' | translate}}</p>
                    <i class="fa fa-upload" aria-hidden="true"></i>
                  </div>
                </label>
                <input class="" id="id_Front_Photo" type="file" formControlName="imageFile" (change)="upload($event, 'idFrontPhoto')" data-target="id_Front_Photo">
                <a id="idFrontPhoto" href="{{id_Front_Photo}}" target="_blank" class="viewFile text-center"></a>
                <div *ngIf="isSubmit && g.id_Front_Photo.errors" class="invalid-feedback">
                  <div *ngIf="g.id_Front_Photo.errors.size">{{'File Size should not be grater than 10 MB.'|translate}}</div>
                  <div *ngIf="g.id_Front_Photo.errors.required">{{'Upload ID Front Photo' | translate}} {{'cannot be blank'|translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label for="id_Back_Photo" class="labeltxt">{{'Upload ID Back Photo' | translate}}</label>
                <label for="id_Back_Photo" class="file-upload-card labeltxt">
                  <div class="custom-container">
                    <p class="text-center">{{'Upload image or drop a file' | translate}}</p>
                    <i class="fa fa-upload" aria-hidden="true"></i>
                  </div>
                </label>
                <input class="" id="id_Back_Photo" type="file" formControlName="imageFile" (change)="upload($event, 'idBackPhoto')" data-target="id_Back_Photo">
                <a id="idBackPhoto" href="{{id_Back_Photo}}" target="_blank" class="viewFile text-center"></a>
                <div *ngIf="isSubmit && g.id_Back_Photo.errors" class="invalid-feedback">
                  <div *ngIf="g.id_Back_Photo.errors.size">{{'File Size should not be grater than 10 MB.'|translate}}</div>
                  <div *ngIf="g.id_Back_Photo.errors.required">{{'Upload ID Back Photo' | translate}} {{'cannot be blank'|translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label for="employee_Photo" class="labeltxt">{{'Upload Employee Photo' | translate}}</label>
                <label for="employee_Photo" class="file-upload-card labeltxt">
                  <div class="custom-container">
                    <p class="text-center">{{'Upload image or drop a file' | translate}}</p>
                    <i class="fa fa-upload" aria-hidden="true"></i>
                  </div>
                </label>
                <input class="" id="employee_Photo" type="file" formControlName="imageFile" (change)="upload($event, 'employeePhoto')" data-target="employee_Photo">
                <a id="employeePhoto" href="{{employee_Photo}}" target="_blank" class="viewFile text-center"></a>
                <div *ngIf="isSubmit && g.employee_Photo.errors" class="invalid-feedback">
                  <div *ngIf="g.employee_Photo.errors.size">{{'File Size should not be grater than 10 MB.'|translate}}</div>
                  <div *ngIf="g.employee_Photo.errors.required">{{'Upload Employee Photo' | translate}} {{'cannot be blank'|translate}}</div>
                </div>
              </div>
            </div>
            <button type="submit" id="submit_btn" class="btn btn-medium">{{'Next' | translate}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
<app-add-bank></app-add-bank>
<div class="modal fade" id="employeeWithCcpsStage2" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body">
        <div class="parent-container">
          <h5 class="form-title text-center">{{'Add Employees step3' | translate}}</h5>
          <div class="text-container text-center">
            <h6 *ngIf="!employeeWithCcps" class="stage-header">{{'Step' | translate}} 2</h6>
            <h6 *ngIf="employeeWithCcps" class="stage-header">{{'Step' | translate}} 3</h6>
            <div *ngIf="!employeeWithCcps" class="stage-container">
              <div class="stepper-wrapper">
                <div class="stepper-item completed active">
                  <div class="step-counter">1</div>
                </div>
                <div class="stepper-item completed active">
                  <div class="step-counter">2</div>
                </div>
              </div>
            </div>
            <div *ngIf="employeeWithCcps" class="stage-container">
              <div class="stepper-wrapper">
                <div class="stepper-item completed active">
                  <div class="step-counter">1</div>
                </div>
                <div class="stepper-item completed active">
                  <div class="step-counter">2</div>
                </div>
                <div class="stepper-item completed active">
                  <div class="step-counter">3</div>
                </div>
              </div>
            </div>
          </div>
          <form id="WithCcpsStage2Form" [formGroup]="CcpsStage2Form">
            <div class="input-container input-box-white">
              <div class="child-container" [owlDateTimeTrigger]="dt45">
                <label class="labeltxt">{{'Join Date' | translate}}</label>
                <div class="date-field">
                  <input class="" [max]="todayDate" [owlDateTime]="dt45" readonly name="dob" formControlName="dob">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                  </div>
                </div>
                <div *ngIf="isSubmit2 && h.dob.errors" class="invalid-feedback">
                  <div *ngIf="h.dob.errors?.required">{{'Join Date'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="h.dob.errors?.pattern">{{'Join Date'|translate}} {{'should be valid' | translate}}</div>
                </div>
                <owl-date-time [pickerType]="'calendar'" #dt45></owl-date-time>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'position' | translate}}</label>
                <input type="text" name="position" formControlName="position">
                <div *ngIf="isSubmit2 && h.position.errors" class="invalid-feedback">
                  <div *ngIf="h.position.errors?.required">{{'position'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="h.position.errors?.pattern">{{'position'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'Base Salary' | translate}}</label>
                <input type="text" name="base_salary" formControlName="base_salary">
                <div *ngIf="isSubmit2 && h.base_salary.errors" class="invalid-feedback">
                  <div *ngIf="h.base_salary.errors?.required">{{'Base Salary'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="h.base_salary.errors?.pattern">{{'Base Salary'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'Salary' | translate}}</label>
                <input type="text" name="salary" formControlName="salary">
                <div *ngIf="isSubmit2 && h.salary.errors" class="invalid-feedback">
                  <div *ngIf="h.salary.errors?.required">{{'Salary'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="h.salary.errors?.pattern">{{'Salary'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
            </div>
            <div class="d-flex buttons">
              <button (click)="onSubmitEmployeeSalary()" id="submit_btn" class="btn btn-medium">{{'Submit' | translate}}</button>
              <!-- <button id="submit_btn" class="btn btn-medium" (click)="addmore()">{{'Add More' | translate}}</button> -->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="modal-section2 bulk-upload-modal">
  <div class="modal fade" id="bulkUpload" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" (click)="closeModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <div class="parent">
            <div class="image-container">
              <img src="assets/images/link_success.png">
            </div>
            <div class="text-container text-center">
              <h4 class="modal-header-txt">{{'Bulk Upload Employees' | translate}}</h4>
              <p class="para-light">{{'Please Download and Use the Below Excel Template to Bulk Upload Employees' | translate}}</p>
              <form [formGroup]="EmpolyeeBulkUploadForm">
                <div class="bulk-upload-container">
                  <button class="btn btn-medium" (click)="downloadExcel()">{{'Download' | translate}} </button>
                  <label class="btn btn-medium" for="bulkUploadEmployee">{{'Upload' | translate}}</label>
                  <input id="bulkUploadEmployee" type="file" formControlName="bulkUpload" (change)="bulkUpload($event, 'bulkUpload')" data-target="bulkUploadEmployee">
                  <a id="bulkUpload" href="{{imageUrl}}" target="_blank" class="viewFile text-center"></a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="modal-section2 bulk-upload-modal">
  <div class="modal fade" id="bulkUpload" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" (click)="closeModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <div class="parent">
            <div class="image-container">
              <img src="assets/images/link_success.png">
            </div>
            <div class="text-container text-center">
              <h4 class="modal-header-txt">{{'Bulk Upload Employees' | translate}}</h4>
              <p class="para-light">{{'Please Download and Use the Below Excel Template to Bulk Upload Employees' | translate}}</p>
              <form [formGroup]="EmpolyeeBulkUploadForm">
                <div class="bulk-upload-container">
                  <button class="btn btn-medium" (click)="downloadExcel()">{{'Download' | translate}} </button>
                  <label class="btn btn-medium" for="bulkUploadEmployee">{{'Upload' | translate}}</label>
                  <input id="bulkUploadEmployee" type="file" formControlName="bulkUpload" (change)="upload($event, 'bulkUpload')" data-target="bulkUploadEmployee">
                  <a id="bulkUpload" href="{{imageUrl}}" target="_blank" class="viewFile text-center"></a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="modal-section2 bulk-upload-modal">
  <div class="modal fade" id="allEmployeeModal" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <div class="parent-container">
            <h5 class="form-title text-center">{{'List of Employees to Add' | translate}}</h5>
            <div class="text-container text-center">
              <h6 *ngIf="!employeeWithCcps" class="stage-header">{{'Step' | translate}} 2</h6>
              <h6 *ngIf="employeeWithCcps" class="stage-header">{{'Step' | translate}} 3</h6>
              <div *ngIf="!employeeWithCcps" class="stage-container">
                <div class="stepper-wrapper">
                  <div class="stepper-item completed active">
                    <div class="step-counter">1</div>
                  </div>
                  <div class="stepper-item completed active">
                    <div class="step-counter">2</div>
                  </div>
                </div>
              </div>
              <div *ngIf="employeeWithCcps" class="stage-container">
                <div class="stepper-wrapper">
                  <div class="stepper-item completed active">
                    <div class="step-counter">1</div>
                  </div>
                  <div class="stepper-item completed active">
                    <div class="step-counter">2</div>
                  </div>
                  <div class="stepper-item completed active">
                    <div class="step-counter">3</div>
                  </div>
                </div>
              </div>
            </div>
            <section class="quiz_section" id="quizeSection">
              <div class="container">
                <div class="row">
                  <div class="col-sm-12" *ngFor="let employee of employeespendingList">
                    <div class="quiz_card_area">
                      <input class="quiz_checkbox" type="checkbox" id="employee.id" value="employee.id" checked="checked" (change)="onChange(employee.id, $event.target.checked)" />
                      <div class="single_quiz_card">
                        <div class="quiz_card_content">
                          <div class="quiz_card_title">
                            <h3><i class="fa fa-check" aria-hidden="true"></i>{{ employee.name }}</h3>
                          </div>
                          <div class="quiz_card_icon">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'Date of Birth' | translate }} :</label>
                                  <h3>{{ employee.dob_type }}</h3>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'Designation' | translate }} :</label>
                                  <h3>{{ employee.designation }}</h3>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'CNAMGS ID' | translate }} :</label>
                                  <h3 *ngIf="employee.cnamgs">{{ employee.cnamgs }}</h3>
                                  <h3 *ngIf="!employee.cnamgs">--</h3>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'CNSS ID' | translate}} :</label>
                                  <h3 *ngIf="employee.cnss">{{ employee.cnss }}</h3>
                                  <h3 *ngIf="!employee.cnss">--</h3>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'Mobile' | translate}} :</label>
                                  <h3>{{ employee.mobile }}</h3>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'Salary' | translate}} :</label>
                                  <h3>{{ employee.salary }} FCFA</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex buttons">
                <button (click)="submitEmployees()" id="submit_btn" class="btn btn-medium">{{'Submit' | translate}}</button>
                <button id="submit_btn" class="btn btn-medium" (click)="addmore()">{{'Add More employees' | translate}}</button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Employees added success modal -->
<section class="modal-section2">
  <div class="modal fade" id="addEmployeeSuccessModal" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="parent">
            <div class="image-container">
              <img src="assets/images/link_success.png">
            </div>
            <div class="text-container text-center">
              <h4 class="modal-header-txt">{{'Congratulations!' | translate}}</h4>
              <p class="para-light">{{'We acknowledge receipt of the insured persons you declare. We will study your declaration with a view to activating their CCPS identifier.' | translate}}</p>
              <button id="submit_btn" class="btn btn-medium" data-dismiss="modal" (click)="closeSuccessModal()">{{'OK' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--bulk upload success modal -->
<section class="modal-section2 bulk-upload-modal">
  <div class="modal fade" id="bulkUploadSuccesssModal" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
      <div class="modal-content">
        <!-- <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" (click)="closeModal()">
          <span aria-hidden="true">&times;</span>
        </button> -->
        <div class="modal-body">
          <div class="parent-container">
            <h5 *ngIf="showSuccessTitle" class="form-title text-center">{{'List of Employees Successfully Added' | translate}}</h5>
            <div class="text-container text-center">
            </div>
              <section class="quiz_section" id="quizeSection">
              <div class="container">
                <div class="row">
                  <div class="col-sm-12" *ngFor="let success of bulkUploadSuccess">
                    <div class="quiz_card_area">
                      <div class="single_quiz_card bulksuccess">
                        <div class="quiz_card_content">
                          <div class="quiz_card_title ">
                            <h3><i class="fa fa-check" aria-hidden="true"></i>{{ success.firstname }} {{ success.lastname }}</h3>
                          </div>
                          <div class="quiz_card_icon">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'Date of Birth' | translate }} :</label>
                                  <h3>{{ success.dob }}</h3>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'Designation' | translate }} :</label>
                                  <h3>{{ success.designation }}</h3>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'CNAMGS ID' | translate }} :</label>
                                  <h3 *ngIf="success.cnamgs">{{ success.cnamgs }}</h3>
                                  <h3 *ngIf="!success.cnamgs">--</h3>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'CNSS ID' | translate}} :</label>
                                  <h3 *ngIf="success.cnss">{{ success.cnss }}</h3>
                                  <h3 *ngIf="!success.cnss">--</h3>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'Mobile' | translate}} :</label>
                                  <h3>{{ success.mobile }}</h3>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'Salary' | translate}} :</label>
                                  <h3>{{ success.salary }} FCFA</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
              <h5 *ngIf="showFailedTitle" class="form-title text-center">{{'List of Employees Failed to Add' | translate}}</h5>
            <section class="quiz_section" id="quizeSection">
              <div class="container">
                <div class="row">
                  <div class="col-sm-12" *ngFor="let failed of bulkUploadFailed">
                    <div class="quiz_card_area">
                      <div class="single_quiz_card bulkfailed">
                        <div class="quiz_card_content">
                          <div class="quiz_card_title ">
                            <h3><i class="fa fa-check" aria-hidden="true"></i>{{ failed.firstname }} {{ failed.lastname }}</h3>
                          </div>
                          <div class="quiz_card_icon">
                            <div class="row">
                              <div class="col-md-6">
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'Date of Birth' | translate }} :</label>
                                  <h3>{{ failed.dob }}</h3>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'Designation' | translate }} :</label>
                                  <h3>{{ failed.designation }}</h3>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'CNAMGS ID' | translate }} :</label>
                                  <h3 *ngIf="failed.cnamgs">{{ failed.cnamgs }}</h3>
                                  <h3 *ngIf="!failed.cnamgs">--</h3>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'CNSS ID' | translate}} :</label>
                                  <h3 *ngIf="failed.cnss">{{ failed.cnss }}</h3>
                                  <h3 *ngIf="!failed.cnss">--</h3>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'Mobile' | translate}} :</label>
                                  <h3>{{ failed.mobile }}</h3>
                                </div>
                                <div class="d-flex align-items-center justify-content-center">
                                  <label>{{'Salary' | translate}} :</label>
                                  <h3>{{ failed.salary }} FCFA</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngFor="let reason of failed.reason; let i = index " class="reason-txt invalid-feedback text-center">* {{reason.message}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex buttons">
                <button  (click)="closeModal('1')" id="submit_btn" class="btn btn-medium">{{'OK' | translate}}</button>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!--Employees added  success modal  end-->
<app-contact-floating></app-contact-floating>
