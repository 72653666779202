import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
declare var $: any;

@Component({
  selector: 'app-reports-list',
  templateUrl: './reports-list.component.html',
  styleUrls: ['./reports-list.component.scss']
})
export class ReportsListComponent implements OnInit {

  data: any;
  response: any;
  cnssreportList: any;

  constructor(private router: Router,
    private ngxUiLoaderService: NgxUiLoaderService,
    private api: ApiService) {

    if (this.router.getCurrentNavigation().extras.state) {
      this.data = this.router.getCurrentNavigation().extras.state.data;
      console.log(this.data, "Line 16")
    }
  }

  ngOnInit() {
    AOS.init({
      disable: function() {
        var maxWidth = 600;
        return window.innerWidth < maxWidth;
      }
    });
    this.getCnssReport();
  }

  gotoUrl() {
    this.router.navigate(['/reports'], { state: { data: '3' } });
  }

  getCnssReport() {
    this.ngxUiLoaderService.start();
    this.api.getCnssreportList().pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res;
          if (this.response.status == 200) {
            this.cnssreportList = this.response.result;
            if (this.data) {
              $('#successModal').modal('show');
            }
          } else {

          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }

  getViewofReports(uuid, status) {
    localStorage.setItem('reportid', uuid)
    console.log(uuid)
    console.log("68",status)
    if (status == 'rejectedCnss') {
      console.log("70")
      this.router.navigate(['reports', uuid]);
    }
    else{
      this.router.navigate(['/report-view', status, uuid]);
    }
    
  }

}
