<app-header></app-header>
<section class="middle-section contactUs" id="parent">
  <!--              <div class="container-fluid">
                
                
             </div> -->
</section>
<section class="contactUsMain">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-8 col-lg-12 col-md-12 col-sm-12">
        <div class="text-container" data-aos="zoom-in" data-aos-delay="100" data-aos-duration="1500">
          <h2 class="title-green-lg">{{'Get in touch' | translate}}</h2>
          <p class="para-light">{{'footer text' | translate}}</p>
        </div>
      </div>
    </div>
    <div class="parent-container" data-aos-anchor="#parent" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1500">
      <form class="form-container" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
        <h5 class="form-title">{{'Send us a message' | translate}}</h5>
        <div class="input-container">
          <div class="child-container">
            <label>{{'Your Name' | translate}}</label>
            <input name="name" formControlName="name">
            <div *ngIf="isSubmitted && f.name.errors" class="invalid-feedback">
              <div *ngIf="f.name.errors?.required">{{'Name'|translate}} {{'is required'|translate}}</div>
              <div *ngIf="f.name.errors?.pattern">{{'Name'|translate}} {{'should be valid'|translate}}</div>
            </div>
          </div>
          <div class="child-container">
            <label>{{'Email Address' | translate}}</label>
            <input type="text" name="email" formControlName="email">
            <div *ngIf="isSubmitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="f.email.errors?.required">{{'Email Address'|translate}} {{'is required'|translate}}</div>
              <div *ngIf="f.email.errors?.email">{{'Email Address'|translate}} {{'should be valid' | translate}}</div>
            </div>
          </div>
          <div class="child-container">
            <label>{{'Phone' | translate}}</label>
            <input name="mobile" formControlName="mobile" minlength="8" maxlength="8">
            <div *ngIf="isSubmitted && f.mobile.errors" class="invalid-feedback">
              <div *ngIf="f.mobile.errors?.required">{{'Phone'|translate}} {{'is required'|translate}}</div>
              <div *ngIf="f.mobile.errors?.pattern|| f.mobile.hasError('minlength') ">{{'Phone'|translate}} {{'should be valid' | translate}}</div>
            </div>
          </div>
          <div class="child-container">
            <label>{{'Company name' | translate}}</label>
            <input name="company_name" formControlName="company_name">
            <div *ngIf="isSubmitted && f.company_name.errors" class="invalid-feedback">
              <div *ngIf="f.company_name.errors?.required">{{'Company name'|translate}} {{'is required'|translate}}</div>
              <div *ngIf="f.company_name.errors?.pattern">{{'Company name'|translate}} {{'should be valid'|translate}}</div>
            </div>
          </div>
          <div class="child-container">
            <label>{{'Message' | translate}}</label>
            <textarea name="message" formControlName="message"></textarea>
            <div *ngIf="isSubmitted && f.message.errors" class="invalid-feedback">
              <div *ngIf="f.message.errors?.required">{{'Message'|translate}} {{'is required'|translate}}</div>
              <div *ngIf="f.message.errors?.pattern">{{'Message'|translate}} {{'should be valid'|translate}}</div>
            </div>
          </div>
        </div>
        <button type="submit" id="submit_btn" class="round_btn"> <img src="assets/images/paper-plane.png"></button>
      </form>
      <div class="info-container">
        <h5 class="form-title text-white">{{'Contact Information' | translate}}</h5>
        <div class="text-container">
          <div class="child-container">
            <img src="assets/images/pin.png">
            <p>Lorem ipsum dolor sit amet,<br> consectetur adipiscing elit, sed</p>
          </div>
          <div class="child-container">
            <img src="assets/images/phone.png">
            <p><a href="tel:+241 543 453 45 ">+241 543 333 45</a>
              <a href="tel:+241 543 543 45">+241 543 543 45</a></p>
          </div>
          <div class="child-container">
            <img src="assets/images/email.png">
            <p><a href="mailto:info.ccps@gmail.com">info.ccps@gmail.com</a></p>
          </div>
          <div class="child-container">
            <div class="social-icons">
              <a href="" target="_blank"><i class="fab fa-facebook-f" aria-hidden="true"></i></a>
              <a href="" target="_blank"><i class="fab fa-instagram" aria-hidden="true"></i></a>
              <a href="" target="_blank"><img src="assets/images/twitter_icon.png"></a>
              <a href="" target="_blank"><i class="fab fa-linkedin-in" aria-hidden="true"></i></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
<app-contact-floating></app-contact-floating>
