<app-header></app-header>
<section class="middle-section getstarted mb-5">
    <div class="container-fluid">
        <div class="image-container">
            <img src="assets/images/getStarted_image.png">
        </div>
        <div class="txt-container">
            <h3 class="text-center">{{'get started message' | translate}}</h3>
            <p>{{'get started para 1' | translate }}</p>
            <p>{{'get started para 2' | translate}}</p>
            <button id="submit_btn" class="btn btn-main2" (click)="getStarted()">{{'Get Started' | translate}}</button>
        </div>
    </div>

</section>
<app-footer></app-footer>
<app-contact-floating></app-contact-floating>