import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '../../../../_services/translate.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var $: any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  login: any;
  url: any;
  isBusiness = false;
  accountDetails: any;
  constructor(private ngZone: NgZone,
    private router: Router,
    private translate: TranslateService,
    private formBuilder: FormBuilder) {
    const data = localStorage.getItem('currentUserDetails');
    if (data) {
      this.accountDetails = JSON.parse(data);
      this.login = this.accountDetails.current_user;
      console.log("36 login value:", this.login)
      if (this.accountDetails.GNI == "1") {
        this.isBusiness = true;
      }
    }
  }

  ngOnInit() {
    this.ngZone.run(() => {
      this.url = window.location.pathname.split('/')[1];
    });


  }

  getCnamgs() {
    console.log("39")
    this.router.navigate(['/reports/cnamgs'], { state: { data: 'cnamgs' } });
  }
  getCnamgsemployee() {
    this.router.navigate(['/cnss/cnamgs'], { state: { data: 'cnamgs' } });
  }

}
