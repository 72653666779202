import { Component, OnInit, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
import { TranslateService } from '../../../_services/translate.service';
import { AnpiService } from '../../../_services/anpi-code.service';
import { Location } from '@angular/common';
import * as $ from 'jquery';
@Component({
  selector: 'app-cnamgs-form',
  templateUrl: './cnamgs-form.component.html',
  styleUrls: ['./cnamgs-form.component.scss']
})
export class CnamgsFormComponent implements OnInit {

  public cnssForm: FormGroup;
  isSubmitted = false;
  response : any;
  bankDetails:any;
  mobileMoney = false;
  moneydata: any;
  bankdata:any;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private api: ApiService,
              private translate: TranslateService,
              private anpiService: AnpiService,
              private location: Location,
              private ngzone: NgZone) { }

    ngOnInit() {
      this.getBanks();
      this.cnssForm = this.formBuilder.group({
        cnss_contributor_number: ['', [Validators.required,Validators.pattern("^[a-zA-Z0-9]+$")]],
        company_name: ['', [Validators.required,Validators.pattern("^[a-zA-Z0-9]+$")]],
        trade_name : ['', [Validators.required,Validators.pattern("^[a-zA-Z0-9]+$")]],
        acronym : ['', [Validators.required,Validators.pattern("^[a-zA-Z0-9 ]+$")]],
        borough  : ['', [Validators.required,Validators.pattern("^[a-zA-Z0-9 ]+$")]],
        neighbourhood : ['', [Validators.required,Validators.pattern("^[a-zA-Z0-9 ]+$")]],
        street  : ['', [Validators.required,Validators.pattern("^[a-zA-Z0-9 ]+$")]],
        door_number : ['', [Validators.required,Validators.pattern("^[a-zA-Z0-9 ]+$")]],
        place_called :['', [Validators.required]],
        locality : ['', [Validators.required,Validators.pattern("^[a-zA-Z0-9 ]+$")]],
        region : ['', [Validators.required,Validators.pattern("^[a-zA-Z0-9 ]+$")]],
        contact_1: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
        bp: ['', [Validators.required]],
        contact_2: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
        fax: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
        email:['', [Validators.required,Validators.email]],
        website:['', [Validators.required]],
        created_date:['', [Validators.required]],
        service_start_date:['', [Validators.required]],
        legal_status:['', [Validators.required]],
        employer_plan:['', [Validators.required]],
        tax_number : ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
        trade_reg_number:['', [Validators.required]],
        nif:['', [Validators.required]],
        seeg_subscription_no: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
        banks: ['', [Validators.required]],
        account_number: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
        account_name: ['', [Validators.required,Validators.pattern("^[a-zA-Z0-9 ]+$")]],
        bank_name: ['', [Validators.required,Validators.pattern("^[a-zA-Z0-9]+$")]],
        bank_number: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
        mobile_money_account_name: ['', [Validators.required,Validators.pattern("^[0-9]*$")]],
        // rib_key:['', [Validators.required]],
        guardianship:['', [Validators.required]],
        facility_name:['', [Validators.required]],
        money:['', [Validators.required]],
        branch_activity:['', [Validators.required]],
        workers_count_men:['', [Validators.required]],
        workers_count_women:['', [Validators.required]],
        confirm_account:['', [Validators.required]],
      });
      if (!this.api.getValidationStatus()) {
        this.api.getFormValidation(this.cnssForm);
      }    
      const storedBankDataString = localStorage.getItem('BankData');
      if (storedBankDataString) {
        this.bankDetails = JSON.parse(storedBankDataString);
        console.log("bak details:",this.bankDetails)
      } else {

      }
      this.setCnssFormValues();
      console.log("27",this.bankDetails)
  }
  onSubmitCnssForm(){
    this.isSubmitted = true;
      if (this.cnssForm.invalid) {
      console.log("61",this.cnssForm)
        return;
  }
  var data = this.cnssForm.value;
    // this.ngxUiLoaderService.start();
    this.api.cnssApprovalForm(data).pipe(first())
      .subscribe(data => {
        // this.ngxUiLoaderService.stop();
        // this.response = data;
        if(this.response.status == 200){
            // this.CompanyDetails = this.response.data;
        }else{
            // this.snackBar.open(res.message, "dismiss", {
            //      duration: 2000,
            //   });
        }
      },
      error => {
          // this.ngxUiLoaderService.stop();
      });  
  this.isSubmitted = false;
  localStorage.setItem('cnamgsForm',"1");
  this.router.navigate(['/cnamgs-report'], { state: { data: '1' } });
  }


  makeInputReadOnly() {
  Object.keys(this.cnssForm.controls).forEach(controlName => {
  const control = this.cnssForm.get(controlName);
    if (control && control.value !== null && control.value !== undefined && control.value !== '') {
      control.disable();
    }
  });
  }

  private showSelectedGridChild(selectedValue: string): void {
  const gridChildren = document.querySelectorAll('.grid-child');
  gridChildren.forEach((child: Element) => {
    const castedChild = child as HTMLElement;
    castedChild.style.display = 'none';
  });

  const selectedGridChild = document.querySelector(`.grid-child input[value="${selectedValue}"]`);
  console.log("126", selectedGridChild);

  if (selectedGridChild) {

    (selectedGridChild as HTMLElement).parentElement.style.display = 'block';
  } else {
    console.warn('No matching grid child found.');
  }
  }
  setCnssFormValues(){

  this.cnssForm.setValue({
    cnss_contributor_number:"",
    company_name:"In Flow Gabon SARL",
    trade_name : "In Flow Gabon SARL",
    acronym : "BW",
    borough  :"85",
    neighbourhood :"1",
    street  :"Avenue Omar Bongo Ondimba",
    door_number :"138",
    place_called :"Avenue Omar Bongo Ondimba",
    locality :"1",
    region :"1",
    contact_1: "241 678563456",
    bp: "BP2345455 ",
    contact_2: "241 76676677 ",
    fax: "657567",
    email:"inflow@gmail.com",
    website:"inflow.com",
    created_date:"24-08-2023",
    service_start_date:"24-08-2023",
    legal_status:"SARLU",
    employer_plan:"Forfait de base",
    tax_number :"TT565677677",
    trade_reg_number:"RC7887887",
    nif:"NIF57655757",
    seeg_subscription_no:"SG67575675",
    banks:"4",
    account_number:"5688647437",
    account_name :"JACOBMILAN",
    bank_name:"UGB",
    bank_number:"879869",
    // rib_key:['', [Validators.required]],
    guardianship:"Owner",
    mobile_money_account_name:"Owner",
    money:"1",
    facility_name:"Owner",
    branch_activity:"Agriculture",
    workers_count_men:"45",
    workers_count_women :"21",
    confirm_account:"true"
  });
    // this.makeInputReadOnly();
  console.log(this.cnssForm.value)
  // const initialValue = this.cnssForm.get('banks').value;
  // if(initialValue){
  //   this.showSelectedGridChild(initialValue);
  // }    
  }

  get f(){
    return this.cnssForm.controls;
  }

  goback() {
    this.location.back();
  }

  getBanks() {
    this.api.getBanks().pipe(first())
    .subscribe(data => { 
      this.bankdata = data.results.banks;
      this.moneydata = data.results.mobilemoney;
      setTimeout(() => {
        this.ngzone.run(() => {
          console.log("fdsfdsfsdfd")
          $('#bank1').prop('checked', true);
          console.log($('#bank1'))
          console.log($('#money1'))
          $('#money1').prop('checked', true);
          this.cnssForm.controls['bank_name'].setValue(this.bankdata[0].name);
          this.cnssForm.controls['facility_name'].setValue(this.moneydata[0].name);
        });
      }, 2000)
    })
   }

   handleChange(e, value, val) {
    console.log(value)
    console.log()
    if (val == 1) {
      this.cnssForm.controls['bank_name'].setValue(value);
    } else {
      this.cnssForm.controls['facility_name'].setValue(value);
    }
  }

  open(val){
    console.log(val)
  
      switch(val){
      case 'bank' : {   
        this.isSubmitted = false; 
        this.cnssForm.controls['facility_name'].setValue('');  
        this.cnssForm.get('facility_name').updateValueAndValidity(); 
        this.mobileMoney = false; 
        console.log('198')
        console.log(this.isSubmitted)
        setTimeout(() =>{
          $('#bank1').prop('checked', true);
          this.cnssForm.controls['bank_name'].setValue(this.bankdata[0].name);
          this.cnssForm.get('bank_name').updateValueAndValidity(); 
        }, 100)
        break;
      }
      case 'money' : {     
        this.isSubmitted = false; 
        this.cnssForm.controls['bank_name'].setValue('');  
        this.cnssForm.get('bank_name').updateValueAndValidity();    
        this.mobileMoney = true;
        console.log(this.isSubmitted)
        setTimeout(() =>{
          console.log($('#money1'))
          $('#money1').prop('checked', true);
          this.cnssForm.controls['facility_name'].setValue(this.moneydata[0].name);
           this.cnssForm.get('facility_name').updateValueAndValidity();  
        }, 100)
        console.log('207')
        break;
      }
     }
  }

}
