import { DatePipe } from '@angular/common';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE, OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';

import { TranslateService } from '../_services/translate.service';
import { TranslatePipe } from '../_pipes/translate.pipe';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HeaderComponent } from './pages/layout/header/header.component';
import { FooterComponent } from './pages/layout/footer/footer.component';
import { HomePageComponent } from './pages/home-page/home-page.component';
import { EmployeesComponent } from './pages/employees/employees.component';
import { HttpClientModule, HTTP_INTERCEPTORS  } from '@angular/common/http';
import { CnssComponent } from './pages/cnss/cnss.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { FaqComponent } from './pages/faq/faq.component';
import { NotificationComponent } from './pages/notification/notification.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { DependenceComponent } from './pages/dependence/dependence.component';
import { ExperienceComponent } from './pages/experience/experience.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { SalaryReviseComponent } from './pages/salary-revise/salary-revise.component';
import { GetStartedComponent } from './pages/get-started/get-started.component';
import { LinkBusinessComponent } from './pages/link-business/link-business.component';
import { ReportsListComponent } from './pages/reports-list/reports-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { PaymentComponent } from './pages/payment/payment.component';
import { ReportViewComponent } from './pages/report-view/report-view.component';
import { MatButtonModule,MatSnackBarModule,MatDatepickerModule,MatNativeDateModule,MAT_DATE_LOCALE, MatRadioModule, MatSelectModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatIconModule } from '@angular/material';
import { ApprovalFormComponent } from './pages/approval-form/approval-form.component';
import { AddBankComponent } from './pages/add-bank/add-bank.component';
import { ContactFloatingComponent } from './pages/layout/contact-floating/contact-floating.component';
import { OnboardingPortalComponent } from './pages/layout/onboarding-portal/onboarding-portal.component';
import { DependenceListComponent } from './pages/dependence-list/dependence-list.component';
import { CnamgsFormComponent } from './pages/cnamgs-form/cnamgs-form.component';
import { CnamgsReportComponent } from './pages/cnamgs-report/cnamgs-report.component';
import { CnamgsReportlistComponent } from './pages/cnamgs-reportlist/cnamgs-reportlist.component';
import { CnamgsReportviewComponent } from './pages/cnamgs-reportview/cnamgs-reportview.component';
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION,
  PB_DIRECTION, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';
import { BasicAuthInterceptor, ErrorInterceptor } from '../_helpers';
import { MainLayoutComponent } from './pages/layout/main-layout/main-layout.component';

  const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'yellow',
  // bgsOpacity: 0.5,
  // bgsPosition: POSITION.bottomCenter,
   bgsSize:420,
  // bgsType: SPINNER.rectangleBounce,
  fgsColor: 'white',
  // fgsPosition: POSITION.centerCenter,
   fgsSize: 200,
   fgsType: SPINNER.ballScaleMultiple,
  // logoUrl: 'assets/angular.png',
  pbColor: '#004e9a',
  // pbDirection: PB_DIRECTION.leftToRight,
   pbThickness: 10,
  // text: 'Welcome to ngx-ui-loader',
  // textColor: '#FFFFFF',
  // textPosition: POSITION.centerCenter
   masterLoaderId: "master"
}; 

export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use(localStorage.getItem('language') || "fr");
}
export const MY_CUSTOM_FORMATS = {
  fullPickerInput: 'YYYY-MM-DD',
  monthYearLabel: 'MMMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    HomePageComponent,
    EmployeesComponent,
    CnssComponent,
    TranslatePipe,
    ContactUsComponent,
    FaqComponent,
    NotificationComponent,
    ProfileComponent,
    DependenceComponent,
    ExperienceComponent,
    ReportsComponent,
    SalaryReviseComponent,
    GetStartedComponent,
    LinkBusinessComponent,
    ReportsListComponent,
    PaymentComponent,
    ReportViewComponent,
    ApprovalFormComponent,
    AddBankComponent,
    ContactFloatingComponent,
    OnboardingPortalComponent,
    DependenceListComponent,
    CnamgsFormComponent,
    CnamgsReportComponent,
    CnamgsReportlistComponent,
    CnamgsReportviewComponent,
    MainLayoutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    OwlDateTimeModule,
    OwlNativeDateTimeModule, MatButtonModule,MatSnackBarModule, MatRadioModule, MatSelectModule, MatCheckboxModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule
  ],
  providers: [
    TranslateService,
     DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: setupTranslateFactory, deps: [ TranslateService ], multi: true },
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'fr'},
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
