import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
declare var $: any;
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { AnpiService } from '../../../_services/anpi-code.service';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  initiateReportForm: FormGroup;
  cnssForm: FormGroup;
  contactForm: FormGroup;
  reportList = true;
  lastsec = false;
  cnsstable = false;
  isSubmitted = false;
  id: any;
  page: any;
  response: any;
  title: any;
  public todayDate: any = new Date();
  Data: any;
  business: any;
  bank: any;
  employees: any;
  hasCnssForm: any;
  value: any;
  accountDetails: any;
  responsequarter: any;
  cnssReport: any;
  submitted = false;
  employerDetails: any;
  reportId: any;
  isResubmit = false;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private anpiService: AnpiService,
    private location: Location,
    private ngxUiLoaderService: NgxUiLoaderService,
    private snackBar: MatSnackBar) {
    if (this.router.getCurrentNavigation().extras.state) {
      console.log(this.router.getCurrentNavigation().extras)
      this.value = this.router.getCurrentNavigation().extras.state.data;
      console.log("47", this.value)
      if (this.value == '1') {
        setTimeout(() => {
          $('#successModal').modal('show');
        }, 1000)
      } else if (this.value == '3') {
        setTimeout(() => {
          $('#reportModal').modal('show');
        }, 200)
      }
    }
    this.resubmitForm();
    this.checkLocalStorage();

  }

  ngOnInit() {
    this.anpiService.changeEvent.subscribe(() => {
      console.log('inside employees emit event')
    });
    this.cnssForm = this.formBuilder.group({
      reportMonth: this.formBuilder.array(['']),
      confirm: ['', [Validators.requiredTrue]]
    });
    this.contactForm = this.formBuilder.group({
      year: ['', [Validators.required]],
      quarter: ['', [Validators.required]]
    });
    this.contactForm.controls['year'].setValue('2024');
    this.contactForm.controls['quarter'].setValue('1');
    this.initiateReportForm = this.formBuilder.group({
      initiate_report_year: ['', [Validators.required]],
      initiate_report_quarter: ['', [Validators.required]],

    });
    this.initiateReportForm.controls['initiate_report_year'].setValue('1');
    this.initiateReportForm.controls['initiate_report_quarter'].setValue('1');
    this.route.params.subscribe(
      (params: Params) => {
        console.log("28", params['id']);
        if (params['id'] != 'cnamgs') {
          this.id = params['id'];
          switch (this.id) {
            case 'normalcnss':
              {
                console.log('normalcnss')
                $('.payment_table').addClass('dNone');
                $('.dateTerminateInput').removeClass('dNone');
                $('.dateTerminate').removeClass('dBlock');
                $('.cnamgs_report').addClass('dNone');
                $('.employer_dash').addClass('dNone');
                $('.pendingStatus').removeClass('dBlock');
                $('.ongoingStatus').removeClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.completedStatus').addClass('dNone');

                $('input').removeClass('changeInput_style');
                $('.tableFooter').removeClass('dNone');
                $('#resubMit_btn').removeClass('d-block');
                $('#submit_btn').addClass('d-block');
                break;
              }
            case 'pendingcnss':
              {
                console.log('pendingcnss')
                $('.cnamgs_report').addClass('dNone');
                $('.employer_dash').addClass('dNone');
                $('.payment_table').addClass('dNone');
                //$('.dateTerminateInput').addClass('dNone');
                $('.dateTerminate').addClass('dBlock');

                $('.pendingStatus').addClass('dBlock');
                $('.ongoingStatus').addClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.completedStatus').addClass('dNone');
                $('input').addClass('changeInput_style');
                $('.tableFooter').addClass('dNone');
                break;
              }
            case 'payNowCnss':
              {
                console.log('payNowCnss')
                $('.cnamgs_report').addClass('dNone');
                $('.employer_dash').addClass('dNone');
                $('.payment_table').addClass('dInTable');
                $('.quarter_table').addClass('dNone');

                $('.pendingStatus').addClass('dNone');
                $('.ongoingStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.payNowStatus').addClass('dBlock');
                $('.completedStatus').addClass('dNone');

                $('.tableFooter').addClass('dNone');
                $('.tableFooter2').addClass('d-block');
                $('.tableFooter3').addClass('dNone');
                break;
              }
            case 'completedCnss':
              {
                console.log('completedCnss')
                $('.cnamgs_report').addClass('dNone');
                $('.employer_dash').addClass('dNone');
                $('.payment_table').addClass('dInTable');
                $('.quarter_table').addClass('dNone');

                $('.pendingStatus').addClass('dNone');
                $('.ongoingStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.completedStatus').addClass('dBlock');

                $('.tableFooter').addClass('dNone');
                $('.tableFooter2').addClass('dNone');
                $('.tableFooter3').addClass('d-block');
                break;
              }
            case 'rejectedCnss':
              {
                console.log('rejectedCnss')
                $('.cnamgs_report').addClass('dNone');
                $('.employer_dash').addClass('dNone');
                $('.payment_table').addClass('dBlock');
                $('.dateTerminateInput').removeClass('dNone');
                $('.dateTerminate').removeClass('dBlock');
                $('.quarter_table').addClass('dNone');

                $('.pendingStatus').addClass('dNone');
                $('.ongoingStatus').addClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dBlock');
                $('.completedStatus').addClass('dNone');


                $('.rejectedNote').addClass('d-block');
                $('input').removeClass('changeInput_style');
                $('.tableFooter').removeClass('dNone');
                break;
              }
            case 'resubmitCnss':
              {
                console.log('resubmitCnss')
                $('.cnamgs_report').addClass('dNone');
                $('.employer_dash').addClass('dNone');
                $('.payment_table').addClass('dBlock');
                $('.dateTerminateInput').removeClass('dNone');
                $('.dateTerminate').removeClass('dBlock');
                $('.quarter_table').addClass('dBlock');

                $('.pendingStatus').addClass('dNone');
                $('.ongoingStatus').addClass('dNone');
                $('.payNowStatus').addClass('dNone');
                $('.rejectedStatus').addClass('dBlock');
                $('.completedStatus').addClass('dNone');

                $('.rejectedNote').addClass('d-block');
                $('input').removeClass('changeInput_style');
                $('.tableFooter').removeClass('dNone');
                break;
              }
          }
        } else {
          this.page = params['id'];
          console.log("114", this.page)
        }
      }
    );
    // this.showCnssList();
    //this.getReports();
  }

  closeModal() {
    $('#reportModal').modal('hide');
    this.isSubmitted = false;
  }
  onSubmitInitiateReport() {
    console.log("175")
    this.isSubmitted = true;
    if (this.initiateReportForm.invalid) {
      console.log("61", this.initiateReportForm)
      return;
    }
    var data = this.initiateReportForm.value;
    // this.ngxUiLoaderService.start();
    this.api.initiateReport(data).pipe(first())
      .subscribe(data => {
          // this.ngxUiLoaderService.stop();
          // this.response = data;
          if (this.response.status == 200) {
            // this.CompanyDetails = this.response.data;
          } else {
            // this.snackBar.open(res.message, "dismiss", {
            //      duration: 2000,
            //   });
          }
        },
        error => {
          // this.ngxUiLoaderService.stop();
        });
    this.isSubmitted = false;
    this.showCnssList();
  }
  showCnssList() {
    console.log(this.contactForm.value)
    if (this.contactForm.invalid) {
      return;
    }
    var data = this.contactForm.value;
    this.ngxUiLoaderService.start();
    this.api.getCnssReportonQuarter(data).pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.responsequarter = res;
          if (this.responsequarter.status == 200) {
            this.cnssReport = this.responsequarter.results;
            this.employerDetails = this.responsequarter.employer;

            this.createMonthArray()
          } else {
            this.snackBar.open(this.responsequarter.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }


  // showCnssList() {
  //   console.log(this.contactForm.value)
  //   if (this.contactForm.invalid) {
  //     return;
  //   }
  //   var data = this.contactForm.value;
  //   this.ngxUiLoaderService.start();
  //   this.api.getCnssReportonQuarter(data).pipe(first())
  //     .subscribe(data => {
  //         this.ngxUiLoaderService.stop();
  //         this.responsequarter = data;
  //         if (this.responsequarter.status == 200) {
  //           window.scrollTo({ top: 0, behavior: 'smooth' });
  //           this.reportList = false;
  //           this.lastsec = false;
  //           this.cnsstable = true;
  //           $('#reportModal').modal('hide');
  //           this.id = 'normalcnss';
  //           switch (this.id) {

  //             case 'normalcnss':
  //               {
  //                 console.log('aa')
  //                 $('.employer_dash').addClass('dNone');
  //                 $('.cnamgs_report').addClass('dNone');
  //                 $('.payment_table').addClass('dNone');
  //                 $('.dateTerminateInput').removeClass('dNone');
  //                 $('.dateTerminate').removeClass('dBlock');

  //                 $('.pendingStatus').removeClass('dBlock');
  //                 $('.ongoingStatus').removeClass('dNone');
  //                 $('.payNowStatus').addClass('dNone');
  //                 $('.rejectedStatus').addClass('dNone');
  //                 $('.completedStatus').addClass('dNone');

  //                 $('input').removeClass('changeInput_style');
  //                 $('.tableFooter').removeClass('dNone');
  //                 $('#resubMit_btn').removeClass('d-block');
  //                 $('#submit_btn').addClass('d-block');
  //                 break;
  //               }
  //           }
  //         } else {
  //           this.snackBar.open(this.responsequarter.message, "dismiss", {
  //             duration: 2000,
  //           });
  //         }
  //       },
  //       error => {
  //         this.ngxUiLoaderService.stop();
  //       });

  // }
  goToViewPage(val = null) {
    if (val) {
      this.value = '1';
    } else {
      $('#successModal').modal('hide');
      this.reportList = false;
      this.lastsec = true;
      this.cnsstable = false;
      this.router.navigate(['/reportList'], { state: { data: this.page } });
    }
  }

  paymentSubmit() {
    this.router.navigate(['/payment']);
  }

  get f() {
    return this.cnssForm.controls;
  }

  onSubmit() {
    console.log("361")
    this.submitted = true;
    var data = [];
    for (let key in this.cnssForm.controls.reportMonth['controls']) {
      this.cnssForm.controls.reportMonth['controls'][key]['controls'].reportEachemployee.updateValueAndValidity();
      data.push(this.cnssForm.controls.reportMonth['controls'][key].value)
    }
    console.log(data)
    if (this.cnssForm.invalid) {
      console.log(this.cnssForm)
      return;
    }
    let namedData = {
      report_data: data,
      report_id: this.employerDetails.report_id
    };
    this.api.submitCNSSreport(namedData).pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          this.response = data;
          if (this.response.status == 200) {
            //$('#successModal').modal('show');
            this.router.navigate(['/reportList'], { state: { data: this.response.result } });
          } else {}
          this.snackBar.open(this.response.message, "dismiss", {
            duration: 2000,
          });
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }

  checkLocalStorage() {
    const data = localStorage.getItem('currentUserDetails');
    if (data) {
      this.accountDetails = JSON.parse(data);
      console.log("sadsadsad", this.accountDetails)
    }
    if (this.accountDetails.GNI == 1) {
      this.business = true;
    }
    if (this.accountDetails.BankDetails == 1) {
      this.bank = true;
    }
    if (this.accountDetails.EmployeeDetails == 1) {
      this.employees = true;
    }
    console.log("142", this.value);
    if (this.value != "1") {
      console.log("sdfsdggsgsdgyuiaaswj")
      if (this.accountDetails.cnss == 1) {
        this.hasCnssForm = true;
      }
    }
    console.log("141", this.hasCnssForm);

    console.log("142", this.bank);
    if (this.accountDetails.GNI == '0') {
      this.title = 'business';
      console.log("138", this.title)
      setTimeout(function() {
        $('#initiateReportContainer').css('display', 'none');
      }, 100);
    } else if (this.business && this.accountDetails.BankDetails == '0') {
      this.title = 'bank';
      console.log("143", this.title)
      setTimeout(function() {
        $('#initiateReportContainer').css('display', 'none');
      }, 100);
    } else if (this.business && this.bank && this.accountDetails.cnss == '0' || this.business && this.bank && this.accountDetails.cnss == '2') {
      this.title = 'cnssForm';
      console.log("288", this.title)
      setTimeout(function() {
        $('#initiateReportContainer').css('display', 'none');
      }, 100);

    } else if (this.business && this.bank && this.accountDetails.cnss == '3') {
      this.title = 'cnssForm';
      console.log("288", this.title)
      setTimeout(function() {
        $('#initiateReportContainer').css('display', 'none');
      }, 100);

    } else if (this.accountDetails.cnss == '1') {
      this.title = 'initiateReport';
      console.log("341")
      setTimeout(function() {
        $('#initiateReportContainer').css('display', 'block');
        $('#report-header').css('display', 'block');
      }, 100);
    }
  }

  onSearchChange(e) {
    console.log(e.replace(' '))
    console.log(e.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '))
  }

  goback(val) {
    if (val == 2) {
      this.id = '';
    } else if (val == 1) {
      this.location.back();
    } else {
      this.location.back();
    }
  }

  resubmitForm() {
    this.route.params.subscribe(params => {
      console.log("471", params)
      this.reportId = params['id'];

      console.log("528", this.reportId)
      if (this.reportId) {
        this.isResubmit = true;
        console.log('476')
        this.getReportsbyID();
      }
    });

  }

  getReportsbyID() {
    this.ngxUiLoaderService.start();
    this.api.getReportsbyID(this.reportId).pipe(first())
      .subscribe(data => {
          this.response = data;
          this.ngxUiLoaderService.stop();
          if (this.response.status == 200) {
            this.employerDetails = this.response.results.data;
            this.cnssReport = this.response.results.quarter;
            console.log("152", this.cnssReport)
            console.log("153", this.employerDetails)
            console.log("154", this.employerDetails.employer_cnss)
            $('.rejectedNote').addClass('d-block');
            this.createMonthArray();
          } else {
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }
  createMonthArray() {
    console.log("506")
    for (let key in this.cnssReport) {
      var reportMonth = this.cnssForm.controls.reportMonth as FormArray;
      reportMonth.controls[key] = this.formBuilder.group({
        quarter: ['', []],
        reportEachemployee: this.formBuilder.array([''])
      })
      reportMonth.controls[key].controls.quarter.setValue(this.cnssReport[key].date)
      for (let key1 in this.cnssReport[key].employees) {
        var reportEachemployee = reportMonth.controls[key].controls.reportEachemployee as FormArray;
        reportEachemployee.controls[key1] = this.formBuilder.group({
          //termination_date: [''],
          working_hour: '',
          working_days: '',
          ccpsid: '',
          salary: '',
          id: ''
        })
        reportEachemployee.controls[key1].get('working_hour').setValue(this.cnssReport[key].employees[key1].working_hour)
        reportEachemployee.controls[key1].get('working_days').setValue(this.cnssReport[key].employees[key1].working_days)
        reportEachemployee.controls[key1].get('salary').setValue(this.cnssReport[key].employees[key1].salary)
        reportEachemployee.controls[key1].get('ccpsid').setValue(this.cnssReport[key].employees[key1].ccpsid)
        reportEachemployee.controls[key1].get('id').setValue(this.cnssReport[key].employees[key1].id)
        console.log("530")
        //console.log(reportEachemployee.controls[key1].get('termination_date'))
        // .toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
    }
    console.log(this.cnssForm.controls.reportMonth)
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.reportList = false;
    this.lastsec = false;
    this.cnsstable = true;
    $('#reportModal').modal('hide');
    this.id = 'normalcnss';
    switch (this.id) {

      case 'normalcnss':
        {
          console.log('aa')
          $('.employer_dash').addClass('dNone');
          $('.cnamgs_report').addClass('dNone');
          $('.payment_table').addClass('dNone');
          $('.dateTerminateInput').removeClass('dNone');
          $('.dateTerminate').removeClass('dBlock');

          $('.pendingStatus').removeClass('dBlock');
          $('.ongoingStatus').removeClass('dNone');
          $('.payNowStatus').addClass('dNone');
          $('.completedStatus').addClass('dNone');

          $('input').removeClass('changeInput_style');
          $('.tableFooter').removeClass('dNone');
          $('#resubMit_btn').removeClass('d-block');
          $('#submit_btn').addClass('d-block');
          $('.rejectedStatus').addClass('dNone');
          break;
        }
    }
  }
  onResubmit() {
    console.log("570")
    this.submitted = true;
    var data = [];
    for (let key in this.cnssForm.controls.reportMonth['controls']) {
      this.cnssForm.controls.reportMonth['controls'][key]['controls'].reportEachemployee.updateValueAndValidity();
      data.push(this.cnssForm.controls.reportMonth['controls'][key].value)
    }
    console.log("577",data)
    if (this.cnssForm.invalid) {
      console.log(this.cnssForm)
      return;
    }
    let namedData = {
      report_data: data,
      report_id: this.reportId
    };
    console.log("586",namedData)
    this.api.resubmitReport(namedData).pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          this.response = data;
          if (this.response.status == 200) {
            //$('#successModal').modal('show');
            this.router.navigate(['/reportList'], { state: { data: this.response.result } });
          } else {}
          this.snackBar.open(this.response.message, "dismiss", {
            duration: 2000,
          });
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }

  closeGenrateReport() {
    $('#reportModal').modal('hide');
  }

}
