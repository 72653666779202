<!-- <section class="container-fluid">
         
        </section> -->
<app-header></app-header>
<section class="middle-section getstarted reportsIMain" [hidden]="!cnssList">
</section>
<section class="middle-section reportsIMain" [hidden]="cnssList">
  <div class="container-fluid">
    <div class="initiale_sestion">
      <div class="head">
        <div class="title-container">
          <i (click)="goBack()" class="fa-solid fa-angle-left"></i>
          <h4 *ngIf="!page" class="form-title-sm">{{'Manage CNSS' | translate}}</h4>
          <h4 *ngIf="page" class="form-title-sm">{{'Manage CNAMGS' | translate}}</h4>
        </div>
      </div>
    </div>
    <div *ngIf="cnssReportList" class="employeeReports cnamgs_report">
      <div class="currentOverview">
        <div class="parent-container">
          <div class="child-container">
            <div class="custom-container">
              <h4 *ngIf="!page" class="title">{{'CNSS Details' | translate}}</h4>
              <h4 *ngIf="page" class="title">{{'CNAMGS Details' | translate}}</h4>
              <div class="sec1">
                <h4 class="text-md colorGrey" *ngIf="!page">{{'CNSS no' | translate}}&nbsp;:</h4>
                <h4 class="text-md colorGrey" *ngIf="page">{{'CNAMGS no' | translate}}&nbsp;:</h4>
                <h4 *ngIf="!page" class="text-md colorBlack">{{cnssReportList.cnss_no}}</h4>
                <h4 *ngIf="page" class="text-md colorBlack">CN185346</h4>
              </div>
              <div class="sec2">
                <h4 class="text-md colorGrey">{{'Registered on' | translate}}&nbsp;:</h4>
                <h4 class="text-md colorBlack">{{cnssReportList.registered_on}}</h4>
              </div>
              <div class="sec2">
                <h4 class="text-md colorGrey">{{'Company' | translate}}&nbsp;: </h4>
                <h4 class="text-md colorBlack">{{cnssReportList.company}}</h4>
              </div>
            </div>
          </div>
          <!-- <div class="child-container">
                                <div class="sec2">
                                    <h4 class="text-md colorGrey">Company&nbsp;: </h4>
                                    <h4 class="text-md colorBlack">In Flow Gabon SARL</h4>
                                </div>
                            </div> -->
          <div class="child-container">
            <div class="sec_3">
              <img src="assets/images/chart.png" alt="">
              <div class="custom-container">
                <div class="fields">
                  <h4 class="text-md colorBlack">{{'Contribution rate' | translate}}</h4>
                  <h4 class="text-md colorBlack">{{cnssReportList.contribution_rate}} %</h4>
                </div>
                <div class="fields">
                  <h4 class="text-md colorGrey">{{'Employer' | translate}}&nbsp;:</h4>
                  <h4 class="text-md colorBlack">{{cnssReportList.employer}} %</h4>
                </div>
                <div class="fields">
                  <h4 class="text-md colorGrey">{{'Worker' | translate}}&nbsp;:</h4>
                  <h4 class="text-md colorBlack">{{cnssReportList.worker}} %</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
     
      </div>
      <table class="emplyee_table mt-4">
        <colgroup>
          <col span="1" class="slno">
          <col span="1" class="month">
          <col span="1" class="baseSalary">
          <col span="1" class="totalSalary">
          <col span="1" class="wHD">
          <col span="1" class="cnssRate">
          <col span="1" class="cnssAmount">
          <col span="1" class="creditAmount">
          <!-- <col span="1" class="action"> -->
        </colgroup>
        <thead>
          <tr>
            <th scope="col">{{'SL NO' | translate}}</th>
            <th scope="col">{{'Month' | translate}}</th>
            <th scope="col">{{'Base Salary' | translate}}</th>
            <th scope="col">{{'Total Salary' | translate}}</th>
            <th scope="col">{{'Working Days/hr' | translate}}</th>
            <th scope="col">{{'Employee Contribution' | translate}}</th>
            <th scope="col">{{'Employer Contribution' | translate}}</th>
            <th scope="col">{{'Credit Amount' | translate}}</th>
            <!-- <th scope="col">{{'Download' | translate}}</th> -->
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let report of cnssReportList.cnss_list">
            <tr>
              <td colspan="11" class="tableChild1">
                <table style="width: 100%;" class="tableSub">
                  <tr>
                    <td data-label="" class="child1">
                       {{'Joined on' | translate}} {{report.join_date}}
                    </td>
                    <td data-label="" class="child2">
                       {{report.company_name}}
                    </td>
                    <td class="child3">
                      <a class="child3" (click)=" moreDetails(report.company_id)">{{'More Company Details' | translate}} <i class="fa-solid fa-angle-right"></i></a>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr *ngFor="let reportDetail of report.report_list; let i = index">
              <td attr.data-label="{{'SL NO' | translate}}">{{i + 1}}</td>
              <td attr.data-label="{{'Month' | translate}}">{{reportDetail.month}}</td>
              <td attr.data-label="{{'Base Salary' | translate}}">{{reportDetail.base_salary}} FCFA</td>
              <td attr.data-label="{{'Total Salary' | translate}}">{{reportDetail.total_salary}} FCFA</td>
              <td attr.data-label="{{'Working Days/h' | translate}}"> {{reportDetail.working_days}} {{'days' | translate}}/ {{reportDetail.working_hr}}&nbsp;{{'hrs' | translate}}</td>
              <td attr.data-label="{{'Employee Contribution' | translate}}">{{reportDetail.employee_contribution}} FCFA</td>
              <td attr.data-label="{{'Employer Contribution'}}">{{reportDetail.employer_contribution}} FCFA</td>
              <td class="amoutCreditCol" attr.data-label="{{'Credit Amount' | translate}}">{{reportDetail.credit_amount}} FCFA</td>
              <!-- <td attr.data-label="{{'Download' | translate}}"><img src="assets/images/pdf.png" alt=""></td> -->
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal fade" id="successModal" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="parent">
            <div class="image-container">
              <img src="assets/images/link_success.png">
            </div>
            <div class="text-container text-center">
              <h4 class="modal-header-txt">{{'report successfully submitted' | translate}}</h4>
              <p class="para-light">{{'report success text' | translate}}</p>
              <a href="reportsList.html"><button id="submit_btn" class="btn btn-medium">{{'Go to home' | translate}}</button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
<app-contact-floating></app-contact-floating>
