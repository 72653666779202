<app-header></app-header>
<section class="creation-form listdep" id="dependenceDetails">
  <div class="container-fluid">
    <div class="text-container">
      <div class="custom-container">
        <div class="backarrow">
          <h4>{{'Dependants Details' | translate}}</h4>
        </div>
        <i class="fa-solid fa-plus" routerLink="/dependence" (click)="goToCreatSection('1')"><span>{{'Add Dependants' | translate}}</span></i>
      </div>
    </div>
    <div class="list-card">
      <div class="cards" *ngFor="let item of dependantsList">
        <div class="row">
          <div class="col-md-2">
            <div class="profile_photo_container">
              <div class="profile_photo">
                <img src="{{item.profile_photo}}" class="img-fluid">
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="child-container d-flex align-items-center">
              <label class="labeltxt">{{'First Name' | translate}} :</label>
              <h6 class="labeltxt">{{item.name}}</h6>
            </div>
            <div class="child-container d-flex align-items-center">
              <label class="labeltxt">{{'Date of Birth' | translate}} :</label>
              <h6 class="labeltxt">{{item.dob}}</h6>
            </div>
            <div class="child-container d-flex align-items-center">
              <label class="labeltxt">{{'Occupation' | translate}} :</label>
              <h6 class="labeltxt">{{item.occupation}}</h6>
            </div>
          </div>
          <div class="col-md-4">
            <div class="child-container d-flex align-items-center">
              <label class="labeltxt">{{'Mobile Number' | translate}} :</label>
              <h6 class="labeltxt">+241 {{item.mobile}}</h6>
            </div>
            <div class="child-container d-flex align-items-center">
              <label class="labeltxt">{{'Nature de la dépendance' | translate}} :</label>
              <h6 class="labeltxt">{{item.relation}}</h6>
            </div>
            <div class="child-container d-flex align-items-center">
              <label class="labeltxt">{{'Gender' | translate}} :</label>
              <h6 class="labeltxt">{{item.gender}}</h6>
            </div>
          </div>
          <div class="col-md-2">
            <button (click)="getUrl(item.id)">{{'View More' | translate}}</button>
          </div>
        </div>
      </div>
      <div *ngIf="dependantsList && dependantsList.length == 0" id="employee-nolist">
        <div class="image-container col-sm-12">
          <img src="assets/images/employee_nolist.png">
        </div>
        <div class="text-container text-center">
          <h3>No Depandants Added yet.</h3>
        </div>
      </div>
      <!-- <div class="cards">
                    <div class="row">
                        <div class="col-md-2">
                            <img src="assets/images/employee1.png" class="img-fluid">
                        </div>
                        <div class="col-md-4">
                            <div class="child-container d-flex align-items-center">
                                <label class="labeltxt">{{'First Name' | translate}} :</label>
                                <h6 class="labeltxt">Stella Ibouanga</h6>
                            </div>
                            <div class="child-container d-flex align-items-center">
                                <label class="labeltxt">{{'Date of Birth' | translate}} :</label>
                                <h6 class="labeltxt">25/05/2000</h6>
                            </div>
                            <div class="child-container d-flex align-items-center">
                                <label class="labeltxt">{{'Occupation' | translate}} :</label>
                                <h6 class="labeltxt">{{'Self Employed' | translate}}</h6>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="child-container d-flex align-items-center">
                                <label class="labeltxt">{{'Mobile Number' | translate}} :</label>
                                <h6 class="labeltxt">+241 85895325</h6>
                            </div>
                            <div class="child-container d-flex align-items-center">
                                <label class="labeltxt">{{'Nature de la dépendance' | translate}} :</label>
                                <h6 class="labeltxt">{{'Mother' | translate}}</h6>
                            </div>
                            <div class="child-container d-flex align-items-center">
                                <label class="labeltxt">{{'Gender' | translate}} :</label>
                                <h6 class="labeltxt">{{'Female' | translate }}</h6>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <button (click)="getUrl()">{{'View More' | translate}}</button>
                        </div>
                    </div>
                </div>
                <div class="cards">
                    <div class="row">
                        <div class="col-md-2">
                            <img src="assets/images/employee2.png" class="img-fluid">
                        </div>
                        <div class="col-md-4">
                            <div class="child-container d-flex align-items-center">
                                <label class="labeltxt">{{'First Name' | translate}} :</label>
                                <h6 class="labeltxt">Stella Ibouanga</h6>
                            </div>
                            <div class="child-container d-flex align-items-center">
                                <label class="labeltxt">{{'Date of Birth' | translate}} :</label>
                                <h6 class="labeltxt">25/05/2000</h6>
                            </div>
                            <div class="child-container d-flex align-items-center">
                                <label class="labeltxt">{{'Occupation' | translate}} :</label>
                                <h6 class="labeltxt">{{'Self Employed' | translate}}</h6>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="child-container d-flex align-items-center">
                                <label class="labeltxt">{{'Mobile Number' | translate}} :</label>
                                <h6 class="labeltxt">+241 85895325</h6>
                            </div>
                            <div class="child-container d-flex align-items-center">
                                <label class="labeltxt">{{'Nature de la dépendance' | translate}} :</label>
                                <h6 class="labeltxt">{{'Mother' | translate}}</h6>
                            </div>
                            <div class="child-container d-flex align-items-center">
                                <label class="labeltxt">{{'Gender' | translate}} :</label>
                                <h6 class="labeltxt">{{'Female' | translate }}</h6>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <button (click)="getUrl()">{{'View More' | translate}}</button>
                        </div>
                    </div>
                </div> -->
    </div>
  </div>
</section>
<app-footer></app-footer>
<app-contact-floating></app-contact-floating>
