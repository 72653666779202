<app-header></app-header>
<!-- with anpi section -->
<section id="anpi-section">
  <div class="container-fluid">
    <div class="text-container card-large">
      <h5 class="form-title text-center">{{'Link your ANPI Account with CCPS portal' | translate}}</h5>
      <p class="para-light text-center">{{'Click' | translate }}<a href="https://www.gni-anpigabon.com" target="_blank"><img src="/assets/images/gni-trans.png"></a><span>{{'link anpi paragraph' | translate}}</span></p>
      <form id="anpi-form" [formGroup]="anpiAccountLinkForm" (ngSubmit)="onSubmitAnpiCode()">
        <div class="input-container input-box-white">
          <label class="labeltxt text-center">{{'ANPI Secret Code' | translate}}</label>
          <input class="" type="text" formControlName="anpi_code" name="anpicode">
          <div *ngIf="isSubmitted && f.anpi_code.errors" class="invalid-feedback text-center">
            <div *ngIf="f.anpi_code.errors?.required">{{'ANPI Secret Code'|translate}} {{'is required'|translate}}</div>
            <div *ngIf="f.anpi_code.errors?.pattern">{{'ANPI Secret Code'|translate}} {{'should be valid' | translate}}</div>
          </div>
        </div>
        <button type="submit" id="submit_btn" class="btn btn-medium">{{'Get Started' | translate}}</button>
      </form>
    </div>
  </div>
</section>
<!-- without anpi section -->
<!--  <section id="noanpi-section" class="creation-form">
      <div class="container-fluid">
        <div class="text-container">
            <h5 class="form-title">{{'Enter Company Details' | translate}}</h5>
            <form id="noanpi-form" [formGroup]="languageForm">
                <div class="input-container form-input">
                    <div class="child-container">
                        <label class="labeltxt">{{'Company Name' | translate}}</label>
                        <input class="" type="text" name="companyName">
                    </div>
                   <div class="child-container">
                        <label class="labeltxt">{{'Company Type' | translate}}</label>
                         <select class="bootstrap-select">
                          <option value="1" selected>SAU</option>
                          <option value="2">SARL</option>
                          <option value="3">SARLU</option>
                          <option value="5">SAS</option>SA
                          <option value="6">SASU</option>SA
                          <option value="7">GIE</option>SA
                          <option value="8">SCI</option>SA
                          <option value="9">Scoops</option>SA
                          <option value="10">Coop-CA</option>SA
                          <option value="11">SNC</option>SA
                          <option value="12">SCS</option>SA 
                        </select>
                    </div>
                    <div class="child-container">
                        <label class="labeltxt">{{'Main Office Line' | translate}}</label>
                        <input class="" type="text" name="companyName">
                    </div>
                    <div class="child-container">
                        <label class="labeltxt">{{'Email Address' | translate}}</label>
                        <input class="" type="text" name="companyName">
                    </div> 
                     <div class="child-container">
                        <label class="labeltxt">{{'ANPI No' | translate}}:</label>
                        <input class="" type="text" name="companyName">
                    </div>
                     <div class="child-container">
                        <label class="labeltxt">{{'N NIF' | translate}}</label>
                        <input class="" type="text" name="companyName">
                    </div>
                     <div class="child-container">
                        <label class="labeltxt">{{'N RCCM' | translate}}</label>
                        <input class="" type="text" name="companyName">
                    </div>
                     <div class="child-container">
                        <label class="labeltxt">{{'N FOLIO' | translate}}</label>
                        <input class="" type="text" name="companyName">
                    </div>
                     <div class="child-container">
                        <label class="labeltxt">{{'N CNSS' | translate}}</label>
                        <input class="" type="text" name="companyName">
                     </div>
                    <div class="child-container">
                        <label class="labeltxt">{{'N CNAMGS' | translate}}</label>
                        <input class="" type="text" name="companyName">
                    </div>
                    <div class="child-container">
                        <label for="company-logo" class="labeltxt">{{'Upload Company Logo' | translate}}</label>
                        <label for="company-logo" class="file-upload-card single-card labeltxt"><div class="custom-container"><img src="assets/images/upload_icon.png">
                                    <p class="text-center">{{'Upload image or drop a file' | translate}}</p></div></label>
                        <input class="" id="company-logo" type="file" name="companyName" onchange="displayFileName('company-logo', 'companyLogoName')">
                        <a class="text-center viewFile" href="" id="companyLogoName"></a>
                    </div>
                </div>
                 <button type="button" id="submit_btn" class="btn btn-main2" (click)="showCompanyDetails()">{{'Done' | translate}}</button>
            </form>
        </div>
      </div>
</section> -->
<!-- company details section -->
<section id="company-details-section">
  <div class="container-fluid">
    <div class="initiale_sestion">
      <div class="head">
        <div class="title-container">
          <i (click)="goBack()" class="fa-solid fa-angle-left"></i>
        </div>
      </div>
    </div>
    <div class="text-container card-large">
      <h5 class="form-title text-center">{{'Description' | translate}}</h5>
      <p class="para-light text-center ">{{'informations para 1' | translate}} <br>
        {{'informations para 2' | translate}}<b>{{'click next' | translate}}</b></p>
      <div class="image-container">
        <div class="logo">
          <img src="assets/images/company_logo_default.png">
          <!-- <i class="fa-regular fa-building"></i> -->
        </div>
        <h3 class="text-capitalize" *ngIf="companyDetails">{{companyDetails.company_name}}</h3>
      </div>
      <form [formGroup]="companyDetailsForm" (ngSubmit)="onSubmitCompanyDetails()">
        <div class="parent-container">
          <div class="enroll_form">
            <div class="input_section">
              <div class="container mt-5">
                <h6 class="">{{'Business or Administration Details' | translate}}</h6>
              </div>
              <div class="line"></div>
              <div class="container">
                <div class="row">
                  <div class="col-md-6 mt-3">
                    <label for="">{{'ANPI Number'|translate}}</label>
                    <input type="text" value="ANPI2531490413246" formControlName="anpi_no">
                    <div *ngIf="isSubmitted && g.anpi_no.errors" class="invalid-feedback">
                      <div *ngIf="g.anpi_no.errors?.required">{{'ANPI Number'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.anpi_no.errors?.pattern">{{'ANPI Number'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Nº NIF (DGI)'|translate}}</label>
                    <input type="text" value="785966 G" formControlName="nif">
                    <div *ngIf="isSubmitted && g.nif.errors" class="invalid-feedback">
                      <div *ngIf="g.nif.errors?.required">{{'Nº NIF (DGI)'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.nif.errors?.pattern">{{'Nº NIF (DGI)'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Nº RCCM'|translate}}</label>
                    <input type="text" value="RG LBV 18 2022" formControlName="rccm">
                    <div *ngIf="isSubmitted && g.rccm.errors" class="invalid-feedback">
                      <div *ngIf="g.rccm.errors?.required">{{'Nº RCCM'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.rccm.errors?.pattern">{{'Nº RCCM'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Nº VOL'|translate}}</label>
                    <input type="text" value="89" formControlName="vol">
                    <div *ngIf="isSubmitted && g.vol.errors" class="invalid-feedback">
                      <div *ngIf="g.vol.errors?.required">{{'Nº VOL'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.vol.errors?.pattern">{{'Nº VOL'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Nº FOLIO'|translate}}</label>
                    <input type="text" value="85" formControlName="folio">
                    <div *ngIf="isSubmitted && g.folio.errors" class="invalid-feedback">
                      <div *ngIf="g.folio.errors?.required">{{'Nº FOLIO'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.folio.errors?.pattern">{{'Nº FOLIO'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Nº CNSS'|translate}}</label>
                    <input type="text" value="859-969-850-850" formControlName="cnss">
                    <div *ngIf="isSubmitted && g.cnss.errors" class="invalid-feedback">
                      <div *ngIf="g.cnss.errors?.required">{{'Nº CNSS'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.cnss.errors?.pattern">{{'Nº CNSS'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Nº CNAMGS'|translate}}</label>
                    <input type="text" value="859-969-850-850" formControlName="cnamgs">
                    <div *ngIf="isSubmitted && g.cnamgs.errors" class="invalid-feedback">
                      <div *ngIf="g.cnamgs.errors?.required">{{'Nº CNAMGS'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.cnamgs.errors?.pattern">{{'Nº CNAMGS'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Company Type'|translate}}</label>
                    <input class="text-capitalize" type="text" value="SARLU" formControlName="legalstatus">
                    <div *ngIf="isSubmitted && g.legalstatus.errors" class="invalid-feedback">
                      <div *ngIf="g.legalstatus.errors?.required">{{'Company Type'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.legalstatus.errors?.pattern">{{'Company Type'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <!-- <div class="col-md-6 mt-3">
                    <label for="">{{'Type d’entreprise'|translate}}</label>
                    <input class="text-capitalize" type="text" value="Entreprise Individuelle" formControlName="company_type">
                    <div *ngIf="isSubmitted && g.company_type.errors" class="invalid-feedback">
                      <div *ngIf="g.company_type.errors?.required">{{'Type d’entreprise'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.company_type.errors?.pattern">{{'Type d’entreprise'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div> -->
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Dénomination sociale'|translate}}</label>
                    <input type="text" value="AURIAN BTP" formControlName="company_name">
                    <div *ngIf="isSubmitted && g.company_name.errors" class="invalid-feedback">
                      <div *ngIf="g.company_name.errors?.required">{{'Dénomination sociale'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.company_name.errors?.pattern">{{'Dénomination sociale'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Mobile'|translate}}</label>
                    <input type="text" value="241" formControlName="telephone" maxlength="8" minlength="8">
                    <div *ngIf="isSubmitted && g.telephone.errors" class="invalid-feedback">
                      <div *ngIf="g.telephone.errors?.required">{{'Mobile'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.telephone.errors?.pattern">{{'Mobile'|translate}} {{'should be valid'|translate}}</div>
                      <div *ngIf="g.telephone.hasError('minlength')">{{ 'Mobile' | translate }} {{ 'must Contain 8 Numbers' | translate }}</div>
                      <div *ngIf="g.telephone.hasError('maxlength')">{{ 'Mobile' | translate }} {{ 'must Contain 8 Numbers' | translate }}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Nom Commercial'|translate}}</label>
                    <input class="text-capitalize" type="text" value="AURIAN BTP" formControlName="name">
                    <div *ngIf="isSubmitted && g.name.errors" class="invalid-feedback">
                      <div *ngIf="g.name.errors?.required">{{'Nom Commercial'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.name.errors?.pattern">{{'Nom Commercial'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Acronym'|translate}}</label>
                    <input type="text" formControlName="acrononym">
                    <div *ngIf="isSubmitted && g.acrononym.errors" class="invalid-feedback">
                      <div *ngIf="g.acrononym.errors?.required">{{'Acronym'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.acrononym.errors?.pattern">{{'Acronym'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container mt-5">
                <h6 class="">{{'Business Location' | translate}}</h6>
              </div>
              <div class="line"></div>
              <div class="container">
                <div class="row">
                  <!-- <div class="col-md-6 mt-3">
                    <label class="labeltxt">{{'District' | translate}}</label>
                    <select class="bootstrap-select" formControlName="district">
                      <option value="1" selected>Premier</option>
                      <option value="1">Deuxième </option>
                    </select>
                    <div *ngIf="isSubmitted && g.district.errors" class="invalid-feedback">
                      <div *ngIf="g.district.errors?.required">{{'District'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.district.errors?.pattern">{{'District'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div> -->
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Region'|translate}}</label>
                    <select class="bootstrap-select" formControlName="region" (change)="changeRegion($event.target.value, 1)">
                      <option selected disabled="true" [ngValue]="null">{{'Please Choose'|translate}}</option>
                      <option *ngFor="let region of regionList" value="{{region.id}}">{{region.name}}</option>
                    </select>
                    <div *ngIf="isSubmitted && g.region.errors" class="invalid-feedback">
                      <div *ngIf="g.region.errors?.required">{{'Region'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.region.errors?.pattern">{{'Region'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'City'|translate}}</label>
                    <select class="bootstrap-select" formControlName="city" (change)="changeCity($event.target.value)">
                      <option selected disabled="true" [ngValue]="null">{{'Please Choose'|translate}}</option>
                      <option *ngFor="let city of cityList" value="{{city.id}}">{{city.name}}</option>
                    </select>
                    <div *ngIf="isSubmitted && g.city.errors" class="invalid-feedback">
                      <div *ngIf="g.city.errors?.required">{{'City'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.city.errors?.pattern">{{'City'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'District'|translate}}</label>
                    <select class="bootstrap-select" formControlName="district">
                      <option selected disabled="true" [ngValue]="null">{{'Please Choose'|translate}}</option>
                      <option *ngFor="let district of districtList" value="{{district.id}}">{{district.name}}</option>
                    </select>
                    <div *ngIf="isSubmitted && g.district.errors" class="invalid-feedback">
                      <div *ngIf="g.district.errors?.required">{{'District'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.district.errors?.pattern">{{'District'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Neighbourhood'|translate}}</label>
                    <select class="bootstrap-select" formControlName="neighborhood">
                      <option selected disabled="true" [ngValue]="null">{{'Please Choose'|translate}}</option>
                      <option *ngFor="let neighborhood of neighborhoodlist" value="{{neighborhood.id}}">{{neighborhood.name}}</option>
                    </select>
                    <div *ngIf="isSubmitted && g.neighborhood.errors" class="invalid-feedback">
                      <div *ngIf="g.neighborhood.errors?.required">{{'Neighbourhood'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.neighborhood.errors?.pattern">{{'Neighbourhood'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Street'|translate}}</label>
                    <input type="text" formControlName="street">
                    <div *ngIf="isSubmitted && g.street.errors" class="invalid-feedback">
                      <div *ngIf="g.street.errors?.required">{{'Street'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.street.errors?.pattern">{{'Street'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Place called'|translate}}</label>
                    <input type="text" formControlName="place">
                    <div *ngIf="isSubmitted && g.place.errors" class="invalid-feedback">
                      <div *ngIf="g.place.errors?.required">{{'Place called'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.place.errors?.pattern">{{'Place called'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Post box'|translate}}</label>
                    <input type="text" value="BP2345455" formControlName="pincode">
                    <div *ngIf="isSubmitted && g.pincode.errors" class="invalid-feedback">
                      <div *ngIf="g.pincode.errors?.required">{{'Post box'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.pincode.errors?.pattern">{{'Post box'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Locality (Village)'|translate}}</label>
                    <input type="text" formControlName="locality">
                    <div *ngIf="isSubmitted && g.locality.errors" class="invalid-feedback">
                      <div *ngIf="g.locality.errors?.required">{{'Locality'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.locality.errors?.pattern">{{'Locality'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container mt-5">
                <h6 class="">{{'Contact Details' | translate}}</h6>
              </div>
              <div class="line"></div>
              <div class="container">
                <div class="row">
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Téléphone'|translate}}</label>
                    <input type="text" value="241 888777666" formControlName="mobile" maxlength="8" minlength="8">
                    <div *ngIf="isSubmitted && g.mobile.errors" class="invalid-feedback">
                      <div *ngIf="g.mobile.errors?.required">{{'Téléphone'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.mobile.errors?.pattern">{{'Téléphone'|translate}} {{'should be valid'|translate}}</div>
                      <div *ngIf="g.mobile.hasError('minlength')">{{ 'Mobile' | translate }} {{ 'must Contain 8 Numbers' | translate }}</div>
                      <div *ngIf="g.mobile.hasError('maxlength')">{{ 'Mobile' | translate }} {{ 'must Contain 8 Numbers' | translate }}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Email Address'|translate}}</label>
                    <input type="text" value="agentdemo@gmail.com" formControlName="email">
                    <div *ngIf="isSubmitted && g.email.errors" class="invalid-feedback">
                      <div *ngIf="g.email.errors?.required">{{'Email Address'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.email.errors?.pattern">{{'Email Address'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Website'|translate}}</label>
                    <input type="text" formControlName="website" placeholder="www.example.com">
                    <div *ngIf="isSubmitted && g.website.errors" class="invalid-feedback">
                      <div *ngIf="g.website.errors?.required">{{'Website'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.website.errors?.pattern">{{'Website'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Fax'|translate}}</label>
                    <input type="text" value="213523623" formControlName="fax">
                    <div *ngIf="isSubmitted && g.fax.errors" class="invalid-feedback">
                      <div *ngIf="g.fax.errors?.required">{{'Fax'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.fax.errors?.pattern">{{'Fax'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container mt-5">
                <h6 class="">{{'Details of Business Owner' | translate}}</h6>
              </div>
              <div class="line"></div>
              <div class="container">
                <div class="row">
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Last name2'|translate}}</label>
                    <input type="text" value="afssdgd" formControlName="lastname">
                    <div *ngIf="isSubmitted && g.lastname.errors" class="invalid-feedback">
                      <div *ngIf="g.lastname.errors?.required">{{'Last name2'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.lastname.errors?.pattern">{{'Last name2'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'First name2'|translate}}</label>
                    <input type="text" value="afssdgd" formControlName="firstname">
                    <div *ngIf="isSubmitted && g.firstname.errors" class="invalid-feedback">
                      <div *ngIf="g.firstname.errors?.required">{{'First name2'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.firstname.errors?.pattern">{{'First name2'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Birthdate'| translate}}</label>
                    <input type="text" formControlName="birthdate">
                    <div *ngIf="isSubmitted && g.birthdate.errors" class="invalid-feedback">
                      <div *ngIf="g.birthdate.errors?.required">{{'Birthdate'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.birthdate.errors?.pattern">{{'Birthdate'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Birthplace'| translate}}</label>
                    <input type="text" formControlName="birthplace">
                    <div *ngIf="isSubmitted && g.birthplace.errors" class="invalid-feedback">
                      <div *ngIf="g.birthplace.errors?.required">{{'Birthplace'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.birthplace.errors?.pattern">{{'Birthplace'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <!--     <div class="col-md-6 mt-3">
                    <label for="">{{'Country'|translate}}</label>
                    <input type="text" formControlName="country">
                    <div *ngIf="isSubmitted && g.country.errors" class="invalid-feedback">
                      <div *ngIf="g.country.errors?.required">{{'Country'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.country.errors?.pattern">{{'Country'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
 -->
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Country'|translate}}</label>
                    <select class="bootstrap-select" formControlName="country">
                      <option *ngFor="let country of countryList" value="{{country.id}}">{{country.name}}</option>
                    </select>
                    <div *ngIf="isSubmitted && g.country.errors" class="invalid-feedback">
                      <div *ngIf="g.country.errors?.required">{{'Country'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.country.errors?.pattern">{{'Country'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Nationality'|translate}}</label>
                    <select class="bootstrap-select" formControlName="nationality">
                      <option *ngFor="let nationality of countryList" value="{{nationality.id}}">{{nationality.nationality}}</option>
                    </select>
                    <div *ngIf="isSubmitted && g.nationality.errors" class="invalid-feedback">
                      <div *ngIf="g.nationality.errors?.required">{{'Nationality'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.nationality.errors?.pattern">{{'Nationality'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Region'|translate}}</label>
                    <select class="bootstrap-select" formControlName="owner_region" (change)="changeRegion($event.target.value, 2)">
                      <option *ngFor="let region of regionList" value="{{region.id}}">{{region.name}}</option>
                    </select>
                    <div *ngIf="isSubmitted && g.owner_region.errors" class="invalid-feedback">
                      <div *ngIf="g.owner_region.errors?.required">{{'Region'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.owner_region.errors?.pattern">{{'Region'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'City'|translate}}</label>
                    <select class="bootstrap-select" formControlName="owner_city">
                      <option selected disabled="true" [ngValue]="null">{{'Please Choose'|translate}}</option>
                      <option *ngFor="let city of ownerCityList" value="{{city.id}}">{{city.name}}</option>
                    </select>
                    <div *ngIf="isSubmitted && g.owner_city.errors" class="invalid-feedback">
                      <div *ngIf="g.owner_city.errors?.required">{{'City'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.owner_city.errors?.pattern">{{'City'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Neighbourhood'|translate}}</label>
                    <select class="bootstrap-select" formControlName="neighbor">
                      <option selected disabled="true" [ngValue]="null">{{'Please Choose'|translate}}</option>
                      <option *ngFor="let neighborhood of neighborhoodlist" value="{{neighborhood.id}}">{{neighborhood.name}}</option>
                    </select>
                    <div *ngIf="isSubmitted && g.neighbor.errors" class="invalid-feedback">
                      <div *ngIf="g.neighbor.errors?.required">{{'Neighbourhood'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.neighbor.errors?.pattern">{{'Neighbourhood'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'ID proof'|translate}}</label>
                    <!-- <input type="text" formControlName="idproof"> -->
                    <select class="bootstrap-select" formControlName="idproof">
                      <option *ngFor="let idProof of idproofList" value="{{idProof.id}}">{{idProof.name}}</option>
                    </select>
                    <div *ngIf="isSubmitted && g.idproof.errors" class="invalid-feedback">
                      <div *ngIf="g.idproof.errors?.required">{{'ID proof'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.idproof.errors?.pattern">{{'ID proof'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'ID proof number'|translate}}</label>
                    <input type="text" formControlName="idnumber">
                    <div *ngIf="isSubmitted && g.idnumber.errors" class="invalid-feedback">
                      <div *ngIf="g.idnumber.errors?.required">{{'ID proof number'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.idnumber.errors?.pattern">{{'ID proof number'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Phone number'|translate}}</label>
                    <input type="text" formControlName="phoneno" maxlength="8" minlength="8">
                    <div *ngIf="isSubmitted && g.phoneno.errors" class="invalid-feedback">
                      <div *ngIf="g.phoneno.errors?.required">{{'Phone number'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.phoneno.errors?.pattern">{{'Phone number'|translate}} {{'should be valid'|translate}}</div>
                      <div *ngIf="g.phoneno.hasError('minlength')">{{ 'Mobile' | translate }} {{ 'must Contain 8 Numbers' | translate }}</div>
                      <div *ngIf="g.phoneno.hasError('maxlength')">{{ 'Mobile' | translate }} {{ 'must Contain 8 Numbers' | translate }}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Post box'|translate}}</label>
                    <input type="text" formControlName="postbox">
                    <div *ngIf="isSubmitted && g.postbox.errors" class="invalid-feedback">
                      <div *ngIf="g.postbox.errors?.required">{{'Post box'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.postbox.errors?.pattern">{{'Post box'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'CNSS ID2'|translate}}</label>
                    <input type="text" formControlName="cnss_id">
                    <div *ngIf="isSubmitted && g.cnss_id.errors" class="invalid-feedback">
                      <div *ngIf="g.cnss_id.errors?.required">{{'CNSS ID2'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.cnss_id.errors?.pattern">{{'CNSS ID2'|translate}} {{'should be valid'|translate}}</div>
                    </div>
                  </div>
                  <div class="col-md-6 mt-3">
                    <label for="">{{'Personal email'|translate}}</label>
                    <input type="text" formControlName="owner_email">
                    <div *ngIf="isSubmitted && g.owner_email.errors" class="invalid-feedback">
                      <div *ngIf="g.owner_email.errors?.required">{{'Personal email'|translate}} {{'is required'|translate}}</div>
                      <div *ngIf="g.owner_email.errors?.pattern">{{'Personal email'|translate}} {{'should be valid'|translate}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="bankdata" id="bank-money-Section">
                <div class="container mt-5">
                  <h6 class="">{{'Bank Details' | translate}}</h6>
                </div>
                <div class="line"></div>
                <div class="container" id="bank_section">
                  <div class="row">
                    <div class="col-md-12 mt-3" formArrayName="selected_bank_id">
                      <label class="bankaccount"> {{'Select Bank'| translate}}</label>
                      <div class="bank-card" *ngFor="let bank of bankdata; let i = index">
                        <div class="row">
                          <div class="col-md-1">
                            <div class="tableFooter">
                              <div class="form-check">
                                <input class="form-check-input" type="checkbox" [value]="bank.bank_id" id="flexCheckDefault" (change)="onChange(bank.bank_id, $event.target.checked)" checked>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <label for="bank{{ bank.bank_id }}">
                              <img src="{{ bank.bank_logo }}">
                            </label>
                          </div>
                          <div class="col-md-8">
                            <div class="card-child">
                              <label for="">{{'Account number'|translate}} : </label>
                              <h5> {{bank.ac_number}}</h5>
                            </div>
                            <div class="card-child">
                              <label for="">{{'Account name'|translate}} : </label>
                              <h5> {{bank.ac_name}}</h5>
                            </div>
                            <div class="card-child">
                              <label for="">{{'Bank name'|translate}} : </label>
                              <h5>{{bank.bank_name}}</h5>
                            </div>
                            <div class="card-child">
                              <label for="">{{'Bank number'|translate}} : </label>
                              <h5> {{bank.bank_number}}</h5>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="isSubmitted && g.selected_bank_id.errors" class="invalid-feedback">
                        <div *ngIf="g.selected_bank_id.errors?.required">{{'Bank Details'|translate}} {{'is required'|translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 mt-3">
                  <div class="tableFooter">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" [checked]="g.confirm_account.value" id="confirm_account" formControlName="confirm_account">
                      <label class="form-check-label" for="confirm_account">
                        {{'By clicking on' | translate}} <b>{{'Following' | translate}} </b>{{"declaration, you certify that the information declared reflects the reality of the company's social situation." | translate}}
                      </label>
                    </div>
                    <div *ngIf="isSubmitted && g.confirm_account.errors" class="invalid-feedback checkvalid">
                      <div *ngIf="g.confirm_account.errors?.required">{{'Please check the declaration to Continue'|translate}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="submit" id="submit_btn" class="btn btn-medium">{{'Proceed' | translate}}</button>
      </form>
    </div>
  </div>
</section>
<section class="footer-section">
  <div class="footer-top">
    <div class="footer-main">
      <div class="row">
        <div class="col-md-4 col-lg-4 col-xl-6 col-sm-12 offset-xl-1">
          <div class="txt-container">
            <a class="logo-block" href="index.html">CCPS</a>
            <p>{{'footer text' | translate}}</p>
          </div>
        </div>
        <div class="col-md-4 col-lg-4 col-xl-2 col-sm-12 offset-xl-1">
          <div class="parent-container">
          </div>
        </div>
        <div class="col-md-4 col-lg-4 col-xl-3 col-sm-12 offset-xl-1">
          <div class="txt-container">
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="footer-main">
      <div class="parent-container">
        <div class="txt-container">
          <h6>{{'All rights reserved. 2024.' | translate}}</h6>
        </div>
        <a href="https://clikafrikgroup.com/" target="_blank">
          <div class="txt-container">
            <h6>{{'Powered by' | translate}}</h6><img src="assets/images/clikafrik-logo.png" alt="">
          </div>
        </a>
      </div>
    </div>
  </div>
</section>
<app-contact-floating></app-contact-floating>
