<app-header></app-header>
<div class="enroll_form approval-section mx-4">
  <div *ngIf="accountDetails.cnss == '3' " class="reason_for_reject container">
    <div class="rejectedNote">
      <h5>{{'Reason for rejection'| translate}}</h5>
      <p *ngIf="companyDetails" class="text-center">{{companyDetails.reason}}</p>
    </div>
  </div>
  <div class="card_bg">
    <div class="head-title position-relative">
      <div class="">
        <a (click)="goback()"><i class="fa fa-angle-left"></i></a>
      </div>
      <div class="sec1">
        <h4>{{'EMPLOYER AFFILIATION REQUEST'|translate}}</h4>
        <h6>(RÉGIME GÉNÉRAL/ADMINISTRATIONS/COLLECTIVITÉS LOCALES)</h6>
      </div>
      <div class="sec2">
        <img src="/assets/images/logoWithText.png" class="img-fluid">
        <div>CAISSE NATIONALE <br>DE SECURITE SOCIALE</div>
      </div>
    </div>
    <div class="line"></div>
    <form [formGroup]="cnssForm" (ngSubmit)="onSubmitCnssForm()">
      <div class="input_section">
        <h6 class="text-center mt-3">Informations concernant l'identité de l'Employeur Personne Morale <br>
          (Régime Général/Administrations/Collectivités locales)</h6>
        <div class="container mt-62">
          <div class="row mt-3">
            <div class="col-md-12">
              <label for="">{{'Company Name / Administrative Name'|translate}}:</label>
              <input type="text" placeholder="" value="" formControlName="company_name">
              <div *ngIf="isSubmitted && f.company_name.errors" class="invalid-feedback">
                <div *ngIf="f.company_name.errors?.required">{{'Raison Sociale / Dénomination Administrative'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.company_name.errors?.pattern">{{'Raison Sociale / Dénomination Administrative'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mt-3">
              <label for="">{{'Nom Commercial'|translate}}</label>
              <input type="text" value="" formControlName="trade_name">
              <div *ngIf="isSubmitted && f.trade_name.errors" class="invalid-feedback">
                <div *ngIf="f.trade_name.errors?.required">{{'Nom Commercial'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.trade_name.errors?.pattern">{{'Nom Commercial'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Acronym'|translate}}</label>
              <input type="text" value="" formControlName="sigle">
              <div *ngIf="isSubmitted && f.sigle.errors" class="invalid-feedback">
                <div *ngIf="f.sigle.errors?.required">{{'Acronym'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.sigle.errors?.pattern">{{'Acronym'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="container mt-5">
          <h6 class="">{{"Informations liées à la localisation de l'employeur"|translate}}</h6>
        </div>
        <div class="line"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-6 mt-3">
              <label for="">{{'District'|translate}}</label>
              <select class="bootstrap-select" formControlName="district">
                <option selected disabled="true" [ngValue]="null">{{'Please Choose'|translate}}</option>
                <option *ngFor="let district of districtList" value="{{district.id}}">{{district.name}}</option>
              </select>
              <div *ngIf="isSubmitted && f.district.errors" class="invalid-feedback">
                <div *ngIf="f.district.errors?.required">{{'District'|translate}} {{'is required'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Neighbourhood'|translate}}</label>
              <select class="bootstrap-select" formControlName="neighborhood">
                <option selected disabled="true" [ngValue]="null">{{'Please Choose'|translate}}</option>
                <option *ngFor="let neighborhood of neighborhoodlist" value="{{neighborhood.id}}">{{neighborhood.name}}</option>
              </select>
              <div *ngIf="isSubmitted && f.neighborhood.errors" class="invalid-feedback">
                <div *ngIf="f.neighborhood.errors?.required">{{'Neighborhood'|translate}} {{'is required'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Street'|translate}}</label>
              <input type="text" value="" formControlName="rue">
              <div *ngIf="isSubmitted && f.rue.errors" class="invalid-feedback">
                <div *ngIf="f.rue.errors?.required">{{'Street'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.rue.errors?.pattern">{{'Street'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'N° Porte'|translate}}</label>
              <input type="text" value="" formControlName="door_address">
              <div *ngIf="isSubmitted && f.door_address.errors" class="invalid-feedback">
                <div *ngIf="f.door_address.errors?.required">{{'N° Porte'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.door_address.errors?.pattern">{{'N° Porte'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'place called'|translate}}</label>
              <input type="text" value="" formControlName="place">
              <div *ngIf="isSubmitted && f.place.errors" class="invalid-feedback">
                <div *ngIf="f.place.errors?.required">{{'place called'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.place.errors?.pattern">{{'place called'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Localité'|translate}}</label>
              <input type="text" value="" formControlName="locality">
              <div *ngIf="isSubmitted && f.locality.errors" class="invalid-feedback">
                <div *ngIf="f.locality.errors?.required">{{'Localité'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.locality.errors?.pattern">{{'Localité'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Region'|translate}}</label>
              <select class="bootstrap-select" formControlName="region">
                <option selected disabled="true" [ngValue]="null">{{'Please Choose'|translate}}</option>
                <option *ngFor="let region of regionList" value="{{region.id}}">{{region.name}}</option>
              </select>
              <div *ngIf="isSubmitted && f.region.errors" class="invalid-feedback">
                <div *ngIf="f.region.errors?.required">{{'Region'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.region.errors?.pattern">{{'Region'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="container mt-5">
          <h6 class="">{{"Informations liées au contact de l'employeur"|translate}}</h6>
        </div>
        <div class="line"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-6 mt-3">
              <label for="">{{'Contact 1' | translate}}</label>
              <input type="text" value="" formControlName="contact_1">
              <div *ngIf="isSubmitted && f.contact_1.errors" class="invalid-feedback">
                <div *ngIf="f.contact_1.errors?.required">{{'Contact 1'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.contact_1.errors?.pattern">{{'Contact 1'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">BP</label>
              <input type="text" value="" formControlName="bp">
              <div *ngIf="isSubmitted && f.bp.errors" class="invalid-feedback">
                <div *ngIf="f.bp.errors?.required">{{'BP'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.bp.errors?.pattern">{{'BP'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Contact 2' | translate}}</label>
              <input type="text" value="" formControlName="contact_2">
              <div *ngIf="isSubmitted && f.contact_2.errors" class="invalid-feedback">
                <div *ngIf="f.contact_2.errors?.required">{{'Contact 2'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.contact_2.errors?.pattern">{{'Contact 2'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">Fax</label>
              <input type="text" value="" formControlName="fax">
              <div *ngIf="isSubmitted && f.fax.errors" class="invalid-feedback">
                <div *ngIf="f.fax.errors?.required">{{'Fax'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.fax.errors?.pattern">{{'Fax'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">E-mail</label>
              <input type="email" value="" formControlName="email">
              <div *ngIf="isSubmitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors?.required">{{'E-mail'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.email.errors?.email">{{'E-mail'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Website'|translate}}</label>
              <input type="text" value="" formControlName="website">
              <div *ngIf="isSubmitted && f.website.errors" class="invalid-feedback">
                <div *ngIf="f.website.errors?.required">{{'Website'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.website.errors?.pattern">{{'Website'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="container mt-5">
          <h6 class="">{{"Informations administratives de l'employeur"| translate}}</h6>
        </div>
        <div class="line"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-6 mt-3">
              <div class="input-container" [owlDateTimeTrigger]="dt42">
                <label>{{'Created Date' | translate}}</label>
                <div class="date-field">
                  <input class="" [max]="todayDate" [owlDateTime]="dt42" name="created_date" formControlName="created_date">
                  <div class="calendar input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                  </div>
                </div>
                <owl-date-time [pickerType]="'calendar'" #dt42></owl-date-time>
                <div *ngIf="isSubmitted && f.created_date.errors" class="invalid-feedback">
                  <div *ngIf="f.created_date.errors?.required">{{'Created Date'|translate}} {{'is required'|translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <!-- <label for="">{{'Date de début de service'|translate}}</label>
              <input type="text" value="3" formControlName="service_start_date">
              <div *ngIf="isSubmitted && f.service_start_date.errors" class="invalid-feedback">
                <div *ngIf="f.service_start_date.errors?.required">{{'Date de début de service'|translate}} {{'is required'|translate}}</div>
              </div> -->
              <div class="input-container" [owlDateTimeTrigger]="dt46">
                <label class="labeltxt-sm">{{'Select Date of Resignation' | translate}}</label>
                <div class="date-field">
                  <input class="" [min]="startat" [owlDateTime]="dt46" name="service_start_date" formControlName="service_start_date">
                  <div class="calendar input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                  </div>
                </div>
                <owl-date-time [pickerType]="'calendar'" #dt46></owl-date-time>
                <div *ngIf="isSubmitted && f.service_start_date.errors" class="invalid-feedback">
                  <div *ngIf="f.service_start_date.errors?.required">{{'Created Date'|translate}} {{'is required'|translate}}</div>
                </div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Legal status'|translate}}</label>
              <input type="text" value="" formControlName="legal_status">
              <div *ngIf="isSubmitted && f.legal_status.errors" class="invalid-feedback">
                <div *ngIf="f.legal_status.errors?.required">{{'Legal status'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.legal_status.errors?.pattern">{{'Legal status'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Employer Plan'|translate}}</label>
              <input type="text" value="" formControlName="employer_plan">
              <div *ngIf="isSubmitted && f.employer_plan.errors" class="invalid-feedback">
                <div *ngIf="f.employer_plan.errors?.required">{{'Employer Plan'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.employer_plan.errors?.pattern">{{'Employer Plan'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Taxpayer Number'|translate}}</label>
              <input type="text" value="" formControlName="tax_no">
              <div *ngIf="isSubmitted && f.tax_no.errors" class="invalid-feedback">
                <div *ngIf="f.tax_no.errors?.required">{{'Taxpayer Number'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.tax_no.errors?.pattern">{{'Taxpayer Number'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Trade register number (RCCM)'|translate}}</label>
              <input type="text" value="" formControlName="rccm">
              <div *ngIf="isSubmitted && f.rccm.errors" class="invalid-feedback">
                <div *ngIf="f.rccm.errors?.required">{{'Trade register number (RCCM)'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.rccm.errors?.pattern">{{'Trade register number (RCCM)'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">NIF</label>
              <input type="text" value="" formControlName="nif">
              <div *ngIf="isSubmitted && f.nif.errors" class="invalid-feedback">
                <div *ngIf="f.nif.errors?.required">{{'NIF'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.nif.errors?.pattern">{{'NIF'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'SEEG subscription number'|translate}}</label>
              <input type="text" value="" formControlName="seeg_subscription_no">
              <div *ngIf="isSubmitted && f.seeg_subscription_no.errors" class="invalid-feedback">
                <div *ngIf="f.seeg_subscription_no.errors?.required">{{'SEEG subscription number'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.seeg_subscription_no.errors?.pattern">{{'SEEG subscription number'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="container mt-5">
          <h6 class="">{{'Bank Details' | translate}}</h6>
        </div>
        <div class="line"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-12 mt-3" formArrayName="selected_bank_id">
              <div class="bank-card" *ngFor="let bank of bankdata; let i = index">
                <div class="row">
                  <div class="col-md-1">
                    <div class="tableFooter">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" [value]="bank.bank_id" id="flexCheckDefault" (change)="onChange(bank.bank_id, $event.target.checked)" checked>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <label for="bank{{ bank.bank_id }}">
                      <img src="{{ bank.bank_logo }}">
                    </label>
                  </div>
                  <div class="col-md-8">
                    <div class="card-child">
                      <label for="">{{'Account number'|translate}} : </label>
                      <h5> {{bank.ac_number}}</h5>
                    </div>
                    <div class="card-child">
                      <label for="">{{'Account name'|translate}} : </label>
                      <h5> {{bank.ac_name}}</h5>
                    </div>
                    <div class="card-child">
                      <label for="">{{'Bank name'|translate}} : </label>
                      <h5>{{bank.bank_name}}</h5>
                    </div>
                    <div class="card-child">
                      <label for="">{{'Bank number'|translate}} : </label>
                      <h5> {{bank.bank_number}}</h5>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="isSubmitted && f.selected_bank_id.errors" class="invalid-feedback">
                <div *ngIf="f.selected_bank_id.errors?.required">{{'Bank Details'|translate}} {{'is required'|translate}}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-12 mt-3">
              <label for="">{{'Guardianship (If Administration/Collectivity)'|translate}}</label>
              <input type="text" value="" formControlName="guardianship">
              <div *ngIf="isSubmitted && f.guardianship.errors" class="invalid-feedback">
                <div *ngIf="f.guardianship.errors?.required">{{'Guardianship (If Administration/Collectivity)'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.guardianship.errors?.pattern">{{'Guardianship (If Administration/Collectivity)'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 col-12 mt-3">
              <label for="">{{"Branch of activity"|translate}}</label>
              <input type="text" value="" formControlName="branch">
              <div *ngIf="isSubmitted && f.branch.errors" class="invalid-feedback">
                <div *ngIf="f.branch.errors?.required">{{"Branch of activity"|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.branch.errors?.pattern">{{"Branch of activity"|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
          </div>
          <h5 class="sm-title ml-4 mt-3">Nombre de travailleurs</h5>
          <div class="row">
            <div class="col-md-3 col-12 mt-2">
              <label for="">{{'Men'|translate}}</label>
              <input type="text" value="" formControlName="workers_men">
              <div *ngIf="isSubmitted && f.workers_men.errors" class="invalid-feedback">
                <div *ngIf="f.workers_men.errors?.required">{{'Men'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.workers_men.errors?.pattern">{{'Men'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-3 col-12 mt-2">
              <label for="">{{'Women'|translate}}</label>
              <input type="text" value="" formControlName="workers_women">
              <div *ngIf="isSubmitted && f.workers_women.errors" class="invalid-feedback">
                <div *ngIf="f.workers_women.errors?.required">{{'Women'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.workers_women.errors?.pattern">{{'Women'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
          </div>
          <div class="last_sec mt-4">
            <div class="tableFooter approval-checkbox">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" [checked]="f.confirm_account.value" id="confirm_account" formControlName="confirm_account">
                <label class="form-check-label" for="confirm_account">
                  {{"By clicking on Submit, you certify that the information declared reflects the reality of the company's social situation." | translate}}
                </label>
              </div>
              <div *ngIf="isSubmitted && f.confirm_account.errors" class="invalid-feedback">
                <div *ngIf="f.confirm_account.errors?.required">{{'Please check the declaration to Continue'|translate}}</div>
              </div>
            </div>
            <div class="buttons report_btn mb-5 text-center">
              <button class="btn btn-medium" type="submit">{{'Submit' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!--   <div class="checkboxSection">
         <mat-checkbox class="example-margin" ></mat-checkbox>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam inventore animi recusandae facilis! Rerum, incidunt et voluptate aut quia voluptates asperiores distinctio tempora aliquid unde?</p>
        </div> -->
</div>
<!-- cnss form ends-->
<app-footer></app-footer>
