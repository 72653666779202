import { Component, OnInit, NgZone } from '@angular/core';
declare var $: any;
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { TranslateService } from '../../../_services/translate.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
import { AnpiService } from '../../../_services/anpi-code.service';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
import AOS from 'aos';
import 'aos/dist/aos.css';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  isSubmitted = false;
  public contactForm: FormGroup;
  response: any;
  constructor(private translate: TranslateService,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private router: Router,
    private anpiService: AnpiService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private snackBar: MatSnackBar,
    private ngzone: NgZone,
    private location: Location) {}

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.pattern("^[A-Za-z][A-Za-z\\s]*$")]],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$"), Validators.minLength(8), Validators.maxLength(8)]],
      company_name: ['', [Validators.required, Validators.pattern("^[A-Za-z0-9][A-Za-z0-9\\s]*$")]],
      message: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]]
    });
    AOS.init({
      disable: function() {
        var maxWidth = 600;
        return window.innerWidth < maxWidth;
      }
    });
  }
  onSubmit() {
    this.isSubmitted = true;
    if (this.contactForm.invalid) {
      return;
    }

    var data = this.contactForm.value;
    console.log(data);
    this.ngxUiLoaderService.start();
    this.api.contactDetails(data).pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res.results;
          this.isSubmitted = false;
          console.log("148 companyDetails:", res.results)
          if (res.status == 200) {
            this.contactForm.reset();
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });

          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }

        },
        error => {
          this.ngxUiLoaderService.stop();
          this.isSubmitted = false;
        });
    this.isSubmitted = false;

  }
  get f() {
    return this.contactForm.controls;
  }

}
