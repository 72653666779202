<app-header></app-header>
<div class="enroll_form approval-section">
  <div class="card_bg">
    <div class="head-title position-relative">
      <div class="sec1">
        <a (click)="goback()"><i class="fa fa-angle-left"></i></a>
        <div>
          <h4>{{'EMPLOYER AFFILIATION REQUEST'|translate}}</h4>
          <h6>(RÉGIME GÉNÉRAL/ADMINISTRATIONS/COLLECTIVITÉS LOCALES)</h6>
        </div>
      </div>
      <div class="sec2">
        <img src="/assets/images/cnamgs.png" class="img-fluid">
        <!-- <div>CAISSE NATIONALE <br>DE SECURITE SOCIALE</div> -->
      </div>
    </div>
    <div class="line"></div>
    <form [formGroup]="cnssForm" (ngSubmit)="onSubmitCnssForm()">
      <div class="input_section">
        <h6 class="text-center mt-3">Informations concernant l'identité de l'Employeur Personne Morale <br>
          (Régime Général/Administrations/Collectivités locales)</h6>
        <div class="container mt-62">
          <div class="row mt-3">
            <div class="col-md-12">
              <label for="">{{'Numéro cotisant CNSS'|translate}} :</label>
              <input type="text" placeholder="" value="" formControlName="cnss_contributor_number">
              <div *ngIf="isSubmitted && f.cnss_contributor_number.errors" class="invalid-feedback">
                <div *ngIf="f.cnss_contributor_number.errors?.required">{{'Numéro cotisant CNSS'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.cnss_contributor_number.errors?.pattern">{{'Numéro cotisant CNSS'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="container mt-5">
          <h6 class="">Identification de l'employeur personne morale</h6>
        </div>
        <div class="line"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-6 mt-3">
              <label for="">Nom/Raison Sociale ou NomCommercial</label>
              <input type="text" value="" formControlName="company_name">
              <div *ngIf="isSubmitted && f.company_name.errors" class="invalid-feedback">
                <div *ngIf="f.company_name.errors?.required">{{'Nom/Raison Sociale ou NomCommercial'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.company_name.errors?.pattern">{{'Nom/Raison Sociale ou NomCommercial'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Acronym'|translate}}</label>
              <input type="text" value="" formControlName="acronym">
              <div *ngIf="isSubmitted && f.acronym.errors" class="invalid-feedback">
                <div *ngIf="f.acronym.errors?.required">{{'Acronym'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.acronym.errors?.pattern">{{'Acronym'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Region'|translate}}</label>
              <select class="bootstrap-select" formControlName="region">
                <option value="1" selected="selected">Haut-Ogooué</option>
                <option value="2">Ogooué-Maritime</option>
                <option value="3">Ogooué-Ivindo</option>
                <option value="5">Estuaire</option>
              </select>
              <div *ngIf="isSubmitted && f.region.errors" class="invalid-feedback">
                <div *ngIf="f.region.errors?.required">{{'Region'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.region.errors?.pattern">{{'Region'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Département'|translate}}</label>
              <select class="bootstrap-select" formControlName="neighbourhood">
                <option value="1" selected="selected">Libreville</option>
                <option value="2">Franceville</option>
                <option value="3">Haut-Ogooué</option>
                <option value="5">Moyen-Ogooué</option>
              </select>
              <div *ngIf="isSubmitted && f.neighbourhood.errors" class="invalid-feedback">
                <div *ngIf="f.neighbourhood.errors?.required">{{'Département'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.neighbourhood.errors?.pattern">{{'Département'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Commune ou District'|translate}}</label>
              <input type="text" value="" formControlName="street">
              <div *ngIf="isSubmitted && f.street.errors" class="invalid-feedback">
                <div *ngIf="f.street.errors?.required">{{'Commune ou District'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.street.errors?.pattern">{{'Commune ou District'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Arrondissement ou canton'|translate}}</label>
              <input type="text" value="" formControlName="door_number">
              <div *ngIf="isSubmitted && f.door_number.errors" class="invalid-feedback">
                <div *ngIf="f.door_number.errors?.required">{{'Arrondissement ou canton'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.door_number.errors?.pattern">{{'Arrondissement ou canton'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Quartier ou Village'|translate}}</label>
              <input type="text" value="" formControlName="place_called">
              <div *ngIf="isSubmitted && f.place_called.errors" class="invalid-feedback">
                <div *ngIf="f.place_called.errors?.required">{{'Quartier ou Village'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.place_called.errors?.pattern">{{'Quartier ou Village'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Téléphone'|translate}}</label>
              <input type="text" value="" formControlName="place_called">
              <div *ngIf="isSubmitted && f.place_called.errors" class="invalid-feedback">
                <div *ngIf="f.place_called.errors?.required">{{'Téléphone'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.place_called.errors?.pattern">{{'Téléphone'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Fax'|translate}}</label>
              <input type="text" value="" formControlName="place_called">
              <div *ngIf="isSubmitted && f.place_called.errors" class="invalid-feedback">
                <div *ngIf="f.place_called.errors?.required">{{'Fax'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.place_called.errors?.pattern">{{'Fax'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'BP'|translate}}</label>
              <input type="text" value="" formControlName="bp">
              <div *ngIf="isSubmitted && f.bp.errors" class="invalid-feedback">
                <div *ngIf="f.bp.errors?.required">{{'BP'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.bp.errors?.pattern">{{'BP'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'E-mail'|translate}}</label>
              <input type="text" value="" formControlName="place_called">
              <div *ngIf="isSubmitted && f.place_called.errors" class="invalid-feedback">
                <div *ngIf="f.place_called.errors?.required">{{'E-mail'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.place_called.errors?.pattern">{{'E-mail'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Site web'|translate}}</label>
              <input type="text" value="" formControlName="place_called">
              <div *ngIf="isSubmitted && f.place_called.errors" class="invalid-feedback">
                <div *ngIf="f.place_called.errors?.required">{{'Site web'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.place_called.errors?.pattern">{{'Site web'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Created Date' | translate}}</label>
              <input type="text" value="" formControlName="created_date">
              <div *ngIf="isSubmitted && f.created_date.errors" class="invalid-feedback">
                <div *ngIf="f.created_date.errors?.required">{{'Created Date'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.created_date.errors?.pattern">{{'Created Date'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Start date of service' | translate}}</label>
              <input type="text" value="" formControlName="service_start_date">
              <div *ngIf="isSubmitted && f.service_start_date.errors" class="invalid-feedback">
                <div *ngIf="f.service_start_date.errors?.required">{{'Created Date'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.service_start_date.errors?.pattern">{{'Created Date'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="container mt-5">
          <h6 class="">En cas de réprise d'activité</h6>
        </div>
        <div class="line"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-6 mt-3">
              <label for="">{{'Date de reprise' | translate}}</label>
              <input type="text" value="" formControlName="created_date">
              <div *ngIf="isSubmitted && f.recovery_date.errors" class="invalid-feedback">
                <div *ngIf="f.recovery_date.errors?.required">{{'Date de reprise'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.created_date.errors?.pattern">{{'Date de reprise'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Former manager' | translate}}</label>
              <input type="text" value="" formControlName="bp">
              <div *ngIf="isSubmitted && f.bp.errors" class="invalid-feedback">
                <div *ngIf="f.bp.errors?.required">{{'Former manager'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.bp.errors?.pattern">{{'Former manager'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Legal status' | translate}}</label>
              <input type="text" value="" formControlName="bp">
              <div *ngIf="isSubmitted && f.bp.errors" class="invalid-feedback">
                <div *ngIf="f.bp.errors?.required">{{'Legal status'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.bp.errors?.pattern">{{'Legal status'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'RCCM' | translate}}</label>
              <input type="text" value="" formControlName="contact_2">
              <div *ngIf="isSubmitted && f.contact_2.errors" class="invalid-feedback">
                <div *ngIf="f.contact_2.errors?.required">{{'RCCM'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.contact_2.errors?.pattern">{{'RCCM'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">NIF</label>
              <input type="text" value="" formControlName="fax">
              <div *ngIf="isSubmitted && f.fax.errors" class="invalid-feedback">
                <div *ngIf="f.fax.errors?.required">{{'NIF'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.fax.errors?.pattern">{{'NIF'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">N° compte bancaire</label>
              <input type="email" value="" formControlName="email">
              <div *ngIf="isSubmitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors?.required">{{'N° compte bancaire'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.email.errors?.email">{{'N° compte bancaire'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Tutelle'|translate}}</label>
              <input type="text" value="" formControlName="website">
              <div *ngIf="isSubmitted && f.website.errors" class="invalid-feedback">
                <div *ngIf="f.website.errors?.required">{{'Tutelle'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.website.errors?.pattern">{{'Tutelle'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Branch of activity'|translate}}</label>
              <input type="text" value="" formControlName="website">
              <div *ngIf="isSubmitted && f.website.errors" class="invalid-feedback">
                <div *ngIf="f.website.errors?.required">{{'Branch of activity'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.website.errors?.pattern">{{'Branch of activity'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Nature of activities'|translate}}</label>
              <input type="text" value="" formControlName="website">
              <div *ngIf="isSubmitted && f.website.errors" class="invalid-feedback">
                <div *ngIf="f.website.errors?.required">{{'Nature of activities'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.website.errors?.pattern">{{'Nature of activities'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Nombre de travailleurs'|translate}}</label>
              <input type="text" value="" formControlName="website">
              <div *ngIf="isSubmitted && f.website.errors" class="invalid-feedback">
                <div *ngIf="f.website.errors?.required">{{'Nombre de travailleurs'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.website.errors?.pattern">{{'Nombre de travailleurs'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Men'|translate}}</label>
              <input type="text" value="" formControlName="website">
              <div *ngIf="isSubmitted && f.website.errors" class="invalid-feedback">
                <div *ngIf="f.website.errors?.required">{{'Men'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.website.errors?.pattern">{{'Men'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-3 col-12 mt-2">
              <label for="">{{'Women'|translate}}</label>
              <input type="text" value="" formControlName="workers_count_women">
              <div *ngIf="isSubmitted && f.workers_count_women.errors" class="invalid-feedback">
                <div *ngIf="f.workers_count_women.errors?.required">{{'Women'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.workers_count_women.errors?.pattern">{{'Women'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Interns'|translate}}</label>
              <input type="text" value="" formControlName="website">
              <div *ngIf="isSubmitted && f.website.errors" class="invalid-feedback">
                <div *ngIf="f.website.errors?.required">{{'Interns'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.website.errors?.pattern">{{'Interns'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="container mt-5">
          <h6 class="">Icantité du responsable</h6>
        </div>
        <div class="line"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-6 mt-3">
              <label for="">{{'Name' | translate}}</label>
              <input type="text" value="" formControlName="account_name">
              <div *ngIf="isSubmitted && f.account_name.errors" class="invalid-feedback">
                <div *ngIf="f.account_name.errors?.required">{{'Name'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.account_name.errors?.pattern">{{'Name'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'First name' | translate}}</label>
              <input type="text" value="3" formControlName="account_name">
              <div *ngIf="isSubmitted && f.account_name.errors" class="invalid-feedback">
                <div *ngIf="f.account_name.errors?.required">{{'First name'|translate}} {{'is required'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Maiden name'|translate}}</label>
              <input type="text" value="" formControlName="legal_status">
              <div *ngIf="isSubmitted && f.legal_status.errors" class="invalid-feedback">
                <div *ngIf="f.legal_status.errors?.required">{{'Maiden name'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.legal_status.errors?.pattern">{{'Maiden name'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Nationality' | translate}}</label>
              <input type="text" value="" formControlName="employer_plan">
              <div *ngIf="isSubmitted && f.employer_plan.errors" class="invalid-feedback">
                <div *ngIf="f.employer_plan.errors?.required">{{'Nationality'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.employer_plan.errors?.pattern">{{'Nationality'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Function' | translate}}</label>
              <input type="text" value="" formControlName="tax_number">
              <div *ngIf="isSubmitted && f.tax_number.errors" class="invalid-feedback">
                <div *ngIf="f.tax_number.errors?.required">{{'Function'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.tax_number.errors?.pattern">{{'Function'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Mobile'|translate}}</label>
              <input type="text" value="" formControlName="trade_reg_number">
              <div *ngIf="isSubmitted && f.trade_reg_number.errors" class="invalid-feedback">
                <div *ngIf="f.trade_reg_number.errors?.required">{{'Mobile'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.trade_reg_number.errors?.pattern">{{'Mobile'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Representative'|translate}}</label>
              <input type="text" value="" formControlName="nif">
              <div *ngIf="isSubmitted && f.nif.errors" class="invalid-feedback">
                <div *ngIf="f.nif.errors?.required">{{'Representative'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.nif.errors?.pattern">{{'Representative'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'I the undersigned'|translate}}</label>
              <input type="text" value="" formControlName="seeg_subscription_no">
              <div *ngIf="isSubmitted && f.seeg_subscription_no.errors" class="invalid-feedback">
                <div *ngIf="f.seeg_subscription_no.errors?.required">{{'I the undersigned'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.seeg_subscription_no.errors?.pattern">{{'I the undersigned'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Date'|translate}}</label>
              <input type="text" value="" formControlName="seeg_subscription_no">
              <div *ngIf="isSubmitted && f.seeg_subscription_no.errors" class="invalid-feedback">
                <div *ngIf="f.seeg_subscription_no.errors?.required">{{'Date'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.seeg_subscription_no.errors?.pattern">{{'Date'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <!-- <div class="col-md-6 mt-3">
                               <label for="">Banque</label>
                               <input type="text" value="" formControlName="bank">
                               <div *ngIf="isSubmitted && f.bank.errors" class="invalid-feedback">
                                 <div *ngIf="f.bank.errors?.required">{{'Banque'|translate}} {{'is required'|translate}}</div>
                                 <div *ngIf="f.bank.errors?.pattern">{{'Banque'|translate}} {{'should be valid'|translate}}</div>
                               </div>
                           </div> -->
          </div>
        </div>
        <div class="container mt-5">
          <h6 class="">{{'Bank Details' | translate}}</h6>
        </div>
        <div class="line"></div>
        <div class="container">
          <div class="enroll_form">
            <div class="input_section profile_bank_section">
              <div class="currentStatus">
                <div id="tab-parent">
                  <div class="tabs">
                    <!-- <button class="tablinks bank" [ngClass]="{'enabled': !mobileMoney}" (click)="open('bank')">{{'Bank'| translate}}</button>
                                      <button class="tablinks money" [ngClass]="{'enabled': mobileMoney}" (click)="open('money')">{{'Mobile Money'| translate}}</button> -->
                    <input type="radio" id="radio-1" name="tabs" checked />
                    <label class="tabq" for="radio-1" (click)="open('bank')">{{'Bank'| translate}}</label>
                    <input type="radio" id="radio-2" name="tabs" />
                    <label class="tabq" for="radio-2" (click)="open('money')">{{'Mobile Money'| translate}}</label>
                    <span class="glider"></span>
                  </div>
                </div>
              </div>
              <div *ngIf="!mobileMoney" id="bankDetailsForm">
                <!-- <div class="container mt-5"><h6 class="">{{'Bank Details' | translate}}</h6></div> -->
                <!-- <div class="line"></div> -->
                <div class="custom-container">
                  <div class="row">
                    <div class="col-md-12 mt-3">
                      <label> {{'Select Bank'| translate}}</label>
                      <div class="grid-container">
                        <div class="grid-child" *ngFor="let bank of bankdata">
                          <input type="radio" id="bank{{ bank.id }}" name="banks" formControlName="banks" value="{{ bank.value }}" (change)="handleChange($event, bank.name, 1)">
                          <label for="bank{{ bank.id }}">
                            <img src="{{ bank.image }}">
                          </label>
                        </div>
                      </div>
                      <div *ngIf="isSubmitted && f.banks?.errors" class="invalid-feedback">
                        <div *ngIf="f.banks?.errors?.required">{{'Bank'|translate}} {{'is required'|translate}}</div>
                      </div>
                    </div>
                    <div class="col-md-6 mt-3">
                      <label for="">{{'Account number'|translate}}</label>
                      <input type="text" formControlName="account_number">
                      <div *ngIf="isSubmitted && f.account_number.errors" class="invalid-feedback">
                        <div *ngIf="f.account_number.errors?.required">{{'Account number'|translate}} {{'is required'|translate}}</div>
                        <div *ngIf="f.account_number.errors?.pattern">{{'Account Number'|translate}} {{'should be valid'|translate}}</div>
                      </div>
                    </div>
                    <div class="col-md-6 mt-3">
                      <label for="">{{'Account name'|translate}}</label>
                      <input type="text" formControlName="account_name">
                      <div *ngIf="isSubmitted && f.account_name.errors" class="invalid-feedback">
                        <div *ngIf="f.account_name.errors?.required">{{'Account name'|translate}} {{'is required'|translate}}</div>
                        <div *ngIf="f.account_name.errors?.pattern">{{'Account name'|translate}} {{'should be valid'|translate}}</div>
                      </div>
                    </div>
                    <div class="col-md-6 mt-3">
                      <label for="">{{'Bank name'|translate}}</label>
                      <input type="text" formControlName="bank_name">
                      <div *ngIf="isSubmitted && f.bank_name.errors" class="invalid-feedback">
                        <div *ngIf="f.bank_name.errors?.required">{{'Bank name'|translate}} {{'is required'|translate}}</div>
                        <div *ngIf="f.bank_name.errors?.pattern">{{'Bank name'|translate}} {{'should be valid'|translate}}</div>
                      </div>
                    </div>
                    <div class="col-md-6 mt-3">
                      <label for="">{{'Bank number'|translate}}</label>
                      <input type="text" formControlName="bank_number">
                      <div *ngIf="isSubmitted && f.bank_number.errors" class="invalid-feedback">
                        <div *ngIf="f.bank_number.errors?.required">{{'Bank number'|translate}} {{'is required'|translate}}</div>
                        <div *ngIf="f.bank_number.errors?.pattern">{{'Bank number'|translate}} {{'should be valid'|translate}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="mobileMoneyForm" *ngIf="mobileMoney">
                <div class="enroll_form">
                  <div>
                    <div class="input_section">
                      <div class="custom-container">
                        <div class="row">
                          <div class="col-md-12">
                            <label for=""> {{'Select Bank'| translate}}</label>
                            <div class="grid-container">
                              <div class="grid-child" *ngFor="let moneybank of moneydata">
                                <input type="radio" id="money{{ moneybank.id }}" name="money" formControlName="money" value="{{ moneybank.value }}" (change)="handleChange($event, moneybank.name, 2)">
                                <label for="money{{ moneybank.id }}">
                                  <img src="{{ moneybank.image }}">
                                </label>
                              </div>
                              <!--       <div class="grid-child">
                                                              <input type="radio" id="clikpay" name="money" formControlName="money" value="0" >
                                                              <label for="clikpay">
                                                                  <img src="assets/images/bank_logo/clikpay-logo.png">
                                                              </label>
                                                          </div>
                                                          <div class="grid-child">
                                                              <input type="radio" id="airtel" name="money" formControlName="money" value="1" >
                                                              <label for="airtel">
                                                                  <img src="assets/images/bank_logo/airtel-moneyLogo.png">
                                                              </label>
                                                          </div>
                                                          <div class="grid-child">
                                                              <input type="radio" id="moov" name="money" formControlName="money" value="2" >
                                                              <label for="moov">
                                                                  <img src="assets/images/bank_logo/mobicash_logo_inner.png">
                                                              </label>
                                                          </div> -->
                            </div>
                            <div *ngIf="isSubmitted && h.money.errors" class="invalid-feedback">
                              <div *ngIf="f.money.errors?.required">{{'Bank'|translate}} {{'is required'|translate}}</div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6 mt-3">
                            <label for="">{{'Account name'|translate}}</label>
                            <input type="text" formControlName="mobile_money_account_name">
                            <div *ngIf="isSubmitted && f.mobile_money_account_name.errors" class="invalid-feedback">
                              <div *ngIf="f.mobile_money_account_name.errors?.required">{{'Account name'|translate}} {{'is required'|translate}}</div>
                              <div *ngIf="f.mobile_money_account_name.errors?.pattern">{{'Account name'|translate}} {{'should be valid'|translate}}</div>
                            </div>
                          </div>
                          <div class="col-md-6 mt-3">
                            <label for="">{{'Account number'|translate}}</label>
                            <input type="text" formControlName="mobile_money_account_number">
                            <div *ngIf="isSubmitted && h.mobile_money_account_number.errors" class="invalid-feedback">
                              <div *ngIf="f.mobile_money_account_number.errors?.required">{{'Account number'|translate}} {{'is required'|translate}}</div>
                              <div *ngIf="f.mobile_money_account_number.errors?.pattern">{{'Account number'|translate}} {{'should be valid'|translate}}</div>
                            </div>
                          </div>
                          <div class="col-md-6 mt-3">
                            <label for="">{{'Facility name'|translate}}</label>
                            <input type="text" formControlName="facility_name">
                            <div *ngIf="isSubmitted && h.facility_name.errors" class="invalid-feedback">
                              <div *ngIf="f.facility_name.errors?.required">{{'Facility name'|translate}} {{'is required'|translate}}</div>
                              <div *ngIf="f.facility_name.errors?.pattern">{{'Facility name'|translate}} {{'should be valid'|translate}}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container mt-5">
          <h6 class="">Cadre reservé à la CNAMGS</h6>
        </div>
        <div class="line"></div>
        <div class="container">
          <div class="row">
            <div class="col-md-6 mt-3">
              <label for="">{{'Employer number' | translate}}</label>
              <input type="text" value="" formControlName="created_date">
              <div *ngIf="isSubmitted && f.created_date.errors" class="invalid-feedback">
                <div *ngIf="f.created_date.errors?.required">{{'Employer number'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.created_date.errors?.pattern">{{'Employer number'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Creation date'|translate}}</label>
              <input type="text" value="3" formControlName="service_start_date">
              <div *ngIf="isSubmitted && f.service_start_date.errors" class="invalid-feedback">
                <div *ngIf="f.service_start_date.errors?.required">{{'Creation date'|translate}} {{'is required'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Legal status'|translate}}</label>
              <input type="text" value="" formControlName="legal_status">
              <div *ngIf="isSubmitted && f.legal_status.errors" class="invalid-feedback">
                <div *ngIf="f.legal_status.errors?.required">{{'Legal status'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.legal_status.errors?.pattern">{{'Legal status'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Branch of activity'|translate}}</label>
              <input type="text" value="" formControlName="employer_plan">
              <div *ngIf="isSubmitted && f.employer_plan.errors" class="invalid-feedback">
                <div *ngIf="f.employer_plan.errors?.required">{{'Branch of activity'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.employer_plan.errors?.pattern">{{'Branch of activity'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="">{{'Employer Fund'|translate}}</label>
              <input type="text" value="" formControlName="tax_number">
              <div *ngIf="isSubmitted && f.tax_number.errors" class="invalid-feedback">
                <div *ngIf="f.tax_number.errors?.required">{{'Employer Fund'|translate}} {{'is required'|translate}}</div>
                <div *ngIf="f.tax_number.errors?.pattern">{{'Employer Fund'|translate}} {{'should be valid'|translate}}</div>
              </div>
            </div>
          </div>
          <div class="last_sec mt-4">
            <div class="tableFooter approval-checkbox">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" formControlName="confirm_account">
                <label class="form-check-label" for="flexCheckDefault">
                  En cliquant sur Soumettre la déclaration, vous attestez que les informations déclarées reflètent la réalité de la situation sociale de l'entreprise.
                </label>
              </div>
              <div *ngIf="isSubmitted && f.confirm_account.errors" class="invalid-feedback">
                <div *ngIf="f.confirm_account.errors?.required">{{'Please check the declaration to Continue'|translate}}</div>
              </div>
            </div>
            <div class="buttons report_btn mb-5 text-center">
              <button class="btn btn-medium" type="submit">{{'Submit' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!--   <div class="checkboxSection">
    <mat-checkbox class="example-margin" ></mat-checkbox>
     <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam inventore animi recusandae facilis! Rerum, incidunt et voluptate aut quia voluptates asperiores distinctio tempora aliquid unde?</p>
   </div> -->
</div>
<!-- cnss form ends-->
<app-footer></app-footer>
