import { Component, OnInit, NgZone } from '@angular/core';
declare var $: any;
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from '../../../_services/api.service';
import { AnpiService } from '../../../_services/anpi-code.service';
import { first } from 'rxjs/operators';
import { ConfirmPasswordValidator } from '../../../_validators/confirm-password.validator';
import { AuthenticationService } from '../../../_services/authentication.service';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  passwordFieldType: string = "password";
  showPassword = false;
  employee = false;
  public bankDetailsForm: FormGroup;
  public mobileMoneyForm: FormGroup;
  public resetPasswordForm: FormGroup;
  bankDetails = false;
  response: any;
  isSubmitted = false;
  profileDetails: any;
  bankdata: any;
  login: any;
  accountDetails: any;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private api: ApiService,
    private anpiService: AnpiService,
    private ngzone: NgZone,
    private authenticationService: AuthenticationService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private snackBar: MatSnackBar) {
    const data = localStorage.getItem('currentUserDetails');
    if (data) {
      this.accountDetails = JSON.parse(data);
      this.login = this.accountDetails.current_user;
      console.log("36 login value:", this.login)
      if (this.login == "1") {
        this.employee = false;
      } else {
        this.employee = true;
      }
    }


  }

  ngOnInit() {
    console.log("36", this.isSubmitted)
    AOS.init({
      disable: function() {
        var maxWidth = 600;
        return window.innerWidth < maxWidth;
      }
    });
    this.resetPasswordForm = this.formBuilder.group({
      current_password: ['', [Validators.required]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*?#&].{7,}')]],
      confirmPassword: ['', [Validators.required]],
    }, { validator: ConfirmPasswordValidator.MatchPassword });

    this.bankDetailsForm = this.formBuilder.group({
      banks: ['1', [Validators.required]],
      account_number: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      account_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$")]],
      bank_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      bank_number: ['', [Validators.required, Validators.pattern("^[0-9]*$")]]
    });

    this.getBanks();
  }
  closeModal() {
    this.isSubmitted = false;
    $('#signinModal').modal('hide');
    this.resetPasswordForm.reset();
  }
  getProfileDetails() {
    // this.ngxUiLoaderService.start();
    this.api.getProfileDetails().pipe(first())
      .subscribe(data => {
          // this.ngxUiLoaderService.stop();
          // this.response = data;
          if (this.response.status == 200) {
            // this.profileDetails = this.response.data;
          } else {
            // this.snackBar.open(res.message, "dismiss", {
            //      duration: 2000,
            //   });
          }
        },
        error => {
          // this.ngxUiLoaderService.stop();
        });
  }

  onSubmitBankForm() {
    this.isSubmitted = true;
    if (this.bankDetailsForm.invalid) {
      console.log("196", this.bankDetailsForm)
      return;
    }
    var data = {
      account_no: this.g.account_number.value,
      account_name: this.g.account_name.value,
      bank_name: this.g.bank_name.value,
      bank_no: this.g.bank_number.value,
      bank_id: this.g.banks.value
    }
    console.log("145", data)
    this.ngxUiLoaderService.start();
    this.api.bankDetails(data).pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          this.response = data;
          if (this.response.status == 200) {
            this.bankDetailsForm.reset();
            this.isSubmitted = false;
            $('#bankAccountModal').modal('hide');
            this.anpiService.emitEvent();
            this.bankDetailsForm.controls['bank_name'].setValue(this.bankdata[0].bank_name);
            this.bankDetails = false;
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
          } else {
            this.isSubmitted = false;
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.isSubmitted = false;
          this.ngxUiLoaderService.stop();
        });

  }
  onSubmitResetPassword() {
    this.isSubmitted = true;
    console.log("160")
    if (this.resetPasswordForm.invalid) {
      console.log("196", this.resetPasswordForm)
      return;
    }
    var data = this.resetPasswordForm.value;
    this.ngxUiLoaderService.start();
    this.api.resetPassword(data).pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res;
          if (this.response.status == 200) {
            $('#signinModal').modal('hide');
            this.snackBar.open(this.response.message, "dismiss", {
              duration: 2000,
            });
          } else {
            this.snackBar.open(this.response.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
    this.isSubmitted = false;
  }
  logout() {
    this.ngxUiLoaderService.start();
    this.authenticationService.logout();
    localStorage.removeItem('business');
    localStorage.removeItem('bank');
    localStorage.removeItem('employees');
    localStorage.removeItem('cnssForm');
    localStorage.removeItem('cnamgsForm');
    localStorage.removeItem('account_status');
    localStorage.removeItem('login');
    this.ngxUiLoaderService.stop();
    this.router.navigate(['/']);
  }
  showSearch() {
    console.log('333')
    $('#searchStyle').addClass('searchTransform');
    $('#searchInput').addClass('changeSearchStyle');
    $('small').css({ display: "none" });

  }

  open(val) {
    console.log(val)

    switch (val) {
      case 'sidenav1':
        {
          this.bankDetails = false;
          console.log('196')
          break;
        }
      case 'sidenav3':
        {
          setTimeout(() => {
            console.log($('#bank1'))
            $('#bank1').prop('checked', true);
            // this.bankDetailsForm.controls['bank_name'].setValue(this.bankdata[0].name);
          }, 1000)
          this.bankDetails = true;
          console.log('202')
          break;
        }
    }
  }

  eyeclose() {
    $('#eye').toggleClass('fa-eye fa-eye-slash');
    this.showPassword = (this.showPassword) ? false : true;
    if (this.showPassword) {
      this.passwordFieldType = "text";
    } else {
      this.passwordFieldType = "password";
    }
  }
  openDoc() {
    this.ngxUiLoaderService.start();
    this.api.getCnssPdf().pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          console.log("263", data)
          if (data.status == 200) {
            window.open(data.imagePath, '_blank');
          } else {
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
    // window.open('/assets/pdf/CNSS_FORM.pdf', '_blank');
  }

  // getBanks() {
  //   this.api.getBanks().pipe(first())
  //     .subscribe(data => {
  //       this.bankdata = data.results.banks;
  //       setTimeout(() => {
  //         this.ngzone.run(() => {
  //           console.log("fdsfdsfsdfd")
  //           $('#bank1').prop('checked', true);
  //           console.log($('#bank1'))
  //           this.bankDetailsForm.controls['bank_name'].setValue(this.bankdata[0].name);
  //         });
  //       }, 2000)
  //     })
  // }
  getBanks() {
    console.log("260")
    this.ngxUiLoaderService.start();
    this.api.getBankList().pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          console.log("263", data)
          this.response = data.Results;
          if (data.status == 200) {
            this.bankdata = data.Results;
            setTimeout(() => {
              this.ngzone.run(() => {
                // $('#bank1').prop('checked', true);
                console.log($('#bank1'))
                this.bankDetailsForm.controls['bank_name'].setValue(this.bankdata[0].bank_name);
              });
            }, 2000)
          } else {
            this.snackBar.open(data.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }
  handleChange(e, value, banks) {
    console.log("284", value)
    this.bankDetailsForm.controls['bank_name'].setValue(value);
    this.bankDetailsForm.controls['banks'].setValue(banks);
  }

  get f() {
    return this.resetPasswordForm.controls;
  }
  get g() {
    return this.bankDetailsForm.controls;
  }


  getBusiness() {
    return localStorage.getItem('business');
  }
  getCnssForm() {
    return localStorage.getItem('cnssForm');
  }


}
