<app-header></app-header>
<section *ngIf="!listView" class="middle-section salary-revise">
  <div class="container-fluid">
    <div class="initiale_sestion">
      <div class="head">
        <div class="title-container">
          <i (click)="goToEmpolyeeList()" class="fa-solid fa-angle-left"></i>
          <h4 class="form-title-sm">{{'Salary Revise' | translate}}</h4>
        </div>
        <div *ngIf="!employee" class="custom-container">
          <button id="submit_btn" class="btn btn-main2" data-target="#employeeWithCcpsStage2" data-toggle="modal">{{'Add Salary Revise' | translate}}</button>
        </div>
      </div>
    </div>
    <div *ngIf="employeeDetails" class="user-data-container">
      <div class="user-data">
        <div class="child-container">
          <label class="labeltxt-sm">{{'Employee CCPS ID' | translate}}</label>
          <h6 *ngIf="employeeDetails.ccps_code">{{employeeDetails.ccps_code}}</h6>
          <h6 *ngIf="!employeeDetails.ccps_code">--</h6>
        </div>
        <div class="child-container">
          <label class="labeltxt-sm">{{'Name' | translate}}</label>
          <h6>{{employeeDetails.name}}</h6>
        </div>
        <div class="child-container">
          <label class="labeltxt-sm">{{'position' | translate}}</label>
          <h6>{{employeeDetails.designation}}</h6>
        </div>
        <div class="child-container">
          <label class="labeltxt-sm">{{'CNAMGS ID' | translate}}</label>
          <h6 *ngIf="employeeDetails.cnamgs">{{employeeDetails.cnamgs}}</h6>
          <h6 *ngIf="!employeeDetails.cnamgs">--</h6>
        </div>
        <div class="child-container">
          <label class="labeltxt-sm">{{'CNSS ID' | translate}}</label>
          <h6 *ngIf="employeeDetails.cnss">{{employeeDetails.cnss}}</h6>
          <h6 *ngIf="!employeeDetails.cnss">--</h6>
        </div>
        <div class="child-container">
          <label class="labeltxt-sm">{{'Mobile' | translate}}</label>
          <h6>{{employeeDetails.mobile}}</h6>
        </div>
      </div>
    </div>
    <div class="reportTable">
      <table id="tooltip-table" class="quarter_table">
        <colgroup>
          <col span="1" class="slno">
          <col span="1" class="date">
          <col span="1" class="designation">
          <col span="1" class="base-salary">
          <col span="1" class="salary">
        </colgroup>
        <thead>
          <tr>
            <th scope="col">{{'SL NO' | translate}}</th>
            <th scope="col">{{'Date' | translate}}</th>
            <th scope="col">{{'position' | translate}}</th>
            <th scope="col">{{'Base salary' | translate}}</th>
            <th scope="col">{{'Salary' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let detail of employeeFullDetails; let i = index">
            <td data-label="SL NO">{{i +1}}</td>
            <td data-label="Date">{{detail.date}}</td>
            <td data-label="Designation">{{detail.designation}}</td>
            <td data-label="Base Salary">{{detail.base_salary}} FCFA</td>
            <td data-label="Salary">{{detail.salary}} FCFA</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>
<div class="modal fade" id="employeeWithCcpsStage2" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
  <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
    <div class="modal-content">
      <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" (click)="closeModal()" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body">
        <div class="parent-container">
          <h5 class="form-title text-center">{{'Add Employees step3' | translate}}</h5>
          <form id="WithCcpsStage2Form" [formGroup]="employeeSalaryForm" (ngSubmit)="onSubmitSalary()">
            <div class="input-container input-box-white">
              <div class="child-container" [owlDateTimeTrigger]="dt45">
                <label class="labeltxt">{{'Join Date' | translate}}</label>
                <div class="date-field">
                  <input class="" [max]="todayDate" [min]="calculateMinDate()" [owlDateTime]="dt45" readonly name="dob" formControlName="join_date">
                  <div class="input-group-prepend">
                    <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                  </div>
                </div>
                <div *ngIf="isSubmitted && f.join_date.errors" class="invalid-feedback">
                  <div *ngIf="f.join_date.errors?.required">{{'Join Date'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="f.join_date.errors?.pattern">{{'Join Date'|translate}} {{'should be valid' | translate}}</div>
                </div>
                <owl-date-time [pickerType]="'calendar'" #dt45></owl-date-time>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'position' | translate}}</label>
                <input type="text" name="position" formControlName="designation">
                <div *ngIf="isSubmitted && f.designation.errors" class="invalid-feedback">
                  <div *ngIf="f.designation.errors?.required">{{'position'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="f.designation.errors?.pattern">{{'position'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'Base Salary' | translate}}</label>
                <input type="text" name="base_salary" formControlName="base_salary">
                <div *ngIf="isSubmitted && f.base_salary.errors" class="invalid-feedback">
                  <div *ngIf="f.base_salary.errors?.required">{{'Base Salary'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="f.base_salary.errors?.pattern">{{'Base Salary'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
              <div class="child-container">
                <label class="labeltxt">{{'Salary' | translate}}</label>
                <input type="text" name="salary" formControlName="salary">
                <div *ngIf="isSubmitted && f.salary.errors" class="invalid-feedback">
                  <div *ngIf="f.salary.errors?.required">{{'Salary'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="f.salary.errors?.pattern">{{'Salary'|translate}} {{'should be valid' | translate}}</div>
                </div>
              </div>
            </div>
            <button type="submit" id="submit_btn" class="btn btn-medium">{{'Submit' | translate}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
<app-contact-floating></app-contact-floating>
