<app-header></app-header>
<section class="reportsList">
    <div class="container-fluid">
     <div class="initiale_sestion">
         <div class="head">
            <h4>{{'Manage CNAMGS'| translate}}</h4>
             <button id="submit_btn" class="btn btn-main2" (click)="gotoUrl()">{{'Initiate a declaration' | translate}}</button>
         </div>
         <div class="legend">
             <div id="pending">
                 <div class="circle"> </div>
                 <h4 class="legend_txt">{{'Waiting for Approval'| translate}}</h4>
             </div>
             <div id="paynow">
                 <div class="circle"> </div>
                 <h4 class="legend_txt">{{'Waiting for payment'| translate}}</h4>
             </div>
             <div id="rejected">
                 <div class="circle"> </div>
                 <h4 class="legend_txt">{{'Report Rejected'| translate}}</h4>
             </div>
             <div id="completed">
                 <div class="circle"> </div>
                 <h4 class="legend_txt">{{'Payment Completed'| translate}}</h4>
             </div>
         </div>
     </div>
        
    </div>


 <!-- cnamgs list start-->
  <div class="mt-4">
     <div class="container-fluid reports_status mt-4 mb-5">
         <div class="card-grid"  >
             
             <div class="child1">
                 <div class="dflex">
                     <div class="status pending"><h6>{{'Pending'| translate}}</h6></div>
                     <div class="columns">
                         <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                         <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Fedrik Schwegnar</span></h6>
                         <h6 class="sm_text">{{'CNAMGS Employer ID'| translate}} : <span>CG2352355</span></h6>
                     </div>
                 </div>
             </div>

             <div class="child2">
                 <div class="columns">
                     <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                     <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                     <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                 </div>
             </div>
             <div class="child3">
                 <div class="columns">
                     <h6 class="sm_text">{{'Post box'| translate}} : <span>235364</span></h6>
                     <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                     <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28/10/2022</span></h6>
                 </div>
             </div>
             <div class="child4">
                 <button id="submit_btn" [routerLink]="['/cnamgs-reportview', 'pendingcnss']" class="btn btn-main2"><a>{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
             </div>
         </div>

         <div class="card-grid"  >
             
             <div class="child1">
                 <div class="dflex">
                     <div class="status payNow"><h6>{{'PayNow'| translate}}</h6></div>
                     <div class="columns">
                         <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                         <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Fedrik Schwegnar</span></h6>
                         <h6 class="sm_text">{{'CNAMGS Employer ID'| translate}} : <span>CG2352355</span></h6>
                     </div>
                 </div>
             </div>

             <div class="child2">
                 <div class="columns">
                     <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                     <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                     <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                 </div>
             </div>
             <div class="child3">
                 <div class="columns">
                     <h6 class="sm_text">{{'Post box'| translate}} : <span>235364</span></h6>
                     <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                     <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28/10/2022</span></h6>
                 </div>
             </div>
             <div class="child4">
                 <button [routerLink]="['/cnamgs-reportview', 'payNowCnss']" id="submit_btn" class="btn btn-main2"><a>{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
             </div>
         </div>

         <div class="card-grid">
             
             <div class="child1">
                 <div class="dflex">
                     <div class="status reject"><h6>{{'Rejected'| translate}}</h6></div>
                     <div class="columns">
                         <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                         <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Fedrik Schwegnar</span></h6>
                         <h6 class="sm_text">{{'CNAMGS Employer ID'| translate}} : <span>CG2352355</span></h6>
                     </div>
                 </div>
             </div>

             <div class="child2">
                 <div class="columns">
                     <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                     <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                     <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                 </div>
             </div>
             <div class="child3">
                 <div class="columns">
                     <h6 class="sm_text">{{'Post box'| translate}} : <span>235364</span></h6>
                     <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                     <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28/10/2022</span></h6>
                 </div>
             </div>
             <div class="child4">
                 <button [routerLink]="['/cnamgs-reportview', 'rejectedCnss']" id="submit_btn" class="btn btn-main2"><a>{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
             </div>
         </div>

         <div class="card-grid completed_bg"  >
             
             <div class="child1">
                 <div class="dflex">
                     <div class="status completed"><h6>{{'Completed'| translate}}</h6></div>
                     <div class="columns">
                         <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                         <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Fedrik Schwegnar</span></h6>
                         <h6 class="sm_text">{{'CNAMGS Employer ID'| translate}} : <span>CG2352355</span></h6>
                     </div>
                 </div>
             </div>

             <div class="child2">
                 <div class="columns">
                     <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                     <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                     <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                 </div>
             </div>
             <div class="child3">
                 <div class="columns">
                     <h6 class="sm_text">{{'Post box'| translate}} : <span>235364</span></h6>
                     <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                     <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28/10/2022</span></h6>
                 </div>
             </div>
             <div class="child4">
                 <button [routerLink]="['/cnamgs-reportview', 'completedCnss']" id="submit_btn" class="btn btn-main2"><a>{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
             </div>
         </div>
         <div class="card-grid completed_bg">
             
             <div class="child1">
                 <div class="dflex">
                     <div class="status completed"><h6>{{'Completed'| translate}}</h6></div>
                     <div class="columns">
                         <h3 class="sub-title">{{'Second quarter Report'| translate}} 2022</h3>
                         <h6 class="sm_text">{{'Employer Name'| translate}} : <span>Fedrik Schwegnar</span></h6>
                         <h6 class="sm_text">{{'CNAMGS Employer ID'| translate}} : <span>CG2352355</span></h6>
                     </div>
                 </div>
             </div>

             <div class="child2">
                 <div class="columns">
                     <h6 class="sm_text">{{'No of Employees'| translate}} : <span>12</span></h6>
                     <h6 class="sm_text">{{'Company name'| translate}} : <span>In Flow Gabon SARL</span></h6>
                     <h6 class="sm_text">{{'Email'| translate}} : <span>fedrikschwegnar@gmail.com</span></h6>
                 </div>
             </div>
             <div class="child3">
                 <div class="columns">
                     <h6 class="sm_text">{{'Post box'| translate}} : <span>235364</span></h6>
                     <h6 class="sm_text">{{'Phone'| translate}} : <span>+241 321 34 452</span></h6>
                     <h6 class="sm_text">{{'Submit Date'| translate}} : <span>28/10/2022</span></h6>
                 </div>
             </div>
             <div class="child4">
                <button [routerLink]="['/cnamgs-reportview', 'completedCnss']" id="submit_btn" class="btn btn-main2"><a>{{'View'| translate}} <br> {{'Details'| translate}}</a></button>
             </div>
         </div>
     </div>
 </div>
 
</section> 
<app-footer></app-footer>
<app-contact-floating></app-contact-floating>