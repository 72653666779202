<section class="top-section home-sec">
  <div class="container-fluid nav-bar">
    <div id="menuOverlay" (click)="closeNav()"></div>
    <div id="mySidenav" class="sidenav">
      <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
      <li *ngIf="login == '2' || isBusiness"> <a routerLink="/dashboard" class="hvr-efx1" [ngClass]="{ 'active': url == 'dashboard'}">{{'Dashboard' | translate}}</a> </li>
      <li *ngIf="isBusiness"><a *ngIf="login == '1'" [ngClass]="{ 'active': url == 'employees'}" class="active" routerLink="/employees">{{'Employees' | translate}}</a></li>
      <li *ngIf="isBusiness"><a *ngIf="login == '1'" routerLink="/reports">{{'CNSS' | translate}} </a></li>
      <li *ngIf="isBusiness"><a *ngIf="login == '1'" (click)="getCnamgs()" [ngClass]="{ 'active': url == 'cnss'}">{{'CNAMGS' | translate}}</a> </li>
      <li>
        <a *ngIf="login == '2'" routerLink="/dependence-list">{{'Dependants' | translate}}</a>
      </li>
      <li>
        <a routerLink="/experience" *ngIf="login == '2'">{{'Experience' | translate}}</a>
      </li>
      <li>
        <a routerLink="/cnss" *ngIf="login == '2'">{{'CNSS' | translate}}</a>
      </li>
      <li>
        <a (click)="getCnamgsemployee()" *ngIf="login == '2'">{{'CNAMGS' | translate}}</a>
      </li>
      <li *ngIf="!isBusiness && login != '2'"><a routerLink="/link-business">{{'Link Business' | translate}}</a></li>
      <li><a routerLink="/contact-us">{{'Contact' | translate}}</a></li>
      <li>
        <a class="register-button" data-toggle="modal" data-target="#signupModal" (click)="closeNav()">{{'Register' | translate}}</a>
      </li>
      <li>
        <a class="login-button" data-toggle="modal" data-target="#signinModal" (click)="closeNav()">{{'Login' | translate}}</a>
      </li>
      <div class="language-container">
        <form [formGroup]="languageForm">
          <!-- <select  formControlName="lang" (change)="setLang($event.target.value)"  class="bootstrap-select">
                  <option value="en" selected="selected">{{'English' | translate}}</option>
                  <option value="fr">{{'French' | translate}}</option>
                </select> -->
          <div class="toggle-button-cover">
            <div class="button-cover">
              <div class="button r" id="button-6">
                <input type="checkbox" class="checkbox" (change)="getChangelang($event)" />
                <div class="knobs"></div>
                <div class="layer"></div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div id="main">
      <header class="block-header home-header">
        <div class="container-custom">
          <div class="row justify-content-between sub-nav">
            <div class="button_container">
              <div (click)="openNav()"><span class="top"></span><span class="middle"></span><span class="bottom">
                </span></div>
            </div>
            <div class="col-sm-2 col-md-2">
              <a data-aos="fade-down" data-aos-delay="400" data-aos-duration="1500" class="logo-block" routerLink="">CCPS</a>
            </div>
            <div class="col-10 justify-content-end align-items-center lang-desktop" data-aos="fade-down" data-aos-delay="400" data-aos-duration="1500">
              <!-- <label class="text-white">{{'Language' | translate}}&nbsp;:</label> -->
              <ul class="mr-5" *ngIf="login">
                <li *ngIf="login == '2' || isBusiness"> <a routerLink="/dashboard" [ngClass]="{ 'active': url == 'dashboard'}">{{'Dashboard' | translate}}</a> </li>
                <li *ngIf="isBusiness"><a *ngIf="login == '1'" [ngClass]="{ 'active': url == 'employees'}" class="active" routerLink="/employees">{{'Employees' | translate}}</a></li>
                <li *ngIf="isBusiness"><a *ngIf="login == '1'" routerLink="/reports">{{'CNSS' | translate}} </a></li>
                <li *ngIf="isBusiness"><a *ngIf="login == '1'" routerLink="/cnamgs-report" [ngClass]="{ 'active': url == 'cnamgs-report'}">{{'CNAMGS' | translate}}</a> </li>
                <li>
                  <a *ngIf="login == '2'" routerLink="/dependence-list">{{'Dependants' | translate}}</a>
                </li>
                <li>
                  <a routerLink="/experience" *ngIf="login == '2'">{{'Experience' | translate}}</a>
                </li>
                <li>
                  <a routerLink="/cnss" *ngIf="login == '2'">{{'CNSS' | translate}}</a>
                </li>
                <li>
                  <a (click)="getCnamgsemployee()" class="text-white" *ngIf="login == '2'">{{'CNAMGS' | translate}}</a>
                </li>
                <li *ngIf="!isBusiness && login != '2'"><a routerLink="/link-business">{{'Link Business' | translate}}</a></li>
                <li><a routerLink="/contact-us">{{'Contact' | translate}}</a></li>
              </ul>
              <div class="dropdown">
                <div class="toggle-button-cover">
                  <div class="button-cover">
                    <div class="button r" id="button-6">
                      <input type="checkbox" class="checkbox" (change)="getChangelang($event)" />
                      <div class="knobs"></div>
                      <div class="layer"></div>
                    </div>
                  </div>
                </div>
                <!-- <select formControlName="lang" (change)="setLang($event.target.value)" class="bootstrap-select">
                                        <option value="en" selected="selected">English</option>
                                        <option value="fr">French</option>
                                    </select> -->
              </div>
            </div>
            <div class="button_container" (click)="openNav()"><span class="top"></span><span class="middle"></span><span class="bottom">
              </span></div>
          </div>
          <hr>
          <div class="top-nav-container">
            <div class="row align-items-center">
              <div class="col-sm-12">
                <div class="row justify-content-end sub-nav">
                  <!-- <div class="notofication_button"><a routerLink="/notification"><i class="fa-solid fa-bell"></i></a></div> -->
                  <ul class="nav-block link-efx justify-content-end" id="page-menu">
                    <!-- <li>
                                 <a class="hvr-efx1">Dashboard</a>
                               </li>
                               <li>
                                 <a class="hvr-efx1">Employees</a>
                               </li>
                               <li>
                                 <a class="hvr-efx1">CNSS</a>
                               </li>
                               <li>
                                 <a class="hvr-efx1">CNAMGS</a>
                               </li>
                               <li>
                                   <a class="hvr-efx1" routerLink="/contact-us">Contact</a>
                               </li> -->
                    <li>
                    <li>
                      <a class="register-button" (click)="showSignUp()" data-aos="fade-down" data-aos-delay="400" data-aos-duration="1500">{{'Register' | translate}}</a>
                    </li>
                    <li>
                      <a class="login-button hvr-efx1" data-toggle="modal" (click)="showLogin()" data-aos="fade-down" data-aos-delay="400" data-aos-duration="1500">{{'Login' | translate}}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  </div>
  <div class="carousel-container">
    <div class="button-container">
      <div class="nxt_icon">
        <!-- <span class="btnprev text-white"><img src="assets/images/arrow-left.svg" alt=""></span> -->
      </div>
    </div>
    <div class="owl-carousel owl-theme" id="service_slider">
      <div class="item">
        <img src="assets/images/background-green.jpg" class="bg" loading="lazy">
        <div class="content">
          <div class="logo-container mobile-ui1">
            <div class="logo2">
              <img src="assets/images/CNSS-logo-wht.png">
            </div>
            <div class="line"></div>
            <div class="logo">
              <img src="assets/images/CNAMGS_logo.png">
            </div>
          </div>
          <h2 class="mobile-ui">{{'title 1' | translate }} <br> {{'title 2' | translate }}</h2>
          <h5 class="mobile-ui2">{{'Make Insurance now' | translate}}</h5>
          <h6 class="mobile-ui3">{{'Connect with CCPS' | translate}}</h6>
        </div>
      </div>
      <div class="item">
        <img src="assets/images/banner.jpg" class="bg" loading="lazy">
        <div class="content">
          <div class="logo-container mobile-ui1">
            <div class="logo2">
              <img src="assets/images/cnssLogo.png">
            </div>
            <div class="line"></div>
            <div class="logo">
              <img src="assets/images/CNAMGS_logo.png">
            </div>
          </div>
          <h2 class="mobile-ui">{{'title 1' | translate }} <br> {{'title 2' | translate }}</h2>
          <h5 class="mobile-ui2">{{'Make Insurance now' | translate}}</h5>
          <h6 class="mobile-ui3">{{'Connect with CCPS' | translate}}</h6>
        </div>
      </div>
    </div>
    <div class="button-container">
      <div class="nxt_icon">
        <!-- <span class="btnJump text-white"><img src="assets/images/arrow-right.svg" alt=""></span> -->
      </div>
    </div>
  </div>
</section>
<section class="middle-section container">
  <div class="custom-container row">
    <div class="custom-card col-md-6 col-lg-3 col-xl-3 col-sm-12" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1500">
      <div class="card-top">
        <h6>{{'Step' | translate}} 1</h6>
        <div class="icon-container">
          <img src="assets/images/key_icon.png">
        </div>
      </div>
      <div class="card-content">
        <h6>{{'Register with CCPS Website' | translate}}</h6>
        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p> -->
      </div>
    </div>
    <div class="custom-card col-md-6 col-lg-3 col-xl-3 col-sm-12" data-aos="fade-down" data-aos-delay="400" data-aos-duration="1500">
      <div class="card-top">
        <h6>{{'Step' | translate}} 2</h6>
        <div class="icon-container">
          <img src="assets/images/link-icon.png">
        </div>
      </div>
      <div class="card-content">
        <h6>{{'Link your Account with CCPS ID' | translate}}</h6>
      </div>
    </div>
    <div class="custom-card col-md-6 col-lg-3 col-xl-3 col-sm-12" data-aos="fade-down" data-aos-delay="700" data-aos-duration="1500">
      <div class="card-top">
        <h6>{{'Step' | translate}} 3</h6>
        <div class="icon-container">
          <img src="assets/images/settings_icon.png">
        </div>
      </div>
      <div class="card-content">
        <h6>{{'Manage your company with CCPS' | translate}}</h6>
      </div>
    </div>
  </div>
  <div class="container-fluid custom-container2">
    <div class="row">
      <div class="col-md-12 col-lg-6 col-xl-6 col-sm-12">
        <div class="parent-container">
          <div class="text-container">
            <h6 class="title-medium">{{'About' | translate}}</h6>
            <h5 data-scroll data-splitting>{{'about head' | translate}}</h5>
            <p data-aos="fade-right" data-aos-delay="700" data-aos-duration="1500">{{'about para 1' | translate }}</p>
            <p data-aos="fade-right" data-aos-delay="700" data-aos-duration="1500">{{'about para 2' | translate }}</p>
          </div>
        </div>
      </div>
      <div class="col-md-12 col-lg-6 col-xl-5 col-sm-12">
        <div class="image-container">
          <img src="assets/images/person1.png" data-aos="fade-right" data-aos-duration="1500" data-aos-delay="200">
        </div>
      </div>
    </div>
    <div class="dots">
      <img src="assets/images/dots.png">
    </div>
  </div>
</section>
<!-- <section class="middle-section gni-banner">
    <div class="banner-container2">
        <img src="assets/images/{{ 'bannerpic' | translate }}" class="webui img-fluid">     
        <img src="assets/images/{{ 'bannermobile' | translate }}" class="mobileui img-fluid">     
    </div>
 </section> -->
<section class="bottom-section">
  <div class="text-container">
    <h5 class="title-green-lg">{{'Our Features' | translate}}</h5>
  </div>
  <div class="custom-container">
    <div class="row">
      <div class="col-md-6 col-lg-6 col-xl-4 offset-xl-1 col-sm-12">
        <div class="image-container">
          <img src="assets/images/feature_image1.png" data-aos="fade-right" data-aos-duration="3000" data-aos-delay="500">
        </div>
      </div>
      <div class="col-md-12 col-lg-6 col-xl-5 col-sm-12">
        <div class="parent-container" data-aos="zoom-out-up" data-aos-duration="3000" data-aos-delay="500">
          <div class="text-container">
            <h6>CNAMGS</h6>
            <h5 class="title-green-md">{{'Manage health insurance with CNAMGS' | translate}}</h5>
            <p class="para-green-md">{{'cnamgs-text' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-container container2">
    <div class="row">
      <div class="col-md-12 col-lg-6 col-xl-5 col-sm-12 element1">
        <div class="parent-container" data-aos="zoom-out-up" data-aos-duration="3000" data-aos-delay="1000">
          <div class="text-container">
            <h6>CNSS</h6>
            <h5 class="title-green-md">{{'Manage provident fund with CNSS' | translate}}</h5>
            <p class="para-green-md">{{'cnss-text' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-xl-5 col-sm-12 element2">
        <div class="image-container">
          <img src="assets/images/feature_image2.png" data-aos="fade-left" data-aos-duration="2000" data-aos-delay="1000">
        </div>
      </div>
    </div>
  </div>
  <div class="custom-container">
    <div class="row">
      <div class="col-md-6 col-lg-6 col-xl-4 offset-xl-1  col-sm-12">
        <div class="image-container">
          <img src="assets/images/feature_image3.png" div data-aos="fade-right" data-aos-duration="1500" data-aos-delay="1500">
        </div>
      </div>
      <div class="col-md-12 col-lg-6 col-xl-5 col-sm-12">
        <div class="parent-container" data-aos="zoom-out-up" data-aos-duration="3000" data-aos-delay="1500">
          <div class="text-container">
            <h6>{{'STATITICS' | translate}}</h6>
            <h5 class="title-green-md">{{'Get your live report on dashboard' | translate}}</h5>
            <p class="para-green-md">{{'statics-test' | translate}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="custom-container container2">
    <div class="row">
      <div class="col-md-12 col-lg-6 col-xl-5 col-sm-12 element1">
        <div class="parent-container" data-aos="zoom-out-up" data-aos-duration="3000" data-aos-delay="1000">
          <div class="text-container">
            <h6>ASSURES</h6>
            <h5 class="title-green-md">{{'Gain management and monitoring autonomy' | translate}}</h5>
            <p class="para-green-md">{{'ASSURES-text' | translate}}</p>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-lg-6 col-xl-5 col-sm-12 element2">
        <div class="image-container">
          <img src="assets/images/feature_image4.png" data-aos="fade-left" data-aos-duration="2000" data-aos-delay="1000">
        </div>
      </div>
    </div>
  </div>
</section>
<app-footer></app-footer>
<section class="modal-section">
  <!-- Modal -->
  <div class="modal fade-scale" id="signinModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" (click)="closeSignInModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <div class="modal-image-container">
            <h5 class="modal-title title-medium" id="exampleModalLongTitle">{{'CCPS' | translate}}</h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                     </button> -->
          </div>
          <div id="signin_as" class="text-container">
            <h5 class="title-medium">{{'Sign In As' | translate}}</h5>
            <p>{{'Select Choice' | translate}}</p>
            <div class="button-container">
              <button type="button" class="btn btn-main" (click)="showSignInForm(2)">{{'Employee' | translate}}</button>
              <h6>{{'or' | translate}}</h6>
              <button type="button" class="btn btn-main" (click)="showSignInForm(1)">{{'Employer' | translate}}</button>
            </div>
          </div>
          <div id="signin" class="text-container">
            <h5 class="title-medium">{{'Sign In' | translate}}</h5>
            <!-- <p>{{'Login to your Account' | translate}}</p> -->
            <form class="form-container" [formGroup]="signInForm" (ngSubmit)="onSubmitSignIn()">
              <div class="input-container">
                <div class="child-container" *ngIf="employeeSignin">
                  <label class="labeltxt">{{'Employee CCPS ID' | translate}}</label>
                  <input type="text" name="ccps_id" formControlName="ccps_id">
                  <div *ngIf="isSubmitted && g.ccps_id.errors" class="invalid-feedback">
                    <div *ngIf="g.ccps_id.errors?.required">{{'Employee CCPS ID'|translate}} {{'is required'|translate}}</div>
                    <div *ngIf="g.ccps_id.errors?.pattern">{{'Employee CCPS ID'|translate}} {{'should be valid' | translate}}</div>
                  </div>
                </div>
                <div class="child-container" *ngIf="!employeeSignin">
                  <label class="labeltxt">{{'Mobile' | translate}}</label>
                  <input type="tel" name="phone" formControlName="phone" maxlength="8" minlength="8">
                  <div *ngIf="isSubmitted && g.phone.errors" class="invalid-feedback">
                    <div *ngIf="g.phone.errors?.required">{{'Mobile'|translate}} {{'is required'|translate}}</div>
                    <div *ngIf="g.phone.errors?.pattern || g.phone.hasError('minlength')">{{'Mobile' | translate}} {{'should be valid' | translate}}</div>
                  </div>
                </div>
                <div class="child-container">
                  <label class="labeltxt">{{'Password' | translate}}</label>
                  <div>
                    <input type="password" name="Password" id="password2" formControlName="password">
                    <span class="eye-icon" (click)="eyeclose('3')"><i id="eye2" class="fa fa-eye" aria-hidden="true"></i></span>
                  </div>
                  <div *ngIf="isSubmitted && g.password.errors" class="invalid-feedback">
                    <div *ngIf="g.password.errors?.required">{{'Password'|translate}} {{'is required'|translate}}</div>
                  </div>
                </div>
              </div>
              <button type="submit" id="submit_btn" class="btn btn-main">{{'Sign In' | translate}}</button>
            </form>
            <p *ngIf="!employeeSignin" class="small-txt">{{'No Account' | translate}} <button id="show-signup" (click)="showSignup()">{{'Sign Up New' | translate}}</button></p>
          </div>
          <div id="otpSignIn" class="text-container">
            <h5 class="title-medium">{{'Sign In' | translate}}</h5>
            <p>{{'Enter your OTP for sign in' | translate}}</p>
            <form class="form-container" [formGroup]="otpFormSignIn" (ngSubmit)="onSubmitSignInOtp()">
              <div class="otp-container">
                <div class="otp-input signInOtp">
                  <input type="tel" formControlName="otp1" #otpInputs maxlength="1" minlength="1" class="input" />
                  <input type="tel" formControlName="otp2" #otpInputs maxlength="1" minlength="1" class="input" />
                  <input type="tel" formControlName="otp3" #otpInputs maxlength="1" minlength="1" class="input" />
                  <input type="tel" formControlName="otp4" #otpInputs maxlength="1" minlength="1" class="input" />
                </div>
                <div *ngIf="isSubmitted">
                  <div *ngIf="i.otp1.errors?.required || i.otp2.errors?.required || i.otp3.errors?.required || i.otp4.errors?.required" class="invalid-feedback">{{'OTP'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="(i.otp1.value || i.otp2.value || i.otp3.value || i.otp4.value) && (i.otp1.errors?.pattern || i.otp2.errors?.pattern || i.otp3.errors?.pattern || i.otp4.errors?.pattern)" class="invalid-feedback">{{'OTP'|translate}} {{'is not valid'|translate}}</div>
                </div>
              </div>
              <button type="submit" id="submit_btn" class="btn btn-main">{{'Sign In' | translate}}</button>
            </form>
            <p *ngIf="!employeeSignin" class="small-txt">{{'No Account' | translate}} <button id="show-signup" (click)="showSignup()">{{'Sign Up New' | translate}}</button></p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
  <!-- Modal 2 -->
  <div class="modal fade-scale" id="signupModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" (click)="closeSignUpModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <div class="modal-image-container">
            <h5 class="modal-title title-medium" id="exampleModalLongTitle">CCPS</h5>
            <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true">&times;</span>
                     </button> -->
          </div>
          <div id="signup" class="text-container">
            <h5 class="title-medium">{{'Sign Up test' | translate}}</h5>
            <p>{{'Create your Account' | translate}}</p>
            <form class="form-container" [formGroup]="signUpForm" (ngSubmit)="onSubmitSignUp()">
              <div class="input-container">
                <div class="child-container">
                  <label for="first_name" class="labeltxt">{{'First name' | translate}}</label>
                  <input type="text" name="first_name" formControlName="first_name">
                  <div *ngIf="isSubmitted && f.first_name.errors" class="invalid-feedback">
                    <div *ngIf="f.first_name.errors?.required">{{'First name'|translate}} {{'is required'|translate}}</div>
                    <div *ngIf="f.first_name.errors?.pattern">{{'First name'|translate}} {{'should be valid' | translate}}</div>
                  </div>
                </div>
                <div class="child-container">
                  <label class="labeltxt">{{'Last name' | translate}}</label>
                  <input type="text" name="last_name" formControlName="last_name">
                  <div *ngIf="isSubmitted && f.last_name.errors" class="invalid-feedback">
                    <div *ngIf="f.last_name.errors?.required">{{'Last name'|translate}} {{'is required'|translate}}</div>
                    <div *ngIf="f.last_name.errors?.pattern">{{'Last name'|translate}} {{'should be valid' | translate}}</div>
                  </div>
                </div>
                <div class="child-container">
                  <label class="labeltxt">Email</label>
                  <input type="text" name="email" formControlName="email">
                  <div *ngIf="isSubmitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors?.required">{{'Email'|translate}} {{'is required'|translate}}</div>
                    <div *ngIf="f.email.errors.email || f.email.errors.pattern">{{'Email'|translate}} {{'should be valid' | translate}}</div>
                    <!-- <div *ngIf="">{{'Email'|translate}} {{'should be valid' | translate}}</div> -->
                  </div>
                </div>
                <div class="child-container">
                  <label class="labeltxt">{{'Mobile' | translate}}</label>
                  <input type="tel" name="number" formControlName="mobile" maxlength="8" minlength="8">
                  <div *ngIf="isSubmitted && f.mobile.errors" class="invalid-feedback">
                    <div *ngIf="f.mobile.errors?.required && f.mobile.value === ''">{{'Mobile'|translate}} {{'is required'|translate}}</div>
                    <div *ngIf="f.mobile.hasError('pattern')">{{'Mobile'|translate}} {{'should be valid' | translate}}</div>
                    <div *ngIf="f.mobile.hasError('minlength') && !f.mobile.hasError('pattern')">{{ 'Mobile' | translate }} {{ 'must Contain 8 Numbers' | translate }}</div>
                  </div>
                </div>
                <div class="child-container">
                  <label class="labeltxt">{{'Password' | translate}}</label>
                  <div>
                    <input type="password" name="number" id="password" formControlName="password">
                    <span class="eye-icon" (click)="eyeclose('1')"><i id="eye" class="fa fa-eye" aria-hidden="true"></i></span>
                  </div>
                  <div *ngIf="isSubmitted && f.password.errors" class="invalid-feedback">
                    <div *ngIf="f.password.errors?.required">{{'Password'|translate}} {{'is required'|translate}}</div>
                    <div *ngIf="f.password.errors.minlength">{{'Password must be at least 8 characters'|translate}}</div>
                    <div *ngIf="f.password.errors?.pattern ">{{'password should be valid' | translate}}</div>
                  </div>
                </div>
                <div class="child-container">
                  <label class="labeltxt">{{'Confirm Password' | translate}}</label>
                  <div>
                    <input type="password" name="number" id="password1" formControlName="confirmPassword">
                    <span class="eye-icon" (click)="eyeclose('2')"><i id="eye1" class="fa fa-eye" aria-hidden="true"></i></span>
                  </div>
                  <div *ngIf="isSubmitted && f.confirmPassword.errors" class="invalid-feedback">
                    <div *ngIf="f.confirmPassword.errors?.required">{{'Confirm Password'|translate}} {{'is required'|translate}}</div>
                    <div *ngIf="f.confirmPassword.errors.ConfirmPassword">{{'Passwords must match' | translate}}</div>
                  </div>
                </div>
              </div>
              <button id="submit_btn" type="submit" class="btn btn-main">{{'Sign Up' | translate}}</button>
            </form>
            <p class="small-txt">{{'Already have Account?' | translate}} <button id="show-signin" (click)="showSignin()">{{'Sign In here' | translate}}</button></p>
          </div>
          <div id="otp" class="text-container">
            <h5 class="title-medium">{{'Otp title' | translate}}</h5>
            <p>{{'Enter your OTP for sign up' | translate}}</p>
            <form class="form-container" [formGroup]="otpForm" (ngSubmit)="onSubmitOtp()">
              <div class="otp-container">
                <div class="otp-input">
                  <input type="tel" formControlName="otp1" maxlength="1" minlength="1" class="input" />
                  <input type="tel" formControlName="otp2" maxlength="1" minlength="1" class="input" />
                  <input type="tel" formControlName="otp3" maxlength="1" minlength="1" class="input" />
                  <input type="tel" formControlName="otp4" maxlength="1" minlength="1" class="input" />
                </div>
                <div *ngIf="isSubmitted">
                  <div *ngIf="h.otp1.errors?.required || h.otp2.errors?.required || h.otp3.errors?.required || h.otp4.errors?.required" class="invalid-feedback">{{'OTP'|translate}} {{'is required'|translate}}</div>
                  <div *ngIf="(h.otp1.value || h.otp2.value || h.otp3.value || h.otp4.value) && (h.otp1.errors?.pattern || h.otp2.errors?.pattern || h.otp3.errors?.pattern || h.otp4.errors?.pattern)" class="invalid-feedback">{{'OTP'|translate}} {{'is not valid'|translate}}</div>
                </div>
              </div>
              <button type="submit" id="submit_btn" class="btn btn-main">{{'Sign Up' | translate}}</button>
            </form>
            <p class="small-txt">{{'Already have Account?' | translate}} <button id="show-signin" (click)="showSignin()">{{'Sign In here' | translate}}</button></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<app-contact-floating></app-contact-floating>
