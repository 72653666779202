import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class ApiService {


  constructor(private httpService: HttpClient) {}

  private post(url, postData) {
    return this.httpService.post < any > (url, postData)
      .pipe(map(data => {
        return data;
      }));
  }

  getValidationStatus() {
    let validation = true
    return validation;
  }

  get(url, options = null) {
    if (options) {
      var headerOptions = this.setHeaderOptions(options);
    }
    return this.httpService.get < any > (url, headerOptions);
  }

  delete(url, id) {
    return this.httpService.delete(url + id);
  }

  signUp(data) {
    return this.post(`${environment.apiUrl}auths/create-employer`, data);
  }
  salaryUpdate(data) {
    return this.post(`${environment.apiUrl}accounts`, data);
  }
  otp(data) {
    return this.post(`${environment.apiUrl}auths/account-validate-otp`, data);
  }
  loginOtp(data) {
    return this.post(`${environment.apiUrl}auths/validate-otp`, data);
  }
  login(data) {
    return this.post(`${environment.apiUrl}accounts`, data);
  }
  postlogOut(data) {
    // return this.post(`${environment.apiUrl}accounts`, data);
  }
  linkBusiness(data) {
    return this.post(`${environment.apiUrl}employers/migrate-anpi`, data);
  }
  getEmployeeDetails(data) {
    return this.post(`${environment.apiUrl}accounts`, data);
  }
  getAccountDetails() {
    return this.get(`${environment.apiUrl}accounts`);
  }
  employeeDetails(data) {
    return this.post(`${environment.apiUrl}employees/create-employee`, data);
  }
  employeesBulkUpload(data) {
    return this.post(`${environment.apiUrl}employees/bulk-upload`, data);
  }
  employeeSalary(data) {
    return this.post(`${environment.apiUrl}accounts`, data);
  }
  dependenceDetails(data) {
    return this.post(`${environment.apiUrl}employees/add-salary`, data);
  }
  getSalaryDetails(uuid) {
    return this.get(`${environment.apiUrl}employees/` + uuid);
  }
  getEmployeeSalaryRevise(uuid){
     return this.get(`${environment.apiUrl}employees/salary-list/` + uuid);
     // return this.get(`${environment.apilocalUrl}salarylist.json`);
  }
  getEmployeeList() {
    return this.get(`${environment.apiUrl}employees`);
  }
  getNotfication() {
    return this.get(`${environment.apiUrl}notifications`);
  }
  getFaqList() {
    return this.get(`${environment.apiUrl}faqs`);
  }
  getcnssReports() {
    return this.get(`${environment.apilocalUrl}data.json`);
  }
  bankDetails(data) {
    return this.post(`${environment.apiUrl}companies/bank-details-add`, data);
  }
  companyDetails(data) {
    return this.post(`${environment.apiUrl}companies/create-company`, data);
  }
  getCompanyDetails() {
    return this.get(`${environment.apiUrl}ccps/employer-form-list`);
  }
  getProfileDetails() {
    return this.get(`${environment.apilocalUrl}accounts`);
  }
  getBankDetails() {
    return this.get(`${environment.apilocalUrl}accounts`);
  }
  cnssApprovalForm(data) {
    return this.post(`${environment.apiUrl}ccps/employer-form`, data);
  }
  mobileMoneyDetails(data) {
    return this.post(`${environment.apiUrl}accounts`, data);
  }
  contactDetails(data) {
    return this.post(`${environment.apiUrl}contacts`, data);
  }
  getDependenceDetails(id) {
    return this.get(`${environment.apiUrl}employees/list-nominie/` + id);
  }
  resetPassword(data) {
    return this.post(`${environment.apiUrl}accounts/change-password`, data);
  }
  getCompanylist() {
    return this.get(`${environment.apiUrl}employees/employee-experiance`);
     // return this.get(`${environment.apilocalUrl}experience.json`);
  }
  getBanks() {
    console.log("125")
    return this.get(`${environment.apilocalUrl}bank.json`);
  }
  initiateReport(data) {
    return this.post(`${environment.apiUrl}accounts`, data);
  }
  getEmployeeDetailswithccpsId(data) {
    return this.post(`${environment.apiUrl}ccps`, data);
  }
  getCnssReportonQuarter(data) {
    return this.post(`${environment.apiUrl}ccps/generate-report`, data);
  }
  submitDependantsDetails(data) {
    return this.post(`${environment.apiUrl}employees/nominies`, data);
  }
  getBankList() {
    return this.get(`${environment.apiUrl}companies`); //bank list api
  }
  getidTypeList() {
    return this.get(`${environment.apiUrl}identity-types`);
  }
  upload(data) {
    return this.post(`${environment.apiUrl}employees/upload`, data);
  }
  updateEmployeewithID(data) {
    return this.post(`${environment.apiUrl}employees/employee-submit`, data);
  }
  resignEmployee(data) {
    return this.post(`${environment.apiUrl}employees/resignation`, data);
  }
  getCountryList() {
    return this.get(`${environment.apiUrl}countries`);
  }
  getRegionList() {
    return this.get(`${environment.apiUrl}regions`);
  }
  getDistrictData() {
    return this.get(`${environment.apiUrl}districts`);
  }
  getDependantsList() {
    return this.get(`${environment.apiUrl}employees/employee-nominies`);
  }
  getCityList(region) {
    return this.get(`${environment.apiUrl}cities/` + region);
  }
  getDistrictList(city) {
    return this.get(`${environment.apiUrl}districts/` + city);
  }
  getNeighbourhoodList() {
    return this.get(`${environment.apiUrl}neighborhoods`);
  }
  getCnssreportList() {
    return this.get(`${environment.apiUrl}ccps/list-report`);
  }
  getReportsbyID(id) {
    return this.get(`${environment.apiUrl}ccps-reports/cnss-report/` + id);
  }
  salaryDetails(data) {
    return this.post(`${environment.apiUrl}employees/add-salary`, data);
  }
  submitCNSSreport(data) {
    return this.post(`${environment.apiUrl}ccps/submit-report`, data);
  }
  dependenceUpload(data) {
    return this.post(`${environment.apiUrl}employees/nominie-upload`, data);
  }
  getDependenceData(id) {
    return this.get(`${environment.apiUrl}employees/nominie-edit/` + id);
  }
  getReportlist() {
    return this.get(`${environment.apiUrl}employees/manage-cnss`);
     // return this.get(`${environment.apilocalUrl}cnsslist.json`)
  }
 
  getCnssPdf() {
    return this.get(`${environment.apiUrl}ccps/report-export`);
  }
  resubmitReport(data) {
    return this.post(`${environment.apiUrl}ccps/resubmit-report`, data);
  }

  setHeaderOptions(paramsArray) {
    let headers = new HttpHeaders();

    for (var key in paramsArray) {
      if (paramsArray.hasOwnProperty(key)) {
        headers = headers.append(key, paramsArray[key]);
      }
    }
    var headerArray = { headers };
    return headerArray;
  }

  getFormValidation(form) {
    Object.keys(form.controls).forEach(controlName => {
      form.get(controlName).setValidators(null);
      form.get(controlName).updateValueAndValidity();
    });
    return form;
  }
}
