<section class="footer-section">
    <div class="footer-top">
        <div class="footer-main">
            <div class="row">
                <div class="col-md-8 col-lg-6 col-xl-6 col-sm-12 offset-xl-1">
                    <div class="txt-container">
                        <a class="logo-block" href="index.html">CCPS</a>
                     <p>{{'footer text' | translate}}</p>
                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-xl-2 col-sm-12 offset-xl-1">
                    <div class="parent-container">
                        <div class="txt-container">
                            <ul>
                                 <li *ngIf="isBusiness"> <a  routerLink="/dashboard"  class="hvr-efx1" [ngClass]="{ 'active': url == 'dashboard'}" >{{'Dashboard' | translate}}</a> </li>
                                <li *ngIf="isBusiness"><a *ngIf = "login == '1'" [ngClass]="{ 'active': url == 'employees'}" class="active" routerLink="/employees">{{'Employees' | translate}}</a></li>
                                <li *ngIf="isBusiness"><a  *ngIf = "login == '1'" routerLink="/reports">{{'CNSS' | translate}} </a></li>
                                 <li *ngIf="isBusiness"><a *ngIf = "login == '1'" (click)="getCnamgs()" [ngClass]="{ 'active': url == 'cnss'}">{{'CNAMGS' | translate}}</a> </li>
                                 <li>
                                  <a *ngIf = "login == '2'" routerLink="/dependence">{{'Dependence' | translate}}</a>
                                </li>
                                <li>
                                  <a routerLink="/experience" *ngIf = "login == '2'" >{{'Experience' | translate}}</a>
                                </li>
                                <li>
                                  <a  routerLink="/cnss"  *ngIf = "login == '2'" >{{'CNSS' | translate}}</a>
                                </li>
                                <li>
                                  <a (click)="getCnamgsemployee()"  *ngIf = "login == '2'">{{'CNAMGS' | translate}}</a>
                                </li>
                                <li *ngIf="!isBusiness && login != '2'"><a routerLink="/link-business">{{'Link Business' | translate}}</a></li>
                                 <li><a routerLink="/contact-us">{{'Contact' | translate}}</a></li>
                            </ul>
                        </div>
                        <!-- <div class="txt-container">
                            <ul>
                                <li>
                                    <a href="">Contact</a>
                                </li>
                                <li>
                                    <a href="">Employer</a>
                                </li>
                                <li>
                                    <a href="">CNSS</a>
                                </li>
                                <li>
                                    <a href="">CNAMGS</a>
                                </li>
                            </ul>
                        </div> -->
                    </div>
                </div>
                <div class="col-md-4 col-lg-4 col-xl-3 col-sm-12 offset-xl-1">
                    <div class="txt-container">
                       
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="footer-bottom">
        <div class="footer-main">
        <div class="parent-container">
            <div class="txt-container">
                <h6>{{'All rights reserved. 2024.' | translate}}</h6>
            </div>


            <a href="https://clikafrikgroup.com/" target="_blank">
                <div class="txt-container">
                    <h6>{{'Powered by' | translate}}</h6><img src="assets/images/clikafrik-logo.png" alt="">
                </div>
            </a>
        </div>
          


        </div>
     
 </div>
</section>