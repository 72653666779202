<div class="container-fluid employer_dash onboarding_section">
  <!-- import business starts -->
  <div *ngIf="!isBusiness" class="parent-container inactive-card" [ngClass]="{'dNone': url != 'dashboard'}" (click)="goToSection('business-inactive')">
    <div class="grid-container">
      <div class="child-container">
        <div class="icon-container">
          <div class="circle red">
            <div class="icon">
              <img src="assets/images/brief.png">
            </div>
          </div>
        </div>
      </div>
      <div class="child-container">
        <div class="text-container">
          <h3>{{'Import Business From GNI' | translate}}</h3>
          <p class="para-txt">{{'Import Business description' | translate}}</p>
        </div>
      </div>
      <div class="child-container">
        <div class="status-container">
          <h3 class="status-txt">{{'Import Business' | translate}}</h3>
          <i class="fa-solid fa-caret-right"></i>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isBusiness" class="parent-container active-card" [ngClass]="{'dNone': url != 'dashboard'}">
    <div class="grid-container">
      <div class="child-container">
        <div class="icon-container">
          <div class="circle green">
            <div class="icon">
              <img src="assets/images/brief.png">
            </div>
          </div>
        </div>
      </div>
      <div class="child-container">
        <div class="text-container">
          <h3>{{'Import Business From GNI' | translate}}</h3>
          <p class="para-txt">{{'Import Business description' | translate}}</p>
        </div>
      </div>
      <div class="child-container">
        <div class="status-container">
          <div class="icon-container">
            <img src="assets/images/tickwithcircle.png">
          </div>
          <h3>{{'Done' | translate}}</h3>
        </div>
      </div>
    </div>
  </div>
  <!-- import business ends -->
  <!-- import bank starts -->
  <div *ngIf="!isBank" class="parent-container inactive-card" (click)="goToSection('bank-inactive')">
    <div class="grid-container">
      <div class="child-container">
        <div class="icon-container">
          <div class="circle red">
            <div class="icon">
              <img src="assets/images/bank.png">
            </div>
          </div>
        </div>
      </div>
      <div class="child-container">
        <div class="text-container">
          <h3>{{'Insert your bank, microfinance or payment details (Mobile Money)' | translate}}</h3>
          <p class="para-txt">{{'Add bank description' | translate}}</p>
        </div>
      </div>
      <div class="child-container">
        <div class="status-container">
          <h3 class="status-txt">{{'Add Bank Details'| translate}}</h3>
          <i class="fa-solid fa-caret-right"></i>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isBank" class="parent-container active-card" [ngClass]="{'dNone': isBank && url != 'dashboard'}">
    <div class="grid-container">
      <div class="child-container">
        <div class="icon-container">
          <div class="circle green">
            <div class="icon">
              <img src="assets/images/bank.png">
            </div>
          </div>
        </div>
      </div>
      <div class="child-container">
        <div class="text-container">
          <h3>{{'Insert your bank, microfinance or payment details (Mobile Money)' | translate}}</h3>
          <p class="para-txt">{{'Add bank description' | translate}}</p>
        </div>
      </div>
      <div class="child-container">
        <div class="status-container">
          <div class="icon-container">
            <img src="assets/images/tickwithcircle.png">
          </div>
          <h3>{{'Done' | translate}}</h3>
        </div>
      </div>
    </div>
  </div>
  <!-- import bank ends -->
  <!-- add employee starts -->
  <div *ngIf="!isEmployees" class="parent-container inactive-card" [ngClass]="{'dNone': (url === 'reports' || url === 'cnamgs-report') || (isEmployees && url =='employees') || (isBank && url =='employees') }" (click)="goToSection('add-Employee-inactive')">
    <div class="grid-container">
      <div class="child-container">
        <div class="icon-container">
          <div class="circle red">
            <div class="icon">
              <img src="assets/images/management.png">
            </div>
          </div>
        </div>
      </div>
      <div class="child-container">
        <div class="text-container">
          <h3>{{'Add Employee Details' | translate}}</h3>
          <p class="para-txt">{{'Add Employee Description' | translate}}</p>
        </div>
      </div>
      <div class="child-container">
        <div class="status-container">
          <h3 class="status-txt">{{'Add Employee' | translate}}</h3>
          <i class="fa-solid fa-caret-right"></i>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isEmployees" class="parent-container active-card" [ngClass]="{'dNone': (url === 'reports' || url === 'cnamgs-report') || (isEmployees && url =='employees'
        )}" (click)="showModal()">
    <div class="grid-container">
      <div class="child-container">
        <div class="icon-container">
          <div class="circle green">
            <div class="icon">
              <img src="assets/images/management.png">
            </div>
          </div>
        </div>
      </div>
      <div class="child-container">
        <div class="text-container">
          <h3>{{'Add Employee Details' | translate}}</h3>
          <p class="para-txt">{{'Add Employee Description' | translate}}</p>
        </div>
      </div>
      <div class="child-container">
        <div class="status-container">
          <div class="icon-container">
            <img src="assets/images/tickwithcircle.png">
          </div>
          <h3>{{'Done' | translate}}</h3>
        </div>
      </div>
    </div>
  </div>
  <!-- add employee ends -->
  <!--  CNAMGS starts -->
  <div *ngIf="!isCnamgs" class="parent-container inactive-card" (click)="goToSection('cnamgs-inactive')" [ngClass]="{'dNone': url == 'employees' || url == 'reports'}">
    <div class="grid-container">
      <div class="child-container">
        <div class="icon-container">
          <div class="circle red">
            <div class="icon">
              <img src="assets/images/approve.png">
            </div>
          </div>
        </div>
      </div>
      <div class="child-container">
        <div class="text-container">
          <h3>{{'Get CNAMGS Approval' | translate}}</h3>
          <p class="para-txt">{{'Get CNAMGS Approval description' | translate}}</p>
        </div>
      </div>
      <div class="child-container">
        <div class="status-container">
          <h3 class="status-txt">{{'Get Approval' | translate}}</h3>
          <i class="fa-solid fa-caret-right"></i>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isCnamgs" class="parent-container active-card" [ngClass]="{'dNone': url == 'employees' || url == 'reports' || isCnamgs && url=='cnamgs-report'}">
    <div class="grid-container">
      <div class="child-container">
        <div class="icon-container">
          <div class="circle green">
            <div class="icon">
              <img src="assets/images/approve.png">
            </div>
          </div>
        </div>
      </div>
      <div class="child-container">
        <div class="text-container">
          <h3>{{'Get CNAMGS Approval' | translate}}</h3>
          <p class="para-txt">{{'Get CNAMGS Approval description' | translate}}</p>
        </div>
      </div>
      <div class="child-container">
        <div class="status-container">
          <div class="icon-container">
            <img src="assets/images/tickwithcircle.png">
          </div>
          <h3>{{'Done' | translate}}</h3>
        </div>
      </div>
    </div>
  </div>
  <!--  CNAMGS ends -->
  <!--  CNSS starts -->
  <div *ngIf="accountDetails.cnss == 0 " class="parent-container inactive-card" [ngClass]="{'dNone': url == 'employees' || url == 'cnamgs-report'}" (click)="goToSection('cnss-inactive')">
    <div class="grid-container">
      <div class="child-container">
        <div class="icon-container">
          <div class="circle red">
            <div class="icon">
              <img src="assets/images/approve.png">
            </div>
          </div>
        </div>
      </div>
      <div class="child-container">
        <div class="text-container">
          <h3>{{'Get CNSS Approval' | translate}}</h3>
          <p class="para-txt">{{'Get CNSS Approval description' | translate}}</p>
        </div>
      </div>
      <div class="child-container">
        <div class="status-container">
          <h3 class="status-txt">{{'Get Approval' | translate}}</h3>
          <i class="fa-solid fa-caret-right"></i>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="accountDetails.cnss == 2 " class="parent-container pending-card" [ngClass]="{'dNone': url == 'employees' || url == 'cnamgs-report'}">
    <div class="grid-container">
      <div class="child-container">
        <div class="icon-container">
          <div class="circle yellow">
            <div class="icon">
              <img src="assets/images/approve_black.png">
            </div>
          </div>
        </div>
      </div>
      <div class="child-container">
        <div class="text-container">
          <h3>{{'CNSS Approval is Pending' | translate}}</h3>
          <p class="para-txt">{{'Get CNSS Approval description' | translate}}</p>
        </div>
      </div>
      <div class="child-container">
        <div class="status-container">
          <!-- <h3 class="status-txt">{{'Approval Pending' | translate}}</h3> -->
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="accountDetails.cnss == 1 " class="parent-container active-card" [ngClass]="{'dNone': (url === 'employees' || url == 'cnamgs-report') || isCnss && url=='reports'} ">
    <div class="grid-container">
      <div class="child-container">
        <div class="icon-container">
          <div class="circle green">
            <div class="icon">
              <img src="assets/images/approve.png">
            </div>
          </div>
        </div>
      </div>
      <div class="child-container">
        <div class="text-container">
          <h3>{{'Get CNSS Approval' | translate}}</h3>
          <p class="para-txt">{{'Get CNSS Approval description' | translate}}</p>
        </div>
      </div>
      <div class="child-container">
        <div class="status-container">
          <div class="icon-container">
            <img src="assets/images/tickwithcircle.png">
          </div>
          <h3>{{'Done' | translate}}</h3>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="accountDetails.cnss == 3 " class="parent-container rejected-card" (click)="goToSection('cnss-inactive')" [ngClass]="{'dNone': (url === 'employees' || url == 'cnamgs-report') || isCnss && url=='reports'} ">
    <div class="grid-container">
      <div class="child-container">
        <div class="icon-container">
          <div class="circle red">
            <div class="icon">
              <img src="assets/images/approve.png">
            </div>
          </div>
        </div>
      </div>
      <div class="child-container">
        <div class="text-container">
          <h3>{{'CNSS Approval is Rejected' | translate}}</h3>
          <p class="para-txt">{{'Get CNSS Approval description' | translate}}</p>
        </div>
      </div>
      <div class="child-container">
        <div class="status-container">
          <h3 class="status-txt">{{'Resubmit Approval Form' | translate}}</h3>
          <i class="fa-solid fa-caret-right"></i>
        </div>
      </div>
    </div>
  </div>
</div>
<section class="modal-section2 bulk-upload-modal getstarted">
  <div class="modal fade" id="onBoarding_modal" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" (click)="closeModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <div class="parent">
            <div class="image-container">
              <img src="assets/images/link_success.png">
            </div>
            <div class="text-container text-center mt-5">
              <h4 class="modal-header-txt">{{'Inorder to Continue' | translate}}</h4>
              <p class="para-light">{{'You must first validate the previous step before proceeding to this step.' | translate }}</p>
            </div>
            <div class="button-container justify-content-center mt-4 d-flex">
              <button type="submit" id="submit_btn" class="btn btn-medium" (click)="closeModal1()">{{'OK' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="modal-section2 bulk-upload-modal getstarted">
  <div class="modal fade" id="employeeSuccessModal" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <div class="parent">
            <div class="image-container">
              <img src="assets/images/link_success.png">
            </div>
            <div class="text-container d-flex text-center mt-5 justify-content-center">
              <div class="child-container">
                <h4 class="modal-header-txt">{{'Employees' | translate}}</h4>
                <p class="para-light">{{'CCPS identifier' | translate }}</p>
              </div>
            </div>
            <div class="button-container justify-content-center mt-4 d-flex">
              <button type="submit" id="submit_btn" class="btn btn-medium" data-dismiss="modal">{{'OK' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="modal-section2 bulk-upload-modal getstarted">
  <div class="modal fade" id="addEmployeeConfirmModal" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content">
        <button type="button" class="close close-modal" [attr.data-title]="'Close' | translate" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <div class="parent">
            <div class="image-container">
              <img src="assets/images/link_success.png">
            </div>
            <div class="text-container d-flex text-center mt-5 justify-content-center">
              <div class="child-container">
                <h4 class="modal-header-txt">{{'Employees' | translate}}</h4>
                <p class="para-light">{{'addEmployeeRules1' | translate }} <br>{{'addEmployeeRules2' | translate }}</p>
              </div>
            </div>
            <div class="button-container justify-content-center mt-4 d-flex">
              <button type="submit" id="submit_btn" class="btn btn-medium" (click)="goToSection('add-employee-modal')">{{'OK' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
