import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthenticationService } from '../_services/authentication.service'
@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log("sdfsf")
        const currentUser = this.authenticationService.currentUserValue;
        var child = route.firstChild;
        if (currentUser) {
            if (child.data.state == 'approval-form' || child.data.state == 'link-business' || child.data.state == 'cnamgs-form') {
                const canActivate = this.getActive(child.data.state);
                if (!canActivate) {
                  this.router.navigate(['/dashboard']);
                } else {
                  return true;
                }
            } else {
              return true;
            }
          // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
          this.router.navigate(['/']);
          return false;
    }
  }

    getActive(val) {
        const currentuserDeatils = JSON.parse(localStorage.getItem('currentUserDetails'));
        console.log("currentuserDeatils", currentuserDeatils)
        switch (val) {
            case 'approval-form':
            case 'cnamgs-form':
              if (!currentuserDeatils || currentuserDeatils.BankDetails === 0) {
                return false; 
              } else {
                if (val == 'approval-form') {
                    if (currentuserDeatils.cnss === 0 || currentuserDeatils.cnss === 3 ) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (currentuserDeatils.cnamgs === 0) {
                        return true;
                    } else {
                        return false;
                    }
                }
              }
            case 'link-business':
              if (!currentuserDeatils || currentuserDeatils.GNI === 1) {
                return false;
              } else {
                return true;
              }
            default:
              return true;
          }
    }
}