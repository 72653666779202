 <a href="https://api.whatsapp.com/send?phone=+24177942629&text=Bonjour,%20je%20suis%20intéressé%20par%20les%20produits CCPS" class="float" target="_blank">
	<i class="fab fa-whatsapp my-float"></i>
</a>
<a href="tel:+24177942629" class="tel">
	<i class="fas fa-phone my-float"></i>
</a> 
<!--  <a href="" class="float" target="_blank">
	<i class="fab fa-whatsapp my-float"></i>
</a>
<a href="" class="tel">
	<i class="fas fa-phone my-float"></i>
</a> -->
