import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '../../../../_services/translate.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../_services/api.service';
import { AnpiService } from '../../../../_services/anpi-code.service';
import { first } from 'rxjs/operators';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  login: any;
  url: any;
  currentLang: any;
  employee = false;
  accountDetails: any;
  currentUser: any;
  isBusiness = false;
  public languageForm: FormGroup;
  constructor(private ngZone: NgZone,
    private router: Router,
    private translate: TranslateService,
    private api: ApiService,
    private formBuilder: FormBuilder,
    private anpiService: AnpiService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    const data = localStorage.getItem('currentUserDetails');
    if (data) {
      this.accountDetails = JSON.parse(data);
      this.login = this.accountDetails.current_user;
      console.log("36 login value:", this.login)
      if (this.login == "1") {
        this.employee = false;
      } else {
        this.employee = true;
      }
      if (this.accountDetails.GNI == "1") {
        this.isBusiness = true;
      }
    }
    this.anpiService.accountEvent.subscribe(() => {
      const data = localStorage.getItem('currentUserDetails');
      console.log("33", data)
      if (data) {
        this.accountDetails = JSON.parse(data);
        this.login = this.accountDetails.current_user;
        console.log("36 login value:", this.login)
        if (this.login == "1") {
          this.employee = false;
        } else {
          this.employee = true;
        }
        if (this.accountDetails.GNI == "1") {
          this.isBusiness = true;
        }
      }
    });

    this.ngZone.run(() => {
      this.url = window.location.pathname.split('/')[1];

      console.log(this.url)
    });

    this.languageForm = this.formBuilder.group({
      lang: ['', []],
    });
    this.currentLang = localStorage.getItem('language') || "fr";
    this.languageForm.get('lang').setValue(this.currentLang);
    if (this.currentLang == 'fr') {
      $('.checkbox').prop('checked', false);
    } else {
      $('.checkbox').prop('checked', true);
    }
    if (this.currentLang == 'fr') {
      $('.myCheckbox').prop('checked', true);
      $("#lang").text("FR");
      console.log("language is french")
    } else {
      $('.myCheckbox').prop('checked', false);
      $("#lang").text("EN");
      console.log("language is english")
    }
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "305px";
    document.getElementById("main").style.marginRight = "0px";
    document.getElementById("menuOverlay").style.display = "block";

  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginRight = "0";
    document.getElementById("menuOverlay").style.display = "none";
  }


  getCnamgs() {
    this.router.navigate(['/reports/cnamgs'], { state: { data: 'cnamgs' } });
  }
  getCnamgsemployee() {
    this.router.navigate(['/cnss/cnamgs'], { state: { data: 'cnamgs' } });
  }
  setLang(lang) {
    console.log('aaa')
    // console.log(lang.target.value)
    // console.log(lang.detail.value)
    // console.log(lang)
    this.currentLang = lang;
    console.log("69", lang)
    var useLang = { language: this.currentLang };
    console.log(useLang)
    this.translate.setCurrentLangage(useLang);
    localStorage.setItem('language', lang);
    this.changeLang();
  }
  changeLang() {
    this.translate.use(this.currentLang);
    console.log(this.translate.currentLanguageValue)
  }
  checkValue(e) {
    console.log(e)
    if (e == true) {
      this.setLang('fr');
      $("#lang").text("FR");
    } else {
      this.setLang('en');
      $("#lang").text("EN");
    }
  }

  getChangelang(e) {
    if (e.target.checked == true) {
      var lang = 'en';
    } else {
      lang = 'fr';
    }
    this.setLang(lang)
  }

  getBusiness() {
    return localStorage.getItem('business');
  }

  getCnssPage() {
    if (this.accountDetails.cnss == 1) {
      this.router.navigate(['/reportList']);
    } else {
      this.router.navigate(['/reports']);
    }
  }

}
