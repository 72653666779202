     <app-header></app-header>
       	<section class="container-fluid mb-5">
       	
            <div class="initiale_sestion">
                <div class="dFlex">
                    <div class="head">
                        <h4 class="form-title-sm">{{'FAQ' | translate}}</h4>
                    </div>
                    <div class="search" (click)="showSearch()">
                        <div class="a">
                            <input type="text" placeholder="Search" id="searchInput" (keyup)="setFilteredFaq($event)" (keydown)="setFilteredFaq($event)">
                            <div id="searchStyle">
                                <i class="fa-solid fa-magnifying-glass"></i><small>{{'Search' | translate}}</small>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

        <div class="faq_list card"   *ngFor="let faqlist of faqDetails;">
            <div class="question card-header">
              <h5>
              <button class="btn btn-link">{{ faqlist.question }}
              </button>
                     <i class="fa fa-caret-down moreButton"></i>
              </h5>
            </div>  
            <div class="answer card-body" style="display: none;">
              <p>{{ faqlist.answer }}</p>
            </div>
          
            <div class="line"></div>
        </div>




        <!-- <div class="faq_list card" >
            <div class="question card-header">
              <h5>
              <button class="btn btn-link">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid  sint occaecat cupidatat non proident, sunt in idunt ut labore et dolore magna aliqua. 
              </button>
                    <i class="fa fa-caret-down moreButton"></i>
              </h5>
            </div>  
            <div class="answer card-body" style="display: none;">
              <p>Votre argent est en sécurité. Il ne réside pas sur une carte SIM mais sur votre compte CLIKPAY Money®/ BUSINESS® auquel personne ne peut accéder sans votre Mpin. L’équivalent de votre argent électronique chez CLIKPAY est positionné et garanti par UBA Gabon SA.Ne divulguez jamais votre Mpin à personne, y compris aux membres de votre famille ou à vos amis.</p>
            </div>
          
            <div class="line"></div>
        </div>



        
        <div class="faq_list card" >
            <div class="question card-header">
              <h5>
              <button class="btn btn-link">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incid  sint occaecat cupidatat non proident, sunt in idunt ut labore et dolore magna aliqua. 
              </button>
                      <i class="fa fa-caret-down moreButton"></i>
              </h5>
            </div>  
            <div class="answer card-body" style="display: none;">
              <p>Votre argent est en sécurité. Il ne réside pas sur une carte SIM mais sur votre compte CLIKPAY Money®/ BUSINESS® auquel personne ne peut accéder sans votre Mpin. L’équivalent de votre argent électronique chez CLIKPAY est positionné et garanti par UBA Gabon SA. Ne divulguez jamais votre Mpin à personne, y compris aux membres de votre famille ou à vos amis.</p>
            </div>
          
            <div class="line"></div>
        </div> -->
    


       	</section>
            <app-footer></app-footer>
<app-contact-floating></app-contact-floating>