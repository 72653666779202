<app-header></app-header>
<section *ngIf="!showDetails" id="dependenceCreate" class="creation-form">
  <div class="container-fluid">
    <div class="text-container">
      <div class="head">
        <div class="d-flex align-items-center">
          <a (click)="goback()"><i class="fa fa-angle-left"></i></a>
          <h5 class="form-title">{{'Enter dependence Details' | translate}}</h5>
        </div>
      </div>
      <form id="dependence-form" [formGroup]="dependenceForm" (ngSubmit)="onSubmitDependence()">
        <div class="input-container form-input">
          <div class="child-container">
            <label class="labeltxt">{{'What is the nature of dependence?' | translate}}</label>
            <select class="bootstrap-select" formControlName="relation">
              <option value="0" disabled [selected]="true">{{'What is the nature of dependence?' | translate}}</option>
              <option value="1">{{'Spouse list' | translate}}</option>
              <option value="2">{{'Child' | translate}}</option>
              <option value="3">{{'Mother' | translate}}</option>
              <option value="4">{{'Father' | translate}}</option>
            </select>
            <div *ngIf="isSubmitted && f.relation.errors" class="invalid-feedback">
              <div *ngIf="f.relation.errors?.required">{{'Nature of dependence'|translate}} {{'is required'|translate}}</div>
            </div>
          </div>
          <div class="child-container">
            <label class="labeltxt">{{'First Name' | translate}}</label>
            <input class="" type="text" name="firstName" formControlName="firstName">
            <div *ngIf="isSubmitted && f.firstName.errors" class="invalid-feedback">
              <div *ngIf="f.firstName.errors?.required">{{'First Name'|translate}} {{'is required'|translate}}</div>
              <div *ngIf="f.firstName.errors?.pattern">{{'First Name'|translate}} {{'should be valid'|translate}}</div>
            </div>
          </div>
          <div class="child-container">
            <label class="labeltxt">{{'Last Name' | translate}}</label>
            <input class="" type="text" name="lastName" formControlName="lastName">
            <div *ngIf="isSubmitted && f.lastName.errors" class="invalid-feedback">
              <div *ngIf="f.lastName.errors?.required">{{'Last Name'|translate}} {{'is required'|translate}}</div>
              <div *ngIf="f.lastName.errors?.pattern">{{'Last Name'|translate}} {{'should be valid'|translate}}</div>
            </div>
          </div>
          <div class="child-container">
            <label class="labeltxt">{{'Mobile Number' | translate}}</label>
            <input class="" type="tel" name="mob" formControlName="mob" maxlength="8" minlength="8">
            <div *ngIf="isSubmitted && f.mob.errors" class="invalid-feedback">
              <div *ngIf="f.mob.errors?.required">{{'Mobile Number'|translate}} {{'is required'|translate}}</div>
              <div *ngIf="f.mob.errors?.pattern">{{'Mobile Number'|translate}} {{'should be valid'|translate}}</div>
              <div *ngIf="f.mob.hasError('minlength')">{{ 'Mobile' | translate }} {{ 'must Contain 8 Numbers' | translate }}</div>
              <div *ngIf="f.mob.hasError('maxlength')">{{ 'Mobile' | translate }} {{ 'must Contain 8 Numbers' | translate }}</div>
            </div>
          </div>
          <div class="child-container" [owlDateTimeTrigger]="dt42">
            <label class="labeltxt">{{'Date of Birth' | translate}}</label>
            <div class="date-field">
              <input class="" [max]="todayDate" [owlDateTime]="dt42" readonly name="dob" formControlName="dob">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fa fa-calendar"></i></span>
              </div>
            </div>
            <owl-date-time format="d B Y" [pickerType]="'calendar'" #dt42></owl-date-time>
            <div *ngIf="isSubmitted && f.dob.errors" class="invalid-feedback">
              <div *ngIf="f.dob.errors?.required">{{'Date of Birth'|translate}} {{'is required'|translate}}</div>
            </div>
          </div>
          <div class="child-container">
            <label class="labeltxt">{{'Gender' | translate}}</label>
            <select class="bootstrap-select" formControlName="gender">
              <option value="0" disabled [selected]="true">{{'Gender' | translate}}</option>
              <option value="1">{{'Male' | translate}}</option>
              <option value="2">{{'Female' | translate}}</option>
              <option value="3">{{'Other' | translate}}</option>
            </select>
            <div *ngIf="isSubmitted && f.gender.errors" class="invalid-feedback">
              <div *ngIf="f.gender.errors?.required">{{'Gender'|translate}} {{'is required'|translate}}</div>
            </div>
          </div>
          <div class="child-container">
            <label class="labeltxt">{{'Marital Status' | translate}}</label>
            <select class="bootstrap-select" formControlName="marital_status">
              <option value="0" disabled [selected]="true">{{'Marital Status' | translate}}</option>
              <option value="1">{{'Spouse' | translate}}</option>
              <option value="2">{{'Unmarried' | translate}}</option>
            </select>
            <div *ngIf="isSubmitted && f.marital_status.errors" class="invalid-feedback">
              <div *ngIf="f.marital_status.errors?.required">{{'Marital Status'|translate}} {{'is required'|translate}}</div>
            </div>
          </div>
          <div class="child-container">
            <label class="labeltxt">{{'Occupation' | translate}}</label>
            <select class="bootstrap-select" formControlName="occupation">
              <option value="0" disabled [selected]="true">{{'Occupation' | translate}}</option>
              <option value="1">{{'Self Employed' | translate}}</option>
              <option value="2">{{'Employeees' | translate}}</option>
              <option value="3">{{'Employee manager' | translate}}</option>
              <option value="4">{{'Non-employee manager' | translate}}</option>
              <option value="5">{{'Auto entrepreneur' | translate}}</option>
              <option value="6">{{'Liberal profession' | translate}}</option>
              <option value="7">{{'Civil servant' | translate}}</option>
              <option value="8">{{'Without activity' | translate}}</option>
              <option value="9">{{'Others' | translate}}</option>
            </select>
            <div *ngIf="isSubmitted && f.occupation.errors" class="invalid-feedback">
              <div *ngIf="f.occupation.errors?.required">{{'Occupation'|translate}} {{'is required'|translate}}</div>
            </div>
          </div>
          <div class="child-container">
            <label for="dependencePhoto" class="labeltxt">{{'Upload Photo' | translate}}</label>
            <label for="dependencePhoto" class="file-upload-card labeltxt">
              <div class="custom-container">
                <!-- <img src="assets/images/upload_icon.png"> -->
                <p class="text-center">{{'Upload image or drop a file' | translate}}</p>
                <i class="fa fa-upload" aria-hidden="true"></i>
              </div>
            </label>
            <input class="" id="dependencePhoto" type="file" name="companyName" formControlName="imageFile" (change)="upload('dependencePhoto', 'photoFileName', $event, 'profile_photo')" data-target="profile_photo">
            <a id="photoFileName" class="viewFile text-center" href="{{employee_Photo}}" target="_blank"></a>
            <div *ngIf="isSubmitted && f.profile_photo.errors" class="invalid-feedback">
              <div *ngIf="f.profile_photo.errors?.required">{{'Upload Photo'|translate}} {{'is required'|translate}}</div>
            </div>
          </div>
          <div class="child-container">
            <label for="dependenceIdFrontPhoto" class="labeltxt">{{'Upload ID Front Photo' | translate}}</label>
            <label for="dependenceIdFrontPhoto" class="file-upload-card labeltxt">
              <div class="custom-container">
                <!-- <img src="assets/images/upload_icon.png"> -->
                <p class="text-center">{{'Upload image or drop a file' | translate}}</p>
                <i class="fa fa-upload" aria-hidden="true"></i>
              </div>
            </label> 
            <input class="" id="dependenceIdFrontPhoto" type="file" name="companyName" formControlName="imageFile" (change)="upload('dependenceIdFrontPhoto', 'frontPhotoName', $event, 'id_front_photo')" data-target="id_front_photo">
            <a id="frontPhotoName" href="{{id_Front_photo}}" class="viewFile text-center" target="_blank"></a>
            <div *ngIf="isSubmitted && f.id_front_photo.errors" class="invalid-feedback">
              <div *ngIf="f.id_front_photo.errors?.required">{{'Upload ID Front Photo'|translate}} {{'is required'|translate}}</div>
            </div>
          </div>
          <div class="child-container">
            <label for="dependenceIdBackPhoto" class="labeltxt">{{'Upload ID Back Photo' | translate}}</label>
            <label for="dependenceIdBackPhoto" class="file-upload-card labeltxt">
              <div class="custom-container">
                <!-- <img src="assets/images/upload_icon.png"> -->
                <p class="text-center">{{'Upload image or drop a file' | translate}}</p>
                <i class="fa fa-upload" aria-hidden="true"></i>
              </div>
            </label>
            <input class="" id="dependenceIdBackPhoto" type="file" data-target="id_back_photo" name="companyName" formControlName="imageFile" (change)="upload('dependenceIdBackPhoto', 'backPhotoName', $event, 'id_back_photo')">
            <a id="backPhotoName" href="{{id_Back_Photo}}" class="viewFile text-center" target="_blank"></a>
            <div *ngIf="isSubmitted && f.id_back_photo.errors" class="invalid-feedback">
              <div *ngIf="f.id_back_photo.errors?.required">{{'Upload ID Back Photo'|translate}} {{'is required'|translate}}</div>
            </div>
          </div>
        </div>
        <button type="submit" id="submit_btn" class="btn btn-main2">{{'Next' | translate}}</button>
      </form>
    </div>
  </div>
</section>
<section *ngIf="showDetails" id="dependenceDetails">
  <div class="container-fluid">
    <div class="text-container" *ngIf="dependenceData">
      <div class="custom-container">
        <div class="backarrow">
          <a (click)="showForm(1)" *ngIf="!uuid"><i class="fa fa-angle-left"></i></a>
          <h4>{{ dependenceData.name }}</h4>
        </div>
        <i class="fa-solid fa-pen-to-square" (click)="editForm()"><span>{{'Correction' | translate}}</span></i>
        <p></p>
      </div>
      <div class="input-container">
        <div class="child-container">
          <label class="labeltxt">{{'First Name' | translate}} :</label>
          <h6 class="labeltxt">{{ dependenceData.name }}</h6>
        </div>
        <div class="child-container">
          <label class="labeltxt">{{'Date of Birth' | translate}} :</label>
          <h6 class="labeltxt">{{ dependenceData.dob }}</h6>
        </div>
        <div class="child-container">
          <label class="labeltxt">{{'Gender' | translate}} :</label>
          <h6 class="labeltxt">{{ dependenceData.gender }}</h6>
        </div>
        <div class="child-container">
          <label class="labeltxt">{{'Marital Status' | translate}} :</label>
          <h6 class="labeltxt">{{ dependenceData.marital_status }}</h6>
        </div>
        <div class="child-container">
          <label class="labeltxt">{{'Occupation' | translate}} :</label>
          <h6 class="labeltxt">{{ dependenceData.occupation }}</h6>
        </div>
        <div class="child-container">
          <label class="labeltxt">{{'Mobile Number' | translate}} :</label>
          <h6 class="labeltxt">{{ dependenceData.mobile }}</h6>
        </div>
        <div class="image-container">
          <div class="child-container">
            <label class="labeltxt">{{'Photo' | translate}} :</label>
            <div class="image-child">
              <img src="{{ dependenceData.profile_photo }}">
            </div>
            <!-- <a href="{{ dependenceData.employee_Photo }}"  class="viewFile text-center" target="_blank">Click here to view the image</a> -->
          </div>
          <div class="child-container">
            <label class="labeltxt">{{'ID Front' | translate}} :</label>
            <div class="image-child">
              <img src="{{ dependenceData.id_front_photo }}">
            </div>
            <!-- <a href="{{ dependenceData.id_Front_photo }}"  class="viewFile text-center" target="_blank">Click here to view the image</a> -->
          </div>
          <div class="child-container">
            <label class="labeltxt">{{'ID Back' | translate}} :</label>
            <div class="image-child">
              <img src="{{ dependenceData.id_back_photo }}">
            </div>
            <!-- <a href="{{ dependenceData.id_Back_photo }}"  class="viewFile text-center" target="_blank">Click here to view the image</a> -->
          </div>
        </div>
      </div>
      <button type="button" id="submit_btn" class="btn btn-main2" (click)="submitDependantsDetails()" *ngIf="!uuid">{{'Done modal' | translate}}</button>
      <button type="button" id="submit_btn" class="btn btn-main2" (click)="showForm(2)" *ngIf="uuid">{{'Back' | translate}}</button>
    </div>
  </div>
</section>
<app-footer></app-footer>
<app-contact-floating></app-contact-floating>
