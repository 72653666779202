import { DatePipe, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
declare var $: any;
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
import { AnpiService } from '../../../_services/anpi-code.service';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-employees',
  templateUrl: './employees.component.html',
  styleUrls: ['./employees.component.scss']
})
export class EmployeesComponent implements OnInit {

  selectedDate: Date = new Date();
  formattedDate: string;
  employeeWithCcps: any;
  employeeList = true;
  salaryRevise = false;
  public employeeWithCcpsForm1: FormGroup;
  isSubmitted = false;
  isSubmit = false;
  isSubmit2 = false;
  showFailedTitle = false;
  showSuccessTitle = false;
  currentLang: any;
  employeeDetails: any;
  bulkUploadDetails: any;
  response: any;
  imageUrl: any;
  allList: any;
  selectedValue = 0;
  accountDetails: any;
  title: any;
  public languageForm: FormGroup;
  public withCcpsForm: FormGroup;
  public CcpsStage1Form: FormGroup;
  public CcpsStage2Form: FormGroup;
  public resignationForm: FormGroup;
  public EmpolyeeBulkUploadForm: FormGroup;
  page: any;
  public todayDate: any = new Date();
  responseupload: any;
  id_Front_Photo: any;
  id_Back_Photo: any;
  employee_Photo: any;
  employeeWithCcpsStage1 = false;
  responseemployee: any;
  responselist: any;
  employeesallList: any;
  responsedetails: any;
  employeeData: any;
  idtype: any;
  idtypelist: any;
  employeespendingList: any;
  idArray: any;
  pendingEmployeeCount = 0;
  employeeDetail: any;
  rsubmitted = false;
  responseresign: any;
  searchList: any;
  bulkUploadSuccess :any;
  bulkUploadFailed :any;
  calculateMinDate(): Date {
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 40);
    return minDate;
  }
  constructor(private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private anpiService: AnpiService,
    private datePipe: DatePipe,
    private ngxUiLoaderService: NgxUiLoaderService,
    private location: Location,
    private snackBar: MatSnackBar) {

    const data = localStorage.getItem('currentUserDetails');
    if (data) {
      this.accountDetails = JSON.parse(data);
      console.log(this.accountDetails)
    }
    this.checkLocalStorage();
    // const data = localStorage.getItem('employees');
    // if (data == '1') {
    //   this.employeeList = false;
    // }
    if (this.router.getCurrentNavigation().extras.state) {
      const data = this.router.getCurrentNavigation().extras.state.show_add_Employee
      if (data == 1) {
        setTimeout(() => {
          $('#addEmployeeModal').modal('show');
        }, 500);
      }
    }

  }

  ngOnInit() {
    console.log("58", this.selectedDate)
    this.anpiService.changeEvent.subscribe(() => {
      console.log('inside employees emit event')
      this.checkLocalStorage();
    });

    this.languageForm = this.formBuilder.group({
      lang: ['', []],
    });
    this.withCcpsForm = this.formBuilder.group({
      ccpsid: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$")]],
    });
    this.CcpsStage1Form = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z]+$")]],
      last_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z]+$")]],
      dob: ['', [Validators.required]],
      mobile: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(8), Validators.maxLength(8)]],
      address: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      company_id: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$")]],
      email: ['', [Validators.required, Validators.email, Validators.pattern("^[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{1,4}$")]],
      cnss: [''],
      cnamgs: [''],
      id_type: ['', [Validators.required]],
      id_number: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$")]],
      imageFile: ['', []],
      employee_Photo: ['', [Validators.required]],
      id_Front_Photo: ['', [Validators.required]],
      id_Back_Photo: ['', [Validators.required]],
      joindate: ['', [Validators.required]],
      position: ['', [Validators.required, Validators.pattern("^(?! )[a-zA-Z0-9 ]+$")]],
      base_salary: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      salary: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    });
    this.CcpsStage2Form = this.formBuilder.group({
      dob: ['', [Validators.required]],
      position: ['', [Validators.required, Validators.pattern("^(?! )[a-zA-Z0-9 ]+$")]],
      base_salary: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      salary: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
    });
    this.employeeWithCcpsForm1 = this.formBuilder.group({
      first_name: ['', []],
      last_name: ['', []],
    });
    this.resignationForm = this.formBuilder.group({
      date_of_resignation: ['', [Validators.required]],
      reason: ['', [Validators.required]],
      reason_type: ['', []],
    });
    this.EmpolyeeBulkUploadForm = this.formBuilder.group({
      bulkUpload: ['', [Validators.required]]
    })
    if (!this.api.getValidationStatus()) {
      // this.api.getFormValidation(this.withCcpsForm);
      this.api.getFormValidation(this.CcpsStage1Form);
      this.api.getFormValidation(this.CcpsStage2Form);
      this.api.getFormValidation(this.employeeWithCcpsForm1);
    }
    this.getEmployeeList(1);
    $(document).ready(() => {
      $("#WithCcpsForm1").click(() => {
        this.idTypeList();
        $('#addEmployeeModal').modal('hide');
        setTimeout(function() {
          $('#employeeWithCcpsModal').modal('show');
        }, 500);
        console.log("450")
        this.employeeWithCcps = true;
        console.log(this.employeeWithCcps)
      });
      $("#WithoutCcpsForm1").click(() => {
        console.log("1755555")
        this.idTypeList();
        $('#addEmployeeModal').modal('hide');
        setTimeout(() => {
          //$('#employeeWithCcpsStage1').modal('show');
          this.employeeWithCcpsStage1 = true;
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }, 500);
        console.log("700")
        this.employeeWithCcps = false;
        console.log(this.employeeWithCcps)
      });
    });

    this.currentLang = localStorage.getItem('language') || "fr";
    console.log("104", this.currentLang)
  }

  checkLocalStorage() {
    if (this.accountDetails && this.accountDetails.GNI == 0) {
      this.title = 'business';
      this.employeeList = true;
      console.log("138", this.title)
      setTimeout(function() {
        $('#addEmployeeContainer').css('display', 'none');
      }, 100);
    } else if (this.accountDetails.GNI && this.accountDetails.BankDetails == 0) {
      this.title = 'bank';
      this.employeeList = true;
      console.log("143", this.title)
      setTimeout(function() {
        $('#addEmployeeContainer').css('display', 'none');
      }, 100);
    } else if (this.accountDetails.GNI && this.accountDetails.BankDetails && this.accountDetails.EmployeeDetails == 0) {
      this.employeeList = true;
      this.title = 'employees';
      console.log("148", this.title)
      setTimeout(function() {
        $('#addEmployeeContainer').css('display', 'flex');
      }, 100);
    }
  }
  closeSuccessModal() {
    this.getEmployeeList(2);
    $('#addEmployeeSuccessModal').modal('hide');
  }
  closeModal(val=null) {
    console.log("215")
    this.withCcpsForm.reset();
    this.CcpsStage1Form.reset();
    this.CcpsStage2Form.reset();
    this.resignationForm.reset();
    this.isSubmitted = false;
    this.isSubmit = false;
    this.isSubmit2 = false;
    if (val) {
      this.getEmployeeList(3);
    }
    console.log("38")
    $('#addEmployeeModal').modal('hide');
    $('#employeeWithCcpsModal').modal('hide');
    $('#employeeWithCcpsStage1').modal('hide');
    $('#employeeWithCcpsStage2').modal('hide');
    $('#employeeResignModal').modal('hide');
    $('#bulkUpload').modal('hide');
    $('#allEmployeeModal').modal('hide');
    $('#bulkUploadSuccesssModal').modal('hide');

    this.makeInputEditable();
    document.getElementById('idFrontPhoto').innerText = '';
    document.getElementById('idBackPhoto').innerText = '';
    document.getElementById('employeePhoto').innerText = '';
  }
  onSubmitEmployeeStage1() {
    console.log(this.withCcpsForm.value)
    this.isSubmitted = true;
    if (this.withCcpsForm.invalid) {
      return;
    }
    var data = this.withCcpsForm.value;
    this.ngxUiLoaderService.start();
    this.api.getEmployeeDetailswithccpsId(data).pipe(first())
      .subscribe(data => {
          this.isSubmitted = false;
          this.ngxUiLoaderService.stop();
          this.responsedetails = data;
          if (this.responsedetails.status == 200) {
            this.employeeData = this.responsedetails.results;
            this.setEmployeeDetailsValues();
            //this.makeInputReadOnly();
            $('#employeeWithCcpsModal').modal('hide');
              this.withCcpsForm.reset();
             this.employeeWithCcpsStage1 = true;

          } else {
            this.snackBar.open(this.responsedetails.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
          this.isSubmitted = false;
        });
  }

  onSubmitEmployeeStage2() {
    this.isSubmit = true;
    console.log("121", this.CcpsStage1Form.value);
    console.log("121", this.CcpsStage1Form);
    if (this.CcpsStage1Form.invalid) {
      return;
    }
    var data = this.CcpsStage1Form.value;
    delete data['imageFile'];
    data['dob'] = this.datePipe.transform(data.dob, 'dd-MM-yyyy');
    const editedDate = this.datePipe.transform(data.joindate, 'dd-MM-yyyy');
    data['joindate'] = this.convertOwl(editedDate)
    console.log("277", data.joindate)
    console.log("278", editedDate)

    console.log("280", data.joindate)
    console.log("271", data.dob)
    this.ngxUiLoaderService.start();
    this.api.employeeDetails(data).pipe(first())
      .subscribe(data => {
        this.isSubmit = false;
          this.ngxUiLoaderService.stop();
          this.responseemployee = data
          if (this.responseemployee.status == 200) {
            this.getEmployeeList(2);
          } else {
          }
          this.snackBar.open(this.responseemployee.message, "Dismiss", {
            duration: 2000,
          });
        },
        error => {
           this.isSubmit = false;
          this.ngxUiLoaderService.stop();
        });

    // $('#employeeWithCcpsStage1').modal('hide');

  }
  bulkUpload(event: any, id): void {
    console.log("2250", event)
    console.log("2251", event.target.files[0])
    const fileNameDisplay = document.getElementById(id);
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log('uploaded image: ', file)
      const filename = file.name;
      var ext = filename.split('.').pop().toLowerCase();
      console.log("304", ext)
      if (file.size > 5242880) {

        if (this.currentLang == 'fr') {
          alert('La taille du fichier doit être inférieure à 5MB')
        } else {
          alert('File Size should be less than 5MB')
        }
        return;
      }
      if (ext != 'csv') {
        console.log("295")
        if (this.currentLang == 'fr') {
          alert('Type de fichier non autorisé. Les types de fichier autorisés sont .csv')
        } else {
          alert('File type not allowed. Allowed file types are .csv')
        }
        return;
      }
      console.log("303")
      this.ngxUiLoaderService.start();
      const target = event.target.dataset.target;
      console.log(event)
      const formData = new FormData();
      formData.append('csvFile', file);
      console.log("308", formData)

      this.api.employeesBulkUpload(formData).pipe(first())
        .subscribe(data => {
            this.ngxUiLoaderService.stop();
            this.response = data;
            if (this.response.status == 200) {
              this.EmpolyeeBulkUploadForm.reset();
              console.log("351", this.bulkUploadDetails = this.response)
              this.bulkUploadSuccess = this.response.successData
              this.bulkUploadFailed = this.response.failedData
              if(this.bulkUploadFailed && this.bulkUploadFailed.length > 0){
                console.log("361")
                this.showFailedTitle = true
              }
              if(this.bulkUploadSuccess&& this.bulkUploadSuccess.length > 0){
                 console.log("365")
                this.showSuccessTitle = true
              }
              $('#bulkUpload').modal('hide');

              setTimeout(()=>{
                 $('#bulkUploadSuccesssModal').modal('show');
               },500)
               
              // this.router.navigate(['/dashboard']);
              this.snackBar.open(data.message, "dismiss", {
                duration: 2000,
              });
            } else {
              this.EmpolyeeBulkUploadForm.reset();
              this.snackBar.open(data.message, "dismiss", {
                duration: 2000,
              });
            }

          },
          error => {});

    }
  }

  upload(event: any, id): void {
    console.log("2250", event)
    console.log("2251", event.target.files[0])
    const fileNameDisplay = document.getElementById(id);
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      console.log('uploaded image: ', file)
      const filename = file.name;
      var ext = filename.split('.').pop();
      if (file.size > 5242880) {

        if (this.currentLang == 'fr') {
          alert('La taille du fichier doit être inférieure à 5MB')
        } else {
          alert('File Size should be less than 5MB')
        }
        return;
      }
      if (ext != 'jpg' && ext != 'png' && ext != 'jpeg') {
        if (this.currentLang == 'fr') {
          alert('Type de fichier non autorisé. Les types de fichier autorisés sont  .jpg .png .jpeg ')
        } else {
          alert('File type not allowed. Allowed file types are .jpg .png .jpeg ')
        }
        return;
      }

      this.ngxUiLoaderService.start();
      const target = event.target.dataset.target;
      console.log(target)
      const formData = new FormData();
      formData.append('image', file);
      formData.append('target', target);
      console.log("file is :", file)
      console.log("target is :", target)
      // formData['encrypt_mode'] = false;
      // formData.append('uuid', this.businessData.business_code);
      this.api.upload(formData).pipe(first())
        .subscribe(data => {
            this.ngxUiLoaderService.stop();
            this.responseupload = data;
            if (this.responseupload.status == 200) {
              if (target == 'id_Front_Photo') {
                this.CcpsStage1Form.get('id_Front_Photo').setValue(this.responseupload.results.file);
                this.id_Front_Photo = this.responseupload.results.imageUrl;
              }
              if (target == 'id_Back_Photo') {
                this.CcpsStage1Form.get('id_Back_Photo').setValue(this.responseupload.results.file);
                this.id_Back_Photo = this.responseupload.results.imageUrl;
              }
              if (target == 'employee_Photo') {
                this.CcpsStage1Form.get('employee_Photo').setValue(this.responseupload.results.file);
                this.employee_Photo = this.responseupload.results.imageUrl;
              }
            }
          },
          error => {

          });

      if (this.currentLang == 'fr') {
        fileNameDisplay.textContent = `Cliquez pour voir le fichier`;
      } else {
        console.log("file name to display", fileNameDisplay.textContent)
        if (id == 'bulkUpload') {
          $('#bulkUpload').modal('hide');
          this.employeeList = false;
        } else {
          fileNameDisplay.textContent = `Click to view File`;
        }
      }

    }
  }

  onSubmitEmployeeSalary() {

    this.isSubmit2 = true;
    if (this.CcpsStage2Form.invalid) {
      return;
    }
    console.log("447")
    var data = this.CcpsStage1Form.value;
    this.ngxUiLoaderService.start();
    this.api.employeeSalary(data).pipe(first())
      .subscribe(data => {
          // this.ngxUiLoaderService.stop();
          if (this.response.status == 200) {

            // this.snackBar.open(res.message, "dismiss", {
            //      duration: 2000,
            //   });
            // this.router.navigate(['/dashboard']);
          } else {
            // this.snackBar.open(res.message, "dismiss", {
            //      duration: 2000,
            //   });
          }
        },
        error => {
          // this.ngxUiLoaderService.stop();
        });
    //  localStorage.setItem('employees',"1");
    //   this.anpiService.emitEvent();
    //  window.scrollTo({ top: 0, behavior: 'smooth' }); 
    $('#employeeWithCcpsStage2').modal('hide');
    setTimeout(() => {
      console.log("469")
      $('#allEmployeeModal').modal('show');
    }, 500)
    //  this.employeeList = false;  

  }

  submitEmployees() {
    console.log(this.idArray)
    if (this.idArray.length == 0) {
      return
    }
    var data = {
      employee_id: this.idArray
    }
    this.ngxUiLoaderService.start();
    this.api.updateEmployeewithID(data).pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          this.response = data;
          if (this.response.status == 200) {
            localStorage.setItem('employees', "1");
            this.anpiService.emitEvent();
            window.scrollTo({ top: 0, behavior: 'smooth' });
            $('#allEmployeeModal').modal('hide');
            console.log("modal hide at 494")
            this.employeeList = false;
            this.employeeWithCcpsStage1 = false;
            $('#addEmployeeSuccessModal').modal('show');
          } else {
            this.snackBar.open(this.response.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }

  getEmployeeList(val) {
    console.log("515")
    this.ngxUiLoaderService.start();
    this.api.getEmployeeList().pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          this.responselist = data;
          if (this.responselist.status == 200) {
            this.employeesallList = this.responselist.result.results;
            this.searchList = this.responselist.result.results;
            if(this.employeesallList) {
              this.pendingEmployeeCount = this.employeesallList.filter(item => item.status == '0').length;

              console.log("549 this.pendingEmployeeCount", this.pendingEmployeeCount)
              if (this.employeesallList.length) {
                this.employeeList = false;
              }
            }
            this.employeespendingList = this.responselist.pending;
            if (this.employeespendingList.length != 0) {
              this.idArray = this.employeespendingList.map(item => item.id);
              console.log(this.idArray)
              if (val != 3) {
                $('#allEmployeeModal').modal('show');
              }
            }
          } else {
            // this.snackBar.open(this.responselist.message, "dismiss", {
            //   duration: 2000,
            // });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });

  }
  setEmployeeDetailsValues() {
    this.CcpsStage1Form.controls['first_name'].setValue(this.employeeData.first_name);
    this.CcpsStage1Form.controls['last_name'].setValue(this.employeeData.last_name);
    this.CcpsStage1Form.controls['dob'].setValue(this.employeeData.dob);
    this.CcpsStage1Form.controls['mobile'].setValue(this.employeeData.mobile);
    this.CcpsStage1Form.controls['address'].setValue(this.employeeData.address);
    this.CcpsStage1Form.controls['company_id'].setValue(this.employeeData.company_id);
    this.CcpsStage1Form.controls['email'].setValue(this.employeeData.email);
    this.CcpsStage1Form.controls['cnss'].setValue(this.employeeData.cnss);
    this.CcpsStage1Form.controls['id_type'].setValue(this.employeeData.id_type);
    this.CcpsStage1Form.controls['id_number'].setValue(this.employeeData.id_number);
    this.CcpsStage1Form.controls['base_salary'].setValue(this.employeeData.base_salary);
    this.CcpsStage1Form.controls['joindate'].setValue(this.employeeData.join_date);
    this.CcpsStage1Form.controls['salary'].setValue(this.employeeData.salary);
    this.CcpsStage1Form.controls['position'].setValue(this.employeeData.designation);
    this.CcpsStage1Form.controls['cnamgs'].setValue(this.employeeData.cnamgs);

    this.CcpsStage1Form.controls['id_Front_Photo'].setValue(this.employeeData.image_Front);
    this.CcpsStage1Form.controls['id_Back_Photo'].setValue(this.employeeData.image_Back);
    this.CcpsStage1Form.controls['employee_Photo'].setValue(this.employeeData.profile_Image);
    this.id_Back_Photo = this.employeeData.image_back;
    this.id_Front_Photo = this.employeeData.image_front;
    this.employee_Photo = this.employeeData.image;
    console.log("569")
    const photoProperties = [
      { elementId: 'idFrontPhoto', apiValue: this.employeeData.image_Front },
      { elementId: 'idBackPhoto', apiValue: this.employeeData.image_Back },
      { elementId: 'employeePhoto', apiValue: this.employeeData.profile_Image }
    ];

    for (const property of photoProperties) {
      const element = document.getElementById(property.elementId);

      if (property.apiValue) {
        element.textContent = this.currentLang === 'fr' ?
          `Cliquez pour voir le fichier` :
          `Click to view File`;
      }
    }
  }

  makeInputReadOnly() {
    Object.keys(this.CcpsStage1Form.controls).forEach(controlName => {
      this.CcpsStage1Form.get(controlName).disable();
      console.log("422")
    });
  }
  makeInputEditable() {
    Object.keys(this.CcpsStage1Form.controls).forEach(controlName => {
      this.CcpsStage1Form.get(controlName).enable();
      console.log("input are editable")
    });
  }
  showAddEmployee(val: any) {
    console.log("172")
    if (val == "ccps_id") {

      $('#employeeWithCcpsModal').modal('show');
    } else {
      $('#employeeWithCcpsStage1').modal('show');
    }
  }
  downloadExcel() {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleString().replace(/[\/\:\,\s]/g, '_');
    const filePath = '/assets/data/employee_bulk_upload.csv';
    const fileName = `${filePath}_${formattedDate}.csv`;

    const link = document.createElement('a');
    link.href = filePath;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    console.log(link)
    console.log("file name :", fileName)
  }

  get f() {
    return this.withCcpsForm.controls;
  }
  get g() {
    return this.CcpsStage1Form.controls;
  }
  get h() {
    return this.CcpsStage2Form.controls;
  }
  get a() {
    return this.resignationForm.controls;
  }

  goback() {
    this.isSubmitted = false;
    this.isSubmit = false;
    this.isSubmit2 = false;
    this.CcpsStage1Form.reset();
    this.location.back();
  }

  addmore() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    this.withCcpsForm.reset();
    this.CcpsStage1Form.reset();
    this.CcpsStage2Form.reset();
    this.selectedValue = 0;
    console.log("651")
    this.isSubmitted = false;
    this.isSubmit = false;
    this.isSubmit2 = false;
    this.id_Back_Photo = null;
    this.id_Front_Photo = null;
    this.employee_Photo = null;


    const elementIds = ['idFrontPhoto', 'idBackPhoto', 'employeePhoto'];

    for (const elementId of elementIds) {
      const element = document.getElementById(elementId);
      if (element) {
        element.textContent = '';
      }
    }

    $('#employeeWithCcpsStage2').modal('hide');
    $('#allEmployeeModal').modal('hide');
    setTimeout(() => {
      $('#addEmployeeModal').modal('show');
    }, 500)
  }

  convertOwl(str) {
    if (str == null)
      return str;
    var mnths = {
        Jan: "01",
        Feb: "02",
        Mar: "03",
        Apr: "04",
        May: "05",
        Jun: "06",
        Jul: "07",
        Aug: "08",
        Sep: "09",
        Oct: "10",
        Nov: "11",
        Dec: "12"
      },

      date = str.toString().split(" ");
    if (date.length == 1)
      return str;
    else
      return [date[2], mnths[date[1]], date[3]].join("-");
  }

  idTypeList() {
    this.api.getidTypeList().pipe(first())
      .subscribe(data => {
          this.idtype = data;
          if (this.idtype.status == 200) {
            this.idtypelist = this.idtype.results;
            console.log("696")
            this.CcpsStage1Form.reset();
            this.CcpsStage1Form.get('id_type').setValue(null);
          }
        },
        error => {});
  }

  gobacklist() {
    if (this.employeesallList.length) {
      this.employeeList = false;
      this.employeeWithCcpsStage1 = false;
    } else {
      this.employeeWithCcpsStage1 = false;
    }
  }

  onChange(value, checked) {
    console.log(value)
    if (checked == false) {
      if (this.idArray.includes(value)) {
        this.idArray = this.idArray.filter(id => id !== value);
        console.log(this.idArray)
      }
    } else {
      this.idArray.push(value);
    }
  }

  resignEmployee(employee) {
    this.employeeDetail = employee;
    $('#employeeResignModal').modal('show');
  }

  onSubmitResign() {
    console.log(this.resignationForm)
    this.rsubmitted = true;
    if (this.resignationForm.invalid) {
      return;
    }
    var data = this.resignationForm.value;
    data['employee_id'] = this.employeeDetail.id;
    data['date_of_resignation'] = this.convertOwl(data.date_of_resignation)
    this.ngxUiLoaderService.start();
    this.api.resignEmployee(data).pipe(first())
      .subscribe(data => {
          this.ngxUiLoaderService.stop();
          this.responseresign = data;
          if (this.responseresign.status == 200) {
            $('#employeeResignModal').modal('hide');

          } else {

          }
          this.snackBar.open(this.responseresign.message, "dismiss", {
            duration: 2000,
          });
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }

  getEmployeeDetails(id) {
    this.router.navigate(['/salary-revise', id]);
  }

  setValidator(event) {
    console.log("737", event.target.value)
    const data = event.target.value;
    if (data == "7") {
      this.a['reason_type'].setValidators([Validators.required]);
      this.a['reason_type'].updateValueAndValidity();
    } else {
      this.a['reason_type'].clearValidators();
      this.a['reason_type'].updateValueAndValidity();
    }
  }

  setFilteredEmployees(e: any) {
    console.log(e)
    this.employeesallList = this.searchList.filter((employeeList: any) => {
      if (employeeList.designation != null) {
        return employeeList.company_id.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 || employeeList.designation.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 || employeeList.mobile.toLowerCase().indexOf(e.target.value.toLowerCase()) > -1 || employeeList.name
          .toLowerCase().indexOf(e.target.value.toLowerCase()) > -1;
      }
    });
  }
}
