 
    <section class="payment-section" [ngClass]="{'cnamgsbackground': id == 'cnamgs', 'cnssbackground': id == 'cnss'}">
        <img class="payment_bg" src="assets/images/payment_bg_mob.png">
        <div class="parent-container">
            <button data-toggle="modal" class="btn-main" data-target="#successModal">{{'Paynow' | translate}} </button>
        </div>
    </section>

    <div class="modal fade" id="successModal" data-backdrop="true" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content">
                
                <div class="modal-body">
                    <div class="parent">
                        <div class="image-container">
                            <img src="assets/images/link_success.png">
                        </div>
                        <div class="text-container text-center">
                            <h4 class="modal-header-txt">{{'Your report has been successfully submitted.'| translate}}</h4>
                            <p class="para-light">{{'review your declaration.' | translate}}</p>
                            <button id="submit_btn" class="btn btn-medium" (click)="goToViewPage()">{{'OK'| translate}}</button> 
                        </div>
                        
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
