import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '../../../_services/translate.service';
import { ApiService } from '../../../_services/api.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { first } from 'rxjs/operators';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmPasswordValidator } from '../../../_validators/confirm-password.validator';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {

  currentLang: any;
  isSubmitted = false;
  public languageForm: FormGroup;
  public signUpForm: FormGroup;
  public signInForm: FormGroup;
  public otpForm: FormGroup;
  public otpFormSignIn: FormGroup;
  currentUser: any;
  employeeSignin: any;
  login: any;
  url: any;
  employee = false;
  accountDetails: any;
  isBusiness = false;
  response: any;
  constructor(private router: Router,
              private ngZone: NgZone,
              private api: ApiService,
              private translate: TranslateService,
              private authenticationService: AuthenticationService,
              private formBuilder: FormBuilder,
              private ngxUiLoaderService: NgxUiLoaderService,
              private snackBar: MatSnackBar) {

    const data = localStorage.getItem('currentUserDetails');
    if (data) {
      this.accountDetails = JSON.parse(data);
      this.login = this.accountDetails.current_user;
      console.log("36 login value:", this.login)
      if (this.login == "1") {
        this.employee = false;
      } else {
        this.employee = true;
      }
      if (this.accountDetails.GNI == "1") {
        this.isBusiness = true;
      }
    }

    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }

  }
  ngOnInit() {
    const inputs = document.querySelectorAll(".signInOtp input");
    const otpInputs = document.querySelectorAll("#otp input");
    const allInputs = Array.from(inputs).concat(Array.from(otpInputs));

    allInputs.forEach((input, index) => {
      const inputElement = input as HTMLElement;
      inputElement.dataset.index = index.toString();

      inputElement.addEventListener("keyup", handleOtp);
      inputElement.addEventListener("paste", handleOnPasteOtp);
    });

    function handleOtp(e) {
      const input = e.target;
      let value = input.value;
      let isValidInput = value.match(/[0-9a-z]/gi);
      input.value = "";
      input.value = isValidInput ? value[0] : "";

      let fieldIndex = input.dataset.index;
      let nextElement = input.nextElementSibling;
      let prevElement = input.previousElementSibling;

      if (nextElement && fieldIndex < allInputs.length - 1 && isValidInput) {
        nextElement.focus();
      }

      if (e.key === "Backspace" && prevElement && fieldIndex > 0) {
        prevElement.focus();
      }
    }

    function handleOnPasteOtp(e) {
      const data = e.clipboardData.getData("text");
      const value = data.split("");

      // Use allInputs.length to check the length
      if (value.length === allInputs.length) {
        allInputs.forEach((input, index) => {
          const inputElement = input as HTMLInputElement;
          inputElement.value = value[index];
        });
      }
    }
    this.ngZone.run(() => {
      this.url = window.location.pathname.split('/')[1];

    });
    this.signInForm = this.formBuilder.group({
      ccps_id: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$ ")]],
      phone: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(8), Validators.maxLength(8)]],
      password: ['', [Validators.required]],
    });

    this.signUpForm = this.formBuilder.group({
      first_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z]+$")]],
      last_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z]+$")]],
      email: ['', [Validators.required, Validators.email]],
      mobile: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(8), Validators.maxLength(8)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.pattern("(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*#?&])[A-Za-z\d$@$!%*?#&].{7,}")]],
      confirmPassword: ['', [Validators.required]],
    }, { validator: ConfirmPasswordValidator.MatchPassword });
    
    this.otpForm = this.formBuilder.group({
      otp1: ['', [Validators.required]],
      otp2: ['', [Validators.required]],
      otp3: ['', [Validators.required]],
      otp4: ['', [Validators.required]],
    })

    this.otpFormSignIn = this.formBuilder.group({
      otp1: ['', [Validators.required]],
      otp2: ['', [Validators.required]],
      otp3: ['', [Validators.required]],
      otp4: ['', [Validators.required]],
    })

    if (!this.api.getValidationStatus()) {
      this.api.getFormValidation(this.otpForm);
      this.api.getFormValidation(this.signUpForm);
      this.api.getFormValidation(this.signInForm);
      this.api.getFormValidation(this.otpFormSignIn);
    }

    this.languageForm = this.formBuilder.group({
      lang: ['', []],
    });

    $('#service_slider').owlCarousel({
      // animateOut: 'slideOutUp',
      // animateIn: 'pulse',
      loop: true,
      margin: 0,
      stagePadding: 0,
      nav: false,
      dots: false,
      touchDrag: false,
      mouseDrag: false,
      // autoPlay:true,
      items: 1,
      //navText : ['<img src="assets/images/arrow-left.svg">','<img src="assets/images/arrow-right.svg">'],
      autoplay: false
    });

    var owl = $('#service_slider');
    owl.owlCarousel();

    $('.next-review-btn').click(function() {
      // slider animation
      owl.trigger('next.owl.carousel');
    });

    $('.prev-review-btn').click(function() {
      // slider animation
      owl.trigger('prev.owl.carousel');
    });
    // Splitting();
    // ScrollOut({
    //  threshold: .2,
    // });
    AOS.init({
      disable: function() {
        var maxWidth = 600;
        return window.innerWidth < maxWidth;
      }
    });

    this.currentLang = localStorage.getItem('language') || "fr";
    this.languageForm.get('lang').setValue(this.currentLang);
    if (this.currentLang == 'fr') {
      $('.checkbox').prop('checked', false);
    } else {
      $('.checkbox').prop('checked', true);
    }
    if (this.currentLang == 'fr') {
      $('.myCheckbox').prop('checked', true);
      $("#lang").text("FR");
    } else {
      $('.myCheckbox').prop('checked', false);
      $("#lang").text("EN");
    }
    console.log(this.languageForm.get('lang').value);
    // Get all select elements
    // const selectElements = document.querySelectorAll("select");

    // // Iterate over each select element
    // selectElements.forEach((selectElement) => {
    //   // Create a new custom select container
    //   const selectContainer = document.createElement("div");
    //   selectContainer.classList.add("select");

    //   // Create the custom select display element
    //   const selectStyled = document.createElement("div");
    //   selectStyled.classList.add("select-styled");
    //   selectStyled.textContent = selectElement.options[0].textContent;

    //   // Create the options list
    //   const selectOptions = document.createElement("ul");
    //   selectOptions.classList.add("select-options");

    //   // Populate the options list
    //   for (let i = 0; i < selectElement.options.length; i++) {
    //     const option = document.createElement("li");
    //     option.textContent = selectElement.options[i].textContent;
    //     option.setAttribute("rel", selectElement.options[i].value);

    //     if (selectElement.options[i].selected) {
    //       option.classList.add("is-selected");
    //     }

    //     // Handle option click event
    //     option.addEventListener("click", (e) => {
    //    console.log(e)
    //       e.stopPropagation();
    //       selectStyled.textContent = option.textContent;
    //       selectElement.value = option.getAttribute("rel");

    //       // Remove 'is-selected' class from all options and add it to the clicked option
    //       selectOptions.querySelectorAll("li.is-selected").forEach((li) => {
    //         li.classList.remove("is-selected");
    //       });
    //       option.classList.add("is-selected");

    //       selectOptions.style.display = "none";
    //       selectStyled.classList.remove("active");
    //     });

    //     selectOptions.appendChild(option);
    //   }

    //   // Handle custom select click event
    //   selectStyled.addEventListener("click", (e) => {
    //     e.stopPropagation();
    //     // Close other open custom selects
    //     document.querySelectorAll(".select-styled.active").forEach((styledSelect) => {
    //       if (styledSelect !== selectStyled) {
    //         styledSelect.classList.remove("active");
    //         (selectStyled.nextElementSibling as HTMLElement).style.display = "none";
    //       }
    //     });

    //     selectStyled.classList.toggle("active");
    //     selectOptions.style.display = selectStyled.classList.contains("active") ? "block" : "none";
    //   });

    //   // Handle document click event to close the custom select
    //   document.addEventListener("click", () => {
    //     selectStyled.classList.remove("active");
    //     selectOptions.style.display = "none";
    //   });

    //   // Hide the original select element and append the custom elements
    //   selectElement.classList.add("select-hidden");
    //   selectElement.parentNode.insertBefore(selectContainer, selectElement);
    //   selectContainer.appendChild(selectStyled);
    //   selectContainer.appendChild(selectOptions);
    // });
  }

  //   var signin = document.getElementById("signin");
  //  var signup = document.getElementById("signup");
  //  var signin_choice = document.getElementById("signin_as");
  //  var otp = document.getElementById("otp");

  // showSignin(){
  //     document.getElementById("signup").style.display = "none";
  //      document.getElementById("signin").style.display = "grid";
  // };

  showSignup() {
    this.signInForm.reset();
    document.getElementById("signin_as").style.display = "block";
    document.getElementById("signin").style.display = "none";
    document.getElementById("otpSignIn").style.display = "none";
    this.isSubmitted = false;
    $('#signinModal').modal('hide');
    console.log('498')
    setTimeout(function() {
      $('#signupModal').modal('show');

    }, 500);

  }
  onSubmitSignUp() {
    this.isSubmitted = true;
    console.log("submitted!!")
    if (this.signUpForm.invalid) {
      console.log("196", this.signUpForm)
      return;
    }
    var data = this.signUpForm.value;
    console.log("194", data)
    this.ngxUiLoaderService.start();
    this.api.signUp(data).pipe(first())
      .subscribe(res => {
          console.log("233333333333")
          this.ngxUiLoaderService.stop();
          if (res.status == 200) {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
            this.isSubmitted = false;
            document.getElementById("signup").style.display = "none";
            document.getElementById("otp").style.display = "block";
            console.log("234", this.isSubmitted)

          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });

  }
  showSignin() {
    $('#signupModal').modal('hide');
    console.log('498')
    setTimeout(function() {
      $('#signinModal').modal('show');
    }, 500);

  }
  showSignInForm(val) {
    this.currentUser = val;
    if (val == 1) {
      this.g['phone'].setValidators([Validators.required, Validators.pattern("^[0-9]*$"), , Validators.minLength(8), Validators.maxLength(8)]);
      this.g['phone'].updateValueAndValidity();
      this.employeeSignin = false;
      this.g['ccps_id'].clearValidators();
      this.g['ccps_id'].updateValueAndValidity();
    } else {
      this.g['ccps_id'].setValidators([Validators.required, Validators.pattern("[A-Za-z0-9_-]*$")]);
      this.g['ccps_id'].updateValueAndValidity();
      this.employeeSignin = true;
      this.g['phone'].clearValidators();
      this.g['phone'].updateValueAndValidity();
    }
    console.log(this.currentUser)
    document.getElementById("signin_as").style.display = "none";
    document.getElementById("signin").style.display = "block";
  }

  onSubmitOtp() {

    this.isSubmitted = true;
    if (this.otpForm.invalid) {
      console.log("254", this.otpForm)
      return;
    }
    var data = {
      mobile: this.f.mobile.value,
      otp: this.h.otp1.value + this.h.otp2.value + this.h.otp3.value + this.h.otp4.value
    };
    console.log("260", data);
    this.ngxUiLoaderService.start();
    this.api.otp(data).pipe(first())
      .subscribe(res => {

          this.ngxUiLoaderService.stop();
          if (res.status == 200) {

            this.isSubmitted = false;
            this.signUpForm.reset();
            this.otpForm.reset();
            $('#signupModal').modal('hide');
            document.getElementById("signup").style.display = "grid";
            document.getElementById("otp").style.display = "none";
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
            // localStorage.setItem('login', "employer");
            // this.router.navigate(['/get-started']);
            console.log("275");
          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }

        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }
  onSubmitSignInOtp() {

    this.isSubmitted = true;
    if (this.otpFormSignIn.invalid) {
      console.log("254", this.otpForm)
      return;
    }
    var data = {
      username: this.g.phone.value,
      otp: this.i.otp1.value + this.i.otp2.value + this.i.otp3.value + this.i.otp4.value,
      entity_type: this.currentUser
    };
    console.log("260", data);
    this.ngxUiLoaderService.start();
    this.authenticationService.loginOtp(data).pipe(first())
      .subscribe(res => {
          if (res.status == 200) {
            this.isSubmitted = false;
            this.getAccountData();
            $('#signinModal').modal('hide');
            this.otpFormSignIn.reset();
            document.getElementById("otpSignIn").style.display = "none";
            document.getElementById("signin_as").style.display = "block";
          } else {
            this.ngxUiLoaderService.stop();
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }

        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }
  getAccountData() {
    console.log("442 get account details")
    this.api.getAccountDetails().pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          console.log("44333333333333333", res)
          this.response = res.results;
          if (res.status == 200) {
            console.log("123456789")
            this.accountDetails = this.response;
            if (this.response.current_user) {
              this.currentUser = this.response.current_user;
            } else if (this.response.employee.current_user) {
              this.currentUser = this.response.employee.current_user;
            }
            this.login = this.currentUser;
            if (this.login == "1") {
              this.employee = false;
            } else {
              this.employee = true;
            }
            if (this.accountDetails.GNI == "1") {
              this.isBusiness = true;
            }
            if (this.currentUser == 1) {
              const data = JSON.stringify(this.accountDetails);
              localStorage.setItem('currentUserDetails', data);
              console.log("468", data)
              console.log("employer logged in")
              console.log("410 account details.gni", this.accountDetails.GNI)
              if (this.accountDetails && this.accountDetails.GNI == 1) {
                console.log("GNI show dashboard")
                this.router.navigate(['/dashboard']);
              } else {
                console.log("no GNI show getstarted")
                this.router.navigate(['/get-started']);
              }
            } else if (this.currentUser == 2) {
              const data = JSON.stringify(this.accountDetails.employee);
              localStorage.setItem('currentUserDetails', data);
              console.log("481", data)
              console.log("employeee logged in")
              this.router.navigate(['/dashboard']);
            }

          } else {
            console.log("no result")
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          console.log("errorrrrrrrrr")
          this.ngxUiLoaderService.stop();
        });
    console.log("no result")
  }
  closeSignUpModal() {
    this.signUpForm.reset();
    this.otpForm.reset();
    this.isSubmitted = false;
    $('#signupModal').modal('hide');
    document.getElementById("signup").style.display = "grid";
    document.getElementById("otp").style.display = "none";
  }
  closeSignInModal() {
    this.signInForm.reset();
    this.otpFormSignIn.reset();
    this.isSubmitted = false;
    $('#signinModal').modal('hide');
    document.getElementById("signin_as").style.display = "block";
    document.getElementById("signin").style.display = "none";
    document.getElementById("otp").style.display = "none";
    document.getElementById("otpSignIn").style.display = "none";
    this.g['phone'].clearValidators();
    this.g['phone'].updateValueAndValidity();
    this.g['ccps_id'].clearValidators();
    this.g['ccps_id'].updateValueAndValidity();
  }
  onSubmitSignIn() {
    this.isSubmitted = true;
    console.log("326", this.signInForm)
    console.log("306");
    if (this.signInForm.invalid) {
      return;
    }
    if (this.currentUser == 2) {
      var data = {
        username: this.g.ccps_id.value,
        password: this.g.password.value,
        entity_type: this.currentUser
      }
    } else {
      var data = {
        username: this.g.phone.value,
        password: this.g.password.value,
        entity_type: this.currentUser
      }
    }
    console.log("331", data)
    this.ngxUiLoaderService.start();
    this.authenticationService.login(data).pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          if (res.status == 200) {
            this.isSubmitted = false;
            // this.snackBar.open(res.message, "dismiss", {
            //   duration: 2000,
            // });
            console.log("368", res)
            if (this.currentUser == 1) {
              document.getElementById("signin").style.display = "none";
              document.getElementById("otpSignIn").style.display = "block";
            }
            if (this.currentUser == 2) {
              this.getAccountData();
              $('#signinModal').modal('hide');

              // localStorage.setItem('login', "employee");
            }

          } else {
            console.log("557")
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
            console.log("397 status not 200")
            this.signInForm.get('password').setValue("");
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
    this.isSubmitted = false;

  }
  showLogin() {
    console.log("589")
    if( $('#signinModal').modal('show')){
      this.signInForm.reset()
      this.isSubmitted = false;
       $('#signinModal').modal('hide');
     document.getElementById("signin_as").style.display = "block";
     document.getElementById("signin").style.display = "none";
    }
    $('#signinModal').modal('show');
  }
  showSignUp(){
    console.log("598")
    if( $('#signupModal').modal('show')){
      this.signUpForm.reset()
      this.isSubmitted = false;
       $('#signupModal').modal('hide');
    }
    $('#signupModal').modal('show');
  }
  openNav() {
    document.getElementById("mySidenav").style.width = "305px";
    document.getElementById("main").style.marginRight = "0px";
    document.getElementById("menuOverlay").style.display = "block";

  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginRight = "0";
    document.getElementById("menuOverlay").style.display = "none";
  }


  setLang(lang) {
    console.log('aaa')
    // console.log(lang.target.value)
    // console.log(lang.detail.value)
    // console.log(lang)
    this.currentLang = lang;
    var useLang = { language: this.currentLang };
    this.translate.setCurrentLangage(useLang);
    localStorage.setItem('language', lang);

    this.changeLang();
  }

  changeLang() {
    this.translate.use(this.currentLang);
  }

  checkValue(e) {
    console.log(e)
    if (e == true) {
      this.setLang('fr');
      $("#lang").text("FR");
    } else {
      this.setLang('en');
      $("#lang").text("EN");
    }
  }

  eyeclose(val) {
    if (val == 1) {
      console.log("ASDsadsad")
      $('#eye').toggleClass('fa-eye fa-eye-slash');
      var passwordField = $('#password');
      var currentType = passwordField.attr('type');

      if (currentType === 'password') {
        passwordField.attr('type', 'text');
      } else {
        passwordField.attr('type', 'password');
      }
    } else if (val == 2) {
      console.log("ASDsadsad")
      $('#eye1').toggleClass('fa-eye fa-eye-slash');
      var passwordField = $('#password1');
      var currentType = passwordField.attr('type');

      if (currentType === 'password') {
        passwordField.attr('type', 'text');
      } else {
        passwordField.attr('type', 'password');
      }
    } else {
      console.log("ASDsadsad111111111111111111111")
      $('#eye2').toggleClass('fa-eye fa-eye-slash');
      var passwordField = $('#password2');
      var currentType = passwordField.attr('type');

      if (currentType === 'password') {
        passwordField.attr('type', 'text');
      } else {
        passwordField.attr('type', 'password');
      }
    }
  }
  get f() { return this.signUpForm.controls; };
  get g() { return this.signInForm.controls; };
  get h() { return this.otpForm.controls; }
  get i() { return this.otpFormSignIn.controls; }

  getChangelang(e) {
    if (e.target.checked == true) {
      var lang = 'en';
    } else {
      lang = 'fr';
    }
    this.setLang(lang)
  }
  getBusiness() {
    return localStorage.getItem('business');
  }
  getCnamgs() {
    this.router.navigate(['/reports/cnamgs'], { state: { data: 'cnamgs' } });
  }
  getCnamgsemployee() {
    this.router.navigate(['/cnss/cnamgs'], { state: { data: 'cnamgs' } });
  }

  clearLocalStorage() {
    localStorage.removeItem('business');
    localStorage.removeItem('bank');
    localStorage.removeItem('employees');
    localStorage.removeItem('cnssForm');
    localStorage.removeItem('cnamgsForm');
    localStorage.removeItem('account_status');
  }
}
