import { Component, OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute, Route } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from '../../../../_services/api.service';
import { first } from 'rxjs/operators';
import { AnpiService } from '../../../../_services/anpi-code.service';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
declare var $: any;
@Component({
  selector: 'app-onboarding-portal',
  templateUrl: './onboarding-portal.component.html',
  styleUrls: ['./onboarding-portal.component.scss']
})
export class OnboardingPortalComponent implements OnInit {

  isBusiness = false;
  isBank = false;
  isEmployees = false;
  isCnamgs = false;
  isCnss = false;
  anpiCode: any;
  url: any;
  currentLang: any;
  showMessage: string;
  url1: any;
  accountDetails: any;
  response: any;
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private route: ActivatedRoute,
    private anpiService: AnpiService,
    private ngZone: NgZone,
    private snackBar: MatSnackBar,
    private ngxUiLoaderService: NgxUiLoaderService) {
    this.anpiCode = this.anpiService.getAnpiCode();
    console.log(" anpi code is :", this.anpiCode)
  }
  ngOnInit() {
    const data = localStorage.getItem('currentUserDetails');
    if (data) {
      this.accountDetails = JSON.parse(data);
      console.log("44",this.accountDetails)
    }
    // localStorage.setItem('business', '1');
    // localStorage.setItem('bank' ,'1');
    // localStorage.setItem('employees', '1');
    // localStorage.setItem('cnssForm', '1');
    // localStorage.setItem('cnamgsForm','1');
    this.currentLang = localStorage.getItem('language') || "fr";
    this.ngZone.run(() => {
      this.url = window.location.pathname.split('/')[1];
      console.log("38", this.url)
    });

    this.updateValue();
    this.anpiService.changeEvent.subscribe(() => {
      console.log("sdfsahdfshadfhasdghsafdghsadfhsgafdgasfdsg")
      const data = localStorage.getItem('currentUserDetails');
      if (data) {
        this.accountDetails = JSON.parse(data);
        console.log("63",this.accountDetails)
      }
      this.updateValue();
    });
  }
  updateValue() {
    if (this.accountDetails.GNI == '1') {
      console.log("business:", this.accountDetails.GNI)
      this.isBusiness = true;
    }
    if (this.accountDetails.BankDetails == '1') {
      console.log("bank:", this.accountDetails.BankDetails)
      this.isBank = true;
    }
    if (this.accountDetails.EmployeeDetails == '1') {
      console.log("employees:", this.accountDetails.EmployeeDetails)
      this.isEmployees = true;
    }
    if (this.accountDetails.cnss == '1') {
      console.log("cnssForm:", this.accountDetails.cnss)
      this.isCnss = true;
    }
    if (this.accountDetails.cnamgs == '1') {
      console.log("cnamgsForm:", this.accountDetails.cnamgs)
      this.isCnamgs = true;
    }
    // if (this.isBusiness && this.isBank && this.isCnss && this.isCnamgs && this.isEmployees) {
    //   localStorage.setItem('account_status', "1");
    // }

  }
  goToSection(val) {
    console.log(val)
    switch (val) {
      case 'business-inactive':
        {
          this.router.navigate(['/link-business']);
          break;
        }
      case 'business-active':
        {
          // localStorage.removeItem('business');
          // localStorage.removeItem('bank');
          // localStorage.removeItem('employees');
          // localStorage.removeItem('cnssForm');
          // localStorage.removeItem('cnamgsForm');
          // localStorage.removeItem('account_status');
          this.anpiService.emitEvent();
          this.isBusiness = false;
          this.isBank = false;
          this.isEmployees = false;
          this.isCnamgs = false;
          this.isCnss = false;
          break;
        }
      case 'bank-inactive':
        {
          if (this.isBusiness == true) {
            this.anpiService.emitEvent();
            this.url1 = '';
            $('#bankAccountModal').modal('show');
          } else {
            this.url1 = '/link-business';
            this.currentLang = localStorage.getItem('language') || "fr";
            if (this.currentLang == "fr") {
              console.log("82", this.currentLang)
              // this.openSnackBar('Veuillez Première Interconnexion au compte GNI de l’ANPI-Gabon ', 'Fermer');
              this.showMessage = 'Pour valider cette option, vous devez d’abord interconnecter ce compte au compte GNI de l’ANPI-Gabon '
              $('#onBoarding_modal').modal('show');
            } else {
              // this.openSnackBar('Please Import Business From GNI First', 'Close');
              this.showMessage = 'Please Import Business From GNI First';
              $('#onBoarding_modal').modal('show');
            }
          }
          break;
        }
      case 'bank-active':
        {
          // localStorage.removeItem('bank');
          // localStorage.removeItem('employees');
          // localStorage.removeItem('cnssForm');
          // localStorage.removeItem('cnamgsForm');
          // localStorage.removeItem('account_status');
          this.anpiService.emitEvent();
          this.isBank = false;
          this.isEmployees = false;
          this.isCnamgs = false;
          this.isCnss = false;
          break;
        }
      case 'add-employee-modal':
        {
          $('#addEmployeeConfirmModal').modal('hide');
          this.router.navigate(['/employees'], { state: { show_add_Employee: '1' } });
          break;
        }
      case 'add-Employee-inactive':
        {
          console.log("107", this.isBank)
          if (this.isBank == true) {
            if (this.url == 'employees') {
              $('#addEmployeeModal').modal('show');
            } else {
              $('#addEmployeeConfirmModal').modal('show');
            }
          } else {
            console.log("117")
            this.currentLang = localStorage.getItem('language') || "fr";
            if (this.isBusiness == false) {
              this.url1 = '/link-business';
              if (this.currentLang == "fr") {
                console.log("121", this.currentLang)
                // this.openSnackBar('Veuillez Première Interconnexion au compte GNI de l’ANPI-Gabon', 'Fermer');
                this.showMessage = 'Pour valider cette option, vous devez d’abord interconnecter ce compte au compte GNI de l’ANPI-Gabon';
                $('#onBoarding_modal').modal('show');
              } else {
                this.openSnackBar('Please Import Business From GNI First', 'Close');
                this.showMessage = 'Please Import Business From GNI First';
                $('#onBoarding_modal').modal('show');
              }
            } else if (this.isBank == false) {
              this.url1 = '';
              if (this.currentLang == "fr") {
                console.log("131", this.currentLang)
                // this.openSnackBar('Veuillez Première Insérer vos coordonnées de banque, microfinance ou de paiement (Mobile Money) ', 'Fermer');
                this.showMessage = 'Veuillez Première Insérer vos coordonnées de banque, microfinance ou de paiement (Mobile Money) ';
                $('#onBoarding_modal').modal('show');
              } else {
                // this.openSnackBar('Please Insert your bank, microfinance or payment details (Mobile Money) First', 'Close');
                this.showMessage = 'Please Insert your bank, microfinance or payment details (Mobile Money) First';
                $('#onBoarding_modal').modal('show');
              }
            }
          }
          break;
        }
      case 'add-Employee-active':
        {
          localStorage.removeItem('employees');
          // localStorage.removeItem('account_status');
          this.anpiService.emitEvent();
          this.isEmployees = false;
          console.log("142")
          break;
        }
      case 'cnamgs-inactive':
        {
          if (this.isBank == true) {
            this.router.navigate(['/cnamgs-form']);
          } else {
            console.log("194", this.isBusiness);
            this.currentLang = localStorage.getItem('language') || "fr";
            if (this.isBusiness == false) {
              this.url1 = '/link-business';
              if (this.currentLang == "fr") {
                console.log("169", this.currentLang)
                this.showMessage = 'Pour valider cette option, vous devez d’abord interconnecter ce compte au compte GNI de l’ANPI-Gabon';
                $('#onBoarding_modal').modal('show');
              } else {
                console.log("202");
                this.showMessage = 'Please Import Business From GNI First';
                $('#onBoarding_modal').modal('show');
              }
            } else if (this.isBank == false) {
              if (this.currentLang == "fr") {
                console.log("178", this.currentLang)
                this.showMessage = 'Veuillez Première Insérer vos coordonnées de banque, microfinance ou de paiement (Mobile Money)'
                $('#onBoarding_modal').modal('show');
              } else {
                this.showMessage = 'Please Insert your bank, microfinance or payment details (Mobile Money) First'
                $('#onBoarding_modal').modal('show');
              }
            }
          }

          break;
        }
      case 'cnamgs-active':
        {
          // localStorage.removeItem('cnamgsForm');
          // localStorage.removeItem('account_status');
          this.anpiService.emitEvent();
          this.isCnamgs = false;
          console.log("cnss-active")
          break;
        }
      case 'cnss-inactive':
        {
          console.log("cnss-inactive")
          if (this.isBank == true) {
            this.router.navigate(['/approval-form']);
          } else {
            console.log("194", this.isBusiness);
            this.currentLang = localStorage.getItem('language') || "fr";
            if (this.isBusiness == false) {
              this.url1 = '/link-business';
              if (this.currentLang == "fr") {
                console.log("169", this.currentLang)
                // this.openSnackBar('Veuillez Première Interconnexion au compte GNI de l’ANPI-Gabon', 'Fermer');
                this.showMessage = 'Pour valider cette option, vous devez d’abord interconnecter ce compte au compte GNI de l’ANPI-Gabon';
                $('#onBoarding_modal').modal('show');
              } else {
                console.log("202");
                // this.openSnackBar('Please Import Business From GNI First', 'Close');
                this.showMessage = 'Please Import Business From GNI First';
                $('#onBoarding_modal').modal('show');
              }
            } else if (this.isBank == false) {
              if (this.currentLang == "fr") {
                console.log("178", this.currentLang)
                // this.openSnackBar('Veuillez Première Insérer vos coordonnées de banque, microfinance ou de paiement (Mobile Money)', 'Fermer');
                this.showMessage = 'Veuillez Première Insérer vos coordonnées de banque, microfinance ou de paiement (Mobile Money)'
                $('#onBoarding_modal').modal('show');
              } else {
                // this.openSnackBar('Please Insert your bank, microfinance or payment details (Mobile Money) First', 'Close');
                this.showMessage = 'Please Insert your bank, microfinance or payment details (Mobile Money) First'
                $('#onBoarding_modal').modal('show');
              }
            }
            // else if(this.isEmployees== false){
            //   this.url1 = '/employees';
            //   console.log("221")
            //   if(this.currentLang =="fr"){
            //     console.log("187", this.currentLang)
            //     // this.openSnackBar('Veuillez Première Insérer les informations de votre personnel', 'Fermer');
            //     this.showMessage = 'Veuillez Première Insérer les informations de votre personnel';
            //      $('#onBoarding_modal').modal('show');
            //   }
            //   else{
            //     console.log("228")
            //     // this.openSnackBar('Please First Add Employee Details', 'Close');
            //     this.showMessage = 'Please First Add Employee Details';
            //     $('#onBoarding_modal').modal('show');
            //   }
            // }
          }

          break;
        }
      case 'cnss-active':
        {
          // localStorage.removeItem('cnssForm');
          // localStorage.removeItem('account_status');
          this.anpiService.emitEvent();
          this.isCnss = false;
          console.log("cnss-active")
          break;
        }
    }
  }
  closeModal() {
    $('#onBoarding_modal').modal('hide');
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  closeModal1() {
    $('#onBoarding_modal').modal('hide');
    setTimeout(() => {
      console.log()
      if (this.url1) {
        if (this.url1 == '/employees') {
          if (this.url == 'employees') {
            $('#addEmployeeModal').modal('show');
          } else {
            this.router.navigate([this.url1], { state: { show_add_Employee: '1' } });
          }
        } else {
          this.router.navigate([this.url1]);
        }
      } else {
        $('#bankAccountModal').modal('show');
      }
    }, 500)
  }

  showModal() {
    console.log("asdadsa")
    $('#employeeSuccessModal').modal('show');
  }
  
  // getAccountData() {
  //   this.ngxUiLoaderService.start();
  //   this.api.getAccountDetails().pipe(first())
  //     .subscribe(res => {
  //         this.ngxUiLoaderService.stop();
  //         this.response = res.results;
  //         if (res.status == 200) {
  //           console.log("347")
  //           this.accountDetails = this.response;
  //         } else {
  //           console.log("no result")
  //           this.snackBar.open(res.message, "dismiss", {
  //             duration: 2000,
  //           });
  //         }
  //       },
  //       error => {
  //         console.log("errorrrrrrrrr")
  //         this.ngxUiLoaderService.stop();
  //       });
  //   console.log("no result and nothing")
  // }

}
