import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AnpiService {
  private anpiCode: string | null = null;
  bankDetails:any;
  setAnpiCode(code: string): void {
    this.anpiCode = code;
    console.log("your anpiCode is :",code)
    console.log("your anpiCode is :",this.anpiCode)
  }

  getAnpiCode(): string | null {
    return this.anpiCode;
  }
   changeEvent: EventEmitter<any> = new EventEmitter<any>();
   accountEvent: EventEmitter<any> = new EventEmitter<any>();

  emitEvent() {
    console.log('emitEvent has called')
    this.changeEvent.emit();
  }
  emitAccountEvent(){
       console.log('accountEvent has called')
    this.accountEvent.emit();
  }



}