import { Component, OnInit, NgZone } from '@angular/core';
declare var $: any;
import { FormBuilder, FormGroup, Validators, FormArray, AbstractControl } from '@angular/forms';
import { TranslateService } from '../../../_services/translate.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
import { AnpiService } from '../../../_services/anpi-code.service';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Location } from '@angular/common';
@Component({
  selector: 'app-link-business',
  templateUrl: './link-business.component.html',
  styleUrls: ['./link-business.component.scss']
})
export class LinkBusinessComponent implements OnInit {

  currentLang: any;
  public languageForm: FormGroup;
  public anpiAccountLinkForm: FormGroup;
  public companyDetailsForm: FormGroup
  isSubmitted = false;
  response: any
  companyDetails: any;
  neighborhoodlist: any;
  regionList: any;
  countryList: any;
  idproofList: any;
  cityList: any;
  anpiCode: any;
  bankdata: any;
  moneydata: any;
  companyAddressInfo: any;
  ownerDistrictList: any;
  districtList: any;
  ownerCityList: any;
  constructor(private translate: TranslateService,
    private formBuilder: FormBuilder,
    private api: ApiService,
    private router: Router,
    private anpiService: AnpiService,
    private ngxUiLoaderService: NgxUiLoaderService,
    private snackBar: MatSnackBar,
    private ngzone: NgZone,
    private location: Location) {}
  ngOnInit() {

    this.languageForm = this.formBuilder.group({
      lang: ['', []],
    });
    this.anpiAccountLinkForm = this.formBuilder.group({
      anpi_code: ['', [Validators.required, Validators.pattern(/^\S*$/)]],
    });
    this.companyDetailsForm = this.formBuilder.group({
      anpi_no: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$")]],
      nif: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      rccm: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      vol: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      folio: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      cnss: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$")]],
      cnamgs: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$")]],
      legalstatus: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$")]],
      // company_type: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      company_name: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      telephone: ['241', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(8), Validators.maxLength(8)]],
      name: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      acrononym: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9 ]+$")]],
      city: ['', [Validators.required]],
      district: [''],
      neighborhood: ['', [Validators.required]],
      street: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9\\séèàûôêëâîç-]+$")]],
      place: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9\\séèàûôêëâîç-]+$")]],
      pincode: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      region: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9\s-]+$")]],
      locality: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$")]],
      mobile: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$"), Validators.minLength(8), Validators.maxLength(8)]],
      email: ['', [Validators.required, Validators.email]],
      website: ['', [Validators.required, , Validators.pattern("^www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,}\.")]],
      fax: ['', [Validators.required]],
      lastname: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$")]],
      firstname: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$")]],
      birthdate: ['', [Validators.required]],
      birthplace: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$")]],
      country: ['', [Validators.required]],
      nationality: ['', [Validators.required]],
      owner_region: ['', [Validators.required]],
      owner_city: ['', [Validators.required]],
      neighbor: ['', [Validators.required]],
      idproof: ['', [Validators.required]],
      idnumber: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      phoneno: ['', [Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(8), Validators.maxLength(8)]],
      postbox: ['', [Validators.required, Validators.pattern("^[0-9]*$")]],
      cnss_id: ['', [Validators.required, Validators.pattern("^[a-zA-Z0-9]+$")]],
      owner_email: ['', [Validators.required, Validators.email]],
      confirm_account: ['', [Validators.requiredTrue]],
      selected_bank_id: this.formBuilder.array([], Validators.required)
    });

    console.log(":11111111111", this.companyDetailsForm.controls['banks'])

    if (!this.api.getValidationStatus()) {
      this.api.getFormValidation(this.companyDetailsForm);
    }
    this.getCountryData();
    this.getRegionData();
    console.log("116")
    this.getNeighbourhoodList();
    this.getIdProofList()
    this.currentLang = localStorage.getItem('language') || "fr";
    this.languageForm.get('lang').setValue(this.currentLang);
    if (this.currentLang == 'fr') {
      $('.checkbox').prop('checked', false);
    } else {
      $('.checkbox').prop('checked', true);
    }
    this.getBanks();
    console.log(this.languageForm.get('lang').value);
  }

  get selectedBanks(): FormArray {
    return this.companyDetailsForm.get('selected_bank_id') as FormArray;
  }

  onSubmitAnpiCode() {
    // const anpiCodeLowercase = this.f.anpi_secret_code.value.toLowerCase();
    // if (anpiCodeLowercase == "anpi123") {
    //   this.anpiCode = anpiCodeLowercase;
    //   this.anpiService.setAnpiCode(this.anpiCode);
    //   console.log("14333333333", anpiCodeLowercase)
    //   if (anpiCodeLowercase == "anpi123") {
    //     this.setCompanyDetailsValues();
    //     this.makeInputReadOnly();
    //   }
    //   window.scrollTo({ top: 0, behavior: 'smooth' });
    //   $('#anpi-section').css('display', 'none');
    //   $('#company-details-section').css('display', 'block');
    // } else if (this.f.anpi_secret_code.value) {
    //   this.g['ac_number'].clearValidators();
    //   this.g['ac_number'].updateValueAndValidity();
    //   this.g['ac_name'].clearValidators();
    //   this.g['ac_name'].updateValueAndValidity();
    //   this.g['bank_name'].clearValidators();
    //   this.g['bank_name'].updateValueAndValidity();
    //   this.g['bank_number'].clearValidators();
    //   this.g['bank_number'].updateValueAndValidity();
    //   this.g['banks'].clearValidators();
    //   this.g['banks'].updateValueAndValidity();
    // }

    this.isSubmitted = true;
    console.log(this.isSubmitted)
    if (this.anpiAccountLinkForm.invalid) {
      console.log("196", this.anpiAccountLinkForm)
      return;
    }
    var data = this.anpiAccountLinkForm.value;
    this.ngxUiLoaderService.start();
    this.api.linkBusiness(data).pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res.results;
          console.log("148 companyDetails:", res.results)
          if (res.status == 200) {
            this.companyDetails = this.response.businessDetails;
            this.companyAddressInfo = this.response.businessAddressInfo;
            console.log("business address info", this.companyAddressInfo)
            this.bankdata = this.response.BankDetails;
            if (this.bankdata) {
              this.bankdata.forEach(item => {
                this.selectedBanks.push(this.formBuilder.control(item.bank_id));
              });
            } else {
              this.selectedBanks.clearValidators();
              this.selectedBanks.updateValueAndValidity();
            }
            this.setCompanyDetailsValues();
            this.makeInputReadOnly();
            console.log("CompanyDetails:", this.companyDetails)
            $('#bank-Section').css('display', 'none');
            window.scrollTo({ top: 0, behavior: 'smooth' });
            $('#anpi-section').css('display', 'none');
            $('#company-details-section').css('display', 'block');
          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
          this.isSubmitted = false;
        },
        error => {
          this.ngxUiLoaderService.stop();
          this.isSubmitted = false;
        });
    this.isSubmitted = false;
    // this.setCompanyDetailsValues();
    // this.makeInputReadOnly();
    // window.scrollTo({ top: 0, behavior: 'smooth' });
    // $('#anpi-section').css('display', 'none');
    // $('#company-details-section').css('display', 'block');
  }

  // getCompanyDetails() {
  //   this.api.getCompanyDetails().pipe(first())
  //     .subscribe(data => {
  //         // this.ngxUiLoaderService.stop();
  //         // this.response = data;
  //         if (this.response.status == 200) {
  //           // this.CompanyDetails = this.response.data;
  //         } else {
  //           // this.snackBar.open(res.message, "dismiss", {
  //           //      duration: 2000,
  //           //   });
  //         }
  //       },
  //       error => {
  //         // this.ngxUiLoaderService.stop();
  //       });
  // }

  handleChange(evt) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    var target = evt.target.value;
    setTimeout(function() {
      if (target == "anpi") {
        $('#create-account-section').css('display', 'none');
        $('#anpi-section').css('display', 'block');
      } else if (target == "noanpi") {
        $('#create-account-section').css('display', 'none');
        $('#noanpi-section').css('display', 'block');
      }
    }, 400);
  }
  makeInputReadOnly() {
    Object.keys(this.companyDetailsForm.controls).forEach(controlName => {
      const control = this.companyDetailsForm.get(controlName);
      if (control && control.value !== null && control.value !== undefined && control.value !== '') {
        if (controlName != 'selected_bank_id') {
          control.disable();
        }
      }
    });
  }
  openNav() {
    document.getElementById("mySidenav").style.width = "305px";
    document.getElementById("main").style.marginRight = "0px";
    document.getElementById("menuOverlay").style.display = "block";

  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("main").style.marginRight = "0";
    document.getElementById("menuOverlay").style.display = "none";
  }

  setLang(lang) {
    console.log('aaa')
    this.currentLang = lang;
    var useLang = { language: this.currentLang };
    this.translate.setCurrentLangage(useLang);
    localStorage.setItem('language', lang);
    this.changeLang();
  }

  changeLang() {
    this.translate.use(this.currentLang);
  }

  checkValue(e) {
    console.log(e)
    if (e == true) {
      this.setLang('fr');
      $("#lang").text("FR");
    } else {
      this.setLang('en');
      $("#lang").text("EN");
    }
  }

  onSubmitCompanyDetails() {
    this.isSubmitted = true;
    console.log("280", this.companyDetailsForm)
    console.log(this.selectedBanks);
    if (this.companyDetailsForm.invalid) {
      console.log("282", this.companyDetailsForm)
      return;
    }
    var data = {} as { type: string, confirm_account ? : any };
    for (const controlName in this.companyDetailsForm.controls) {
      const formControl = this.companyDetailsForm.get(controlName);
      if (formControl) {
        data[controlName] = formControl.value;
      }
    }
    if (data) {
      delete data.confirm_account;
    }
    console.log(data);
    console.log("283", data)
    this.ngxUiLoaderService.start();
    this.api.companyDetails(data).pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res;
          if (this.response.status == 200) {
            this.isSubmitted = false;
            console.log(this.companyDetails)
            this.anpiService.emitEvent();
            this.router.navigate(['/dashboard'], { state: { show_modal: '1' } });

          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });

  }
  getChangelang(e) {
    if (e.target.checked == true) {
      var lang = 'en';
    } else {
      lang = 'fr';
    }
    this.setLang(lang);
  }

  getBanks() {
    this.api.getBanks().pipe(first())
      .subscribe(data => {
        // this.bankdata = data.results.banks;
        // this.moneydata = data.results.mobilemoney;
        setTimeout(() => {
          this.ngzone.run(() => {
            console.log("fdsfdsfsdfd")
            $('#bank1').prop('checked', true);
            console.log($('#bank1'))
            console.log($('#money1'))
            $('#money1').prop('checked', true);
            // this.companyDetailsForm.controls['bank_name'].setValue(this.bankdata[0].name);
            // this.companyDetailsForm.controls['facility_name'].setValue(this.moneydata[0].name);
          });
        }, 2000)
      })
  }

  setCompanyDetailsValues() {

    // if (this.companyDetailsForm.valid) {
    //   const formData = this.companyDetailsForm.value;
    //   const BankData = {
    //     banks: formData.banks,
    //     ac_number: formData.ac_number,
    //     ac_name: formData.ac_name,
    //     bank_name: formData.bank_name,
    //     bank_number: formData.bank_number
    //   };
    //   localStorage.setItem('BankData', JSON.stringify(BankData));
    // }
    // localStorage.setItem('bank', "1");

    console.log('anpi random')
    this.companyDetailsForm.controls['region'].setValue(this.companyAddressInfo.region);
    if (this.g.region.value) {
      const region = this.g.region.value;
      console.log("370", region)
      this.changeRegion(region, 1);
    }
    this.companyDetailsForm.controls['city'].setValue(this.companyAddressInfo.city_id);
    if (this.g.city.value) {
      const city = this.g.city.value;
      console.log("370", city)
      this.changeCity(city);
    }
    this.companyDetailsForm.controls['district'].setValue(this.companyAddressInfo.district);
    this.companyDetailsForm.controls['neighborhood'].setValue(this.companyAddressInfo.neighborhood);
    this.companyDetailsForm.controls['anpi_no'].setValue(this.companyDetails.anpi_code);
    this.companyDetailsForm.controls['nif'].setValue(this.companyDetails.nif_number);
    this.companyDetailsForm.controls['rccm'].setValue(this.companyDetails.rccm_number);
    this.companyDetailsForm.controls['vol'].setValue(this.companyDetails.vol_number);
    this.companyDetailsForm.controls['folio'].setValue(this.companyDetails.folio_number);
    this.companyDetailsForm.controls['cnss'].setValue(this.companyDetails.cnss_number);
    this.companyDetailsForm.controls['cnamgs'].setValue(this.companyDetails.cnamgs_number);
    this.companyDetailsForm.controls['legalstatus'].setValue(this.companyDetails.legal_status);
    // this.companyDetailsForm.controls['company_type'].setValue(this.companyDetails.company_type);
    this.companyDetailsForm.controls['company_name'].setValue(this.companyDetails.company_name);
    this.companyDetailsForm.controls['telephone'].setValue(this.companyDetails.telephone);
    this.companyDetailsForm.controls['name'].setValue(this.companyDetails.trade_name);
    this.companyDetailsForm.controls['acrononym'].setValue(this.companyDetails.acronym);
    this.companyDetailsForm.controls['street'].setValue(this.companyAddressInfo.street);
    this.companyDetailsForm.controls['place'].setValue('');
    this.companyDetailsForm.controls['pincode'].setValue(this.companyAddressInfo.pincode);

    this.companyDetailsForm.controls['locality'].setValue(this.companyAddressInfo.locality);
    this.companyDetailsForm.controls['mobile'].setValue(this.companyDetails.mobile);
    this.companyDetailsForm.controls['email'].setValue(this.companyDetails.email);
    this.companyDetailsForm.controls['website'].setValue(this.companyDetails.website);
    this.companyDetailsForm.controls['fax'].setValue(this.companyDetails.fax);
    this.companyDetailsForm.controls['lastname'].setValue(this.companyDetails.last_name);
    this.companyDetailsForm.controls['firstname'].setValue(this.companyDetails.first_name);
    this.companyDetailsForm.controls['birthdate'].setValue(this.companyDetails.birth_date);
    this.companyDetailsForm.controls['birthplace'].setValue(this.companyDetails.birth_place);
    this.companyDetailsForm.controls['country'].setValue(this.companyDetails.country);
    this.companyDetailsForm.controls['nationality'].setValue(this.companyDetails.nationality);
    this.companyDetailsForm.controls['idproof'].setValue(this.companyDetails.id_proof);
    this.companyDetailsForm.controls['idnumber'].setValue(this.companyDetails.id_number);
    this.companyDetailsForm.controls['phoneno'].setValue(this.companyDetails.phone_no);
    this.companyDetailsForm.controls['postbox'].setValue('');
    this.companyDetailsForm.controls['neighbor'].setValue('');
    this.companyDetailsForm.controls['cnss_id'].setValue(this.companyDetails.cnss_id);
    this.companyDetailsForm.controls['owner_email'].setValue(this.companyDetails.owner_email);

    // this.companyDetailsForm.controls['banks'].setValue('11');

    // console.log(this.companyDetailsForm.controls['banks'])
    // this.companyDetailsForm.controls['ac_number'].setValue('855888500');
    // this.companyDetailsForm.controls['ac_name'].setValue('asaf');
    // this.companyDetailsForm.controls['bank_name'].setValue('BGFIBANK');
    // this.companyDetailsForm.controls['bank_number'].setValue('452169198');
    console.log('402', this.companyDetailsForm.value)
    // this.g.banks.setValue('');
    // this.g.ac_number.setValue('');
    // this.g.ac_name.setValue('');
    // this.g.bank_name.setValue('');
    // this.g.bank_number.setValue('');
    // this.g.mobile_money.setValue('');
    // this.g.mobile_money_account_name.setValue('');
    // this.g.mobile_money_account_number.setValue('');
    // this.g.facility_name.setValue('');
    // this.g.confirm_account.setValue('');

    // Object.keys(this.companyDetailsForm.controls).forEach(controlName => {

    //   const control = this.companyDetailsForm.get(controlName);

    //   if (control) {
    //     control.updateValueAndValidity();
    //   }
    // });
    // if (this.g.banks) {
    //   console.log('419')
    //   this.showSelectedGridChild();
    // }


  }

  get f() {
    return this.anpiAccountLinkForm.controls;
  }
  get g() {
    return this.companyDetailsForm.controls;
  }
  goBack() {
    // this.location.back();
    $('#company-details-section').css('display', 'none');
    $('#anpi-section').css('display', 'block');
  }
  onChange(id: string, isChecked: boolean) {

    if (isChecked) {
      console.log("465")
      this.selectedBanks.push(this.formBuilder.control(id));
    } else {
      console.log("469")
      const index = this.selectedBanks.controls.findIndex(x => x.value === id);
      this.selectedBanks.removeAt(index);
    }
  }
  changeRegion(region, val) {
    if (val == 1) {
      console.log("475")
      this.cityList = null;
      this.districtList = null;
      this.g.city.setValue(null);
      this.g.city.updateValueAndValidity();
      this.g.district.setValue(null);
      this.g.district.clearValidators();
      this.g.district.updateValueAndValidity();
    } else {
      console.log("483")
      this.ownerCityList = null;
      this.ownerDistrictList = null;
      this.g.owner_city.setValue(null);
      this.g.owner_city.updateValueAndValidity();
    }
    console.log("486", region)
    this.ngxUiLoaderService.start();
    this.api.getCityList(region).pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res;
          if (this.response.status == 200) {
            if (val == 1) {
              this.cityList = this.response.results;
            } else {
              this.ownerCityList = this.response.results;
            }
            console.log("501", this.cityList)
            console.log("502", this.ownerCityList)
          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }

  changeCity(city) {

    console.log("518")
    this.districtList = null;
    this.g.district.setValue(null);
    this.g.district.updateValueAndValidity();
    console.log("492", city)
    this.ngxUiLoaderService.start();
    this.api.getDistrictList(city).pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res;
          if (this.response.status == 200) {
            if (this.response.results.length === 0) {
              this.g.district.clearValidators();
              this.g.district.updateValueAndValidity();
            } else {
              this.g.district.setValidators([Validators.required]);
              this.g.district.updateValueAndValidity();
              this.districtList = this.response.results;
              console.log("544", this.districtList)
            }
          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }
  // changeDistrict(district) {
  //   this.g.neighborhood.setValue(null);
  //   this.g.neighborhood.updateValueAndValidity();
  //   if (this.g.city.value) {
  //     var data = {
  //       city_id: this.g.city.value,
  //       district_id: district
  //     };
  //   } else {
  //     return
  //   }
  //   console.log("521", data)
  //   this.ngxUiLoaderService.start();
  //   this.api.getNeighbourhoodList(data).pipe(first())
  //     .subscribe(res => {
  //         this.ngxUiLoaderService.stop();
  //         this.response = res;
  //         console.log("527", this.response)
  //         if (this.response.status == 200) {
  //           if (this.response.results.length === 0) {
  //             this.g.neighborhood.clearValidators();
  //             this.g.neighborhood.updateValueAndValidity();
  //           } else {
  //             this.neighborhoodlist = this.response.results;
  //           }

  //         } else {
  //           this.snackBar.open(res.message, "dismiss", {
  //             duration: 2000,
  //           });
  //         }
  //       },
  //       error => {
  //         this.ngxUiLoaderService.stop();
  //       });
  // }

  getCountryData() {
    this.ngxUiLoaderService.start();
    this.api.getCountryList().pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res;
          if (this.response.status == 200) {
            this.countryList = this.response.results;
            console.log("503", this.countryList)
          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }
  getRegionData() {
    this.ngxUiLoaderService.start();
    this.api.getRegionList().pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res;
          console.log("541", this.response)
          if (this.response.status == 200) {
            this.regionList = this.response.results;
            console.log("522", this.regionList)
          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }
  getNeighbourhoodList() {
    console.log("612")
    this.ngxUiLoaderService.start();
    this.api.getNeighbourhoodList().pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res;
          console.log("617", this.response)
          if (this.response.status == 200) {
            if (this.response.results.length === 0) {
              this.g.neighborhood.clearValidators();
              this.g.neighborhood.updateValueAndValidity();
            } else {
              this.neighborhoodlist = this.response.results;
              console.log("626", this.neighborhoodlist)
            }

          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }
  getIdProofList() {
    this.ngxUiLoaderService.start();
    this.api.getidTypeList().pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res;
          console.log("682", this.response)
          if (this.response.status == 200) {
            this.idproofList = this.response.results;
            console.log("685", this.idproofList)
          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          this.ngxUiLoaderService.stop();
        });
  }

}
