import { Component, OnInit } from '@angular/core';
declare var $: any;
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Location } from '@angular/common';
import { ApiService } from '../../../_services/api.service';
import { first } from 'rxjs/operators';
import { NgxUiLoaderService, Loader, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-cnss',
  templateUrl: './cnss.component.html',
  styleUrls: ['./cnss.component.scss']
})
export class CnssComponent implements OnInit {
  cnssList = false;
  page: any;
  response: any;
  cnssReportList: any;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private snackBar: MatSnackBar,
    private ngxUiLoaderService: NgxUiLoaderService,
    private api: ApiService, ) {
    if (this.router.getCurrentNavigation().extras.state) {
      this.page = this.router.getCurrentNavigation().extras.state.data;
      console.log("26", this.page)
    }
  }

  ngOnInit() {
    this.route.params.subscribe(
      (params: Params) => {
        console.log(params['id']);
        if (params['id']) {
          this.page = params['id'];
        }
      });
    this.getReports();
  }
  getReports() {
    this.ngxUiLoaderService.start();
    this.api.getReportlist().pipe(first())
      .subscribe(res => {
          this.ngxUiLoaderService.stop();
          this.response = res.results;
          console.log(res)
          if (res.status == 200) {
            this.cnssReportList = this.response
            console.log("50",this.cnssReportList)
          } else {
            this.snackBar.open(res.message, "dismiss", {
              duration: 2000,
            });
          }
        },
        error => {
          console.log("error")
          this.ngxUiLoaderService.stop();
        });
  }


  goBack() {
    this.location.back();
  }
  showCnssList() {
    this.cnssList = true;
    $('#reportModal').modal('hide');
  }
  moreDetails(id:any){
    return ;
  }
}
