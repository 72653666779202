<app-header></app-header>
<section class="container-fluid mb-5">
  <div class="initiale_sestion">
    <div class="head">
      <div class="title-container">
        <i (click)="goBack()" class="fa-solid fa-angle-left"></i>
        <h4 class="form-title-sm">{{'Experience' | translate}}</h4>
      </div>
    </div>
  </div>
  <div class="employee_experience mt-4">
    <div class="company_details bgStyle p45">
      <div class="grp" *ngFor="let details of profileDetails">
        <div class="data">
          <div class="logo"><img src="assets/images/company_logo_default.png" alt=""></div>
          <div class="designation_cotnainer">
            <div class="cName">
              <h4 class="text-md">{{details.company_name}}<span *ngIf="details.currently_active == 1"> ({{'Currently working here' | translate}})</span></h4>
              <h6 class="sm_text">{{details.experience}}</h6>
            </div>
            <div class="position">
  
              <div class="items">
                <div class="designation-child" *ngFor="let company of details.designation_details">
                  <h5 class="title">{{company.designation}}</h5>
                  <h6 class="sm_text">{{company.from}} - {{company.to}}</h6>
                  <h6 class="sm_text">{{'Salary' | translate}} - 50 000 FCFA</h6>
                  <h6 class="sm_text">{{company.location}}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="options">
          <div (click)="redirectTo('salary',details.company_id)"><i class="fa-solid fa-caret-right"></i> {{'Get Salary Details' | translate}}</div>
          <div routerLink="/cnss/cnamgs"><i class="fa-solid fa-caret-right"></i>{{'Get CNAMGS Details' | translate}}</div>
          <div routerLink="/cnss"><i class="fa-solid fa-caret-right"></i>{{'Get CNSS Details' | translate}}</div>
        </div>
      </div>
     <!--  <div class="grp">
        <div class="data">
          <div class="logo"><img src="assets/images/company_logo3.png" alt=""></div>
          <div>
            <div class="cName">
              <h4 class="text-md">In Flow Gabon SARL</h4>
              <h6 class="sm_text">1 {{'years' | translate}}</h6>
            </div>
            <div class="position">
              <div class="lineStyle">
                <div class="circle"></div>
                <div class="line"></div>
                <div class="circle"></div>
              </div>
              <div class="items">
                <div>
                  <h5 class="title">{{'Technical Head' | translate}}</h5>
                  <h6 class="sm_text">{{'March' | translate}} 2022 - {{'Ongoing' | translate}}</h6>
                  <h6 class="sm_text">{{'Salary' | translate}} - 50 000 FCFA</h6>
                  <h6 class="sm_text">Gabon, Libreville</h6>
                </div>
                <div>
                  <h5 class="title">{{'Developer' | translate}}</h5>
                  <h6 class="sm_text">{{'November' | translate}} 2021 - {{'February' | translate}} 2022</h6>
                  <h6 class="sm_text">{{'Salary' | translate}} - 35 000 FCFA</h6>
                  <h6 class="sm_text">Gabon, Libreville</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="options">
          <div routerLink="/salary-revise"><i class="fa-solid fa-caret-right"></i> {{'Get Salary Details' | translate}}</div>
          <div routerLink="/cnss/cnamgs"><i class="fa-solid fa-caret-right"></i>{{'Get CNAMGS Details' | translate}}</div>
          <div routerLink="/cnss"><i class="fa-solid fa-caret-right"></i>{{'Get CNSS Details' | translate}}</div>
        </div>
      </div> -->
    </div>
  </div>
</section>
<app-footer></app-footer>
<app-contact-floating></app-contact-floating>
